import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query PlanAdmissionWays($page: Int, $perPage: Int, $search: String) {
    PlanAdmissionWays(page: $page, perPage: $perPage, search: $search) {
      items {
        id
        title
        tag
        admissionWayId
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

/** Type each item in response data*/
export interface GetAllItem {
  id: number;
  title: string;
  tag?: string;
  admissionWayId: number;
}

export const responseGetAllKey = "PlanAdmissionWays";

/** Type response data */
export interface GetAllGql {
  PlanAdmissionWays: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
