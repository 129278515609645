import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BuyAsGiftCustomAppFields, GetItemGql } from "./queries/getItem";
import { formatPrice } from "utils/formatPrice";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { formatDate } from "./utils";
import RightIcon from "@mui/icons-material/KeyboardArrowRight";
import DownIcon from "@mui/icons-material/KeyboardArrowDown";

type Data = GetItemGql["Order"]["items"][0];

function Row({
  item,
  buyAsGiftProduct,
}: {
  item: Data;
  buyAsGiftProduct: BuyAsGiftCustomAppFields[0] | undefined;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {/* <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
        <TableCell component="th" scope="row" sx={{ maxWidth: "400px" }}>
          <Stack direction={"row"} alignItems={"flex-start"} gap={"10px"}>
            <Box
              component={"img"}
              src={item.imageUrl}
              alt="product"
              sx={{
                width: "48px",
                height: "48px",
                objectFit: "cover",
              }}
            />
            <Stack gap={"6px"}>
              <Typography
                component={"p"}
                sx={{
                  margin: 0,
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "black",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: "12px",
                }}
              >
                {item.id} (Ref: {item.refId}) {buyAsGiftProduct && "(Comprado como presente)"}
              </Typography>
              {buyAsGiftProduct && (
                <>
                  <Typography
                    component={"button"}
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                      padding: "4px 14px",
                      margin: 0,
                      border: "1px solid rgb(221, 229, 247)!important",
                      background: "rgb(221, 229, 247)",
                      height: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      color: "rgb(41, 83, 178)!important",
                      width: "fit-content",
                      borderRadius: "8px",
                    }}
                    onClick={() => setOpen((old) => !old)}
                  >
                    Ver destinatário do presente:{" "}
                    {open ? <DownIcon fontSize="medium" /> : <RightIcon fontSize="medium" />}
                  </Typography>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography
                        component={"p"}
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        Destinatário: <br />
                        <span>
                          <strong>Nome:</strong> {buyAsGiftProduct.name}
                        </span>
                        <br />
                        <span>
                          <strong>E-mail:</strong> {buyAsGiftProduct.email}
                        </span>
                        <br />
                        <span>
                          <strong>Data de envio da mensagem:</strong>{" "}
                          {formatDate(buyAsGiftProduct.messageSendDate).split("-")[0]}
                        </span>
                        <br />
                        <span style={{ display: "block", maxWidth: "400px" }}>
                          <strong>Mensagem:</strong> {buyAsGiftProduct.message}
                        </span>
                      </Typography>
                    </Box>
                  </Collapse>
                </>
              )}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell align="right">
          {formatPrice(item.price / 100)}/{item.measurementUnit}
        </TableCell>
        <TableCell align="right">{item.quantity}</TableCell>
        <TableCell align="right">{formatPrice((item.price * item.quantity) / 100)}</TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

export default function CustomTable({
  items,
  buyAsGiftProducts,
}: {
  items: Data[];
  buyAsGiftProducts: BuyAsGiftCustomAppFields;
}) {
  const getBuyAsGiftProduct = (ecommerceProductId: string) =>
    buyAsGiftProducts?.find((data) => data.ecommerceProductId === ecommerceProductId);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ display: "table-header-group !important" }}>
          <TableRow>
            <TableCell>Produto</TableCell>
            <TableCell align="right">Preço</TableCell>
            <TableCell align="right">Quant.</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, i) => (
            <Row item={item} key={i} buyAsGiftProduct={getBuyAsGiftProduct(item.productId)} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
