import { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import TablePrice from "./Components/TablePrice";
import useGetItem from "./hooks/useGetItem";
import useUpdate from "./hooks/useUpdate";
import { path, SchemaData } from "./hooks/types";
import { UpdateGql } from "./queries/update";
import { getCourseStepper } from "../courses/hooks/stepperCourseCreate";
import { Prices } from "../Price";
import { Inventorys } from "../Inventory";

function Update() {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>();
  const id = Number(useParams()?.id);

  if (!id) return null;
  return (
    <GenericMethods.Update<SchemaData, UpdateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath={path}
      hideDeleteButton
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={id}
      stepper={getCourseStepper(2, id)}
      afterForm={
        <MDBox paddingBottom={3}>
          <Card>
            {!state?.data?.prices ? (
              <MDBox width="100%" padding={4} justifyContent="center" display="flex">
                <MDButton color="dark" href={`${Prices.path}/new/${id}`}>
                  Criar novo Valor
                </MDButton>
              </MDBox>
            ) : (
              <Table>
                <TablePrice prices={state?.data?.prices} />
              </Table>
            )}
          </Card>
        </MDBox>
      }
      state={state}
      setState={setState}
    >
      <MDButton color="dark" href={`${Prices.path}/new/${id}`}>
        Novo Valor
      </MDButton>
      {state?.data?.eCommerceControlSKUId && (
        <MDButton
          color="dark"
          variant="outlined"
          href={`${Inventorys.path}/${state?.data?.eCommerceControlSKUId}`}
        >
          Editar Vagas
        </MDButton>
      )}
    </GenericMethods.Update>
  );
}

export default Update;
