import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const CategoryConversion = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:CategoryConversion",
    read: "read:CategoryConversion",
    list: "list:CategoryConversion",
    edit: "update:CategoryConversion",
    delete: "delete:CategoryConversion",
  },
};
