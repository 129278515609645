import { Stack, Box, FormHelperText } from "@mui/material";
import { Fragment, useEffect } from "react";
import useData from "./hooks/useData";
import SearchSelect from "components/CRPComponents/SearchSelect";
import { CustomRendererProps } from "../types";
import { withJsonFormsControlProps } from "@jsonforms/react";

const btnStyle = {
  border: "1px solid #7b809a",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

function SearchMultiple({
  data,
  handleChange,
  path,
  label,
  required,
  errors,
  enabled,
  rootSchema,
  uischema,
}: CustomRendererProps<{ id: string; name: string }[]>) {
  const { getItems, options, loading } = useData({ queryType: uischema?.options?.queryType });

  useEffect(() => {
    if (
      (data !== undefined && !Array.isArray(data)) ||
      data?.some((item) => typeof item !== typeof data[0])
    ) {
      throw new Error("Invalid params");
    }
  }, [data]);

  return (
    <Fragment>
      <Stack gap={1} margin={"10px 0"}>
        <Box
          component={"p"}
          sx={{
            fontFamily: "Roboto",
            fontSize: "0.9rem",
            fontWeight: "400",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            padding: 0,
            position: "relative",
            color: !!errors ? "#F44335" : "#7b809a",
          }}
        >
          {label}
          {required && " *"}
        </Box>
        <Stack>
          <SearchSelect
            value={data}
            onChange={(newData) => handleChange(path, newData)}
            onInputChange={(value) => getItems(value)}
            options={options}
            loading={loading}
          />
        </Stack>
      </Stack>
      {!!errors && <FormHelperText error>{errors}</FormHelperText>}
    </Fragment>
  );
}

export default withJsonFormsControlProps(SearchMultiple);
