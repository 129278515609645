import { useCallback, useEffect, useState } from "react";
import { ApiFinancialRequest } from "data/ApiFinancial";
import { GET_INVOICES, Invoice, InvoicesGql } from "../queries/OrderInvoices";
import { print } from "graphql";
import { gql } from "@apollo/client";

const useInvoices = (id: string) => {
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [loadingActions, setLoadingActions] = useState(false);

  const loadInvoices = useCallback(() => {
    setLoadingActions(true);
    ApiFinancialRequest<InvoicesGql>({
      query: GET_INVOICES,
      variables: { id },
    })
      .then((response) => {
        const items = response?.data?.data?.OrderInvoiceDetails?.invoices;
        if (items) {
          setInvoices(items);
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => setLoadingActions(false));
  }, [id]);

  useEffect(() => loadInvoices(), [id]);

  const confirmDelivery = useCallback((invoiceNumber: string) => {
    setLoadingActions(true);
    ApiFinancialRequest<void>({
      query: print(gql`
        mutation ConfirmDelivery($id: String!, $invoiceNumber: String!) {
          ConfirmDelivery(orderId: $id, invoiceNumber: $invoiceNumber) {
            receipt
          }
        }
      `),
      variables: { id, invoiceNumber },
    })
      .catch((e) => console.log(e))
      .finally(() => {
        loadInvoices();
        setLoadingActions(false);
      });
  }, []);

  return {
    invoices,
    loadingActions,
    loadInvoices,
    confirmDelivery,
  };
};

export default useInvoices;
