import { useEffect, useState } from "react";
import useRoles from "./useRoles";
import schemaJson from "../schema/SchemaJson.json";
import SchemaJsonEdit from "../schema/SchemaJsonEdit.json";

export default function useTemplate(create?: boolean) {
  const [templateSchema, setTemplateSchema] = useState<string>();
  const { roles } = useRoles();

  useEffect(() => {
    if (!roles) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(create ? schemaJson : SchemaJsonEdit));

    schemaJsonParsed.properties.roleId = {
      ...schemaJsonParsed.properties.roleId,
      ...(roles.length
        ? {
            oneOf: [
              ...roles.map(({ name, id }) => ({
                title: name,
                const: id,
              })),
            ],
          }
        : { readOnly: true }),
    };

    setTemplateSchema(JSON.stringify(schemaJsonParsed));
  }, [roles]);

  return {
    templateSchema,
  };
}
