import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Blog($id: Int) {
    Blog(id: $id) {
      id
      title
      name
      description
      contentHtml
      keyWords
      publicVisibility
      autor
      publishOn
      expiresIn
      status
      slug
      mediaFileDesktop {
        id
      }
      mediaFileMobile {
        id
      }
      allowcomments
      tags {
        id
      }
      relatedProductsIds
      categoryBlog {
        slug
      }
    }
  }
`);

export interface GetItemGql {
  Blog: {
    id: number;
    title: string;
    name: string;
    description: string;
    contentHtml: string;
    keyWords: string;
    publicVisibility: boolean;
    autor: string;
    publishOn: string;
    expiresIn: string;
    status: string;
    slug: string;
    allowcomments: boolean;
    mediaFileDesktop: {
      id: number;
    };
    mediaFileMobile: {
      id: number;
    };
    tags: Array<{
      id: number;
    }>;
    relatedProductsIds: number[];
    categoryBlog: {
      slug: string;
    };
  };
}
