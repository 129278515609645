import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql, Price, responseGetAllKey } from "./queries/getAll";
import { path } from "./hooks/types";
import IconMenu from "components/CRPComponents/Menu";
import { Inventorys } from "../Inventory";
import { InvestmentInfos } from "../InvestmentInfo";
import { Prices } from "../Price";
import { PlanAdmissionWays } from "../PlansAdmissionWay";
import { AdmissionWays } from "../AdmissionWays";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import TablePrice from "./Components/TablePrice";
// import CreateModal from "./CreateModal";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          active: true,
        }}
        path={path}
        query={GET_ALL_QUERY}
        formatRows={(data) => data[responseGetAllKey].items}
        formatPagination={(data) => data[responseGetAllKey].paginationInfo}
        ApiSetData={ApiFinancialWithSetData}
        // buttonCreate={<CreateModal path={path} title="teste" />}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => <LinkCell href={`${path}/${value}`} value={value} />,
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },

          {
            Header: "Id Produto Vtex",
            accessor: "eCommerceProductId",
            Cell: ({ value }: any) => <DefaultCell value={value ?? ""} />,
          },
          {
            Header: "Orientações Financeiras",
            accessor: "investmentInfoId",
            Cell: ({ value, row }: any) => (
              <LinkCell
                value={value ? value : "Atribuir"}
                href={value ? `${InvestmentInfos.path}/${value}` : `${path}/${row.original.id}`}
              />
            ),
          },
          {
            Header: "Categoria",
            accessor: "category.name",
            Cell: ({ value }: any) => <DefaultCell value={value ?? ""} />,
          },
          {
            Header: "Departamento",
            accessor: "category.department.name",
            Cell: ({ value }: any) => <DefaultCell value={value ?? ""} />,
          },
          {
            Header: "Preços",
            accessor: "prices",
            Cell: ({ value, row, ...prop }: any) => (
              <TablePrice
                prices={value}
                PricesPath={Prices.path}
                AdmissionWaysPath={AdmissionWays.path}
                PlanAdmissionWaysPath={PlanAdmissionWays.path}
              />
            ),
          },
          {
            Header: "",
            accessor: "eCommerceControlSKUId",
            Cell: ({ row, value }: any) => (
              <IconMenu
                options={[
                  {
                    key: "1",
                    children: <Link to={`${Prices.path}/new/${row.original.id}`}>Novo Valor</Link>,
                  },
                  {
                    key: "2",
                    children: value && (
                      <Link children="Editar Vagas" to={`${Inventorys.path}/${value}`} />
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
