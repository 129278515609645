import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import useTemplatesInvestments from "../../TemplateInvestments/hooks/useTemplatesInvestiments";
import { schemaJson as schemaJsonAdmissionWay } from "../schema/schemaJson";
import UISchemaCreate from "../schema/UISchema.json";
import UISchemaEdit from "../schema/UISchemaEdit.json";

export default function useTemplate(create?: boolean) {
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const { TemplatesInvestments } = useTemplatesInvestments();

  useEffect(() => {
    if (!create) {
      setTemplate({
        schemaJson: schemaJsonAdmissionWay,
        UISchema: UISchemaEdit,
      });
      return;
    }
    if (!TemplatesInvestments?.length) return;

    const templateInvestmentName = {
      type: "string",
      oneOf: [
        ...TemplatesInvestments.map(({ name }) => ({
          title: name,
          const: name,
        })),
      ],
    };
    setTemplate({
      schemaJson: {
        ...schemaJsonAdmissionWay,
        properties: { ...schemaJsonAdmissionWay.properties, templateInvestmentName },
      },
      UISchema: UISchemaCreate,
    });
  }, [TemplatesInvestments]);

  return {
    template,
  };
}
