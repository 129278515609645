import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query GlobalsFinancial($key: String!) {
    GlobalFinancial(key: $key) {
      key
      value
      updatedAt
      description
      createdAt
    }
  }
`);

export interface GetItemGql {
  GlobalFinancial: {
    key: string;
    value: string;
    updatedAt: string;
    description: string;
    createdAt: string;
  };
}
