import StatusCell from "../../components/StatusCell";

export function getStatusCell(value: string) {
  switch (value) {
    case "ACTIVE":
      return <StatusCell icon="done" color="success" status="ATIVO" />;
    case "DRAFT":
      return <StatusCell icon="replay" color="dark" status="RASCUNHO" />;
    case "INACTIVE":
      return <StatusCell icon="close" color="error" status="DESATIVADO" />;
    default:
      return <StatusCell icon="close" color="error" status="DESATIVADO" />;
  }
}
