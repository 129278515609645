import useCreate from "./hooks/useCreate";
import useTemplate from "./hooks/useTemplate";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";

function Create() {
  const { template } = useTemplate(true);

  if (!template) return null;
  return (
    <GenericMethods.Create<SchemaData, CreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath="/config"
      useCreate={useCreate}
      template={template}
    />
  );
}

export default Create;
