import List from "./List";

export const ReviewAndRates = {
  List: List,
  Permissions: {
    create: "create:FeedbackProduct",
    update: "update:FeedbackProduct",
    list: "list:FeedbackProduct",
    read: "read:FeedbackProduct",
    delete: "delete:FeedbackProduct",
  },
};
