import { ApiCourseRequest } from "data/queries/ApiCourse";
import { UPDATE_QUERY, UpdateGql } from "../queries/UpsertFacultyMember";
import { CreateFacultyMemberInput } from "./convertObjectToUpsertInput";
import { removeNullValues } from "utils/removeNullValues";

export const upsertFacultyMember = async (
  data: CreateFacultyMemberInput
): Promise<{ id: number }> => {
  const res = await ApiCourseRequest<UpdateGql>({
    query: UPDATE_QUERY,
    variables: {
      data: removeNullValues(data),
    },
  });
  return res?.data?.data?.upsertFacultyMember;
};
