export const schemaJsonFaqs = {
  type: "object",
  properties: {
    details: {
      type: "object",
      required: ["question", "answerHtml"],
      properties: {
        question: {
          title: "Pergunta",
          type: "string",
          description: "Adicione a Pergunta",
        },
        answerHtml: {
          title: "Resposta",
          type: "string",
          description: "Adicione a resposta da pergunta.",
        },
        status: {
          title: "Status",
          oneOf: [
            {
              const: "ACTIVE",
              title: "ATIVO",
            },
            {
              const: "DRAFT",
              title: "RASCUNHO",
            },
            {
              const: "INACTIVE",
              title: "DESATIVADO",
            },
          ],
          description: "Selecione o Status",
        },
        student: {
          title: "Aluno",
          type: "boolean",
          description: "Pergunta para Aluno?",
        },
        sort: {
          type: "integer",
          description: "Ordem",
        },
      },
    },
  },
};

export const uischemaJsonFaqs = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Informações relacionadas a Perguntas Frequentes",
      type: "Group",
      elements: [
        {
          type: "Control",
          scope: "#/properties/details",
          options: {
            detail: {
              type: "VerticalLayout",
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/question",
                      label: "Pergunta",
                      options: {
                        showUnfocusedDescription: true,
                      },
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/answerHtml",
                      label: "Resposta",
                      options: {
                        showUnfocusedDescription: true,
                        htmlEditor: true,
                        basic: false,
                      },
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/status",
                      options: {
                        showUnfocusedDescription: true,
                      },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/student",
                      options: {
                        showUnfocusedDescription: true,
                        multi: true,
                      },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/sort",
                      label: "Ordem",
                      options: {
                        showUnfocusedDescription: true,
                        multi: true,
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
  ],
};
