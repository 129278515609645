import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentForm } from "components/CRPComponents/CRPAddBanner.style";
import {
  FormBanner,
  ErrorBannerInfos,
  BannerInfos,
} from "components/CRPComponents/Forms/FormBanner";
import { MDSnackbar, MDSnackbarProps } from "components/MDSnackbar";
import { UpdateBannerInput } from "types/CMS/Banner";
import { validateSyncClass } from "components/CRPComponents/Forms/utils";
import { ApiCMSRequest } from "data/ApiCMS";
import {
  MUTATION_UPDATE_BANNER,
  ResponseUpdateBanner,
} from "data/queries/ApiCMS/Banner/MutationUpdateBanner";
import { QUERY_BANNER, ResponseBanner } from "data/queries/ApiCMS/Banner/QueryBanner";
import ButtonDeleteBanner from "./ButtonDeleteBanner";

function FormUpdateBanner({
  titleBox,
  idSite,
  path = "",
  bannerId,
  inicialEditing = false,
}: FormUpdateBannerProps) {
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });

  const navigate = useNavigate();
  const [editing, setEditing] = useState(inicialEditing);
  const [banner, setBanner] = useState<BannerInfos>();

  const handleCloseSnackBar = () => {
    setSnackBarInfo(({ redirectId, props }) => {
      if (redirectId) setEditing(false);
      if (redirectId === -1) navigate(`${path}/banners`);
      return { ...props, open: false, autoHideDuration: undefined };
    });
    setLoading(false);
  };

  const [errors, setErrors] = useState<ErrorBannerInfos>({});
  const [loading, setLoading] = useState(false);

  const isValid = (data: any): boolean => {
    const errors = validateSyncClass(UpdateBannerInput, data);
    console.log({ errors });
    if (!Object?.keys(errors)?.length) {
      setErrors({});
      return true;
    }

    setErrors(errors);
    return false;
  };

  const handleIsValid = async (data: UpdateBannerInput) => {
    const response = await ApiCMSRequest<ResponseUpdateBanner>({
      query: MUTATION_UPDATE_BANNER,
      variables: { data },
    });
    if (response?.data?.errors) {
      throw response.data.errors;
    }
    return response.data.data.updateBanner;
  };

  const handleSaveBanner = async (data: BannerInfos) => {
    const banner: UpdateBannerInput = {
      id: bannerId,
      name: data?.name,
      title: data.title,
      description: data.description,
      url: data.url,
      targetBlank: data.targetBlank,
      active: data.active,
      sort: data.sort,
      idSite: idSite,
      idMediaFileDesktop: data?.mediaFileDesktop?.id,
      idMediaFileMobile: data?.mediaFileMobile?.id,
      locale: data?.locale ?? null,
    };
    if (!isValid(banner)) {
      setSnackBarInfo({
        color: "error",
        title: "Dados inválidos",
        content: "Verifique o formulário, algum dado não é válido!",
      });
      return;
    }

    //isValid === true
    try {
      setLoading(true);
      const response = await handleIsValid(banner);
      setSnackBarInfo({
        open: true,
        color: "success",
        icon: "check",
        title: "Banner editado com sucesso",
        redirectId: response.id,
      });
      setBanner(response);
      return;
    } catch (e: any) {
      setLoading(false);
      const apolloErrors = e?.networkError?.result?.errors?.map(
        ({ message }: { message: string }) => message
      );

      console.log(e?.networkError?.result?.errors);
      const messageError: string = apolloErrors?.join(", ") || e?.message || "Ops. Algo Deu errado";
      setErrors((prev) => ({ ...prev, form: messageError }));
      setSnackBarInfo({
        open: true,
        color: "error",
        title: "Ops, algo deu errado",
        content: messageError,
      });
    }
  };

  const handleClickButton = (data: BannerInfos) => {
    if (editing) return handleSaveBanner(data);
    //TODO see permitions to edit
    setEditing(true);
  };

  const handleDeleteError = (e: Error) => {
    setSnackBarInfo({
      open: true,
      color: "error",
      title: "Ops, algo deu errado",
      content: e?.message || "Ops. Algo Deu errado",
      autoHideDuration: null,
    });
  };

  const handleDeleteSuccess = () => {
    setEditing(false);
    setSnackBarInfo({
      open: true,
      color: "success",
      icon: "check",
      autoHideDuration: null,
      title: "Banner deletado com sucesso",
      redirectId: -1,
    });
    navigate(`${path}/banners`);
  };

  useEffect(() => {
    setLoading(true);
    ApiCMSRequest<ResponseBanner>({
      query: QUERY_BANNER,
      variables: {
        bannerId,
      },
    })
      .then((response) => {
        if (!response.data.data.banner) {
          if (response.data?.errors) throw response.data?.errors;
          throw response?.data || "Ops! algo deu errado";
        }
        setBanner(response.data.data.banner);
        console.log(response.data.data.banner);
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        const apolloErrors = e?.networkError?.result?.errors?.map(
          ({ message }: { message: string }) => message
        );

        console.log(e?.networkError?.result?.errors);
        const messageError: string =
          apolloErrors?.join(", ") || e?.message || "Ops. Algo Deu errado";
        setErrors((prev) => ({ ...prev, form: messageError }));
        setSnackBarInfo({
          open: true,
          color: "error",
          title: "Ops, algo deu errado",
          content: messageError,
          autoHideDuration: null,
          redirectId: -1,
        });
      });
  }, [bannerId]);

  return (
    <ContentForm>
      <FormBanner
        titleBox={titleBox}
        idSite={idSite}
        onClickButton={handleClickButton}
        labelButton={editing ? "Salvar" : "Editar"}
        errors={errors}
        loading={loading}
        disabled={loading || !banner?.id || !editing}
        disabledButton={loading || !banner?.id || !!snackBarInfo?.redirectId}
        initialValues={banner}
      >
        {editing && (
          <ButtonDeleteBanner
            deleteBannerId={bannerId}
            onDeleteError={handleDeleteError}
            onDeleteSuccess={handleDeleteSuccess}
            disabled={!!snackBarInfo?.redirectId}
          />
        )}
      </FormBanner>
      <MDSnackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        {...snackBarInfo}
        title={snackBarInfo.title}
        close={handleCloseSnackBar}
        onClose={snackBarInfo?.autoHideDuration === null ? undefined : handleCloseSnackBar}
      />
    </ContentForm>
  );
}

export default FormUpdateBanner;

export interface FormUpdateBannerProps {
  titleBox: String;
  idSite: number;
  /**
   * navigate(`:path}/banners/:id`)
   */
  path?: string;
  bannerId: number;
  inicialEditing?: boolean;
}
