import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Coupon = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    archive: "archive:Coupon",
    create: "create:Coupon",
    read: "read:Coupon",
    list: "list:Coupon",
    update: "update:Coupon",
    delete: "delete:Coupon",
  },
};
