import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Redirects = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    list: "list:Redirect",
    create: "create:Redirect",
    read: "read:Redirect",
    update: "update:Redirect",
    delete: "delete:Redirect",
  },
};
