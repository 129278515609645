import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCMSRequest } from "data/ApiCMS";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query CalendarModalities {
    CalendarModalities(perPage: -1) {
      items {
        id
        title
      }
    }
  }
`);

export interface QueryGql {
  CalendarModalities: {
    items: Array<{
      id: number;
      title: string;
    }>;
  };
}

export default function useCalendarModalities() {
  const [modalities, setModalities] = useState<null | QueryGql["CalendarModalities"]["items"]>(
    null
  );

  useEffect(() => {
    ApiCMSRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => setModalities(data.data.CalendarModalities.items));
  }, []);

  return {
    modalities,
  };
}
