import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";

import MDInput from "components/MDInput";
import Col from "components/Col";
import Row from "components/Row";
import { getImageCmsURL } from "data/ApiCMS";
import { InputAdornment, Link } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";

interface Midia {
  id: number;
  name: string;
  path: string;
  type: string;
  size?: number;
  uploadedAt?: Date | string;
}

interface MidiaViewWithDeleteProps {
  midia?: Midia;
}
export function MidiaViewWithDelete({ midia }: MidiaViewWithDeleteProps) {
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [titleCopy, setTitleCopy] = useState("");
  const handleCopy = () => {
    const copied = copy(getImageCmsURL(midia?.path));
    if (copied) {
      setTitleCopy("Link copiado com sucesso");
    }
  };

  return (
    <Col pt={4}>
      <MDInput value={midia?.name} label="Nome" />
      <MDInput value={midia?.path} label="Caminho" />
      <MDInput
        value={getImageCmsURL(midia?.path)}
        label="URL"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Link
                // component={IconButton}
                // aria-label="Copiar URL da imagem"
                href={getImageCmsURL(midia?.path)}
                target="_blank"
                // edge="start"
                fontSize="1.5rem"
                p={0}
              >
                <LinkIcon />
              </Link>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Copiar URL da imagem"
                onClick={handleCopy}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={titleCopy}
        open={!!titleCopy}
        autoHideDuration={5000}
        onClose={() => setTitleCopy("")}
        close={() => setTitleCopy("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />

      <Row justifyContent="space-between" width="100%" gap={2}>
        <MDInput value={midia?.id} label="ID" />
        <MDInput value={midia?.size} label="Tamanho" />
        <MDInput value={midia?.type} label="Tipo" />
      </Row>
      <MDInput
        value={!midia?.uploadedAt ? "" : new Date(midia?.uploadedAt).toLocaleString()}
        label="Data de Upload"
      />
    </Col>
  );
}
