import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_INVOICES = print(gql`
  query OrderInvoiceDetails($id: String!) {
    OrderInvoiceDetails(orderId: $id) {
      invoices {
        invoiceNumber
        issuanceDate
        invoiceValue
        courierStatus {
          finished
        }
      }
    }
  }
`);

export interface Invoice {
  invoiceNumber: string;
  issuanceDate: string;
  invoiceValue: number;
  courierStatus: {
    finished: boolean;
  };
}

export interface InvoicesGql {
  OrderInvoiceDetails: {
    invoices: Invoice[];
  };
}
