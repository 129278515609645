import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateCourse($data: UpdateCourseInput!) {
    updateCourse(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateCourse: {
    id: number;
  };
}
