import { DataRequest } from "components/CRPComponents/Tables/GenericTable/types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { groupBy } from "lodash";
import { Fragment, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { CardPaymentMethod } from "./components/CardPaymentMethod";
import { GET_ALL_QUERY, GetAllGql, PaymentMethod } from "./queries/getAll";

function List() {
  const [groupedPayments, setGroupedPayments] = useState<any>([]);
  const [{ data }, setData] = useState<DataRequest>({
    loading: true,
  });

  useEffect(() => {
    ApiFinancialWithSetData({
      query: GET_ALL_QUERY,
      setData,
    });
  }, []);

  useEffect(() => {
    if ((data as GetAllGql)?.PaymentMethods.length > 0) {
      const groupedPayments = groupBy((data as GetAllGql).PaymentMethods, "paymentSystem.name");

      setGroupedPayments(groupedPayments);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDButton variant="gradient" color="info" href={`/ecommerce/metodos-de-pagamentos/new`}>
        Novo
      </MDButton>

      {Object.entries(groupedPayments).map(([key, values]: any) => (
        <Fragment key={key}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <MDBox>
              <MDTypography variant="h5" gutterBottom>
                {key}
              </MDTypography>
            </MDBox>

            <MDBox>
              <MDTypography
                component="span"
                variant="button"
                bgColor="#fff"
                border={1}
                borderRadius="25px"
                p={1}
                px={2}
              >
                id: {values[0].paymentSystem.id}
              </MDTypography>
            </MDBox>
          </MDBox>

          <Grid container spacing={3}>
            {values.map((value: PaymentMethod) => (
              <Grid key={value.id} item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <CardPaymentMethod paymentMethod={value} />
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      ))}
      <Footer />
    </DashboardLayout>
  );
}

export default List;
