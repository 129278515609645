import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Events = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:Event",
    read: "read:Event",
    list: "list:Event",
    update: "update:Event",
    delete: "delete:Event",
  },
};
