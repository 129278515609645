import List from "./List";

export const FormCancel = {
  List: List,
  Permissions: {
    read: "read:Form",
    list: "list:Form",
    delete: "delete:Form",
    update: "update:Form-ALL",
  },
};
