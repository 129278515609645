import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Modality($id: Int!) {
    Modality(id: $id) {
      id
      name
      description
      active
    }
  }
`);

export interface GetItemGql {
  Modality: {
    id: number;
    name: string;
    description: string;
    active: boolean;
  };
}
