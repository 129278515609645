import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateFacultyMember($data: UpdateFacultyMemberInput!) {
    updateFacultyMember(data: $data) {
      id
      name
      description
      photoUrl
      active
      title
      email
      lattesUrl
      idInternMember
      isCoordinator
      createdAt
      updatedAt
    }
  }
`);

export interface UpdateGql {
  updateFacultyMember: {
    id: number;
    name: string;
    description: string;
    photoUrl: string;
    title: string;
    email: string;
    lattesUrl: string;
    idInternMember: string;
    createdAt: string;
    updatedAt: string;
    isCoordinator: boolean;
    active: boolean;
  };
}
