import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpsertFacultyMember($data: CreateFacultyMemberInput!) {
    upsertFacultyMember(data: $data) {
      id
    }
  }
`);

export const responseUpdateKey = "upsertFacultyMember";
export interface UpdateGql {
  upsertFacultyMember: {
    id: number;
  };
}
