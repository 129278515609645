import useCreate from "./hooks/useCreate";
import schemaJson from "./schema/SchemaJson.json";
import UISchemaJson from "./schema/UISchema.json";
import { GenericMethods } from "../GenericMethods";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import useCategories from "./hooks/useCategories";

function Create() {
  const { categories } = useCategories();
  const [template, setTemplate] = useState<ShemaJsonTemplate | null>(null);

  useEffect(() => {
    if (!categories) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.relatedBlogCategorySlug = {
      ...schemaJsonParsed.properties.relatedBlogCategorySlug,
      oneOf: categories.map(({ slug, name }) => ({
        const: slug,
        title: name,
      })),
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });
  }, [categories]);

  if (!template) return null;

  return (
    <GenericMethods.Create<SchemaData, CreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      useCreate={useCreate}
      template={template}
    />
  );
}

export default Create;
