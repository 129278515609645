import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query EducationalGroup($id: Int!) {
    EducationalGroup(id: $id) {
      id
      title
      updatedAt
      createdAt
      groupBy
    }
  }
`);

export interface GetItemGql {
  EducationalGroup: {
    id: number;
    title: string;
    groupBy: string;
    updatedAt: string;
    createdAt: string;
  };
}
