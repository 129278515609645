import { print } from "graphql";
import { gql } from "@apollo/client";
import { User } from "../UserData/QueryGetMe";

export const MUTATION_LOGIN = print(gql`
  mutation Login($password: String!, $userIdentificator: String!) {
    login(password: $password, userIdentificator: $userIdentificator) {
      accessToken
      user {
        id
        firstName
        email
        role {
          id
          name
          description
          permissions {
            id
            name
            description
          }
        }
        permissions {
          id
          name
          description
        }
        lastName
        userName
        phone
        cpf
        rg
        birthDate
        # residentialPhone
        active
        academicEmail
      }
    }
  }
`);

export type ResponseLogin = {
  login: {
    accessToken: string;
    user: User;
  };
};
