export const getKeyNumber = (key: string): string => {
  // Expressão regular para verificar o padrão "1- Nome Grupo de Curso*"
  var padrao1 = /^(\d{1,5})-\s.+$/;

  // Expressão regular para verificar o padrão "Nome_Grupo_de_Curso_1"
  var padrao2 = /^.+_(\d{1,5})$/;

  if (padrao1.test(key)) {
    return key.match(padrao1)[1];
  }
  if (padrao2.test(key)) {
    return key.match(padrao2)[1];
  }

  return key;
};

const fixString = (value: any) => {
  if (typeof value !== "string") return value;
  return value.replace(/^\s+/, "");
};

const convertKeys = (data: Record<string, string>): Record<string, string> => {
  const responseData: Record<string, string> = {};

  Object.entries(data).map(([key, value]) => (responseData[getKeyNumber(key)] = fixString(value)));

  return responseData;
};

const JsonParseOrUndefined = (data?: string) => {
  try {
    const json = JSON.parse(data);
    return json;
  } catch (error) {
    if (data) console.log({ FailJsonParseOrUndefined: data, error });
    return undefined;
  }
};

export const slugfy = (slug: string): string => {
  return slug.trim().toLocaleLowerCase().replaceAll("/", "-");
};

export const editEachDataRead = (data: Record<string, string>): Record<string, string> => {
  const clone = { ...data };
  // for (const [key, value] of Object.entries(clone)) {
  //   const keyNumber = getKeyNumber(key);
  //   if (keyNumber === "7" || keyNumber === "2" || keyNumber === "31") {
  //     clone[key] = slugfy(value);
  //   }
  // }
  return clone;
};

export const convertObjectToUpsertInput = (data: Record<string, string>): CreatePriceInput => {
  const convertedData = convertKeys(data);
  console.log({ convertedData });
  return {
    courseId: Number(convertedData?.["1"]) || undefined,
    courseSlug: String(convertedData?.["2"]) || undefined,
    eCommerceProductId: Number(convertedData?.["3"]) || undefined,
    active:
      String(convertedData?.["4"]) === "true" || String(convertedData?.["4"]) === "VERDADEIRO",
    value: Number(convertedData?.["5"]),
    valueWithoutDiscout: Number(convertedData?.["6"]) || undefined,
    installments: Number(convertedData?.["7"]) || 1,
    eCommerceSkuId: Number(convertedData?.["8"]) || undefined,
    eCommerceSkuName: String(convertedData?.["9"]) || undefined,
    // eCommerceLastUpdate: undefined,
    // ? undefined
    // : new Date(convertedData?.["10"]) || undefined,
    planAdmissionWay: {
      title: String(convertedData?.["11"]),
      tag: String(convertedData?.["12"]),
      admissionWay: {
        name: String(convertedData?.["13"]),
        content: String(convertedData?.["14"]),
      },
    },
  };
};

export type CreatePriceInput = {
  active?: boolean;
  value?: number;
  installments: number;
  valueWithoutDiscout?: number;
  eCommerceSkuId?: number;
  eCommerceSkuName?: string;
  eCommerceLastUpdate?: Date;
  courseId?: number;
  eCommerceProductId?: number;
  courseSlug?: string;
  planAdmissionWay: {
    title: string;
    tag?: string;
    id?: string;
    admissionWay: {
      name: string;
      content?: string;
      id?: string;
    };
  };
};
