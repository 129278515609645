import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LongTextCell from "components/CRPComponents/Tables/components/LongTextCell";
// import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ApiGetCouseERPWithSetData } from "data/apiGetCourseERP";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { path } from "./hooks/types";

function List() {
  const [initalVariables, setInitalVariables] = useState({
    page: 1,
    perPage: 10,
    search: null,
    isValityCourse: null,
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={initalVariables}
        childrenFilters={
          <MDInput
            value={String(initalVariables?.isValityCourse)}
            label="Pode Criar Curso"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInitalVariables((old) => {
                switch (e.target.value) {
                  case "true":
                    return {
                      ...old,
                      page: 1,
                      isValityCourse: true,
                    };
                  case "false":
                    return {
                      ...old,
                      page: 1,
                      isValityCourse: false,
                    };
                  case "null":
                  default:
                    return {
                      ...old,
                      page: 1,
                      isValityCourse: null,
                    };
                }
              })
            }
            sx={{ width: "200px" }}
            select
          >
            <MenuItem value={"null"}>Todos</MenuItem>
            <MenuItem value={"true"}>Sim</MenuItem>
            <MenuItem value={"false"}>Não</MenuItem>
          </MDInput>
        }
        useDefaultFilter={false}
        path={path}
        createAddPath={"sync-totvs"}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.ProviderHabilitations.items}
        formatPagination={(data) => data.ProviderHabilitations.paginationInfo}
        title="Sync Totvs"
        ApiSetData={ApiGetCouseERPWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Pode Criar Curso",
            accessor: "valityCourseErrors",
            Cell: ({ value }: any) => (
              <Tooltip title={value?.join(", ")} arrow placement="right-start">
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  color={value?.length === 0 ? "success" : "error"}
                >
                  {value?.length === 0 ? "Sim" : "Não"}
                </MDTypography>
              </Tooltip>
            ),
          },
          {
            Header: "Nome Habilitação",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "COD CURSO",
            accessor: "idCourse",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "ID Matriz Aplicada",
            accessor: "idAppliedMatrice",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "COD HABILITACAO",
            accessor: "idHabilitation",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "banco de dados",
            accessor: "databaseName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Cod Coligada",
            accessor: "idColligate",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Nome Parceiro",
            accessor: "providerCourse.colligate.partnerVisibleName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "ID Tipo de Curso",
            accessor: "idCourseType",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Slug Categoria",
            accessor: "category",
            Cell: ({ value }: any) => <DefaultCell value={value?.categorySlug || ""} />,
          },
          {
            Header: "ID Filial",
            accessor: "idBranch",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Nome Unidade",
            accessor: "educationalUnit",
            Cell: ({ value }: any) => (
              <DefaultCell value={value?.educationalUnitVisibleName || ""} />
            ),
          },
          {
            Header: "ID Grade",
            accessor: "idGrade",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "ID Turno",
            accessor: "idShift",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Ativo no Totvs",
            accessor: "activeOnProvider",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Carga Horária do Curso",
            accessor: "courseLoadHours",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Total de Créditos",
            accessor: "totalCredits",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Máximo de Créditos por Período",
            accessor: "maxCredPeriod",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Mínimo de Créditos por Período",
            accessor: "minCredPeriod",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Data de Modificação Matriz Curricular",
            accessor: "recModifiedOn_CM",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Exclui Pagamento com Cartão de Crédito",
            accessor: "excludesCreditCardPayment",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Descrição",
            accessor: "description",
            Cell: ({ value }: any) => <LongTextCell value={value} maxLength={100} />,
          },
          {
            Header: "Decreto",
            accessor: "decree",
            Cell: ({ value }: any) => <LongTextCell value={value} maxLength={100} />,
          },
          {
            Header: "ID Tipo de Habilitação",
            accessor: "idHabilitationType",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Data de Modificação Totvs",
            accessor: "recModifiedOn",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "URL da Imagem",
            accessor: "imageURL",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Slug",
            accessor: "slug",
            Cell: ({ value, row }: any) =>
              !value ? (
                <Tooltip
                  title={`SUGESTÃO DE SLUG: ${row?.original?.slugSugestion}`}
                  arrow
                  placement="right-start"
                >
                  <MDTypography variant="caption" fontWeight="medium" color="text">
                    {row?.original?.slugSugestion ? "..." : ""}
                  </MDTypography>
                </Tooltip>
              ) : (
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  {value}
                </MDTypography>
              ),
          },
          {
            Header: "Slug do Grupo de Curso",
            accessor: "slugCourseGroup",
            Cell: ({ value, row }: any) =>
              !value ? (
                <Tooltip
                  title={`SUGESTÃO DE SLUG: ${row?.original?.slugCourseGroupSugestion}`}
                  arrow
                  placement="right-start"
                >
                  <MDTypography variant="caption" fontWeight="medium" color="text">
                    {row?.original?.slugCourseGroupSugestion ? "..." : ""}
                  </MDTypography>
                </Tooltip>
              ) : (
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  {value}
                </MDTypography>
              ),
          },
          {
            Header: "Criado em",
            accessor: "createdAt",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Atualizado em",
            accessor: "updatedAt",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Id Curso Api",
            accessor: "providerCourse.id",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Nome Curso",
            accessor: "providerCourse.name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "ID Curso Totvs",
            accessor: "providerCourse.idCourse",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "ID Escola",
            accessor: "providerCourse.idSchool",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Modificado em",
            accessor: "providerCourse.recModifiedOn",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Descrição Curta Curso",
            accessor: "providerCourse.descriptionShort",
            Cell: ({ value }: any) => <LongTextCell value={value} maxLength={100} />,
          },
          {
            Header: "Presencial ou a Distância",
            accessor: "providerCourse.inPersonOrDistance",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "ID Modalidade",
            accessor: "providerCourse.idModality",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Modalidade",
            accessor: "providerCourse.modality",
            Cell: ({ value }: any) => <DefaultCell value={value?.modalityName} />,
          },
          {
            Header: "ID Área",
            accessor: "providerCourse.idArea",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Área de Ensino",
            accessor: "providerCourse.teachingArea",
            Cell: ({ value }: any) => <DefaultCell value={value?.teachingAreaName} />,
          },
          {
            Header: "Criado em",
            accessor: "providerCourse.createdAt",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Atualizado em",
            accessor: "providerCourse.updatedAt",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
