import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiRequest, path, SchemaData } from "./types";
import { CreateGql, CREATE_QUERY, responseCreateKey } from "../queries/create";

export default function useCreate() {
  const navigate = useNavigate();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const data = state?.data;

    const response = await ApiRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data,
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (
      !response?.data?.data ||
      !response?.data?.data?.[responseCreateKey]?.databaseName ||
      !response?.data?.data?.[responseCreateKey]?.id
    ) {
      throw new Error("Ops. Sem resposta!");
    }

    const id = response.data.data[responseCreateKey].databaseName.concat(
      "%2F",
      String(response.data.data[responseCreateKey].id)
    );
    navigate(`${path}/${id}`);

    return response;
  }, []);

  return {
    handleSave,
  };
}
