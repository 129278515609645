import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query Orders(
    $page: Int!
    $perPage: Int!
    $creationDate: String
    $orderBy: OrderBy
    $invoicedDate: String
    $status: OrderStatusTypeEnum
    $incompleteOrders: Boolean
    $ratesAndBenefits: String
    $paymentNames: String
    $utmSource: String
    $hasInputInvoice: Boolean
    $query: String
  ) {
    Orders(
      page: $page
      perPage: $perPage
      creationDate: $creationDate
      orderBy: $orderBy
      invoicedDate: $invoicedDate
      status: $status
      incompleteOrders: $incompleteOrders
      ratesAndBenefits: $ratesAndBenefits
      paymentNames: $paymentNames
      utmSource: $utmSource
      hasInputInvoice: $hasInputInvoice
      query: $query
    ) {
      list {
        orderId
        clientName
        totalValue
        totalItems
        creationDate
        status
      }
      paging {
        total
        pages
        currentPage
        perPage
      }
    }
  }
`);

export interface GetAllItem {
  orderId: string;
  clientName: string;
  totalValue: number;
  totalItems: number;
  creationDate: string;
  status: OrderStatusType;
}

export interface GetAllGql {
  Orders: {
    list: GetAllItem[];
    paging: {
      total: number;
      pages: number;
      currentPage: number;
      perPage: number;
    };
  };
}

export enum OrderStatusTypeEnum {
  "waiting-for-sellers-confirmation" = "À espera de confirmação de vendedor",
  "payment-pending" = "Pagamento pendente",
  "payment-approved" = "Pagamento aprovado",
  "ready-for-handling" = "Pronto para manuseio",
  "handling" = "Em manuseamento",
  "invoiced" = "Faturado",
  "canceled" = "Cancelado",
  "window-to-cancel" = "Janela para cancelamento",
}

export type OrderStatusType = keyof typeof OrderStatusTypeEnum;
