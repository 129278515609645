import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { COURSE_FRAGMENT, Course } from "./getItem";

export const UPDATE_QUERY = print(gql`
  mutation Update($data: UpdateCourseInput!) {
    updateCourse(data: $data) {
      ...CourseFields
    }
  }
  ${COURSE_FRAGMENT}
`);

export const responseUpdateKey = "updateCourse";
export interface UpdateGql {
  updateCourse: Course;
}
