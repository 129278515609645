import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateCourseGroup($data: UpdateCourseGroupInput!) {
    updateCourseGroup(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateCourseGroup: {
    id: number;
  };
}
