import React from "react";
// Layout
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormCMSComponent from "components/CRPComponents/Forms/FormCMSComponents";
import { Permissions } from "./permissions";

const NAME_COMPONENT = "header";

function HeaderPage(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FormCMSComponent
        nameComponent={NAME_COMPONENT}
        actionsPermission={{
          createPermision: Permissions.create,
          updatePermision: Permissions.update,
          deletePermision: Permissions.delete,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default HeaderPage;
