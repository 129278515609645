import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCMSRequest } from "data/ApiCMS";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query BlogCategories {
    BlogCategories(perPage: -1) {
      items {
        slug
        name
      }
    }
  }
`);

export interface QueryGql {
  BlogCategories: {
    items: Array<{
      slug: number;
      name: string;
    }>;
  };
}

export default function useCategories() {
  const [categories, setCategories] = useState<null | QueryGql["BlogCategories"]["items"]>(null);

  useEffect(() => {
    ApiCMSRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => setCategories(data.data.BlogCategories.items));
  }, []);

  return {
    categories,
  };
}
