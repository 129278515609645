import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation CreateOrUpdateInventory($data: CreateOrUpdateInputInventory!) {
    createOrUpdateInventory(data: $data)
  }
`);

export const responseUpdateKey = "CreateOrUpdateInventory";
export interface UpdateGql {
  createOrUpdateInventory: boolean;
}
