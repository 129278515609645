import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Matoerial Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { columnsTable } from "./data";
import SearchInputControled from "components/CRPComponents/Forms/components/SearchInputControled";
import DataTableControled from "examples/Tables/DataTableControled";
import FilterMenu from "./data/FilterMenu";
import {
  QUERY_SCHOLAR_SHIP_AND_INCENTIVES,
  ResponseScholarshipAndIncentives,
  VariablesTypeScholarshipAndIncentives,
} from "data/queries/ApiCourse/ScholarshipAndIncentive/QueryScholarshipAndIncentives";
import { ApiCourseWithSetData, DataState } from "data/queries/ApiCourse";
import type { userPermissionsProps } from "hooks/types";
import { userPermissions } from "hooks/Permissions";

function ScholarShipsTable({
  path = "",
  actionsPermission,
}: {
  path: string;
  actionsPermission: userPermissionsProps;
}): JSX.Element {
  const permission = userPermissions(actionsPermission);
  const [variables, setVariables] = useState<VariablesTypeScholarshipAndIncentives>({
    page: 1,
    perPage: 10,
    search: null,
    active: null,
  });
  const [{ error, loading, data }, setData] = useState<DataState<ResponseScholarshipAndIncentives>>(
    {
      loading: true,
    }
  );

  const handleChangePage = (page: number) => {
    setVariables((prev) => ({ ...prev, page }));
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = () => {
    setVariables((prev) => ({ ...prev, search: searchValue }));
  };

  const handleChangeFilter = ({ active }: { active?: boolean | null }) => {
    setVariables((prev) => ({ ...prev, active }));
  };

  useEffect(() => {
    if (variables !== undefined) {
      setSearchValue(variables?.search || "");
      ApiCourseWithSetData({
        query: QUERY_SCHOLAR_SHIP_AND_INCENTIVES,
        variables,
        setData,
      });
    }
  }, [variables]);

  if (error) {
    return <p>Error : {error.message}</p>;
  }

  return (
    <MDBox my={3}>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton
          variant="gradient"
          color="info"
          href={!permission.create ? "#" : `${path}/scholarShips/create`}
          disabled={!permission.create}
        >
          Novo item Bolsas, Incentivos e Financiamento
        </MDButton>
        <MDBox display="flex" gap={3} alignItems="baseline">
          <SearchInputControled
            onSubmitSearch={handleSearch}
            value={searchValue}
            onChangeValue={setSearchValue}
            disabled={loading}
          />
          <FilterMenu
            variables={variables}
            onChange={handleChangeFilter}
            buttonProps={{ loading, disabled: loading }}
          />
        </MDBox>
      </MDBox>
      <Card>
        <DataTableControled
          table={{
            columns: columnsTable,
            rows: data?.ScholarshipAndIncentives?.items || [],
          }}
          pagination={data?.ScholarshipAndIncentives?.paginationInfo}
          onChangePage={handleChangePage}
          loading={loading}
        />
      </Card>
    </MDBox>
  );
}

export default ScholarShipsTable;
