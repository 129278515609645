import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeletePopUp($id: Int!) {
    deletePopUp(id: $id) {
      id
    }
  }
`);

export interface DeleteGql {
  deletePopUp: {
    id?: number;
  };
}
