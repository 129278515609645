import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiCMSRequest } from "data/ApiCMS";
import { Sites } from "data/utils/globals";
import { useCallback } from "react";
import { SchemaData } from "./types";
import { CreateGql, CREATE_QUERY } from "../queries/create";
import { useNavigate } from "react-router-dom";

export default function useCreate() {
  const navigate = useNavigate();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const data = state?.data;

    const response = await ApiCMSRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data: {
          name: data.details.name,
          status: data.details.status,
          publishOn: data.details.publishOn,
          expiresIn: data.details.expiresIn,
          author: 1,
          contentJSON: data.dynamicPageContent.contentHtml,
          nameTemplateSchema: "DynamicPage",
          slug: data.details.slug,
          title: data.details.title,
          description: data.details.description,
          keywords: data.details.keywords,
          addSiteMap: data.details.addSiteMap ?? false,
          dynamicPage: true,
        },
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data) {
      throw new Error("Ops. Sem resposta!");
    }

    navigate(`${Sites.UNIFTEC.path}/paginas-dinamicas/${data.details.slug}`);

    return response;
  }, []);

  return {
    handleSave,
  };
}
