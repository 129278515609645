export const QUERY_SCHOLAR_SHIP_AND_INCENTIVES = `query ScholarshipAndIncentives($page: Int, $perPage: Int, $search: String, $active: Boolean, $categoryId: Float, $modalityId: Float) {
  ScholarshipAndIncentives(page: $page, perPage: $perPage, search: $search, active: $active, categoryId: $categoryId, modalityId: $modalityId) {
    items {
      id
      active
      imageUrl
      title
      linkRegulation
      startDate
      endDate
      templateSchemaName
      contentJson
      templateSchema {
        id
        name
        schemaJson
        UISchema
      }
      categories {
        id
        name
        slug
        active
      }
      modalities {
        id
        name
        active
      }
      createdAt
      updatedAt
    }
    paginationInfo {
      page
      perPage
      hasMore
      totalPages
      totalData
      nextPage {
        perPage
        page
      }
    }
  }
}
`;
export type VariablesTypeScholarshipAndIncentives = {
  page?: number | null;
  perPage?: number | null;
  search?: string | null;
  active?: boolean | null;
  categoryId?: number | null;
  modalityId?: number | null;
};
export type ResponseScholarshipAndIncentives = {
  ScholarshipAndIncentives: {
    items: {
      id: number;
      active: boolean;
      imageUrl: string;
      title: string;
      linkRegulation?: string;
      startDate?: Date;
      endDate?: Date;
      templateSchemaName: string;
      contentJson: string;
      templateSchema: {
        id: number;
        name: string;
        schemaJson: string;
        UISchema: string;
      };
      categories?: {
        id: number;
        name: string;
        slug: string;
        active: boolean;
      }[];
      modalities?: {
        id: number;
        name: string;
        slug: string;
        active: boolean;
      }[];
      createdAt?: Date;
      updatedAt?: Date;
    }[];
    paginationInfo: {
      hasMore: boolean;
      page: number;
      perPage: number;
      totalData: number;
      totalPages: number;
      nextPage?: null | {
        page: number;
        perPage: number;
      };
    };
  };
};
