import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeleteBlogCategory($id: Int!) {
    deleteBlogCategory(id: $id) {
      id
    }
  }
`);

export interface DeleteGql {
  deleteBlogCategory: {
    id?: number;
  };
}
