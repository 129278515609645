export default {
  type: "object",
  properties: {
    databaseName: {
      type: "string",
    },
    idColligate: {
      type: "number",
    },
    idBranch: {
      type: "number",
    },
    educationalUnitVisibleName: {
      type: "string",
    },
  },
  required: ["databaseName", "idBranch", "idColligate", "educationalUnitVisibleName"],
};
