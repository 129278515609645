import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateEducationalUnit($data: UpdateEducationalUnitInput!) {
    updateEducationalUnit(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateEducationalUnit: {
    id: number;
  };
}
