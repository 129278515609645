import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation Delete($id: Int!) {
    deleteInvestmentInfo(id: $id) {
      id
    }
  }
`);

export const responseDeleteKey = "deleteInvestmentInfo";
export interface DeleteGql {
  deleteInvestmentInfo: {
    id?: number;
  };
}
