import { SchemaData, UseUpdateProps } from "./types";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { CREATE_OR_UPDATE_QUERY, CreateOrUpdateGql } from "../queries/createOrUpdate";
import { formatRecurrency } from "../schemas/format";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const {
        useRecurrencyCrons,
        discountType,
        discountValue,
        defineEligbleProducts,
        maxUsageUnlimited,
        utmSource,
        utmCampaign,
        utmiCampaign,
        maxNumberOfAffectedItemsUnlimited,
        maxUsagePerClientUnlimited,
        days,
        recurrencyDays,
        especificTimes,
        recurrencyTimes,
        discountTypeMoreForLess,
        morForLessDiscountValue,
        ...data
      } = state?.data;

      let dataFormatted = {
        ...data,
        idCalculatorConfiguration: queryParam,
        utmSource: utmSource?.join(",")?.length ? utmSource?.join(",") : null,
        utmCampaign: utmCampaign?.join(",")?.length ? utmCampaign?.join(",") : null,
        utmiCampaign: utmiCampaign?.join(",")?.length ? utmiCampaign?.join(",") : null,
        [discountType]: discountValue,
        recurrencyCrons: useRecurrencyCrons
          ? formatRecurrency({
              days,
              especificTimes,
              recurrencyDays,
              recurrencyTimes,
            })
          : null,
        maxUsage: maxUsageUnlimited ? null : data?.maxUsage,
        maxNumberOfAffectedItems: maxNumberOfAffectedItemsUnlimited
          ? null
          : data?.maxNumberOfAffectedItems,
        maxUsagePerClient: maxUsagePerClientUnlimited ? null : data?.maxUsagePerClient,
      };

      if (dataFormatted.type === "forThePriceOf") {
        dataFormatted = {
          ...dataFormatted,
          [discountTypeMoreForLess]: morForLessDiscountValue,
        };
      }

      const response = await ApiFinancialRequest<CreateOrUpdateGql>({
        query: CREATE_OR_UPDATE_QUERY,
        variables: {
          data: dataFormatted,
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
