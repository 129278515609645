import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpsertCourse($data: UpsertCourseInput!) {
    upsertCourse(data: $data) {
      id
    }
  }
`);

export const responseUpdateKey = "upsertCourse";
export interface UpdateGql {
  upsertCourse: {
    id: number;
  };
}
