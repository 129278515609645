import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { useState } from "react";
import MDInput from "components/MDInput";
import { MenuItem } from "@mui/material";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";

function List(props: ListProps) {
  const [status, setStatus] = useState<"archived" | "all">("all");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          archive: status === "archived",
        }}
        path={`/ecommerce/cupoms`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Coupons}
        formatPagination={() => [] as any}
        title="NOVO CUPOM"
        ApiSetData={ApiFinancialWithSetData}
        hiddenSearch
        useDefaultFilter={false}
        childrenFilters={
          <MDInput
            value={status}
            label="Status"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setStatus(e.target.value as typeof status)
            }
            sx={{ width: "100px" }}
            select
          >
            <MenuItem value={"archived"}>Arquivados</MenuItem>
            <MenuItem value={"all"}>Todos</MenuItem>
          </MDInput>
        }
        columnsTable={[
          {
            Header: "Código do cupom",
            accessor: "couponCode",
            Cell: ({
              value,
              cell: {
                row: {
                  original: { isArchived },
                },
              },
            }: any) => (
              <LinkCell
                href={`/ecommerce/cupoms/${value}?isArchived=${isArchived}`}
                value={value}
              />
            ),
          },
          {
            Header: "UTM source",
            accessor: "utmSource",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "UTM campaign",
            accessor: "utmCampaign",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
