import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import { ListProps } from "./types";
import { CellProps, GET_ALL_QUERY, GetAllGql, GetAllItem } from "./queries/getAll";
import { Fragment, useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  IconButton,
  MenuItem,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AnalysisButton,
  ApproveButton,
  Comment,
  Group,
  Key,
  ReprovedButton,
  Value,
} from "./styles";
import { ApiCMSRequest } from "data/ApiCMS";
import { UPDATE_QUERY, UpdateGql } from "./queries/update";
import StatusCell from "components/CRPComponents/Tables/components/StatusCell";
import MDInput from "components/MDInput";

function List(props: ListProps) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<GetAllItem>();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<GetAllItem[]>([]);
  const [initialVariables, setInitialVariables] = useState({
    page: 1,
    perPage: 10,
    search: null,
    statusExibition: null,
  });

  const handleClick = (data: GetAllItem) => {
    setLoading(false);
    setOpen(true);
    setContent(data);
  };

  const handleOpen = useCallback(() => setOpen((old) => !old), []);

  const formatStatus = (status: GetAllItem["statusExibition"]) => {
    switch (status) {
      case "PENDING":
        return <StatusCell icon="replay" color="dark" status="PENDENTE" />;
      case "APPROVED":
        return <StatusCell icon="done" color="success" status="APROVADO" />;
      case "DENIED":
        return <StatusCell icon="close" color="error" status="REPROVADO" />;
      default:
        return <StatusCell icon="replay" color="dark" status="PENDENTE" />;
    }
  };

  const handleUpdate = (id: number, statusExibition: GetAllItem["statusExibition"]) => {
    setLoading(true);
    ApiCMSRequest<UpdateGql>({
      query: UPDATE_QUERY,
      variables: {
        data: {
          id,
          statusExibition,
        },
      },
    })
      .then(({ data }) => {
        if (data.data.updateComment.id) {
          setContent((old) => ({ ...old, statusExibition }));
          setRows((old) =>
            old.map((item) => (item.id === id ? { ...item, statusExibition } : item))
          );
          setOpen(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <GenericTable<GetAllGql>
          initialVariables={initialVariables}
          path={`${props.path}/blog-comentarios`}
          query={GET_ALL_QUERY}
          formatRows={() => rows ?? []}
          formatPagination={(data) => data.Comments.paginationInfo}
          hnadleResponse={(data) => setRows(data?.Comments?.items)}
          useDefaultFilter={false}
          childrenFilters={
            <MDInput
              value={initialVariables.statusExibition}
              label="Status"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInitialVariables((old) => ({ ...old, statusExibition: e.target.value }))
              }
              sx={{ width: "100px" }}
              select
            >
              <MenuItem value={"APPROVED"}>Aprovado</MenuItem>
              <MenuItem value={"DENIED"}>Reprovado</MenuItem>
              <MenuItem value={"PENDING"}>Pendente</MenuItem>
              <MenuItem value={null}>Todos</MenuItem>
            </MDInput>
          }
          columnsTable={[
            {
              Header: "Status",
              accessor: "statusExibition",
              Cell: ({ value, cell }: CellProps) =>
                cell.row.original.statusExibition === "PENDING" ? (
                  <AnalysisButton onClick={() => handleClick(cell.row.original)}>
                    Analisar
                  </AnalysisButton>
                ) : (
                  <Stack
                    sx={{
                      ".seeContent": {
                        display: "none",
                      },
                      "&:hover": {
                        ".seeContent": {
                          display: "block",
                        },
                        ".status": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <Box className="status">{formatStatus(value)}</Box>
                    <AnalysisButton
                      className="seeContent"
                      onClick={() => handleClick(cell.row.original)}
                    >
                      Ver
                    </AnalysisButton>
                  </Stack>
                ),
            },
            {
              Header: "Comentário",
              accessor: "comment",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "Slug",
              accessor: "blogSlug",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "ID do usúario",
              accessor: "userId",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
          ]}
        />
        <Footer />
      </DashboardLayout>
      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "30vw",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleOpen}>
          <Stack>
            <IconButton
              aria-label="close"
              size="large"
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              onClick={handleOpen}
            >
              <CloseIcon />
            </IconButton>
            {content && (
              <Stack
                sx={{
                  padding: "30px",
                  gap: "15px",
                }}
              >
                <Stack>
                  <Group>
                    <Key>id:</Key>
                    <Value>{content.id}</Value>
                  </Group>
                  <Group>
                    <Key>slug:</Key>
                    <Value>{content.blogSlug}</Value>
                  </Group>
                  <Group>
                    <Key>ID do usúario:</Key>
                    <Value>{content.userId}</Value>
                  </Group>
                  <Group>
                    <Key>Status:</Key>
                    <Value>{content.statusExibition}</Value>
                  </Group>
                </Stack>
                <Stack>
                  <Stack>
                    <Key>Comentário:</Key>
                    <Comment>{content.comment}</Comment>
                  </Stack>
                </Stack>
                {loading ? (
                  <Stack alignItems={"center"}>
                    <CircularProgress sx={{ color: "#7b809a" }} />
                  </Stack>
                ) : (
                  <Stack direction={"row"} alignContent={"center"} gap={"10px"}>
                    <ReprovedButton onClick={() => handleUpdate(content.id, "DENIED")}>
                      Reprovar
                    </ReprovedButton>
                    <ApproveButton onClick={() => handleUpdate(content.id, "APPROVED")}>
                      Aprovar
                    </ApproveButton>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </ClickAwayListener>
      </Dialog>
    </Fragment>
  );
}

export default List;
