import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FaqsTable from "components/CRPComponents/Tables/FaqsTable";
import { Permissions } from "./permissions";

function FaqsUniftecListPage(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FaqsTable
        path="/uniftec"
        siteId={1}
        actionsPermission={{
          createPermision: Permissions.create,
          deletePermision: Permissions.delete,
          updatePermision: Permissions.update,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default FaqsUniftecListPage;
