import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ButtonTwoLines from "components/CRPComponents/ButtonTwoLines";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { path as CoursesFinancialPath } from "../../CoursesFinancial/hooks/types";
import { UPSERT_QUERY, UpsertGql } from "../../CoursesFinancial/queries/upsertCourse";
import { convertCourseInUpsertCourseInput } from "../../CoursesFinancial/hooks/convertCourseInUpsertCourseInput";
import { StateProp } from "../CreateCourseFinancial";

function ButtonCreateCourse({
  loading,
  setLoading,
  data,
  onFailCreate,
}: {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  data?: StateProp["data"];
  onFailCreate: (reason: any) => any | PromiseLike<any>;
}) {
  const navigate = useNavigate();

  const handleCreateCourseFinancial = useCallback(async () => {
    setLoading(true);
    ApiFinancialRequest<UpsertGql>({
      query: UPSERT_QUERY,
      variables: { data: convertCourseInUpsertCourseInput(data) },
    })
      .then((response) => {
        if (!response?.data?.data?.upsertCourse?.id) {
          throw new Error("Não foi possivel Criar", {
            cause: { response: response?.data || response },
          });
        }
        navigate(`${CoursesFinancialPath}/${response?.data?.data?.upsertCourse?.id}`);
      })
      .catch(onFailCreate)
      .finally(() => {
        setLoading(false);
      });
  }, [data]);

  return (
    <ButtonTwoLines
      loading={loading}
      title="Criar Curso"
      onClick={handleCreateCourseFinancial}
      disabled={!data}
    >
      Forçar Criação
    </ButtonTwoLines>
  );
}

export default ButtonCreateCourse;
