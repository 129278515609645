import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_OR_UPDATE_QUERY = print(gql`
  mutation CreateOrUpdateCoupon($data: CreateOrUpdateInputCoupon!) {
    createOrUpdateCoupon(data: $data) {
      couponCode
      isArchived
    }
  }
`);

export interface CreateOrUpdateGql {
  createOrUpdateCoupon: {
    couponCode: string;
    isArchived: boolean;
  };
}
