import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps, SelectChangeEvent } from "@mui/material/Select";

interface SelectComponentOptionsProps {
  value: string;
  label: string;
}
export interface SelectComponentProps {
  value: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
  label: string;
  options: SelectComponentOptionsProps[];
  labelId?: string;
  id?: string;
  disabled?: SelectProps["disabled"];
  autoWidth?: SelectProps["autoWidth"];
  sx?: SelectProps["sx"];
}
function SelectComponent({
  value,
  onChange,
  disabled = false,
  label,
  options,
  labelId = "demo-simple-select-label",
  id = "demo-simple-select",
  autoWidth = true,
  sx = { minWidth: "150px" },
}: SelectComponentProps) {
  return (
    <FormControl>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        value={value || ""}
        label={label}
        onChange={onChange}
        disabled={disabled}
        autoWidth={autoWidth}
        sx={sx}
      >
        {options?.map(({ value, label }) => (
          <MenuItem key={String(value)} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectComponent;
