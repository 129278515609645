export const MUTATION_DELETE_SCHOLAR_SHIP = `mutation Mutation($deleteScholarshipAndIncentiveId: Int!) {
  deleteScholarshipAndIncentive(id: $deleteScholarshipAndIncentiveId) {
    id
  }
}`;

export type ResponseDeleteScholarship = {
  deleteScholarshipAndIncentive: {
    id: number;
  };
};
