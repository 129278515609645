import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback } from "react";
import { SchemaData } from "./types";
import { CreateGql, CREATE_QUERY } from "../queries/create";
import { ApiAuthRequest } from "data/ApiAuth";

export default function useCreate() {
  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const data = state?.data;

    const response = await ApiAuthRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data: {
          ...data,
        },
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data || !response?.data?.data?.createAddress?.id) {
      throw new Error("Ops. Sem resposta!");
    }

    window.location.pathname = `/ecommerce/tags/${response.data.data.createAddress.id}`;

    return response;
  }, []);

  return {
    handleSave,
  };
}
