import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Redirects($page: Int, $perPage: Int, $search: String) {
    Redirects(page: $page, perPage: $perPage, search: $search) {
      items {
        id
        urlFrom
        urlTo
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  urlFrom: string;
  urlTo: string;
}

export interface GetAllGql {
  Redirects: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
