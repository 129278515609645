import Icon from "@mui/material/Icon";
import { Route } from "App";
import SalesAnalytics from "components/CRPComponents/SalesAnalytics";

export const panelRoutes: Route[] = [
  {
    type: "collapse",
    name: "Painel",
    key: "painel",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analises",
        key: "Analises",
        route: "/dashboards/analises",
        component: <SalesAnalytics />,
      },
    ],
  },
  { type: "divider", key: "divider-panelRoutes" },
];
