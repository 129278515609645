import { withJsonFormsControlProps } from "@jsonforms/react";
import SelectMultiple from "../SelectMultiple";
import { CustomRendererProps } from "../types";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { print } from "graphql";
import { gql } from "@apollo/client";
import ModalFacultyMemberSelect from "components/CRPComponents/ModalFacultyMemberSelect";

const query = print(gql`
  query FacultyMember($id: Int!) {
    FacultyMember(id: $id) {
      name
      id
    }
  }
`);

interface ItemGql {
  FacultyMember: {
    id: number;
    name: string;
  };
}

const FacultyMemberSelectMultiple = (props: CustomRendererProps<number[] | undefined>) => {
  return (
    <SelectMultiple
      {...props}
      Modal={ModalFacultyMemberSelect}
      formatItem={({ data }) => data.data.FacultyMember}
      requestItem={(id: number) => ApiCourseRequest<ItemGql>({ query, variables: { id } })}
    />
  );
};

export default withJsonFormsControlProps(FacultyMemberSelectMultiple, true);
