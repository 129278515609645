import { useCallback, useState } from "react";
import { DELETE_RESPONSE_QUERY, DeleteResponseGql } from "../queries/deleteResponse";
import { ApiAuthRequest } from "data/ApiForm";
import { UseDeleteResponseProps } from "./types";

const useDeleteResponse = ({ setSnackBarInfo }: UseDeleteResponseProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteError = (e: Error) => {
    setSnackBarInfo({
      open: true,
      color: "error",
      title: "Ops, algo deu errado",
      content: e?.message || "Ops. Algo Deu errado",
      autoHideDuration: null,
    });
  };

  const handleDeleteSuccess = () => {
    setSnackBarInfo({
      open: true,
      color: "success",
      icon: "check",
      autoHideDuration: null,
      title: "Deletado com sucesso",
    });
  };

  const handleDelete = useCallback(async (id: number) => {
    setLoading(true);
    try {
      const response = await ApiAuthRequest<DeleteResponseGql>({
        query: DELETE_RESPONSE_QUERY,
        variables: { id },
      });

      if (!response?.data?.data?.deleteFormResponse?.id) {
        if (response.data?.errors?.[0]) throw response.data?.errors?.[0];
        throw new Error(JSON.stringify(response?.data || "Algo deu errado!"));
      }

      handleDeleteSuccess();
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      handleDeleteError(error);
    }
  }, []);

  return {
    handleDelete,
    handleDeleteSuccess,
    handleDeleteError,
  };
};

export default useDeleteResponse;
