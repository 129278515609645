import MDTypography from "components/MDTypography";
import { MDButton, MDButtonProps } from "components/MDButton";

// Declaring props types for ButtonCell
interface ButtonCellProps extends Omit<MDButtonProps, "children"> {
  value: string;
  suffix?: string | boolean;
}

function ButtonCell({ value, suffix = "", ...props }: ButtonCellProps): JSX.Element {
  return (
    <MDButton {...props}>
      <MDTypography variant="caption" fontWeight="medium" color="inherit">
        {value}
        {suffix && (
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            &nbsp;&nbsp;{suffix}
          </MDTypography>
        )}
      </MDTypography>
    </MDButton>
  );
}

export default ButtonCell;
