import React from "react";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import { IProps, Option } from "./types";
import { throttle } from "lodash";
import { CircularProgress } from "@mui/material";
import { InputWrapper, Listbox, Root, StyledTag } from "./styles";

export default function SearchSelect({
  onChange,
  onInputChange,
  options = [],
  defaultValue = [],
  value = [],
  loading = false,
  sx,
}: IProps) {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
    inputValue,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: options,
    getOptionLabel: (option: Option) => option.name,
    defaultValue: defaultValue,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    onChange(event, value, reason, details) {
      if (reason === "removeOption" || reason === "selectOption") {
        onChange(value);
      }
    },
    // value,
  });

  const handleChange = React.useCallback(
    throttle((value: string) => {
      onInputChange(value);
    }, 2000),
    []
  );

  React.useEffect(() => handleChange(inputValue), [inputValue]);

  return (
    <Root sx={sx}>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {value?.map((option: Option, index: number) => (
            <StyledTag key={index} label={option.name} {...getTagProps({ index })} />
          ))}
          <input {...getInputProps()} placeholder="Pesquise aqui..." />
        </InputWrapper>
      </div>
      {loading ? (
        <Listbox
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingBlock: "10px",
          }}
        >
          <CircularProgress
            sx={{
              width: "25px !important",
              height: "25px !important",
              color: "#1A73E8",
            }}
          />
        </Listbox>
      ) : (
        groupedOptions.length > 0 && (
          <Listbox {...getListboxProps()}>
            {options?.map((option, index) => (
              <li key={index} {...getOptionProps({ option, index })}>
                <span>{option.name}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        )
      )}
    </Root>
  );
}
