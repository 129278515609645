import useGetItem from "./hooks/useGetItem";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Stack } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { formatDate, getBuyAsGiftProducts } from "./utils";
import { OrderStatusTypeEnum } from "./queries/getAll";
import { Group } from "./styles";
import { formatPrice } from "utils/formatPrice";
import CustomTable from "./Table";
import Annotations from "./Annotations";
import Status from "./Status";
import Payments from "./Payments";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Invoices from "./Invoices";
import { userPermissions } from "hooks/Permissions";
import { Orders } from ".";

function Update() {
  const { update } = userPermissions({ updatePermision: Orders.Permissions.update });
  const id = useParams()?.id;

  if (!id) return null;

  const { data, loadingActions, cancelOrder, handleOrder } = useGetItem(id);

  if (!data) {
    return (
      <DashboardLayout>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
          <CircularProgress color="info" />
        </Stack>
      </DashboardLayout>
    );
  }

  const getStatusColor = (status: (typeof data)["status"]) => {
    switch (status) {
      case "canceled":
        return "#F44335";
      case "invoiced":
        return "#368369";
      default:
        return "#344767";
    }
  };

  const buyAsGiftProducts = getBuyAsGiftProducts(data?.customData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Stack gap={2} marginBlock={"30px"}>
        <Stack
          direction={"row"}
          gap={"10px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <MDButton
            sx={{
              width: "fit-content",
            }}
            color="info"
            disabled={loadingActions}
            onClick={() => window.location.reload()}
          >
            Atualizar
          </MDButton>
          <Stack direction={"row"} gap={"10px"} alignItems={"center"} justifyContent={"flex-end"}>
            {data?.status === "ready-for-handling" && update && (
              <MDButton
                sx={{
                  width: "fit-content",
                  color: "rgb(41, 83, 178) !important",
                  background: "rgb(221, 229, 247)  !important",
                }}
                color="info"
                disabled={loadingActions}
                onClick={handleOrder}
              >
                Pronto para manuseio
              </MDButton>
            )}
            {data.allowCancellation && update && (
              <MDButton
                sx={{
                  width: "fit-content",
                }}
                color="error"
                disabled={loadingActions}
                onClick={cancelOrder}
              >
                Cancelar pedido
              </MDButton>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            background: "white",
            borderRadius: "6px",
          }}
        >
          <Group>
            <Stack gap={"5px"}>
              <h3>Pedido:</h3>
              <span>Nº {data.orderId}</span>
              <span>
                Status:{" "}
                <Box
                  component={"span"}
                  sx={{
                    background: getStatusColor(data.status),
                    color: "white !important",
                    padding: "6px 12px",
                    borderRadius: "6px",
                  }}
                >
                  {OrderStatusTypeEnum[data.status] ?? data.status}
                </Box>
              </span>
              <span>Data: {formatDate(data.creationDate)}</span>
            </Stack>
          </Group>
        </Box>
        <Stack
          direction={"row"}
          alignItems={"stretch"}
          sx={{
            background: "white",
            borderRadius: "6px",
          }}
        >
          <Group>
            <h3>Cliente</h3>
            <h4>Pedido realizado por</h4>
            <span>{data.clientProfileData.firstName}</span>
            <span>
              {data.clientProfileData.documentType}: {data.clientProfileData.document}
            </span>
            <span>{data.clientProfileData.phone}</span>
            <span>{data.clientProfileData.email}</span>
          </Group>
          <Group>
            <h3>Entrega</h3>
            <h4>Endereço</h4>
            <span>
              {data.shippingData.selectedAddresses[0].street}
              {data.shippingData.selectedAddresses[0].number &&
                `, ${data.shippingData.selectedAddresses[0].number}`}
            </span>
            <span>{data.shippingData.selectedAddresses[0].neighborhood}</span>
            <span>
              {data.shippingData.selectedAddresses[0].city}
              {data.shippingData.selectedAddresses[0].state &&
                `, ${data.shippingData.selectedAddresses[0].state}`}
            </span>
            <span>{data.shippingData.selectedAddresses[0].postalCode}</span>
            <span>{data.shippingData.selectedAddresses[0].complement}</span>
            <h4>Destinatário</h4>
            <span>{data.shippingData.selectedAddresses[0].receiverName}</span>
          </Group>
          <Group>
            <h3>Valores</h3>
            <h4>Detalhamento</h4>
            {data.totals.map((item) => (
              <Stack
                key={item.id}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <span>{item.name}</span>
                <span>{formatPrice(item.value / 100)}</span>
              </Stack>
            ))}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <span style={{ color: "black !important", fontWeight: 600 }}>Valor final</span>
              <span style={{ fontWeight: 600 }}>{formatPrice(data.value / 100)}</span>
            </Stack>
          </Group>
        </Stack>
        <Stack
          sx={{
            background: "white",
            borderRadius: "6px",
          }}
        >
          <Group
            sx={{
              minHeight: "unset",
            }}
          >
            <h3>Itens</h3>
            <CustomTable items={data.items} buyAsGiftProducts={buyAsGiftProducts} />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginTop={"30px"}
              borderTop={"1px solid black"}
              paddingTop={"10px"}
            >
              <span style={{ color: "black !important", fontWeight: 600 }}>Valor total</span>
              <span style={{ fontWeight: 600 }}>
                {formatPrice(
                  data.items.reduce((total, { price, quantity }) => total + price * quantity, 0) /
                    100
                )}
              </span>
            </Stack>
          </Group>
        </Stack>
        <Invoices orderId={id} status={data.status} />
        <Stack
          sx={{
            background: "white",
            borderRadius: "6px",
          }}
        >
          <Group
            sx={{
              minHeight: "150px",
            }}
          >
            <h3>Promoções e parcerias</h3>
            {data?.ratesAndBenefitsData?.rateAndBenefitsIdentifiers?.map((item) => (
              <span key={item.name}>{item.name}</span>
            ))}
          </Group>
        </Stack>
        <Stack
          sx={{
            background: "white",
            borderRadius: "6px",
          }}
        >
          <Group>
            <h3>Pagamento</h3>
            <Payments paymentData={data.paymentData} />
          </Group>
        </Stack>
        <Status timeline={data.timeline} cancellationData={data.cancellationData} />
        <Annotations orderId={id} />
      </Stack>
    </DashboardLayout>
  );
}

export default Update;
