import Icon from "@mui/material/Icon";
import { Route } from "App";
import { Sites } from "data/utils/globals";
import { Banner } from "layouts/aplicacoes/Banners";
import { Contact } from "layouts/aplicacoes/Contact";
import { Popups } from "layouts/aplicacoes/Popups";
import { ScheduleYourVisit } from "layouts/aplicacoes/ScheduleYourVisit";

const { id, path } = Sites.IBGEN;

export const ibgenRoutes: Route[] = [
  {
    type: "collapse",
    name: "IBGEN",
    key: "IBGEN",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        type: "hide",
        name: " Novo Banner",
        key: `${path}/banners/new`,
        route: `${path}/banners/new`,
        component: <Banner.Create titleBox="adicionar banner" idSite={id} path={path} />,
        permission: Banner.Permissions.create,
      },
      {
        name: "Banners",
        key: `${path}/banners`,
        route: `${path}/banners`,
        component: <Banner.List idSite={id} path={path} />,
        permission: Banner.Permissions.list,
      },
      {
        type: "hide",
        name: " Ver Banner",
        key: `${path}/banners/:id`,
        route: `${path}/banners/:id`,
        component: <Banner.Update idSite={id} path={path} />,
        permission: Banner.Permissions.read,
      },
      {
        type: "hide",
        name: " Editar Banner",
        key: `${path}/banners/edit/:id`,
        route: `${path}/banners/edit/:id`,
        component: <Banner.Update inicialEditing idSite={id} path={path} />,
        permission: Banner.Permissions.update,
      },

      {
        name: "Popups",
        key: `${path}/popups`,
        route: `${path}/popups`,
        component: <Popups.List idSite={id} path={path} />,
        permission: Popups.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo",
        key: `${path}/popups/new`,
        route: `${path}/popups/new`,
        component: <Popups.Create idSite={id} path={path} />,
        permission: Popups.Permissions.create,
      },
      {
        name: "Atualizar",
        type: "hide",
        key: `${path}/popups/:id`,
        route: `${path}/popups/:id`,
        component: <Popups.Update idSite={id} path={path} />,
        permission: Popups.Permissions.update,
      },

      {
        name: "Contato",
        key: `${path}/contato`,
        route: `${path}/contato`,
        component: <Contact.List slug="ibgen-contato" path={Sites.IBGEN.path} />,
        permission: Contact.Permissions.list,
      },

      {
        name: "Agenda de visitas",
        key: `${path}/agenda-de-visitas`,
        route: `${path}/agenda-de-visitas`,
        component: (
          <ScheduleYourVisit.List slug="agende-sua-visita-ibgen" path={Sites.IBGEN.path} />
        ),
        permission: ScheduleYourVisit.Permissions.list,
      },
    ],
  },
];
