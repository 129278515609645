import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback } from "react";
import { CreateGql, CREATE_QUERY } from "../queries/create";
import { ApiRequest, path, SchemaData } from "./types";

import { useNavigate } from "react-router-dom";

export default function useCreate() {
  const navigate = useNavigate();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const {
      name,
      active,
      createdAt,
      updatedAt,
      maxAdmissionWays,
      maxAmountInstallmentsPrices,
      maxPlans,
      urlInscription,
      allowGift,
      allowPurchase,
      ...data
    } = state?.data;

    const response = await ApiRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data: {
          active,
          maxAdmissionWays: !maxAdmissionWays ? null : maxAdmissionWays,
          maxAmountInstallmentsPrices: !maxAmountInstallmentsPrices
            ? null
            : maxAmountInstallmentsPrices,
          maxPlans: !maxPlans ? null : maxPlans,
          allowPurchase,
          allowGift: !allowPurchase ? false : allowGift,
          urlInscription: !allowPurchase ? urlInscription : null,
          ...data,
        },
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data || !response?.data?.data?.createTemplateInvestment?.id) {
      throw new Error("Ops. Sem resposta!");
    }

    navigate(`${path}/${response.data.data.createTemplateInvestment.id}`);

    return response;
  }, []);

  return {
    handleSave,
  };
}
