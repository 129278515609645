import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const AdminUser = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:user",
    read: "read:user",
    list: "list:user",
    delete: "delete:user",
    update: "update:user",
  },
};
