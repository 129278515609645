import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import useCreate from "./hooks/useCreate";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import schemaJson from "./schema/schemaJson.json";
import UISchema from "./schema/UISchema.json";

function Create() {
  return (
    <GenericMethods.Create<SchemaData, CreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      useCreate={useCreate}
      template={{
        schemaJson: schemaJson,
        UISchema: UISchema,
      }}
    />
  );
}

export default Create;
