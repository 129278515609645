import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiCMSRequest } from "data/ApiCMS";
import {
  CategoryFaqMutationCreateGql,
  MUTATION_CREATE_CATEGORY_FAQ,
} from "data/queries/ApiCMS/CategoriesFaq/MutationCreate";
import { CategoryFaqGql } from "data/queries/ApiCMS/CategoriesFaq/QueryCategoryFaq";
import { Sites } from "data/utils/globals";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function useCreate() {
  const navigate = useNavigate();

  const handleSave = useCallback(
    async (state: JsonFormsStateProps<CategoryFaqGql["CategoryFaq"]>) => {
      const { slug, name, sort } = state?.data;

      const response = await ApiCMSRequest<CategoryFaqMutationCreateGql>({
        query: MUTATION_CREATE_CATEGORY_FAQ,
        variables: {
          data: {
            slug,
            name,
            sort,
          },
        },
      });

      if (response?.data?.errors) {
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }

      navigate(`${Sites.UNIFTEC.path}/faq-categorias/${response.data.data.createCategoryFaq.id}`);

      return response;
    },
    []
  );

  return {
    handleSave,
  };
}
