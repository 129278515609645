import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query Modalities {
    Modalities(perPage: -1, active: true) {
      items {
        id
        name
      }
    }
  }
`);

export interface QueryGql {
  Modalities: {
    items: Array<{
      id: number;
      name: string;
    }>;
  };
}

export default function useModalities() {
  const [modalities, setModalities] = useState<null | QueryGql["Modalities"]["items"]>(null);

  useEffect(() => {
    ApiCourseRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => setModalities(data.data.Modalities.items));
  }, []);

  return {
    modalities,
    setModalities,
  };
}
