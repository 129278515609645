export const QUERY_FAQS = `query Query($siteId: Int) {
  Faqs(siteId: $siteId) {
    items {
      id
      question
      answer
      sort
      student
      site {
        name
        id
      }
      status
      createdAt
      updatedAt
      categoryFaq {
        slug
        parentId
        name
        id
        level
      }
    }
    paginationInfo {
      page
      perPage
      hasMore
      totalPages
      totalData
      nextPage {
        perPage
        page
      }
    }
  }
}
`;
export type VariablesTypeFaqs = {
  page?: number | null;
  perPage?: number | null;
  search?: string | null;
  siteId?: number | null;
  status?: string | null;
};
export type ResponseFaqs = {
  Faqs: {
    items: {
      id: number;
      question: string;
      answer: string;
      sort?: number;
      linkRegulation?: string;
      student?: boolean;
      site: {
        id: number;
        name: string;
      };
      status?: string;
      categoryFaq?: {
        id: number;
        name: string;
        slug: string;
        parentId?: number;
        level?: number;
      };
      createdAt?: Date;
      updatedAt?: Date;
    }[];
    paginationInfo: {
      hasMore: boolean;
      page: number;
      perPage: number;
      totalData: number;
      totalPages: number;
      nextPage?: null | {
        page: number;
        perPage: number;
      };
    };
  };
};
