import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";
import { path as pathAdmissionWayEdit } from "../AdmissionWays/hooks/types";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql, responseGetAllKey } from "./queries/getAll";
import { path } from "./hooks/types";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
        }}
        path={path}
        query={GET_ALL_QUERY}
        formatRows={(data) => data[responseGetAllKey].items}
        formatPagination={(data) => data[responseGetAllKey].paginationInfo}
        ApiSetData={ApiFinancialWithSetData}
        columnsTable={[
          {
            Header: "Id Preço",
            accessor: "id",
            Cell: ({ value }: any) => <LinkCell href={`${path}/${value}`} value={value} />,
          },
          {
            Header: "Forma de Ingresso",
            accessor: "price.planAdmissionWay.admissionWay.name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Plano",
            accessor: "price.planAdmissionWay.title",
            Cell: ({ value }: any) => <DefaultCell value={value ?? ""} />,
          },
          {
            Header: "Parcela",
            accessor: "price.installments",
            Cell: ({ value }: any) => <DefaultCell value={value > 1 ? `${value}x` : "A vista"} />,
          },
          {
            Header: "Valor",
            accessor: "admissionWayId",
            Cell: ({ row: { original: price } }: any) => (
              <p>
                {price?.valueWithoutDiscout && <s>{`R$ ${price.valueWithoutDiscout}`}</s>}
                {"  "}
                <strong>{`R$ ${price.value}`}</strong>
                {!!price?.valueOtherInstallments &&
                  price.installments > 1 &&
                  ` + ${price.installments - 1}x de R$ ${price?.valueOtherInstallments}`}
              </p>
            ),
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
