import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CANCEL_ORDER = print(gql`
  mutation CancelOrder($cancelOrderId: String!, $reason: String) {
    cancelOrder(id: $cancelOrderId, reason: $reason) {
      receipt
      orderId
      date
    }
  }
`);

export interface cancelOrderInput {
  cancelOrderId: string;
  reason?: string;
}

export interface CancelOrderGql {
  receipt: string;
  orderId: string;
  date: string;
}
