interface ValidationError {
  property: string;
  children?: ValidationError[];
  constraints?: { [key: string]: string };
}

export function extractValidationErrorMessages(validationErrors: ValidationError[]): string[] {
  const errorMessages: string[] = [];

  function traverseAndCollectErrors(errors: ValidationError[], path: string) {
    errors.forEach((error) => {
      if (error.children && error.children.length > 0) {
        traverseAndCollectErrors(
          error.children,
          path ? `${path}.${error.property}` : error.property
        );
      } else if (error.constraints) {
        Object.keys(error.constraints).forEach((key) => {
          errorMessages.push(
            `${path ? `${path}.${error.property}` : error.property}: ${error.constraints[key]}`
          );
        });
      }
    });
  }

  traverseAndCollectErrors(validationErrors, "");
  console.log({ errorMessages });

  return errorMessages;
}

export const getErrorsList = (e: any): string[] => {
  if (e?.response?.data?.errors?.[0]?.extensions?.exception?.validationErrors) {
    return [
      ...e.response.data.errors
        .map(({ extensions }: any) =>
          extractValidationErrorMessages(extensions?.exception?.validationErrors)
        )
        .flat(),
    ];
  } else if (e?.response?.data?.errors?.[0]?.message) {
    return [...e.response.data.errors.map(({ message }: { message: string }) => message)];
  } else {
    return [e.message];
  }
};
