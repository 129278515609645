import { Route } from "App";
import { componentsRoutes } from "./components";
import { configRoutes } from "./config";
import { ecommerceRoutes } from "./ecommerce";
import { mediasRoutes } from "./medias";
import { panelRoutes } from "./panel";
import { ecoinoveRoutes } from "./sites/ecoinove";
import { ftecRoutes } from "./sites/ftec";
import { ibgenRoutes } from "./sites/ibgen";
import { polyuniRoutes } from "./sites/polyuni";
import { redHouseRoutes } from "./sites/redHouse";
import { uniftecRoutes } from "./sites/uniftec";
import { userRoutes } from "./user";

export const SideBarMenu: Route[] = [
  ...userRoutes,
  ...panelRoutes,
  { type: "title", title: "Aplicações", key: "Aplicações Title" },
  ...uniftecRoutes,
  ...polyuniRoutes,
  ...ibgenRoutes,
  ...ecoinoveRoutes,
  ...ftecRoutes,
  ...redHouseRoutes,
  { type: "divider", key: "divider-SideBarMenu" },
  ...componentsRoutes,
  ...mediasRoutes,
  ...ecommerceRoutes,
  ...configRoutes,
];
