import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const TeachingAreas = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:TeachingArea",
    update: "update:TeachingArea",
    list: "list:TeachingArea",
    read: "read:TeachingArea",
  },
};
