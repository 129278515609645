import { Box, FormHelperText, IconButton, InputLabel, TextField } from "@mui/material";
import { CustomRendererProps } from "../types";
import { withJsonFormsControlProps } from "@jsonforms/react";
import IntlCurrencyInput from "react-intl-currency-input";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

function PriceInput({
  data,
  handleChange,
  path,
  label,
  required,
  errors,
  enabled,
  description,
  uischema,
}: CustomRendererProps<number>) {
  const [focused, setFocused] = useState(false);

  return (
    <Box
      sx={{
        marginBlock: "5px",
        "& fieldset": {
          borderRadius: 0,
          border: "none",
          borderBottom: `1px solid ${!!errors ? "#F44335" : "#d2d6da"} !important`,
          padding: 0,
        },
        "& input": {
          padding: "10px 0",
        },
        "& .MuiFormControl-root": {
          maxWidth: "100%",
          width: "100%",
        },
      }}
    >
      <InputLabel
        sx={{
          ...(!!errors && {
            color: "#F44335",
          }),
        }}
      >
        {label}
        {required && " *"}
      </InputLabel>
      <Box>
        <IntlCurrencyInput
          currency="BRL"
          value={data}
          component={(props: any) => (
            <TextField
              autoFocus={focused}
              {...props}
              value={data === undefined ? "" : props.value}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={!enabled}
              InputProps={{
                endAdornment: typeof data === "number" && (
                  <IconButton onClick={() => handleChange(path, undefined)}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            />
          )}
          config={{
            locale: "pt-BR",
            formats: {
              number: {
                BRL: {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              },
            },
          }}
          onChange={(_, value: number) => handleChange(path, value)}
          defaultValue={null}
          max={undefined}
        />
      </Box>
      {(!!focused || !!uischema?.options?.showUnfocusedDescription) && (
        <FormHelperText error={!!errors}>{description}</FormHelperText>
      )}
      {!!errors && <FormHelperText error>{errors}</FormHelperText>}
    </Box>
  );
}

export default withJsonFormsControlProps(PriceInput);
