export const MUTATION_USER_NAVIGATE = `mutation SendUserNavigate($path: String!) {
  sendUserNavigate(path: $path) {
    content
  }
}`;

export type ResponseSendUserNavigate = {
  sendUserNavigate: {
    content: string;
  };
};
