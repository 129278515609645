export const QUERY_SCHOLAR_SHIP_AND_INCENTIVE = `query Query($scholarshipAndIncentiveId: Int!) {
  ScholarshipAndIncentive(id: $scholarshipAndIncentiveId) {
    id
    active
    imageUrl
    title
    linkRegulation
    startDate
    endDate
    templateSchemaName
    contentJson
    templateSchema {
      id
      name
      schemaJson
      UISchema
    }
    categories {
      id
      name
      slug
      active
    }
    modalities {
      id
      name
      active
    }
    createdAt
    updatedAt
  }
  Categories {
    items {
      id
      name
    }
  }
  Modalities {
    items {
      id
      name
    }
  }
}`;

export const QUERY_PROPS_SCHOLAR_SHIP_AND_INCENTIVE = `query Query($name: String!) {
  templateSchema(name: $name) {
    id
    name
    schemaJson
    UISchema
  }
  Categories {
    items {
      id
      name
    }
  }
  Modalities {
    items {
      id
      name
    }
  }
}`;

export type ResponseScholarshipAndIncentive = {
  id: number;
  active: boolean;
  imageUrl: string;
  title: string;
  linkRegulation?: string;
  startDate?: Date;
  endDate?: Date;
  templateSchemaName: string;
  contentJson: string;
  templateSchema: {
    id: number;
    name: string;
    schemaJson: string;
    UISchema: string;
  };
  categories?: {
    id: number;
    name: string;
    slug: string;
    active: boolean;
  }[];
  modalities?: {
    id: number;
    name: string;
    slug: string;
    active: boolean;
  }[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type ResponseTemplateSchema = {
  id: number;
  name: string;
  schemaJson: string;
  UISchema: string;
};

export type ResponseCategoriesScholarshipAndIncentive = {
  items?: {
    id: number;
    name: string;
  }[];
};

export type ResponseModalitiesScholarshipAndIncentive = {
  items?: {
    id: number;
    name: string;
  }[];
};
