import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import {
  ContentForm,
  CRPTitleOne,
  CRPContainerVert,
  CRPContainerHoriz,
} from "components/CRPComponents/CRPAddBanner.style";
import MDInput from "components/MDInput";
import { MDButton, MDButtonProps } from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MediaFileField from "components/CRPComponents/Forms/components/MediaFileField";
import Col from "components/Col";
import Row from "components/Row";
import { SelectInputControledForm } from "../components";
import { Sites } from "data/utils/globals";

type REvent = React.ChangeEvent<HTMLInputElement>;

export function FormBanner({
  titleBox,
  idSite,
  initialValues = undefined,
  errors = {},
  onClickButton = () => {},
  labelButton = "Salvar",
  buttonProps = {
    variant: "outlined",
    color: "info",
  },
  children,
  loading = false,
  disabled = false,
  disabledButton = disabled,
}: FormBannerProps) {
  const [bannerInfos, setBannerInfos] = useState<BannerInfos>(initialValues);
  const handleChangeDesktopImage = (mediaFileDesktop: any) => {
    setBannerInfos((prev) => ({ ...prev, mediaFileDesktop }));
  };
  const handleChangeMobileImage = (mediaFileMobile: any) => {
    setBannerInfos((prev) => ({ ...prev, mediaFileMobile }));
  };
  function addBanner() {
    onClickButton({ ...bannerInfos, site: { id: idSite } });
  }

  useEffect(() => {
    if (initialValues) {
      setBannerInfos(initialValues);
    }
  }, [initialValues]);

  return (
    <ContentForm>
      <CRPTitleOne>
        <span>{titleBox}</span>
      </CRPTitleOne>
      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="baseline"
        gap={3}
        flexDirection="row"
        mb={2}
      >
        {children}
        <Col>
          <MDButton onClick={addBanner} {...buttonProps} disabled={disabledButton}>
            {labelButton}
          </MDButton>
          <FormHelperText error>{errors?.active}</FormHelperText>
          <FormHelperText error>{errors?.idSite}</FormHelperText>
        </Col>
      </MDBox>
      <CRPContainerHoriz $justifyContent="space-around">
        <MediaFileField
          value={bannerInfos?.mediaFileDesktop}
          onChange={handleChangeDesktopImage}
          label="Imagem Desktop"
          errorMessage={errors?.idMediaFileDesktop}
          disabled={disabled}
        />
        <MediaFileField
          value={bannerInfos?.mediaFileMobile}
          onChange={handleChangeMobileImage}
          label="Imagem Mobile"
          errorMessage={errors?.idMediaFileMobile}
          disabled={disabled}
        />
      </CRPContainerHoriz>
      <Col mb="12px">
        <Row justifyContent="start" width="100%">
          <Switch
            checked={bannerInfos?.active || false}
            onChange={(e: REvent, checked: boolean) => {
              setBannerInfos((prev) => ({ ...prev, active: checked }));
            }}
            disabled={disabled}
          />
          <MDTypography variant="button" fontWeight="regular" color="text">
            Ativar Banner
          </MDTypography>
        </Row>
        <Row justifyContent="start" width="100%">
          <FormHelperText error sx={{ textAlign: "left" }}>
            {errors?.active}
          </FormHelperText>
        </Row>
      </Col>
      <CRPContainerVert>
        <MDInput
          onChange={(e: REvent) => setBannerInfos((prev) => ({ ...prev, name: e.target.value }))}
          value={bannerInfos?.name || ""}
          label="Nome"
          error={!!errors?.name}
          helperText={errors?.name}
          disabled={disabled}
        />
        <MDInput
          onChange={(e: REvent) => setBannerInfos((prev) => ({ ...prev, title: e.target.value }))}
          value={bannerInfos?.title || ""}
          label="Titulo"
          error={!!errors?.title}
          helperText={errors?.title}
          disabled={disabled}
        />
        <CRPContainerHoriz $justifyContent="initial">
          <MDInput
            label="Url"
            onChange={(e: REvent) => setBannerInfos((prev) => ({ ...prev, url: e.target.value }))}
            value={bannerInfos?.url || ""}
            error={!!errors?.url}
            helperText={errors?.url}
            disabled={disabled}
          />
          <CRPContainerHoriz $justifyContent="initial">
            <Col mb="12px" minWidth="190px">
              <Row justifyContent="start" width="100%">
                <Switch
                  checked={bannerInfos?.targetBlank || false}
                  onChange={(e: REvent, checked: boolean) => {
                    setBannerInfos((prev) => ({ ...prev, targetBlank: checked }));
                  }}
                  disabled={disabled}
                />
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Abrir em outra aba
                </MDTypography>
              </Row>
              <Row justifyContent="start" width="100%">
                <FormHelperText error sx={{ textAlign: "left" }}>
                  {errors?.targetBlank}
                </FormHelperText>
              </Row>
            </Col>
            <MDInput
              label="Ordem"
              inputProps={{ type: "number", min: 1 }}
              onChange={(e: REvent) => {
                if (Number.isInteger(e.target.valueAsNumber) || e.target.value === "") {
                  setBannerInfos((prev) => ({ ...prev, sort: e.target.valueAsNumber || 0 }));
                }
              }}
              disabled={disabled}
              value={bannerInfos?.sort || ""}
              error={!!errors?.sort}
              helperText={errors?.sort}
            />
          </CRPContainerHoriz>
        </CRPContainerHoriz>

        <MDInput
          label="Descrição"
          onChange={(e: REvent) => {
            setBannerInfos((prev) => ({ ...prev, description: e.target.value }));
          }}
          value={bannerInfos?.description || ""}
          error={!!errors?.description}
          helperText={errors?.description}
          disabled={disabled}
        />
        {idSite === Sites.RED_HOUSE.id && (
          <SelectInputControledForm
            label="Idioma"
            onChange={(e: REvent) => {
              setBannerInfos((prev) => ({ ...prev, locale: e.target.value }));
            }}
            formData={bannerInfos}
            name="locale"
            options={[
              { label: "Português", value: "pt_BR" },
              { label: "Inglês", value: "en_US" },
            ]}
          />
        )}
      </CRPContainerVert>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="baseline"
        gap={3}
        flexDirection="row"
        mb={2}
      >
        {children}
        <Col>
          <MDButton onClick={addBanner} {...buttonProps} disabled={disabledButton}>
            {labelButton}
          </MDButton>
          <FormHelperText error>{errors?.active}</FormHelperText>
          <FormHelperText error>{errors?.idSite}</FormHelperText>
        </Col>
      </MDBox>
    </ContentForm>
  );
}

export default FormBanner;

export interface FormBannerProps {
  titleBox: String;
  idSite: number;
  initialValues?: BannerInfos | undefined;
  errors?: ErrorBannerInfos;
  onClickButton?: (banner: BannerInfos) => void;
  labelButton?: string;
  buttonProps?: Omit<MDButtonProps, "onClick">;
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  disabledButton?: boolean;
}
export interface BannerInfos {
  id?: number;
  name: string;
  title: string;
  description: string;
  url: string;
  targetBlank: boolean;
  active: boolean;
  sort: number;
  site: {
    id: number;
  };
  mediaFileDesktop: {
    id: number;
    path: string;
  };
  mediaFileMobile: {
    id: number;
    path: string;
  };
  locale?: string | null;
}
export interface ErrorBannerInfos {
  name?: string;
  title?: string;
  description?: string;
  url?: string;
  targetBlank?: string;
  active?: string;
  sort?: string;
  idSite?: string;
  idMediaFileDesktop?: string;
  idMediaFileMobile?: string;
  form?: string;
}
