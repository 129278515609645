import { path } from "./hooks/types";
import ReadCSVCoursePage from "./ReadCSVCoursePage";

export const ReadCSVCourses = {
  Page: ReadCSVCoursePage,
  path: path,
  Permissions: {
    uploadCsv: "upsert:Course",
  },
};
