import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query EducationalUnits {
    EducationalUnits(perPage: -1, active: true) {
      items {
        id
        visibleName
      }
    }
  }
`);

export interface QueryGql {
  EducationalUnits: {
    items: Array<{
      id: number;
      visibleName: string;
    }>;
  };
}

export default function useEducationalUnits() {
  const [EducationalUnits, setEducationalUnits] = useState<
    null | QueryGql["EducationalUnits"]["items"]
  >(null);

  useEffect(() => {
    ApiCourseRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => setEducationalUnits(data.data.EducationalUnits.items));
  }, []);

  return {
    EducationalUnits,
    setEducationalUnits,
  };
}
