import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { Handler } from "../types";

type CourseGroupsReponse = {
  CourseGroups: {
    items: Array<{
      id: number;
      name: string;
      active: boolean;
    }>;
  };
};
type CourseGroupReponse = {
  CourseGroup: {
    id: number;
    name: string;
    active: boolean;
  };
};
const CourseGroupHandler: Handler<CourseGroupsReponse, CourseGroupReponse> = {
  query: print(gql`
    query CourseGroups($search: String) {
      CourseGroups(search: $search, active: null) {
        items {
          id
          name
          active
        }
      }
    }
  `),
  formater: (data) =>
    data.CourseGroups.items.map((item) => ({
      id: item.id,
      label: item.name,
      active: item.active,
    })),
  api: ApiCourseRequest,
  queryId: print(gql`
    query Query($id: Int!) {
      CourseGroup(id: $id) {
        id
        name
        active
      }
    }
  `),
  formaterId: (data) => ({
    id: data.CourseGroup.id,
    label: data.CourseGroup.name,
    active: data.CourseGroup.active,
  }),
};

export default CourseGroupHandler;
