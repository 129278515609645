import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Partner($id: Int!) {
    Partner(id: $id) {
      id
      name
      visibleName
      description
      imageUrl
      active
      cnpj
      phone
      email
      site
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  Partner: {
    id: number;
    name: string;
    visibleName: string;
    description?: string;
    imageUrl?: string;
    active: boolean;
    cnpj?: string;
    phone?: string;
    email?: string;
    site?: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
