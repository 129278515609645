import { useMemo, useState } from "react";
import { DataRequest, useDataTableProps } from "./types";

export default function useDataTable<T>({
  initialVariables,
  query,
  ApiSetData,
}: useDataTableProps) {
  const [variables, setVariables] = useState<any>();
  const [{ error, loading, data }, setData] = useState<DataRequest>({
    loading: true,
  });

  const handleChangePage = (page: number) => {
    setVariables((prev: any) => ({ ...prev, page }));
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = () => {
    console.log({ searchValue });
    setVariables((prev: any) => ({ ...prev, fileName: searchValue }));
  };

  const handleChangeFilter = ({ active }: { active?: boolean | null }) => {
    setVariables((prev: any) => ({ ...prev, active }));
  };

  useMemo(() => setVariables(initialVariables), [initialVariables]);

  useMemo(() => {
    if (variables) {
      setData((old) => ({ ...old, loading: true }));
      setSearchValue(variables?.fileName || "");
      console.log({ variables });
      ApiSetData({
        query,
        variables,
        setData,
      });
    }
  }, [variables]);

  return {
    handleChangePage,
    handleChangeFilter,
    error,
    loading,
    data,
    handleSearch,
    searchValue,
    setSearchValue,
    variables,
  };
}
