import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { Handler } from "../types";

type CommercialConditionsReponse = {
  CommercialConditions: Array<{
    Id: number;
    Name: string;
  }>;
};
type CommercialConditionReponse = {
  CommercialCondition: {
    Id: number;
    Name: string;
  };
};
const CommercialConditionHandler: Handler<CommercialConditionsReponse, CommercialConditionReponse> =
  {
    query: print(gql`
      query CommercialConditions {
        CommercialConditions {
          Id
          Name
        }
      }
    `),
    formater: (data) =>
      data.CommercialConditions.map((item) => ({
        id: item.Id,
        label: item.Name,
        active: true,
      })),
    api: ApiFinancialRequest,
    queryId: print(gql`
      query Query($Id: Int!) {
        CommercialCondition(Id: $Id) {
          Id
          Name
        }
      }
    `),
    formaterId: (data) => ({
      id: data.CommercialCondition.Id,
      label: data.CommercialCondition.Name,
      active: true,
    }),
  };

export default CommercialConditionHandler;
