import { ClickAwayListener, Dialog, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ComponentProps from "./types";

function ModalCustom(props: ComponentProps) {
  return (
    <Dialog
      open={props.open}
      className="ModalCustom"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "80vw",
        },
        ...props.sx,
      }}
    >
      <ClickAwayListener onClickAway={props.onClose}>
        <Stack>
          <IconButton
            aria-label="close"
            size="large"
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: "9",
              ...props.closeButtonSx,
            }}
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
          {props.children}
        </Stack>
      </ClickAwayListener>
    </Dialog>
  );
}

export default ModalCustom;
