type Types = any;

const grid: Types = {
  styleOverrides: {
    root: {
      wrap: "unset",
      flexWrap: "unset",
    },
  },
};

export default grid;
