import { useEffect } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import { PromotionType, promotionTypeList } from "../schemas/types";
import {
  getTypeDiscount,
  formatRecurrencyState,
  getSchemaJsonWithRequired,
} from "../schemas/format";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  useEffect(() => {
    const type = new URLSearchParams(location.search).get("type") as PromotionType;
    if (!type || !promotionTypeList.includes(type)) {
      throw new Error(`Unsupported promotion type: ${type}`);
    }

    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiFinancialRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.Promotion) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          setTemplate({
            schemaJson: getSchemaJsonWithRequired(type),
            UISchema: require(`../schemas/${type}/UISchema.json`),
          });

          const data = response?.data?.data?.Promotion;

          let dataFormatted: SchemaData = {
            ...data,
            useRecurrencyCrons: !!data.recurrencyCrons?.length,
            discountType: getTypeDiscount(data, "type") as any,
            discountValue: getTypeDiscount(data, "value") as number,
            ...formatRecurrencyState(data),
            defineEligbleProducts:
              data.products?.length || data.categories?.length || data.skus?.length
                ? "BenefitAppliedToFollowingProducts"
                : "BenefitAppliedToAllProducts",
            utmSource: data.utmSource?.split(","),
            utmCampaign: data.utmCampaign?.split(","),
            utmiCampaign: data.utmiCampaign?.split(","),
            maxUsageUnlimited: !data.maxUsage,
            maxUsagePerClientUnlimited: !data.maxUsagePerClient,
            maxNumberOfAffectedItemsUnlimited: !data.maxNumberOfAffectedItems,
            maxNumberOfAffectedItems: !data.maxNumberOfAffectedItems
              ? undefined
              : data.maxNumberOfAffectedItems,
            maxUsage: !data.maxUsage ? undefined : data.maxUsage,
            maxUsagePerClient: !data.maxUsagePerClient ? undefined : data.maxUsagePerClient,
          };

          if (type === "forThePriceOf") {
            dataFormatted = {
              ...dataFormatted,
              discountTypeMoreForLess: data.percentualDiscountValue
                ? "percentualDiscountValue"
                : "maximumUnitPriceDiscount",
              morForLessDiscountValue:
                data.percentualDiscountValue > 0
                  ? data.percentualDiscountValue
                  : data.maximumUnitPriceDiscount,
            };
          }

          setState({
            data: removeNullValues(dataFormatted),
          });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
