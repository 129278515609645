import Create from "./Create";
import { path } from "./hooks/types";
import List from "./List";
import Update from "./Update";

export const Colligates = {
  List: List,
  Update: Update,
  Create: Create,
  path: path,
  Permissions: {
    create: "create:Colligate",
    edit: "update:Colligate",
    read: "read:Colligate",
    delete: "delete:Colligate",
    list: "list:Colligate",
  },
};
