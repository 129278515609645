export const QUERY_BANNERS = `query Banners($page: Int, $perPage: Int, $search: String, $active: Boolean, $siteId: Int) {
  banners(page: $page, perPage: $perPage, search: $search, active: $active, siteId: $siteId) {
    items {
      id
      name
      title
      description
      url
      targetBlank
      sort
      mediaFileDesktop {
        id
        name
        path
      }
      mediaFileMobile {
        id
        name
        path
      }
      active
      site {
        id
        name
      }
    }
    paginationInfo {
      hasMore
      page
      perPage
      totalData
      totalPages
      nextPage {
        page
        perPage
      }
    }
  }
}
`;
export type VariablesTypeBanners = {
  page?: number | null;
  perPage?: number | null;
  search?: string | null;
  active?: boolean | null;
  siteId?: number | null;
};
export type ResponseBanners = {
  banners: {
    items: {
      id: number;
      name: string;
      title: string;
      description: string;
      url: string;
      targetBlank: boolean;
      sort: number;
      mediaFileDesktop: {
        id: number;
        name: string;
        path: string;
      };
      mediaFileMobile: {
        id: number;
        name: string;
        path: string;
      };
      active: boolean;
      site: {
        id: number;
        name: string;
      };
    }[];
    paginationInfo: {
      hasMore: boolean;
      page: number;
      perPage: number;
      totalData: number;
      totalPages: number;
      nextPage?: null | {
        page: number;
        perPage: number;
      };
    };
  };
};
