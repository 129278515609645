import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Calendar = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    list: "list:Calendar",
    create: "create:Calendar",
    read: "read:Calendar",
    update: "update:Calendar",
    delete: "delete:Calendar",
  },
};
