import {
  Card,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/pagesExamples/authentication/components/BasicLayout";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ApiAuthRequest } from "data/ApiAuth";
import { print } from "graphql";
import { gql } from "@apollo/client";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";

function RecoverPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState({
    password: "",
    repeat: "",
  });
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const changePassword = () => {
    const token = new URLSearchParams(location.search).get("token");

    if (!token) {
      setMessage("Token necessário para está ação.");
      return;
    }

    if (newPassword.password !== newPassword.repeat) {
      setMessage("As senhas devem ser iguais.");
      return;
    }

    setLoading(true);
    ApiAuthRequest<{ ChangePassword: { email: string } }>({
      query: print(gql`
        mutation ChangePassword($token: String!, $newPassword: String!) {
          ChangePassword(token: $token, newPassword: $newPassword) {
            email
          }
        }
      `),
      variables: { token, newPassword: newPassword.password },
    })
      .then(() => {
        setMessage("Senha alterada com sucesso!");
        setTimeout(() => navigate("/auth/login"), 3000);
      })
      .catch((e) => {
        setMessage("Algo deu errado!");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Recuperação de senha
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <Typography
              component={"p"}
              sx={{
                fontSize: "1rem",
                color: "#344767",
                fontWeight: 400,
              }}
            >
              Insira sua nova senha:
            </Typography>
            <Stack gap={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="standard-adornment-password">Nova senha</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e: any) => {
                    setNewPassword((old) => ({ ...old, password: e.target.value }));
                  }}
                  value={newPassword.password}
                  sx={{ width: "100%" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Mostrar Senha" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Repita a senha novamente
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e: any) => {
                    setNewPassword((old) => ({ ...old, repeat: e.target.value }));
                  }}
                  value={newPassword.repeat}
                  sx={{ width: "100%" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Mostrar Senha" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <MDButton
                onClick={changePassword}
                variant="gradient"
                color="info"
                disabled={loading}
                sx={{
                  "& .MuiCircularProgress-root": {
                    color: "white !important",
                    width: "22px !important",
                    height: "auto !important",
                  },
                }}
              >
                {loading ? <CircularProgress /> : <span>Salvar</span>}
              </MDButton>
            </Stack>
          </MDBox>
        </Card>
      </BasicLayout>
      <Snackbar
        open={!!message}
        autoHideDuration={5000}
        onClose={() => setMessage(undefined)}
        message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setMessage(undefined)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Fragment>
  );
}

export default RecoverPassword;
