import { print } from "graphql";
import { gql } from "@apollo/client";
import { DataInfo } from "./metrics";

export const QUERY_SALLES_FUNNEL = print(gql`
  query SalesFunnel(
    $startDate: String!
    $endDate: String!
    $compareStartDate: String!
    $compareEndDate: String!
  ) {
    SalesFunnel(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      conversion {
        referenceData
        comparedData
      }
      salesFunnel {
        totalSessions {
          referenceData
          comparedData
        }
        productPageView {
          referenceData
          comparedData
        }
        cartPageView {
          referenceData
          comparedData
        }
        shippingPageView {
          referenceData
          comparedData
        }
        paymentPageView {
          referenceData
          comparedData
        }
        ordersPlaced {
          referenceData
          comparedData
        }
      }
    }
  }
`);

export interface QuerySalesFunnelGql {
  SalesFunnel: {
    conversion: DataInfo;
    salesFunnel: {
      totalSessions: DataInfo;
      productPageView: DataInfo;
      cartPageView: DataInfo;
      shippingPageView: DataInfo;
      paymentPageView: DataInfo;
      ordersPlaced: DataInfo;
    };
  };
}
