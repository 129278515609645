/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox, { MDBoxProps } from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";
import Container from "./Container";

// Declaring prop types for DefaultStatisticsCard
interface Props {
  title: string;
  count: string | number;
  percentage?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "white";
    value: string | number;
    label?: string;
  };
  [key: string]: any;
  sx?: MDBoxProps["sx"];
}

function SimpleCard({ title, count, percentage, sx }: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Container className="container" sx={sx}>
      <MDBox mb={0.5} lineHeight={1}>
        <MDTypography variant="button" fontWeight="medium" color="text" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox lineHeight={1}>
        <MDTypography variant="h5" fontWeight="bold">
          {count}
        </MDTypography>
        {percentage?.value && (
          <MDTypography variant="button" fontWeight="bold" color={percentage.color}>
            {percentage.value}&nbsp;
            {percentage.label && (
              <MDTypography
                variant="button"
                fontWeight="regular"
                color={darkMode ? "text" : "secondary"}
              >
                {percentage.label}
              </MDTypography>
            )}
          </MDTypography>
        )}
      </MDBox>
    </Container>
  );
}

export default SimpleCard;
