import { useEffect } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import { ApiAuthRequest } from "data/ApiAuth";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiAuthRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        headers: {
          Authorization: `${queryParam}`,
        },
      })
        .then((response) => {
          if (!response?.data?.data?.Me) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          setTemplate({
            schemaJson: schemaJson,
            UISchema: UISchemaJson,
          });

          const { birthDate, ..._data } = response.data.data?.Me;

          const data = removeNullValues({
            ..._data,
            birthDate: birthDate.split("T")[0],
          });

          setState({
            data,
          });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
