import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiRequest, path, SchemaDataCreate } from "./types";
import { CreateGql, CREATE_QUERY, responseCreateKey } from "../queries/create";
import { path as pathCourse } from "../../CoursesFinancial/hooks/types";

export default function useCreate() {
  const navigate = useNavigate();
  const courseId = Number(useParams()?.courseId);

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaDataCreate>) => {
    const {
      noPlanAdmissionWays,
      noAdmissionWays,
      createPlanAdmissionWay,
      createAdmissionWay,
      activateIfPossible,
      active,
      ...data
    } = state?.data;

    const response = await ApiRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data: {
          ...data,
          activateIfPossible,
          courseId,
        },
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data || !response?.data?.data?.[responseCreateKey]?.courseId) {
      throw new Error("Ops. Sem resposta!");
    }

    // navigate(`${path}/${response.data.data[responseCreateKey].id}`);
    navigate(`${pathCourse}/${response.data.data[responseCreateKey].courseId}`);

    return response;
  }, []);

  return {
    handleSave,
  };
}
