import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiRequest, SchemaDataCreate } from "./types";
import { schemaJson as schemaJsonCreate, schemaJsonEdit } from "../schema/SchemaJson";
import UISchemaCreate from "../schema/UISchema.json";
import UISchemaEdit from "../schema/UISchemaEdit.json";
import { cloneDeep } from "lodash";

const QUERY = `
  query Course($courseId: Int) {
    Course(id: $courseId) {
      category {
        templateInvestmentName
      }
    }
    AdmissionWays(perPage: 200, courseId: $courseId) {
      items {
        id
        name
        templateInvestmentName
        content
        plans {
          id
          title
          tag
        }
      }
    }
  }
`;

interface QueryGql {
  Course: {
    category: {
      templateInvestmentName: string;
    };
  };
  AdmissionWays: {
    items: {
      id: number;
      name: string;
      content: string;
      templateInvestmentName: string;
      plans: { id: number; title: string; tag: string }[];
    }[];
  };
}

export default function useTemplate(create?: boolean, courseId?: number) {
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const [state, setState] = useState<JsonFormsStateProps<SchemaDataCreate>>({ loading: true });
  const [admissionWayRespose, setAdmissionWayResponse] = useState<QueryGql>();

  useEffect(() => {
    if (!create) {
      // console.log("Not create");
      //CANOT EDIT Plan
      setTemplate({
        schemaJson: schemaJsonEdit,
        UISchema: UISchemaEdit,
      });
      setState((old) => ({ ...old, loading: false }));

      return;
    }
    // console.log("create", { status: !courseId ? "ERRO. sem curso" : "OK", courseId });
    if (!courseId) return; //ERROOO

    ApiRequest<QueryGql>({
      query: QUERY,
      variables: { courseId },
    })
      .then(({ data }) => {
        //handle Course
        // console.log({ ApiRequestGetAdmissionWaysData: data });

        // No Admission Ways
        if (!data?.data?.AdmissionWays?.items?.length) {
          setState(({ data, ...old }) => ({
            ...old,
            data: {
              ...data,
              noAdmissionWays: true,
              noPlanAdmissionWays: true,
              createAdmissionWay: true,
              createPlanAdmissionWay: true,
            },
          }));
          setTemplate({
            schemaJson: schemaJsonCreate,
            UISchema: UISchemaCreate,
          });
          return;
        }
        setAdmissionWayResponse(data.data);

        const admissionWayIdOneOf =
          data.data.AdmissionWays.items
            .map(({ name, templateInvestmentName, id }) => ({
              title: `${templateInvestmentName} - ${name}`,
              const: id,
            }))
            ?.flat() || [];

        const planAdmissionWayIdOneOf =
          data.data.AdmissionWays.items
            .map(
              ({ name, templateInvestmentName, plans }) =>
                plans?.map(({ id: planId, title }) => ({
                  title: `${templateInvestmentName} - ${name} - ${title}`,
                  const: planId,
                })) || []
            )
            ?.flat() || [];

        // console.log({ planAdmissionWayIdOneOf, admissionWayIdOneOf });

        setState(({ data, ...old }) => ({
          ...old,
          data: {
            ...data,
            noAdmissionWays: !admissionWayIdOneOf?.length,
            noPlanAdmissionWays: !planAdmissionWayIdOneOf?.length,
            createAdmissionWay: !admissionWayIdOneOf?.length,
            createPlanAdmissionWay: !planAdmissionWayIdOneOf?.length,
          },
        }));
        const schemaJson: any = cloneDeep(schemaJsonCreate);
        schemaJson.properties.planAdmissionWay.properties.id = !planAdmissionWayIdOneOf?.length
          ? { type: "number" }
          : {
              type: "number",
              oneOf: planAdmissionWayIdOneOf.slice(0, 200), // to avoid error
            };

        schemaJson.properties.planAdmissionWay.properties.admissionWay.properties.id =
          !admissionWayIdOneOf?.length
            ? { type: "number" }
            : {
                type: "number",
                oneOf: admissionWayIdOneOf.slice(0, 200), // to avoid error
              };

        // console.log({ schemaJson, UISchemaCreate });

        setTemplate({
          schemaJson: schemaJson,
          UISchema: UISchemaCreate,
        });
        return;
      })
      .catch((e) => {
        console.log(e);
        setState((old) => ({
          ...old,
          criticalError: { message: "Curso não encontrado" },
          loading: false,
        }));
      })
      .finally(() => {
        setState((old) => ({ ...old, loading: false }));
      });
  }, [courseId]);

  useEffect(() => {
    // console.log("useEffect state?.data?.planAdmissionWay?.admissionWay?.id", {
    //   admissionWayId: state?.data?.planAdmissionWay?.admissionWay?.id,
    //   planId: state?.data?.planAdmissionWay?.id,
    //   admissionWayRespose,
    // });

    //Select Admission Way
    if (state?.data?.planAdmissionWay?.admissionWay?.id) {
      const founded = admissionWayRespose.AdmissionWays.items?.find(
        ({ id }) => id === state?.data?.planAdmissionWay?.admissionWay?.id
      );
      // console.log("Select Admission Way, founded AdmissionWays", { founded });
      if (!founded.name) return;
      setState((old) => ({
        ...old,
        data: {
          ...old?.data,
          createAdmissionWay: false,
          planAdmissionWay: {
            ...old?.data?.planAdmissionWay,
            admissionWay: {
              ...old?.data?.planAdmissionWay?.admissionWay,
              name: founded.name,
              content: founded?.content || "",
            },
          },
        },
      }));
    }

    //Select Plan
    if (state?.data?.planAdmissionWay?.id) {
      const plans = admissionWayRespose.AdmissionWays.items
        .map(({ plans, ...admissionWay }) => [...plans.map((plan) => ({ ...plan, admissionWay }))])
        .flat();
      const founded = plans?.find(({ id }) => id === state.data.planAdmissionWay.id);
      // console.log("Select Plan, founded planAdmissionWay", { founded });
      if (!founded.title) return;
      setState((old) => ({
        ...old,
        data: {
          ...old?.data,
          createPlanAdmissionWay: false,
          createAdmissionWay: false,
          planAdmissionWay: {
            ...old?.data?.planAdmissionWay,
            title: founded.title,
            tag: founded?.tag || "",
            admissionWay: {
              id: founded.admissionWay.id,
              name: founded.admissionWay.name,
              content: founded.admissionWay?.content || "",
            },
          },
        },
      }));
    }
  }, [
    admissionWayRespose,
    state?.data?.planAdmissionWay?.admissionWay?.id,
    state?.data?.planAdmissionWay?.id,
  ]);

  useEffect(() => {
    // console.log("useEffect state?.data?.createAdmissionWay or createPlanAdmissionWay", {
    //   createAdmissionWay: state?.data?.createAdmissionWay,
    //   createPlanAdmissionWay: state?.data?.createPlanAdmissionWay,
    // });
    if (state?.data?.createAdmissionWay) {
      // console.log("useEffect state?.data?.createAdmissionWay setState admissionWay id");
      setState((old) => ({
        ...old,
        data: {
          ...old?.data,
          planAdmissionWay: {
            ...old?.data?.planAdmissionWay,
            admissionWay: {
              ...old?.data?.planAdmissionWay?.admissionWay,
              id: undefined,
            },
          },
        },
      }));
    }

    if (state?.data?.createPlanAdmissionWay) {
      // console.log("useEffect state?.data?.createPlanAdmissionWay setState Plan id");
      setState((old) => ({
        ...old,
        data: {
          ...old?.data,
          planAdmissionWay: {
            ...old?.data?.planAdmissionWay,
            id: undefined,
          },
        },
      }));
    }
  }, [state?.data?.createAdmissionWay, state?.data?.createPlanAdmissionWay]);

  return {
    template,
    state,
    setState,
  };
}
