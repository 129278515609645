import { SchemaData, UseUpdateProps } from "./types";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiAuthRequest } from "data/ApiForm";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const data = state?.data;

      const response = await ApiAuthRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            id: data.id,
            // can't edit  // slug: data?.slug,
            // can't edit  // status: data?.status,
            subtitle: data?.subtitle,
            title: data?.title,
            description: data?.description,
            // extraJson: data?.extraJson,
            formMessageHtml: data?.formMessageHtml,
            submitButtonText: data?.submitButtonText,
            requiredUserId: data?.requiredUserId,
            // can't edit  // publishOn: data?.publishOn,
            // can't edit  // expiresIn: data?.expiresIn,
            keywords: data?.keywords,
            addSiteMap: data?.addSiteMap,
            sendResponsesTo: data?.sendResponsesTo || [],
            formProperties: data?.formProperties || [],
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
