import { ApiCMSRequest } from "data/ApiCMS";
import { SchemaData, UseUpdateProps } from "./types";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const data = state?.data;

      const response = await ApiCMSRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            name: data.details.name,
            status: data.details.status,
            publishOn: data.details.publishOn,
            expiresIn: data.details.expiresIn,
            contentJSON: data.dynamicPageContent.contentHtml,
            slug: data.details.slug,
            title: data.details.title,
            description: data.details.description,
            keywords: data.details.keywords,
            addSiteMap: data.details.addSiteMap ?? false,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
