import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_PAYMENT_METHOD = print(gql`
  mutation UpdatePaymentMethod($data: UpdateInput!) {
    updatePaymentMethod(data: $data) {
      id
    }
  }
`);

export interface UpdateInput {
  data: {
    id: string;
    salesChannels: [
      {
        id: null;
      }
    ];
    requiresAuthentication: null;
    paymentSystem: {
      name: null;
      implementation: null;
      id: null;
    };
    paymentOptions: [
      {
        interestRate: null;
        days: null;
      }
    ];
    name: null;
    multiMerchantList: null;
    minimumValue: null;
    issuer: {
      name: null;
    };
    isSelfAuthorized: null;
    isDefault: null;
    installmentsService: null;
    installmentOptions: {
      minimumInstallmentValue: null;
      interestRateMethod: null;
      installments: [
        {
          value: null;
          ruleId: null;
          quantity: null;
          isExternalInstallmentService: null;
          interestTax: null;
          interestRate: null;
        }
      ];
      dueDateType: null;
    };
    externalInterest: null;
    excludedBinsRanges: null;
    endDate: null;
    enabled: null;
    deadlines: [
      {
        interestRate: null;
        days: null;
      }
    ];
    dateIntervals: [
      {
        start: null;
        end: null;
      }
    ];
    country: {
      name: null;
      isoCode: null;
    };
    connector: {
      implementation: null;
      affiliationId: null;
    };
    condition: {
      pullRate: null;
      name: null;
      id: null;
    };
    cobrand: {
      name: null;
    };
    cardLevel: {
      name: null;
    };
    beginDate: null;
    antifraud: {
      implementation: null;
      affiliationId: null;
    };
  };
}

export interface updatePaymentMethod {
  updatePaymentMethod: {
    id: string;
  };
}
