import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const BlogCategories = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:BlogCategory",
    read: "read:BlogCategory",
    list: "list:BlogCategory",
    update: "update:BlogCategory",
    delete: "delete:BlogCategory",
  },
};
