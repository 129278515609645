import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateTemplateEmail($data: UpdateTemplateEmailInput!) {
    updateTemplateEmail(data: $data) {
      id
      name
      fileName
      sendCopyTo
      subjectDefault
      jsonDataExample
      createdAt
      updatedAt
    }
  }
`);

export interface UpdateGql {
  updateTemplateEmail: {
    id: 1;
    name: string;
    fileName: string;
    sendCopyTo: string[];
    subjectDefault: string;
    jsonDataExample: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
