import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Categories {
    Categories(perPage: -1, active: null, parentId: 0) {
      items {
        name
        parentId
        id
        level
      }
    }
  }
`);

export interface GetAllItem {
  name: string;
  parentId: number | null;
  id: number;
  level: number;
}

export interface GetAllGql {
  Categories: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}

export interface NodeTree extends GetAllItem {
  children?: NodeTree[];
}
