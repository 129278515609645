import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query EducationalUnits($page: Int, $perPage: Int, $search: String, $active: Boolean) {
    EducationalUnits(page: $page, perPage: $perPage, search: $search, active: $active) {
      items {
        active
        city
        name
        state
        id
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  active: boolean;
  city: string;
  name: string;
  state: string;
  id: number;
}

export interface GetAllGql {
  EducationalUnits: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
