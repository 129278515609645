import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_PAYMENT_PROVIDERS = print(gql`
  query PaymentProviders {
    PaymentProviders {
      id
      name
      implementation
      groupName
    }
  }
`);

export interface PaymentProvidersGQL {
  PaymentProviders: Array<{
    id: string;
    name: string;
    implementation: string;
    groupName: string;
  }>;
}
