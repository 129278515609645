import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  CRPContainerVert,
  CRPBannerInputDesk,
  CRPBannerLabelDesktop,
  CRPContainerHoriz,
} from "components/CRPComponents/CRPAddBanner.style";
import { MDBox, MDBoxProps } from "components/MDBox";
import {
  MidiaGallery,
  MidiaGalleryProps,
  MidiaAndUrl as MidiaAndUrlRoot,
} from "components/CRPComponents/MidiaGallery";
import MDSnackbar from "components/MDSnackbar";
import { getImageCmsURL, ApiCMSWithSetData, DataState } from "data/ApiCMS";
import {
  QUERY_MEDIA_FILES,
  VariablesTypeMediaFiles,
  ResponseMediaFiles,
} from "data/queries/ApiCMS/QueryMediaFile";
import {
  ApiCMSCreateMidiaFile,
  ResponseCreateMediaFile,
} from "data/queries/ApiCMS/MutationCreateMediaFile";

import SearchInputControled from "../components/SearchInputControled";
import MDInput from "components/MDInput";
import { MenuItem } from "@mui/material";
import Row from "components/Row";

export type VariablesProps = VariablesTypeMediaFiles;
export type MidiaAndUrl = MidiaAndUrlRoot;
export interface MidiaGalleryWithUploadProps extends MDBoxProps {
  onClickMidia?: MidiaGalleryProps["onClickMidia"];
  variables: VariablesProps;
  onChangeVariables: React.Dispatch<React.SetStateAction<VariablesProps>>;
  hideFileTypeSelect?: boolean;
  hideUploadButton?: boolean;
}

export function MidiaGalleryWithUpload({
  onClickMidia,
  variables,
  onChangeVariables,
  hideFileTypeSelect = false,
  hideUploadButton = false,
  ...props
}: MidiaGalleryWithUploadProps) {
  const [searchValue, setSearchValue] = useState("");
  const [{ error, loading, data }, setData] = useState<DataState<ResponseMediaFiles>>({
    loading: true,
  });
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    onChangeVariables((prev) => ({ ...prev, page }));
  };
  const handleSearch = () => {
    onChangeVariables((prev) => ({ ...prev, search: searchValue }));
  };

  const handleChangeFileType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hideFileTypeSelect) return;
    onChangeVariables((prev) => ({ ...prev, fileType: e.target.value }));
  };

  const [uploadFile, setUploadFile] = useState<DataState<ResponseCreateMediaFile>>({
    loading: false,
  });
  const handleUploadFile = (file: any) => {
    setUploadFile({ loading: true });
    ApiCMSCreateMidiaFile(file)
      .then((response) => {
        if (!!response?.data?.data?.createMediaFile?.name) {
          onChangeVariables({ page: 1, search: response.data.data.createMediaFile.name });
          return setUploadFile({ data: response.data.data, loading: false });
        }
        onChangeVariables((prev) => prev);
        return setUploadFile({ data: response?.data?.data, loading: false });
      })
      .catch((error) => {
        return setUploadFile({
          loading: false,
          error: error?.response?.data?.errors?.[0] || error,
        });
      });
  };
  const handleCloseSnackbar = () => {
    setUploadFile((prev) => ({ ...prev, data: undefined, error: undefined }));
  };
  const handleUpload = (e: any) => {
    if (e?.target?.files?.[0] && e?.target?.validity?.valid && !hideUploadButton) {
      return handleUploadFile(e.target.files[0]);
    }
    setUploadFile({
      loading: false,
      error: new Error("Erro ao selecionar aquivo"),
    });
    return;
  };

  useEffect(() => {
    setSearchValue(variables?.search || "");
    ApiCMSWithSetData({
      query: QUERY_MEDIA_FILES,
      variables,
      setData,
    });
  }, [variables]);

  if (loading) {
    return (
      <MDBox {...props}>
        <p>Loading...</p>
      </MDBox>
    );
  }
  if (error) {
    return (
      <MDBox {...props}>
        <p>Error : {error.message}</p>
      </MDBox>
    );
  }

  return (
    <MDBox {...props}>
      <CRPContainerVert>
        <CRPContainerHoriz $justifyContent="space-beetween">
          <Row justifyContent="start" alignItems="flex-start" width="100%" p="0 1rem">
            <SearchInputControled
              onSubmitSearch={handleSearch}
              value={searchValue}
              onChangeValue={setSearchValue}
              disabled={uploadFile?.loading || loading}
            />
          </Row>
          <Row justifyContent="end" alignItems="flex-start" width="100%">
            {!hideFileTypeSelect && (
              <MDInput
                select
                label="Tipo de Arquivo"
                onChange={handleChangeFileType}
                value={variables?.fileType || null}
              >
                <MenuItem value="IMAGES">Imagens</MenuItem>
                <MenuItem value="DOCUMENTS">Documentos</MenuItem>
                <MenuItem value="SOUNDS">Audios</MenuItem>
                <MenuItem value="VIDEOS">Videos</MenuItem>
              </MDInput>
            )}
            {!hideUploadButton && (
              <CRPContainerVert>
                <CRPBannerInputDesk onChange={handleUpload} disabled={uploadFile?.loading} />
                <CRPBannerLabelDesktop>
                  <CloudUploadIcon /> {uploadFile?.loading ? "Loading ..." : "Upload novo arquivo"}
                </CRPBannerLabelDesktop>
                <MDSnackbar
                  color="success"
                  icon="check"
                  title={`Upload feito com sucesso! Nome: ${uploadFile?.data?.createMediaFile?.name}`}
                  open={!!uploadFile?.data?.createMediaFile?.name}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackbar}
                  close={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                />
                <MDSnackbar
                  color="error"
                  icon="error"
                  title={`Erro ao Fazer Upload`}
                  content={uploadFile?.error?.message}
                  open={!!uploadFile?.error}
                  autoHideDuration={10000}
                  onClose={handleCloseSnackbar}
                  close={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                />
              </CRPContainerVert>
            )}
          </Row>
        </CRPContainerHoriz>

        <div>Galeria</div>
        <MidiaGallery
          midias={data?.mediaFiles?.items || []}
          onClickMidia={onClickMidia}
          urlPrePath={getImageCmsURL("")}
        />

        <CRPContainerVert>
          {data.mediaFiles.paginationInfo.totalPages > 1 && (
            <Pagination
              count={data.mediaFiles.paginationInfo.totalPages}
              page={variables.page}
              onChange={handleChangePage}
            />
          )}
        </CRPContainerVert>
      </CRPContainerVert>
    </MDBox>
  );
}

MidiaGalleryWithUpload.defaultProps = {
  bgColor: "#fff",
  borderRadius: "10px",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px 0 ",
};

export default MidiaGalleryWithUpload;
