import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";
import { path as pathAdmissionWayEdit } from "../AdmissionWays/hooks/types";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql, responseGetAllKey } from "./queries/getAll";
import { path } from "./hooks/types";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
        }}
        useDefaultFilter={false}
        path={path}
        query={GET_ALL_QUERY}
        formatRows={(data) => data[responseGetAllKey].items}
        formatPagination={(data) => data[responseGetAllKey].paginationInfo}
        title="Planos"
        ApiSetData={ApiFinancialWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => <LinkCell href={`${path}/${value}`} value={value} />,
          },
          {
            Header: "Título",
            accessor: "title",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Tag",
            accessor: "tag",
            Cell: ({ value }: any) => <DefaultCell value={value ?? ""} />,
          },
          {
            Header: "Forma De Ingresso",
            accessor: "admissionWayId",
            Cell: ({ value }: any) => (
              <LinkCell href={`${pathAdmissionWayEdit}/${value}`} value={value} />
            ),
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
