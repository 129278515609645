import TagCell from "../TagCell";

// Declaring props types for ActiveOrInactiveCell
interface ActiveOrInactiveCellProps {
  value: boolean;
}

function ActiveOrInactiveCell({ value }: ActiveOrInactiveCellProps): JSX.Element {
  return <TagCell value={!value ? "Inativo" : "Ativo"} color={!value ? "error" : "success"} />;
}

export default ActiveOrInactiveCell;
