import { useEffect } from "react";
import { removeNullValues } from "utils/removeNullValues";
import { schemaJson } from "../schema/SchemaJson";
import UISchema from "../schema/UISchema.json";
import UISchemaNoList from "../schema/UISchemaNoList.json";
import { GET_ITEM_QUERY, GetItemGql, responseGetItemKey } from "../queries/getItem";
import { ApiRequest, SchemaData, UseGetItemProps } from "./types";
import getCourseFinancialDependencies from "./getCourseFinancialDependencies";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then(async (response) => {
          if (!response?.data?.data?.[responseGetItemKey]) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const dependencies = await getCourseFinancialDependencies();
          if (!dependencies) {
            setTemplate({
              schemaJson: schemaJson,
              UISchema: UISchemaNoList,
            });
          }

          const categoryIdOneOf =
            dependencies?.Categories?.items
              ?.filter(({ id, active }) => id === response.data.data.Course.categoryId || active)
              ?.map(({ id, slug }) => ({ const: id, title: slug })) || [];
          const partnerBrandIdOneOf =
            dependencies?.PartnersBrands?.items
              ?.filter(
                ({ id, active }) => id === response.data.data.Course.partnerBrandId || active
              )
              ?.map(({ id, visibleName }) => ({ const: id, title: visibleName })) || [];
          const investmentInfoIdOneOf =
            dependencies?.InvestmentInfos?.items?.map(({ id, subtitle, title }) => ({
              const: id,
              title: `${subtitle} ${title}`,
            })) || [];

          const categoryId = categoryIdOneOf?.length
            ? { type: "number", oneOf: categoryIdOneOf }
            : { type: "number" };
          const partnerBrandId = partnerBrandIdOneOf?.length
            ? { type: "number", oneOf: partnerBrandIdOneOf }
            : { type: "number" };
          const investmentInfoId = investmentInfoIdOneOf?.length
            ? { type: "number", oneOf: investmentInfoIdOneOf }
            : { type: "number" };

          setTemplate({
            schemaJson: {
              ...schemaJson,
              properties: {
                ...schemaJson.properties,
                categoryId,
                partnerBrandId,
                investmentInfoId,
              },
            },
            UISchema: UISchema,
          });

          const data = response.data.data?.[responseGetItemKey];

          setState({
            data: removeNullValues(data),
          });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
