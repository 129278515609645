import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation SyncTotvsCourses($data: SyncTotvsCoursesInput!) {
    syncTotvsCourses(data: $data) {
      updatesCourses
      updatesHabilitations
      providerHabilitations {
        id
        updatedAt
      }
    }
  }
`);
export interface CreateGql {
  syncTotvsCourses: {
    updatesCourses: number;
    updatesHabilitations: number;
    providerHabilitations: {
      id: number;
      updatedAt: Date;
    }[];
  };
}
