import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeleteFacultyMember($id: Int!) {
    deleteFacultyMember(id: $id) {
      id
    }
  }
`);

export interface DeleteGql {
  deleteFacultyMember: {
    id?: number;
  };
}
