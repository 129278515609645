import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const BlogTags = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:Tag",
    read: "read:Tag",
    list: "list:Tag",
    update: "update:Tag",
    delete: "delete:Tag",
  },
};
