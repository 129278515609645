import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query HighlightCourseShelf($id: Int) {
    HighlightCourseShelf(id: $id) {
      id
      name
      active
      content {
        courseId
        sortValue
      }
    }
  }
`);

export interface GetItemGql {
  HighlightCourseShelf: {
    id: number;
    name: string;
    active: boolean;
    content: Array<{
      courseId: number;
      sortValue: number;
    }>;
  };
}
