import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ListProps } from "./types";
import { NodeTree } from "./queries/getAll";
import { Box, IconButton, Stack } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/ModeEditRounded";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import useGetAll from "./hooks/useGetAll";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";

function List(props: ListProps) {
  const { categories } = useGetAll();
  const [tree, setTree] = useState<NodeTree[]>();

  useEffect(() => {
    if (!categories) return;

    setTree(buildTree(categories));
  }, [categories]);

  if (!tree) return null;

  const renderLabel = (node: NodeTree) => {
    return (
      <Stack direction={"row"} gap={"40px"}>
        <Box
          component={"span"}
          sx={{
            fontFamily: "Roboto",
            color: "#344767",
            fontSize: "1.2rem",
          }}
        >
          {`${node.id} - ${node.name}`}
        </Box>
        <Link to={`/ecommerce/nivel-de-ensino/${node.id}`}>
          <IconButton
            sx={{
              width: 32,
              height: 32,
              borderRadius: "6px",
              background: "white",
              border: "2px solid #1A73E8",
              "&:hover": {
                background: "#1A73E8",
                border: "none",
                svg: {
                  color: "white !important",
                },
              },
            }}
          >
            <EditIcon
              sx={{
                color: "#1A73E8",
                width: 17,
                height: 17,
              }}
            />
          </IconButton>
        </Link>
      </Stack>
    );
  };

  const renderTree = (nodes: NodeTree) => (
    <TreeItem key={nodes.id} itemId={String(nodes.id)} label={renderLabel(nodes)}>
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  function buildTree(data: NodeTree[], parentId: number | null = null): NodeTree[] {
    const tree: { [key: number]: NodeTree } = {};
    data.forEach((item) => {
      if (item.parentId === parentId) {
        const children = buildTree(data, item.id);
        if (children.length > 0) {
          item.children = children;
        }
        tree[item.id] = item;
      }
    });

    return Object.values(tree);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton variant="gradient" color="info" href={`/ecommerce/nivel-de-ensino/new`}>
        Criar Novo Nível de ensino
      </MDButton>
      <Box
        sx={{
          height: "100%",
          marginBlock: 5,
          flexGrow: 1,
          "& .MuiTreeItem-content": {
            background: "white !important",
          },
          background: "white !important",
          "& .MuiTreeItem-iconContainer .MuiSvgIcon-root": {
            color: "#1A73E8",
            width: 26,
            height: 26,
            transition: "all 0.4s ease-in",
          },
          color: "rgba(0, 0, 0, 0.87)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderRadius: "4px",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          minWidth: "100%",
          backgroundColor: "#ffffff !important",
          backgroundClip: "border-box",
          padding: "1.5rem",
          position: "relative",
        }}
      >
        <SimpleTreeView>{tree?.map((category) => renderTree(category))}</SimpleTreeView>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default List;
