import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { GetItemGql } from "./getItem";

export const UPDATE_QUERY = print(gql`
  mutation DeletePage($data: UpdatePageInput!) {
    updatePage(data: $data) {
      id
      name
      status
      publishOn
      expiresIn
      author
      contentJSON
      dynamicPage
      templateSchema {
        id
        name
        schemaJson
        UISchema
      }
      slug
      title
      description
      keywords
      addSiteMap
      createdAt
      updatedAt
    }
  }
`);

export interface UpdateGql {
  updatePage: GetItemGql["Page"];
}
