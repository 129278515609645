import GenericTable from "../Tables/GenericTable";
import MDButton from "components/MDButton";
import DefaultCell from "../Tables/components/DefaultCell";
import ActiveOrInactiveCell from "../Tables/components/ActiveOrInactiveCell";
import { GET_ALL_QUERY, GetAllGql, GetAllItem } from "./queries/getAll";
import { ApiCourseWithSetData } from "data/queries/ApiCourse";
import ModalCustom from "../ModalCustom";
import { ModalTableSelect } from "../JsonForm/renderers/SelectMultiple/types";

const btnStyle = {
  border: "1px solid #7b809a",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

const btnStyleRemove = {
  ...btnStyle,
  border: "1px solid #F44335",
  color: "#F44335",
};

export default function ModalCoursesSelect({ ids, handleOpen, onChange, open }: ModalTableSelect) {
  return (
    <ModalCustom
      onClose={handleOpen}
      open={open}
      sx={{
        "& .head": {
          justifyContent: "center",
          width: "100%",
        },
      }}
    >
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 8,
          search: null,
          active: null,
        }}
        path={`/ecommerce/courses`}
        query={GET_ALL_QUERY}
        formatRows={(data) =>
          data.Courses.items.map((item) => ({
            ...item,
            categoryName: item.courseGroup.category.name,
            modalityName: item.modality.name,
          }))
        }
        formatPagination={(data) => data.Courses.paginationInfo}
        ApiSetData={ApiCourseWithSetData}
        columnsTable={[
          {
            Header: "Ação",
            accessor: "id",
            Cell: ({ value, cell }: any) => (
              <MDButton
                sx={ids.includes(Number(value)) ? btnStyleRemove : btnStyle}
                onClick={() => onChange(cell.row.original as GetAllItem)}
              >
                {ids.includes(Number(value)) ? "Remover" : "Adicionar"}
              </MDButton>
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Categoria",
            accessor: "categoryName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Modalidade",
            accessor: "modalityName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Ativo",
            accessor: "active",
            Cell: ({ value }: any) => <ActiveOrInactiveCell value={value} />,
          },
        ]}
      />
    </ModalCustom>
  );
}
