import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation Update($data: UpdateFormWithPropertiesInput!) {
    updateFormWithProperties(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateFormWithProperties: {
    id: number;
  };
}
