import { GetItemGql } from "../queries/getItem";
import { PromotionType } from "./types";
import SchemaJson from "../schemas/SchemaJson.json";

export const getTypeDiscount = (data: GetItemGql["Promotion"], type: "value" | "type") => {
  if (data?.nominalDiscountValue > 0)
    return type === "value" ? data?.nominalDiscountValue : "nominalDiscountValue";
  if (data?.maximumUnitPriceDiscount > 0)
    return type === "value" ? data?.maximumUnitPriceDiscount : "maximumUnitPriceDiscount";
  if (data?.percentualDiscountValue > 0)
    return type === "value" ? data?.percentualDiscountValue : "percentualDiscountValue";
  return;
};

export const formatTime = (time: string) => (time?.length === 1 ? `0${time}` : time);

export const formatRecurrencyState = (data: GetItemGql["Promotion"]) => {
  const recurrency = data.recurrencyCrons?.[0];

  const times: string[] = recurrency?.match(/\d+-\d+/g) ?? [];
  const days: string[] = recurrency?.match(/\b(SUN|MON|TUE|WED|THU|FRI|SAT)\b/g) ?? [];

  return {
    days: days.length ? days : undefined,
    recurrencyDays: (days.length === 7 || days.length === 0 ? "everyDay" : "especificDays") as any,
    especificTimes: times.length
      ? times.map((time) => {
          const [init, end] = time.split("-");
          return {
            initTime: `${formatTime(init)}:00:00`,
            endTime: `${formatTime(Number(end).toString())}:00:00`,
          };
        })
      : undefined,
    recurrencyTimes: (times.length ? "especificTimes" : "allDay") as any,
  };
};

export const formatRecurrency = ({
  days,
  especificTimes,
  recurrencyDays,
  recurrencyTimes,
}: {
  days: string[];
  especificTimes: { initTime: string; endTime: string }[];
  recurrencyDays: "everyDay" | "especificDays";
  recurrencyTimes: "especificTimes" | "allDay";
}): [string] | null => {
  if (
    (!days?.length && !especificTimes?.length) ||
    (recurrencyDays === "everyDay" && recurrencyTimes === "allDay")
  ) {
    return null;
  }

  if ((!days?.length || recurrencyDays === "everyDay") && especificTimes?.length) {
    return [
      `* * ${(especificTimes ?? [])
        .map(
          ({ initTime, endTime }) =>
            `${formatTime(initTime.split(":")[0])}-${formatTime(endTime.split(":")[0])}`
        )
        .join(",")} * * *`,
    ];
  }

  if (days?.length && (!especificTimes?.length || recurrencyTimes === "allDay")) {
    return [`* * * * * ${days.join(",")}`];
  }

  return [
    `* * ${(especificTimes ?? [])
      .map(
        ({ initTime, endTime }) =>
          `${formatTime(initTime.split(":")[0])}-${formatTime(endTime.split(":")[0])}`
      )
      .join(",")} * * ${days.join(",")}`,
  ];
};

export const getSchemaJsonWithRequired = (type: PromotionType) => {
  const parsed = JSON.parse(JSON.stringify(SchemaJson));
  const requiredDefault: string[] = ["beginDateUtc", "endDateUtc", "name"];

  switch (type) {
    case "regular":
      parsed.required = ["discountType", "discountValue", "defineEligbleProducts"];
      break;

    case "combo":
      parsed.required = [
        "listSku1BuyTogether",
        "percentualDiscountValueList1",
        "minimumQuantityBuyTogether",
        "listSku2BuyTogether",
        "percentualDiscountValueList2",
      ];
      break;

    case "forThePriceOf":
      parsed.required = [
        "quantityToAffectBuyTogether",
        "minimumQuantityBuyTogether",
        "discountTypeMoreForLess",
        "isAppliedToMostExpensive",
        "enableBuyTogetherPerSku",
        "shouldDistributeDiscountAmongMatchedItems",
      ];
      break;

    case "progressive":
      parsed.required = ["percentualDiscountValueList"];
      break;
    default:
      break;
  }

  parsed.required = [...requiredDefault, ...(parsed.required ?? [])];

  return JSON.stringify(parsed);
};
