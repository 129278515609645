import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_CATEGORIES_FAQS_QUERY = print(gql`
  query CategoryFaqs($page: Int, $perPage: Int, $search: String) {
    CategoryFaqs(page: $page, perPage: $perPage, search: $search) {
      items {
        id
        name
        slug
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          perPage
          page
        }
      }
    }
  }
`);

export interface CategoryFaq {
  id: number;
  name: string;
  slug: string;
}

export interface CategoryFaqsGql {
  CategoryFaqs: {
    items: CategoryFaq[];
    paginationInfo: PaginationInfo;
  };
}
