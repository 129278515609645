import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormCreateFaq from "components/CRPComponents/Forms/FormFaq/FormCreateFaq";
import { Permissions } from "../permissions";

function CreateFaqUniftec(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FormCreateFaq
        siteId={1}
        includeCategory={true}
        path="/uniftec"
        actionsPermission={{
          createPermision: Permissions.create,
          deletePermision: Permissions.delete,
          updatePermision: Permissions.update,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CreateFaqUniftec;
