import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { useEffect } from "react";
import { removeNullValues } from "utils/removeNullValues";

import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import schemaJson from "../schemas/SchemaJson.json";
import UISchemaJson from "../schemas/UISchema.json";
import { SchemaData, UseGetItemProps } from "./types";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  useEffect(() => {
    setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));

    ApiFinancialRequest<GetItemGql>({
      query: GET_ITEM_QUERY,
      variables: { paymentMethodId: queryParam },
    })
      .then((response) => {
        if (!response?.data?.data?.PaymentMethod) {
          throw new Error("Não encontrado", {
            cause: { response: response?.data || response },
          });
        }

        setTemplate({
          schemaJson: schemaJson,
          UISchema: UISchemaJson,
        });

        const data = response?.data?.data?.PaymentMethod;

        const newData: any = {
          ...data,
          enabled: `${Number(data.enabled)}`,
          connector: data.connector.affiliationId,
          installmentsService: data.installmentsService === false ? "À vista" : "Parcelado",
          externalInterest: data.externalInterest === false ? "Inativo" : "Ativo",
        };

        if (data.installmentsService === true) {
          newData.hasInstallmentTax = "Adicionar parcelas";
        }

        setState({
          data: removeNullValues(newData),
        });
      })
      .catch((error) => {
        console.log({ error });

        setState((prev) => ({
          ...prev,
          criticalError: error?.response?.data?.errors?.[0] || error,
        }));
      });
  }, []);
};

export default useGetItem;
