import useCreate from "./hooks/useCreate";
import schemaJson from "./schema/SchemaJson.json";
import UISchemaJson from "./schema/UISchema.json";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import useCategories from "./hooks/useCategories";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import usePartners from "./hooks/usePartners";
import useTeachingAreas from "./hooks/useTeachingAreas";
import { CreateProps } from "./types";

function Create(props: CreateProps) {
  const { categories } = useCategories();
  const { partners } = usePartners();
  const { teachingAreas } = useTeachingAreas();
  const [template, setTemplate] = useState<ShemaJsonTemplate | null>(null);

  useEffect(() => {
    if (!categories || !partners || !teachingAreas) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.categoryId = {
      ...schemaJsonParsed.properties.categoryId,
      oneOf: categories.map(({ name, id }) => ({
        title: name,
        const: id,
      })),
    };

    schemaJsonParsed.properties.partnerId = {
      ...schemaJsonParsed.properties.partnerId,
      oneOf: partners.map(({ name, id }) => ({
        title: name,
        const: id,
      })),
    };

    schemaJsonParsed.properties.teachingAreaId = {
      ...schemaJsonParsed.properties.teachingAreaId,
      oneOf: teachingAreas.map(({ name, id }) => ({
        title: name,
        const: id,
      })),
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });
  }, [categories, partners, teachingAreas]);

  if (!template) return null;

  return (
    <GenericMethods.Create<SchemaData, CreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      useCreate={useCreate}
      template={template}
      {...props}
    />
  );
}

export default Create;
