import {
  Stack,
  Box,
  Typography,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import MDButton from "components/MDButton";
import { Fragment, useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Props from "./types";
import useData from "./hooks/useData";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const btnStyle = {
  border: "1px solid #7b809a",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

const orderButtonSx = {
  "&:disabled": {
    opacity: 0.6,
  },
};

function SelectMultiple<itemGql>({
  data,
  handleChange,
  path,
  label,
  required,
  errors,
  Modal,
  requestItem,
  formatItem,
  enabled,
  uischema,
}: Props<itemGql>) {
  const [open, setOpen] = useState(false);
  const { selectedItems, setSelectedItems, loading } = useData<itemGql>({
    ids: data,
    requestItem,
    formatItem,
  });

  useEffect(() => {
    if (
      (data !== undefined && !Array.isArray(data)) ||
      data?.some((item) => typeof item !== "number" && typeof item !== "string")
    ) {
      throw new Error("Invalid param, must be an Array of number or string");
    }
  }, [data]);

  useEffect(() => {
    if (selectedItems.length >= (uischema?.options?.max ?? Infinity)) {
      setOpen(false);
    }
  }, [selectedItems]);

  const onChange = ({ id, name }: { name: string; id: number }) => {
    if (!id || !name || !enabled) {
      console.log(id, name);
      return;
    }

    const remove = (data as any)?.includes(id);
    const newData = remove
      ? [...selectedItems.filter((_item) => _item.id !== id)]
      : [...selectedItems, { id, name }];
    handleChange(path, newData.length ? newData.map((item) => item.id) : undefined);

    setSelectedItems(newData);
  };

  const handleOrder = (orderType: "up" | "down", index: number) => {
    if (index < 0 || index >= selectedItems.length) {
      return;
    }

    const updatedItems = [...selectedItems];

    if (orderType === "up" && index > 0) {
      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
    } else if (orderType === "down" && index < selectedItems.length - 1) {
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
    } else {
      return;
    }

    setSelectedItems(updatedItems);
    handleChange(
      path,
      updatedItems.map((item) => item.id)
    );
  };

  const handleOpen = useCallback(() => setOpen((old) => (enabled ? !old : false)), []);

  if (loading) {
    return (
      <Stack alignItems={"center"}>
        <CircularProgress sx={{ color: "#7b809a" }} />
      </Stack>
    );
  }

  return (
    <Fragment>
      <Stack gap={1} margin={"10px 0"}>
        <Box
          component={"p"}
          sx={{
            fontFamily: "Roboto",
            fontSize: "0.9rem",
            fontWeight: "400",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            padding: 0,
            position: "relative",
            color: !!errors ? "#F44335" : "#7b809a",
          }}
        >
          {label}
          {required && " *"}
        </Box>
        <Stack>
          {selectedItems?.map((item, index) => (
            <Stack
              key={item.id}
              direction={"row"}
              alignItems={"center"}
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  color: "#344767",
                  fontSize: "1rem",
                }}
              >
                {item.name}
              </Typography>
              <Stack alignItems={"center"} direction={"row"} gap={"6px"}>
                <IconButton
                  disabled={!enabled || index === selectedItems.length - 1}
                  size="medium"
                  onClick={() => handleOrder("down", index)}
                  sx={orderButtonSx}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  disabled={!enabled || index === 0}
                  size="medium"
                  onClick={() => handleOrder("up", index)}
                  sx={orderButtonSx}
                >
                  <ArrowUpwardIcon />
                </IconButton>

                <IconButton
                  disabled={!enabled}
                  aria-label="delete"
                  size="medium"
                  onClick={() => onChange(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <MDButton
          sx={btnStyle}
          onClick={handleOpen}
          disabled={!enabled || selectedItems.length >= (uischema?.options?.max ?? Infinity)}
        >
          Adicionar
          <AddIcon />
        </MDButton>
      </Stack>
      {!!errors && <FormHelperText error>{errors}</FormHelperText>}
      {enabled && (
        <Modal open={open} ids={data ?? []} onChange={onChange} handleOpen={handleOpen} />
      )}
    </Fragment>
  );
}

export default SelectMultiple;
