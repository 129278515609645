import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateCourse($data: CreateCourseInput!) {
    createCourse(data: $data) {
      id
    }
  }
`);

export interface CreateGql {
  createCourse: {
    id: number;
  };
}
