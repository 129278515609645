import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { Colligate } from "../types";

export const CREATE_QUERY = print(gql`
  mutation CreateTeachingAreaConversion($data: CreateTeachingAreaConversionInput!) {
    createTeachingAreaConversion(data: $data) {
      id
      colligate {
        id
        databaseName
        description
        active
        partnerVisibleName
        createdAt
        updatedAt
      }
      idColligate
      databaseName
      idArea
      teachingAreaName
      createdAt
      updatedAt
    }
  }
`);
export interface CreateGql {
  createTeachingAreaConversion: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;
    idArea: string;
    teachingAreaName: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
