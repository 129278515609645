import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $roleId: Int
    $permissionsIds: [Int!]
    $lastName: String
    $phone: String
    $cpf: String
    $rg: String
    $birthDate: DateTime
    $residentialPhone: String
    $active: StatusEnum
    $academicEmail: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      roleId: $roleId
      permissionsIds: $permissionsIds
      lastName: $lastName
      phone: $phone
      cpf: $cpf
      rg: $rg
      birthDate: $birthDate
      residentialPhone: $residentialPhone
      active: $active
      academicEmail: $academicEmail
    ) {
      id
    }
  }
`);

export interface UpdateGql {
  updateUser: {
    id: number;
  };
}
