import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LayoutContainer from "examples/LayoutContainers/LayoutContainer";
import { useAuth } from "context/useUserAuth";
import ButtonReadQueue from "layouts/ecommerce/courses/Components/ButtonReadQueue";
import MDTypography from "components/MDTypography";
import { Message } from "layouts/ecommerce/CoursesFinancial/queries/readQueue";
import { TableMessages } from "./TableMessages";
import Row from "components/Row";

export const permissionsRequiredsReadQueue = {
  ReadSQS: "ReadSQS",
};

export function PageReadQueueFinancial() {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>();
  const [loadingPermission, setLoadingPermission] = useState<boolean>();
  const [permissions, setPermissions] = useState<{
    ReadSQS: boolean;
  }>({ ReadSQS: false });
  const [criticalError, setCriticalError] = useState<string>();
  const [messages, setMessages] = useState<Message[]>([]);

  const navigate = useNavigate();

  const handleCriticalError = (error: any) => {
    console.log({ error });
    setCriticalError(error.message);
  };

  const handleSuccessCreate = (messages: Message[]) => {
    setMessages((old) => [...old, ...messages]);
  };

  useEffect(() => {
    setLoadingPermission(true);
    const permissions = user?.permissions.map(({ name }) => name) ?? [];
    setPermissions({
      ReadSQS: permissions.includes(permissionsRequiredsReadQueue.ReadSQS),
    });
    setLoadingPermission(false);
  }, [user?.permissions]);

  return (
    <LayoutContainer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={loading || loadingPermission}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDTypography color="error">{criticalError}</MDTypography>
      {!permissions?.ReadSQS && loadingPermission === false && (
        <p>Você não possui permissão para Ler fila</p>
      )}
      <Row sx={{ paddingBottom: 2 }}>
        {!!permissions?.ReadSQS && (
          <ButtonReadQueue
            loading={loading || loadingPermission}
            setLoading={setLoading}
            onFailCreate={handleCriticalError}
            onSuccessCreate={handleSuccessCreate}
            readQueueProps={{ MaxNumberOfMessages: 4 }}
            showButtonRead10Times
          />
        )}
      </Row>
      <TableMessages messages={messages || []} />
    </LayoutContainer>
  );
}

export default PageReadQueueFinancial;
