import { useEffect } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const isArchived = new URLSearchParams(location.search).get("isArchived") === "true";

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiFinancialRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { couponCode: queryParam, archive: isArchived },
      })
        .then((response) => {
          if (!response?.data?.data?.Coupon) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const schema = JSON.parse(JSON.stringify(schemaJson));

          schema.properties.couponCode.readOnly = true;

          setTemplate({
            schemaJson: JSON.stringify(schema),
            UISchema: UISchemaJson,
          });

          const data = removeNullValues(response.data.data?.Coupon);

          setState({
            data,
          });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
