// @mui material components
import Card from "@mui/material/Card";

// Matoerial Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SearchInputControled from "components/CRPComponents/Forms/components/SearchInputControled";
import DataTableControled from "examples/Tables/DataTableControled";
import LogDownloadsTableProps from "./types";
import useDataTable from "./useDataTable";
import { useMemo } from "react";
import { ApiAuthWithSetData } from "data/ApiAuth";

function LogDownloadsTable<T>(props: LogDownloadsTableProps<T>): JSX.Element {
  const {
    handleChangePage,
    handleChangeFilter,
    error,
    loading,
    data,
    handleSearch,
    setSearchValue,
    searchValue,
    variables,
  } = useDataTable({
    ...props,
    ApiSetData: props.ApiSetData ?? ApiAuthWithSetData,
  });

  useMemo(() => {
    if (props?.hnadleResponse) props?.hnadleResponse(data as T);
  }, [data]);

  if ((data && error) || error) {
    return <p>Error : {error.message}</p>;
  }

  if (!data) return null;

  return (
    <MDBox my={3}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={2}
        className="head"
      >
        {props.title && (
          <MDButton variant="gradient" color="info" href={`${props.path}/new`}>
            {props.title}
          </MDButton>
        )}
        <MDBox display="flex" gap={3} alignItems="baseline">
          <SearchInputControled
            onSubmitSearch={handleSearch}
            value={searchValue}
            onChangeValue={setSearchValue}
            disabled={loading}
            placeholder="Pesquisar Nome do Arquivo"
          />
          {/* {props.useDefaultFilter !== false && (
            <FilterMenu
              variables={variables}
              onChange={handleChangeFilter}
              buttonProps={{ loading, disabled: loading }}
            />
          )} */}
          {props.childrenFilters}
        </MDBox>
      </MDBox>
      <Card>
        <DataTableControled
          table={{
            columns: props.columnsTable.map((item) =>
              props?.action?.acessor === item.accessor ? props?.action?.column : item
            ),
            rows: props.formatRows(data as T),
          }}
          pagination={props.formatPagination(data as T)}
          onChangePage={handleChangePage}
          loading={loading}
        />
      </Card>
    </MDBox>
  );
}

export default LogDownloadsTable;
