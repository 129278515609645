import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { UpdateGql } from "./queries/update";
import useGetItemInfo from "./hooks/useGetItemInfo";
import useUpdateInfo from "./hooks/useUpdateInfo";
import { SchemaDataContentJson } from "./hooks/types";
import { getCourseStepper } from "./hooks/stepperCourseCreate";

function UpdateInfo() {
  const id = Number(useParams()?.id);
  if (!id) return null;

  return (
    <Fragment>
      <GenericMethods.Update<SchemaDataContentJson, UpdateGql>
        siteId={Sites.UNIFTEC.id}
        sitePath={Sites.UNIFTEC.path}
        redirectPath="/ecommerce/cursos"
        useGetItem={useGetItemInfo}
        useUpdate={useUpdateInfo}
        queryParam={id}
        hideDeleteButton
        stepper={getCourseStepper(1, id)}
      />
    </Fragment>
  );
}

export default UpdateInfo;
