import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Calendar($id: Int!) {
    Calendar(id: $id) {
      calendarModalities {
        id
        title
      }
      endDate
      startDate
      title
      unitName
      description
    }
  }
`);

export interface CalendarModality {
  id: number;
  title: string;
}

export interface GetItemGql {
  Calendar: {
    calendarModalities: CalendarModality[];
    id: number;
    title: string;
    endDate: string;
    startDate: string;
    unitName: string;
    description: string;
  };
}
