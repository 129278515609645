import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormCreateScholarShip from "components/CRPComponents/Forms/FormScholarShip/FormCreateScholarShip";
import { Permissions } from "../permissions";

function CreateScholarShipUniftec(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FormCreateScholarShip
        actionsPermission={{
          createPermision: Permissions.create,
          deletePermision: Permissions.delete,
          updatePermision: Permissions.update,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CreateScholarShipUniftec;
