import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateCourseGroup($data: CreateCourseGroupInput!) {
    createCourseGroup(data: $data) {
      id
      name
      active
      category {
        templateSchemaDefault {
          UISchema
          schemaJson
        }
      }
    }
  }
`);

export interface CreateGql {
  createCourseGroup: {
    id: number;
    name: string;
    active: boolean;
    category: {
      templateSchemaDefault: {
        UISchema: string;
        schemaJson: string;
      };
    };
  };
}
