import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { OrderStatusType } from "./getAll";

export const GET_ITEM_QUERY = print(gql`
  query Order($id: String!) {
    Order(id: $id) {
      approvedBy
      cancelReason
      orderId
      sequence
      sellerOrderId
      origin
      allowCancellation
      salesChannel
      value
      status
      creationDate
      orderGroup
      cancellationData {
        requestedByUser
        requestedBySystem
        requestedBySellerNotification
        requestedByPaymentNotification
        reason
        cancellationDate
      }
      customData {
        customApps {
          id
          fields
        }
      }
      totals {
        id
        name
        value
      }
      items {
        productId
        name
        price
        quantity
        imageUrl
        productRefId
        priceDefinition {
          total
        }
        measurementUnit
        refId
        id
      }
      clientProfileData {
        id
        email
        firstName
        lastName
        documentType
        document
        phone
        email
      }
      invoicedDate
      authorizedDate
      ratesAndBenefitsData {
        rateAndBenefitsIdentifiers {
          id
          name
          featured
          description
        }
      }
      statusDescription
      shippingData {
        selectedAddresses {
          receiverName
          street
          number
          complement
          neighborhood
          postalCode
          city
          state
        }
      }
      paymentData {
        transactions {
          payments {
            paymentSystemName
            value
            id
            tid
            group
            connectorResponses
            installments
          }
        }
      }
      invoiceData {
        changesAttachment {
          changesData {
            reason
            discountValue
            incrementValue
            itemsAdded
            itemsRemoved {
              id
              name
              quantity
              price
              unitMultiplier
            }
          }
        }
        receipt {
          date
          orderId
          receipt
        }
      }
      timeline {
        type
        date
        step {
          stepName
          date
          name
          actionsAllowed {
            type
          }
          children {
            stepName
            date
          }
          isAnEnd
        }
        state
      }
    }
  }
`);

export interface GetItemGql {
  Order: {
    approvedBy: string;
    cancelReason: string;
    orderId: string;
    allowCancellation: boolean;
    sequence: string;
    sellerOrderId: string;
    origin: string;
    salesChannel: string;
    value: number;
    status: OrderStatusType;
    orderGroup: string;
    creationDate: string;
    totals: Array<{
      id: string;
      name: string;
      value: number;
    }>;
    items: Array<{
      productId: string;
      name: string;
      price: number;
      quantity: number;
      imageUrl: string;
      refId: string;
      id: string;
      productRefId: string;
      measurementUnit: string;
      priceDefinition: {
        total: number;
      };
    }>;
    clientProfileData: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      documentType: string;
      document: string;
      phone: string;
    };
    invoicedDate: string;
    authorizedDate: string;
    ratesAndBenefitsData: {
      rateAndBenefitsIdentifiers: Array<{
        id: string;
        name: string;
        featured: boolean;
        description: string;
      }>;
    };
    statusDescription: string;
    shippingData: {
      selectedAddresses: Array<{
        receiverName: string;
        street: string;
        number: string;
        complement: string;
        neighborhood: string;
        postalCode: string;
        city: string;
        state: string;
      }>;
    };
    timeline: Array<{
      type: string;
      date: string;
      state: string;
      step: {
        stepName: string;
        date: string;
        name: string;
        actionsAllowed: {
          type: string;
        };
        children: Array<{
          stepName: string;
          date: string;
        }>;
        isAnEnd: boolean;
      };
    }>;
    paymentData: {
      transactions: Array<{
        payments: Array<{
          paymentSystemName: string;
          value: number;
          id: string;
          tid: string;
          group: string;
          connectorResponses?: {
            tid: string;
            maxValue: string;
            paymentId: string;
            nsu: string;
            authId: string;
            code: string;
            delayToCancel: string;
            acquirer: string;
            message: string;
            Message: string;
            ReturnCode: string;
            status: string;
            delayToAutoSettle: string;
            Tid: string;
            authorizationId: string;
          };
          installments: number;
        }>;
      }>;
    };
    customData: {
      customApps: Array<{
        id: string;
        fields: Record<string, string>;
      }>;
    };
    cancellationData: {
      requestedByUser: boolean;
      requestedBySystem: boolean;
      requestedBySellerNotification: boolean;
      requestedByPaymentNotification: boolean;
      reason: string;
      cancellationDate: string;
    };
  };
}

export type BuyAsGiftCustomAppFields = Array<{
  name: string;
  email: string;
  message: string;
  messageSendDate: string;
  productId: string;
  ecommerceProductId: string;
}>;
