import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { getStatusCell } from "components/CRPComponents/Tables/GenericTable/utils/getStatusCell";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          status: null,
        }}
        path={`${props.path}/eventos`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Events.items}
        formatPagination={(data) => data.Events.paginationInfo}
        title="NOVO EVENTO"
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/eventos/${value}`} value={value} />
            ),
          },
          {
            Header: "Título",
            accessor: "title",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "slug",
            accessor: "slug",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }: any) => getStatusCell(value),
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
