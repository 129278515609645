import Create from "./Create";
import { path } from "./hooks/types";
import List from "./List";
import Update from "./Update";

export const Prices = {
  List: List,
  Update: Update,
  Create: Create,
  path: path,
  Permissions: {
    read: "read:Price",
    create: "create:Price",
    delete: "delete:Price",
    list: "list:Price",
    edit: "update:Price",
  },
};
