import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const CategoriesFaq = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    list: "list:CategoryFaq",
    create: "create:CategoryFaq",
    read: "read:CategoryFaq",
    update: "update:CategoryFaq",
    delete: "delete:CategoryFaq",
  },
};
