import { removeNullValues } from "utils/removeNullValues";
import { UPDATE_QUERY, UpdateGql } from "../queries/CreatePriceWithAdmissionWay";
import { ApiFinancialRequest } from "data/ApiFinancial";
import { CreatePriceInput } from "./convertObjectToUpsertInput";

export const upsertPrice = async (data: CreatePriceInput): Promise<{ id: number }> => {
  const res = await ApiFinancialRequest<UpdateGql>({
    query: UPDATE_QUERY,
    variables: {
      data: removeNullValues(data),
    },
  });
  console.log({ res });
  return res?.data?.data.upsertPrice;
};
