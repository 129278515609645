import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback } from "react";
import { SchemaData, UseCreateProps } from "./types";
import { CreateOrUpdateGql, CREATE_OR_UPDATE_QUERY } from "../queries/createOrUpdate";
import { useNavigate } from "react-router-dom";
import { PromotionType } from "../schemas/types";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { formatRecurrency } from "../schemas/format";

const defaultPayload: any = {
  areSalesChannelIdsExclusive: false,
  offset: -3,
  newOffset: -3,
  recurrencyCrons: null,
  activeDaysOfWeek: [],
  brands: [],
  brandsAreInclusive: true,
  // campaigns: [],
  categories: [],
  clusterExpressions: [],
  // clusterOperator: "all",
  categoriesAreInclusive: true,
  collections1BuyTogether: [],
  cumulative: false,
  accumulateWithManualPrice: false,
  enableBuyTogetherPerSku: false,
  description: null,
  isMinMaxInstallments: false,
  slasIds: [],
  // isShippingCountriesSelected: false,
  isSlaSelected: false,
  idCalculatorConfiguration: null,
  // idCollectionIsInclusive: true,
  collectionsIsInclusive: true,
  collections: [],
  idsSalesChannel: [],
  idSeller: null,
  idSellerIsInclusive: true,
  installment: null,
  minInstallment: null,
  maxInstallment: null,
  isActive: true,
  isArchived: false,
  isFeatured: false,
  isFirstBuy: false,
  firstBuyIsProfileOptimistic: true,
  listSku1BuyTogether: [],
  listSku2BuyTogether: [],
  maxUsage: null,
  maxUsagePerClient: null,
  maxNumberOfAffectedItems: null,
  maxNumberOfAffectedItemsGroupKey: null,
  origin: "Marketplace",
  minimumQuantityBuyTogether: null,
  quantityToAffectBuyTogether: null,
  paymentsMethods: [],
  paymentsRules: [],
  products: [],
  affiliates: [],
  productsAreInclusive: true,
  skus: [],
  skusAreInclusive: true,
  restrictionsBins: [],
  marketingTags: [],
  utmSource: null,
  utmCampaign: null,
  utmiCampaign: null,
  totalValueFloor: null,
  totalValueCeling: null,
  totalValueMode: "IncludeMatchedItems",
  itemMinPrice: null,
  itemMaxPrice: null,
  compareListPriceAndPrice: false,
  isDifferentListPriceAndPrice: false,
  totalValuePurchase: null,
  rebatePercentualDiscountValue: null,
  percentualDiscountValueList: [],
  percentualDiscountValueList1: null,
  percentualDiscountValueList2: null,
  // skusGift: {
  //   quantitySelectable: 1,
  //   gifts: [],
  // },
  activateGiftsMultiplier: false,
  applyToAllShippings: false,
  zipCodeRanges: [],
  // stores: [],
  storesAreInclusive: true,
  // maxPricePerItem: [],
  // skusMaxPrices: [],
  maxPricesPerItems: [],
  // generalValues: null,
  productsSpecifications: [],
  orderStatusRewardValue: "invoiced",
  // recurrencyErrors: null,
  marketingTagsAreNotInclusive: false,
  daysAgoOfPurchases: null,
  // beginDateOfPurchasesUtc: null,
  // endDateOfPurchasesUtc: null,
  nominalDiscountValue: null,
};

export default function useCreate(props: UseCreateProps) {
  const navigate = useNavigate();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const type = new URLSearchParams(location.search).get("type") as PromotionType;

    const {
      useRecurrencyCrons,
      discountType,
      discountValue,
      defineEligbleProducts,
      maxUsageUnlimited,
      utmSource,
      utmCampaign,
      utmiCampaign,
      maxNumberOfAffectedItemsUnlimited,
      maxUsagePerClientUnlimited,
      days,
      recurrencyDays,
      especificTimes,
      recurrencyTimes,
      discountTypeMoreForLess,
      morForLessDiscountValue,
      ...data
    } = state?.data;

    let dataFormatted = {
      ...defaultPayload,
      ...data,
      utmSource: utmSource?.join(","),
      utmCampaign: utmCampaign?.join(","),
      utmiCampaign: utmiCampaign?.join(","),
      [discountType]: discountValue,
      discountType,
      type,
      recurrencyCrons: useRecurrencyCrons
        ? formatRecurrency({
            days,
            especificTimes,
            recurrencyDays,
            recurrencyTimes,
          })
        : null,
      maxUsage: maxUsageUnlimited ? null : data?.maxUsage,
      maxNumberOfAffectedItems: maxNumberOfAffectedItemsUnlimited
        ? null
        : data?.maxNumberOfAffectedItems,
      maxUsagePerClient: maxUsagePerClientUnlimited ? null : data?.maxUsagePerClient,
    };

    if (dataFormatted.type === "forThePriceOf") {
      dataFormatted = {
        ...dataFormatted,
        [discountTypeMoreForLess]: morForLessDiscountValue,
      };
    }

    const response = await ApiFinancialRequest<CreateOrUpdateGql>({
      query: CREATE_OR_UPDATE_QUERY,
      variables: {
        data: dataFormatted,
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (
      !response?.data?.data ||
      !response?.data?.data?.createOrUpdatePromotion.idCalculatorConfiguration
    ) {
      throw new Error("Ops. Sem resposta!");
    }

    navigate(
      `/ecommerce/promocoes/${response.data.data.createOrUpdatePromotion.idCalculatorConfiguration}?type=${type}`
    );

    return response;
  }, []);

  return {
    handleSave,
  };
}
