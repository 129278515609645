import { JsonSchema, UISchemaElement } from "@jsonforms/core";

export type DataFormTypeDetails = {
  id: number;
  name: string;
  status: string;
  publishOn?: Date;
  expiresIn?: Date;
  author?: number;
  slug: string;
  title: string;
  description?: string;
  keywords?: string;
  addSiteMap: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

// Add details to schema
export const addDetailsToSchema = (schema: string): JsonSchema => {
  const details = {
    details: {
      type: "object",
      required: ["name", "slug", "title", "description", "status"],
      properties: {
        // "author": {
        //   "type": "string",
        //   "readOnly": true,
        //   "description": "ID do Autor"
        // },
        name: {
          type: "string",
          readOnly: true,
        },
        slug: {
          type: "string",
          readOnly: true,
        },
        status: {
          description: "Nome da Página",
          oneOf: [
            {
              const: "ACTIVE",
              title: "ATIVO",
            },
            {
              const: "DRAFT",
              title: "RASCUNHO",
            },
            {
              const: "INACTIVE",
              title: "DESATIVADO",
            },
          ],
        },
        // "publishOn": {
        //   "type": "string",
        //   "description": "Data de publicação",
        //   "format": "date-time",
        // },
        // "expiresIn": {
        //   "type": "string",
        //   "description": "Data de expiração",
        //   "format": "date-time",
        // },
        title: {
          type: "string",
          minLength: 3,
        },
        description: {
          type: "string",
          minLength: 3,
        },
        keywords: {
          type: "string",
          minLength: 3,
          description: "Separe-as por vírgula",
        },
        addSiteMap: {
          type: "boolean",
          description: "Selecione para adicionar o link no site map do front",
        },
        createdAt: {
          type: "string",
          description: "Data de criação",
          format: "date-time",
          readOnly: true,
        },
        updatedAt: {
          type: "string",
          description: "Data de atualização",
          format: "date-time",
          readOnly: true,
        },
      },
    },
  };

  const newSchema = JSON.parse(schema) as JsonSchema;

  Object.assign(newSchema.properties, details);

  return newSchema;
};

// Add details to uiSchema
export const addDetailsToUiSchema = (uischema: string): UISchemaElement => {
  const uischemaDetails = {
    label: "Informações relacionadas a página",
    type: "Group",
    elements: [
      {
        type: "Control",
        scope: "#/properties/details",
        options: {
          detail: {
            type: "VerticalLayout",
            elements: [
              //"author",
              //"name", "slug",
              //"createdAt", "updatedAt",
              //"title", "description",
              //"keywords",
              //"status", "publishOn", "expiresIn",
              //"addSiteMap"
              // {
              //   "type": "HorizontalLayout",
              //   "elements": [
              //     {
              //       "type": "Control",
              //       "scope": "#/properties/author",
              //       "label": "Id do autor",
              //       "options": {
              //         "showUnfocusedDescription": true,
              //       }
              //     }
              //   ]
              // },
              {
                type: "HorizontalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/name",
                    label: "Nome da página",
                    options: {
                      // "showUnfocusedDescription": true,
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/slug",
                    label: "Slug da página",
                    options: {
                      // "showUnfocusedDescription": true,
                    },
                  },
                ],
              },
              {
                type: "HorizontalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/createdAt",
                    label: "Data de criação",
                    options: {
                      readonly: true,
                      format: "date-time",
                      dateTimeFormat: "DD/MM/YYYY HH:mmZ",
                      dateTimeSaveFormat: "YYYY-MM-DDTHH:mm:ssZ",
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/updatedAt",
                    label: "Data da última atualização",
                    options: {
                      readonly: true,
                      format: "date-time",
                      dateTimeFormat: "DD/MM/YYYY HH:mmZ",
                      dateTimeSaveFormat: "YYYY-MM-DDTHH:mm:ssZ",
                    },
                  },
                ],
              },
              {
                type: "HorizontalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/title",
                    label: "Título da Página - SEO",
                    options: {
                      // "showUnfocusedDescription": true
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/description",
                    label: "Descrição da Página - SEO",
                    options: {
                      // "showUnfocusedDescription": true,
                      multi: true,
                    },
                  },
                ],
              },
              {
                type: "HorizontalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/keywords",
                    label: "Palavras chave",
                    options: {
                      // "showUnfocusedDescription": true
                    },
                  },
                ],
              },
              {
                type: "HorizontalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/status",
                    label: "Status da página",
                    options: {
                      // "showUnfocusedDescription": true
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/addSiteMap",
                    label: "Adicionar ao site map do front",
                    options: {
                      showUnfocusedDescription: true,
                    },
                  },
                  // {
                  //   "type": "Control",
                  //   "scope": "#/properties/publishOn",
                  //   "label": "Data de publicação",
                  //   "options": {
                  //     "showUnfocusedDescription": true,
                  //     "multi": true
                  //   }
                  // },
                  // {
                  //   "type": "Control",
                  //   "scope": "#/properties/expiresIn",
                  //   "label": "Data de expiração",
                  //   "options": {
                  //     "showUnfocusedDescription": true,
                  //     "multi": true
                  //   }
                  // }
                ],
              },
            ],
          },
        },
      },
    ],
  };

  const newUiSchema = JSON.parse(uischema);

  newUiSchema.elements.unshift(uischemaDetails);

  return newUiSchema;
};
