import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query FindManyRoles($search: String) {
    findManyRoles(search: $search) {
      id
      name
      description
    }
  }
`);

export interface GetAllItem {
  id: number;
  name: string;
  description: string;
}

export interface GetAllGql {
  findManyRoles: GetAllItem[];
}
