import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { TypePropertyEnum } from "../hooks/types";

export const CREATE_QUERY = print(gql`
  mutation Mutation($data: CreateFormWithPropertiesInput!) {
    createFormWithProperties(data: $data) {
      id
      slug
      schemaJson
      UISchema
      status
      isDefaultForm
      subtitle
      title
      description
      formMessageHtml
      submitButtonText
      requiredUserId
      publishOn
      expiresIn
      keywords
      addSiteMap
      createdAt
      updatedAt
      formProperties {
        name
        typeProperty
        isRequired
        title
        description
        showUnfocusedDescription
        enum
        default
        isVisible
      }
      sendResponsesTo {
        id
        name
        active
        email
      }
    }
  }
`);

export interface CreateGql {
  createFormWithProperties: {
    id: number;
    slug: string;
    schemaJson: string;
    UISchema: string;
    status: string;
    isDefaultForm: boolean;
    subtitle?: string;
    title: string;
    description?: string;
    formMessageHtml?: string;
    submitButtonText: string;
    requiredUserId: boolean;
    publishOn?: Date;
    expiresIn?: Date;
    keywords?: string;
    addSiteMap?: boolean;
    createdAt: Date;
    updatedAt: Date;
    formProperties: {
      name: string;
      typeProperty: TypePropertyEnum;
      isRequired: boolean;
      title?: string;
      description?: string;
      showUnfocusedDescription?: boolean;
      enum?: string[]; // only allow in list, radio and multipleChoise
      default?: string;
      isVisible?: boolean;
    }[];
    sendResponsesTo: {
      id: number;
      name: string;
      active: boolean;
      email: string;
    }[];
  };
}
