import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query Categories {
    Categories(perPage: -1, active: true, parentId: 0) {
      items {
        slug
        name
        description
        active

        level
        id
        parentId
      }
    }
  }
`);
export interface Category {
  slug: string;
  name: string;
  description: string;
  active: boolean;

  level: number;
  id: number;
  parentId?: number;
}

export interface QueryGql {
  Categories: {
    items: Array<Category>;
  };
}

export default function useCategories() {
  const [categories, setCategories] = useState<null | Array<Category & { label?: string }>>(null);

  useEffect(() => {
    ApiCourseRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => {
      const categories = data.data.Categories.items
        ?.filter(({ level }) => level < 3)
        .sort((a, b) => a.level - b.level);

      const getLabel = (id: number): string => {
        const founded = categories.find(({ id: idArray }) => idArray === id);
        if (!founded) return `id:${id}`;
        if (!founded.parentId) return founded.name;
        return getLabel(founded.parentId).concat("/").concat(founded.name);
      };

      setCategories(categories.map((cat) => ({ ...cat, label: `${cat.id}- ${getLabel(cat.id)}` })));
    });
  }, []);

  return {
    categories,
    setCategories,
  };
}
