import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import schemaJson from "../schema/SchemaJson";
import UISchema from "../schema/UISchema.json";
import useCategories from "./useCategories";

export default function useTemplate(create?: boolean) {
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const { categories } = useCategories();

  useEffect(() => {
    if (!categories?.length) {
      setTemplate({
        schemaJson,
        UISchema,
      });
      return;
    }

    const categorySlug = {
      type: "string",
      oneOf: [
        ...categories.map(({ slug, name }) => ({
          title: name,
          const: slug,
        })),
      ],
    };
    setTemplate({
      schemaJson: {
        ...schemaJson,
        properties: { ...schemaJson.properties, categorySlug },
      },
      UISchema,
    });
  }, [categories]);

  return {
    template,
  };
}
