import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { Colligate } from "../types";

export const DELETE_QUERY = print(gql`
  mutation DeleteEducationalUnitConversion($deleteEducationalUnitConversionId: Int!) {
    deleteEducationalUnitConversion(id: $deleteEducationalUnitConversionId) {
      id
      colligate {
        id
        databaseName
        description
        active
        partnerVisibleName
        createdAt
        updatedAt
      }
      idColligate
      databaseName
      idBranch
      educationalUnitVisibleName
      createdAt
      updatedAt
    }
  }
`);

export interface DeleteGql {
  deleteEducationalUnitConversion: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;
    idArea: string;
    teachingAreaName: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
