import Icon from "@mui/material/Icon";
import { Navigate } from "react-router-dom";
import { Sites } from "data/utils/globals";
import { EducationalGroups } from "layouts/ecommerce/EducationalGroups";
import { EducationalUnit } from "layouts/ecommerce/EducationalUnit";
import { FacultyMembers } from "layouts/ecommerce/FacultyMembers";
import { CourseGroups } from "layouts/ecommerce/Methodologies";
import { Modalities } from "layouts/ecommerce/Modalities";
import { Partners } from "layouts/ecommerce/Partners";
import { Tags } from "layouts/ecommerce/Tags";
import { TeachingAreas } from "layouts/ecommerce/TeachingAreas";
import { Courses } from "layouts/ecommerce/courses";
import { ReviewAndRates } from "layouts/ecommerce/ReviewAndRates";
import { Subjects } from "layouts/ecommerce/Subjects";
import { Category } from "layouts/ecommerce/Category";
import { PartnerCompanies } from "layouts/ecommerce/PartnerCompanies";
import { GlobalKeys } from "layouts/ecommerce/GlobalKeys";
import { Promotions } from "layouts/ecommerce/Promotions";
import { Coupon } from "layouts/ecommerce/Coupon";
import { FormCancel } from "layouts/ecommerce/FormCancel";
import { Shelf } from "layouts/ecommerce/Shelf";
import { AdmissionWays } from "layouts/ecommerce/AdmissionWays";
import { PlanAdmissionWays } from "layouts/ecommerce/PlansAdmissionWay";
import { TemplateInvestments } from "layouts/ecommerce/TemplateInvestments";
import { CoursesFinancial } from "layouts/ecommerce/CoursesFinancial";
import { Inventorys } from "layouts/ecommerce/Inventory";
import { Prices } from "layouts/ecommerce/Price";
import { InvestmentInfos } from "layouts/ecommerce/InvestmentInfo";
import { Colligates } from "layouts/integrationGetERP/Colligate";
import {
  ReadCSVCourses,
  ReadCSVFacultyMembers,
  ReadCSVPriceCourses,
} from "layouts/integrationGetERP/ReadCSV";
import { PaymentMethod } from "layouts/ecommerce/PaymentMethod";
import { Orders } from "layouts/aplicacoes/Orders";
import { ModalityConversion } from "layouts/integrationGetERP/ModalityConversion";
import { TeachingAreaConversion } from "layouts/integrationGetERP/TeachingAreaConversion";
import { EducationalUnitConversion } from "layouts/integrationGetERP/EducationalUnitConversion";
import { CategoryConversion } from "layouts/integrationGetERP/CategoryConversion";
import { ProviderHabilitation } from "layouts/integrationGetERP/ProviderHabilitation";
import { Route } from "App";
import {
  PageReadQueueFinancial,
  permissionsRequiredsReadQueue,
} from "layouts/integrationGetERP/Queue/QueueFinancial";

const { path } = Sites.UNIFTEC;

export const ecommerceRoutes: Route[] = [
  { type: "title", title: "E-commerce", key: "E-commerce" },
  {
    type: "collapse",
    name: "E-commerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Cursos",
        icon: <Icon fontSize="medium">image</Icon>,
        key: "/ecommerce/courses",
        route: "/ecommerce/cursos",
        component: <Courses.List path={path} />,
        permission: Courses.Permissions.list,
      },
      {
        name: " Novo Curso",
        key: "/ecommerce/courses/new",
        route: "/ecommerce/cursos/new",
        component: <Courses.Create />,
        permission: Courses.Permissions.create,
        type: "hide",
      },
      {
        name: " Editar",
        key: "/ecommerce/courses/edit",
        route: "/ecommerce/cursos/:id",
        type: "hide",
        component: <Courses.Update />,
        permission: Courses.Permissions.read,
      },
      {
        name: " Editar",
        key: "/ecommerce/courses/editInfo",
        route: "/ecommerce/cursos/:id/info",
        type: "hide",
        component: <Courses.UpdateInfo />,
        permission: Courses.Permissions.read,
      },
      {
        name: " Editar Info",
        key: "/ecommerce/courses/editInfo",
        route: "/ecommerce/cursos/:id/info",
        type: "hide",
        component: <Courses.UpdateInfo />,
        permission: Courses.Permissions.read,
      },
      {
        type: "hide",
        name: " Criar Investimentos",
        key: "/ecommerce/courses/investimentos",
        route: "/ecommerce/cursos/:id/investimentos",
        component: <Courses.CreateCourseFinancial />,
      },
      {
        // type: "collapse",
        name: "Grupo de Cursos",
        // key: "ecomerce - Grupo de Cursos",
        icon: <Icon fontSize="medium">image</Icon>,
        // href: "/ecommerce/grupo-de-cursos",
        // collapse: [
        //   {
        //     name: " Ver todos",
        key: "/ecommerce/grupo-de-cursos",
        route: "/ecommerce/grupo-de-cursos",
        component: <CourseGroups.List path={path} />,
        permission: CourseGroups.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/grupo-de-cursos/new",
        route: "/ecommerce/grupo-de-cursos/new",
        component: <CourseGroups.Create />,
        permission: CourseGroups.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/grupo-de-cursos/:id`,
        route: `/ecommerce/grupo-de-cursos/:id`,
        component: <CourseGroups.Update />,
        permission: CourseGroups.Permissions.update,
      },
      // ],
      // },
      {
        name: "Avaliações",
        key: "ecomerce - avaliações",
        route: "/ecommerce/avaliacoes",
        component: <ReviewAndRates.List path={path} />,
        permission: ReviewAndRates.Permissions.list,
      },
      {
        // type: "collapse",
        name: "Nível de Ensino",
        // key: "ecomerce - Nível de Ensino",
        icon: <Icon fontSize="medium">image</Icon>,
        // href: "/ecommerce/nivel-de-ensino",
        // collapse: [
        //   {
        //     name: " Ver todas",
        key: "/ecommerce/nivel-de-ensino",
        route: "/ecommerce/nivel-de-ensino",
        component: <Category.List path={path} />,
        permission: Category.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/nivel-de-ensino/new",
        route: "/ecommerce/nivel-de-ensino/new",
        component: <Category.Create />,
        permission: Category.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/nivel-de-ensino/:id`,
        route: `/ecommerce/nivel-de-ensino/:id`,
        component: <Category.Update />,
        permission: Category.Permissions.update,
      },
      //   ],
      // },
      {
        // type: "collapse",
        name: "Modalidades",
        // key: "ecomerce - Modalidades",
        // href: "/ecommerce/modalidades",
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/ecommerce/modalidades",
        route: "/ecommerce/modalidades",
        component: <Modalities.List path={path} />,
        permission: Modalities.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/modalidades/new",
        route: "/ecommerce/modalidades/new",
        component: <Modalities.Create />,
        permission: Modalities.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/modalidades/:id`,
        route: `/ecommerce/modalidades/:id`,
        component: <Modalities.Update />,
        permission: Modalities.Permissions.update,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Tags",
        // key: "ecomerce - Tags",
        // href: "/ecommerce/tags",
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/ecommerce/tags",
        route: "/ecommerce/tags",
        component: <Tags.List path={path} />,
        permission: Tags.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/tags/new",
        route: "/ecommerce/tags/new",
        component: <Tags.Create />,
        permission: Tags.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/tags/:id`,
        route: `/ecommerce/tags/:id`,
        component: <Tags.Update />,
        permission: Tags.Permissions.update,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Parceiros",
        // key: "ecomerce - Parceiros",
        // href: "/ecommerce/parceiros",
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/ecommerce/parceiros",
        route: "/ecommerce/parceiros",
        component: <Partners.List path={path} />,
        Permission: Partners.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/parceiros/new",
        route: "/ecommerce/parceiros/new",
        component: <Partners.Create />,
        Permission: Partners.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/parceiros/:id`,
        route: `/ecommerce/parceiros/:id`,
        component: <Partners.Update />,
        Permission: Partners.Permissions.update,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Empresas Conveniadas",
        // key: "ecomerce - empresas-conveniadas",
        // href: "/ecommerce/empresas-conveniadas",
        // collapse: [
        // {
        // name: " Ver Todas",
        key: "/ecommerce/empresas-conveniadas",
        route: "/ecommerce/empresas-conveniadas",
        component: <PartnerCompanies.List path={path} />,
        Permission: PartnerCompanies.Permissions.list,
      },
      {
        name: " Nova",
        key: "/ecommerce/empresas-conveniadas/new",
        route: "/ecommerce/empresas-conveniadas/new",
        component: <PartnerCompanies.Create />,
        Permission: PartnerCompanies.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/empresas-conveniadas/:id`,
        route: `/ecommerce/empresas-conveniadas/:id`,
        component: <PartnerCompanies.Update />,
        Permission: PartnerCompanies.Permissions.update,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Áreas de ensino",
        // key: "ecomerce - areas-de-ensino",
        // href: "/ecommerce/areas-de-ensino",
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/ecommerce/areas-de-ensino",
        route: "/ecommerce/areas-de-ensino",
        component: <TeachingAreas.List path={path} />,
        Permission: TeachingAreas.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/areas-de-ensino/new",
        route: "/ecommerce/areas-de-ensino/new",
        component: <TeachingAreas.Create />,
        Permission: TeachingAreas.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/areas-de-ensino/:id`,
        route: `/ecommerce/areas-de-ensino/:id`,
        component: <TeachingAreas.Update />,
        Permission: TeachingAreas.Permissions.update,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Corpo Docente",
        // key: "ecomerce - corpo-docente",
        // href: "/ecommerce/corpo-docente",
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/ecommerce/corpo-docente",
        route: "/ecommerce/corpo-docente",
        component: <FacultyMembers.List path={`/ecommerce/corpo-docente`} />,
        Permission: FacultyMembers.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/corpo-docente/new",
        route: "/ecommerce/corpo-docente/new",
        component: <FacultyMembers.Create />,
        Permission: FacultyMembers.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/corpo-docente/:id`,
        route: `/ecommerce/corpo-docente/:id`,
        component: <FacultyMembers.Update />,
        Permission: FacultyMembers.Permissions.update,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Matérias",
        // key: "ecomerce - materias",
        // href: "/ecommerce/materias",
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/ecommerce/materias",
        route: "/ecommerce/materias",
        component: <Subjects.List path={"/ecommerce/materias"} />,
        Permission: Subjects.Permissions.list,
      },
      {
        name: " Novo",
        key: "/ecommerce/materias/new",
        route: "/ecommerce/materias/new",
        component: <Subjects.Create />,
        Permission: Subjects.Permissions.create,
        type: "hide",
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/materias/:id`,
        route: `/ecommerce/materias/:id`,
        component: <Subjects.Update />,
        Permission: Subjects.Permissions.update,
      },
      // ],
      // },
      {
        type: "collapse",
        name: "Polos e Unidades",
        key: "ecomerce - Polos",
        href: "/ecommerce/polos",
        collapse: [
          {
            name: " Unidades",
            key: "/ecommerce/unidades-educacionais",
            route: "/ecommerce/unidades-educacionais",
            component: <EducationalUnit.List path={path} />,
            Permission: EducationalUnit.Permissions.list,
          },
          {
            type: "hide",
            name: " Nova Unidade",
            key: "/ecommerce/unidades-educacionais/new",
            route: "/ecommerce/unidades-educacionais/new",
            component: <EducationalUnit.Create />,
            Permission: EducationalUnit.Permissions.create,
          },
          {
            type: "hide",
            name: "Editar Unidade",
            key: `/ecommerce/unidades-educacionais/:id`,
            route: `/ecommerce/unidades-educacionais/:id`,
            component: <EducationalUnit.Update />,
            Permission: EducationalUnit.Permissions.update,
          },
          {
            name: " Grupos Educacionais",
            key: "/ecommerce/grupos-educacionais",
            route: "/ecommerce/grupos-educacionais",
            component: <EducationalGroups.List path={path} />,
            Permission: EducationalGroups.Permissions.list,
          },
          {
            type: "hide",
            name: " Novo Grupo Educacional",
            key: "/ecommerce/grupos-educacionais/new",
            route: "/ecommerce/grupos-educacionais/new",
            component: <EducationalGroups.Create />,
            Permission: EducationalGroups.Permissions.create,
          },
          {
            type: "hide",
            name: "Editar Grupo Educacional",
            key: `/ecommerce/grupos-educacionais/:id`,
            route: `/ecommerce/grupos-educacionais/:id`,
            component: <EducationalGroups.Update />,
            Permission: EducationalGroups.Permissions.update,
          },
        ],
      },
      {
        name: "Chaves Globais",
        key: "ecomerce - chaves-globais",
        route: "/ecommerce/chaves-globais",
        component: <GlobalKeys.List path={path} />,
        Permission: GlobalKeys.Permissions.list,
      },
      {
        type: "hide",
        name: "Ver Chave Global",
        key: `/ecommerce/chaves-globais/:key`,
        route: `/ecommerce/chaves-globais/:key`,
        component: <GlobalKeys.Update />,
        Permission: GlobalKeys.Permissions.update,
      },
      {
        name: "Pedidos",
        key: `/ecommerce/pedidos`,
        route: `/ecommerce/pedidos`,
        component: <Orders.List path={"/ecommerce"} />,
        Permission: Orders.Permissions.list,
      },
      {
        type: "hide",
        name: " Ver Pedido",
        key: `/ecommerce/pedidos:id`,
        route: `/ecommerce/pedidos/:id`,
        component: <Orders.Update />,
        Permission: Orders.Permissions.read,
      },

      {
        name: "Solic. Cancelamentos",
        key: `${path}/cancelamento`,
        route: `${path}/cancelamento`,
        component: <FormCancel.List slug="form-cancel" path={path} />,
        permission: FormCancel.Permissions.list,
      },

      {
        // type: "collapse",
        name: "Promoções",
        // key: "ecomerce - promoções",
        // href: "/ecommerce/promocoes",
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: "/ecommerce/promocoes",
        route: "/ecommerce/promocoes",
        component: <Promotions.List path={path} />,
        Permission: Promotions.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo promocoes",
        key: "/ecommerce/promocoes/new",
        route: "/ecommerce/promocoes/new",
        component: <Promotions.Create />,
        Permission: Promotions.Permissions.create,
      },
      {
        type: "hide",
        name: "Ver promocoes",
        key: `/ecommerce/promocoes/:id`,
        route: `/ecommerce/promocoes/:id`,
        component: <Promotions.Update />,
        Permission: Promotions.Permissions.update,
        // },
        // ],
      },
      {
        // type: "collapse",
        name: "Metodos de pagamentos",
        // key: "ecomerce - Payment Method",
        // href: "/ecommerce/metodos-de-pagamentos",
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: "/ecommerce/metodos-de-pagamentos",
        route: "/ecommerce/metodos-de-pagamentos",
        component: <PaymentMethod.List />,
        Permission: PaymentMethod.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo Metodos de pagamentos",
        key: "/ecommerce/metodos-de-pagamentos/new",
        route: "/ecommerce/metodos-de-pagamentos/new",
        component: <PaymentMethod.Create />,
        Permission: PaymentMethod.Permissions.create,
      },
      {
        type: "hide",
        name: "Ver Metodos de pagamentos",
        key: `/ecommerce/metodos-de-pagamentos/:id`,
        route: `/ecommerce/metodos-de-pagamentos/:id`,
        component: <PaymentMethod.Update />,
        Permission: PaymentMethod.Permissions.update,
        //   },
        // ],
      },
      {
        // type: "collapse",
        name: "Cupom",
        // key: "ecomerce - cupom",
        // href: "/ecommerce/cupoms",
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: "/ecommerce/cupoms",
        route: "/ecommerce/cupoms",
        component: <Coupon.List path={path} />,
        Permission: Coupon.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo",
        key: "/ecommerce/cupoms/new",
        route: "/ecommerce/cupoms/new",
        component: <Coupon.Create />,
        Permission: Coupon.Permissions.create,
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/cupoms/:couponCode`,
        route: `/ecommerce/cupoms/:couponCode`,
        component: <Coupon.Update />,
        Permission: Coupon.Permissions.update,
        //   },
        // ],
      },
      {
        // type: "collapse",
        name: "Vitrine",
        // key: "ecomerce - vitrines",
        // href: "/ecommerce/vitrines",
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: "/ecommerce/vitrines",
        route: "/ecommerce/vitrines",
        component: <Shelf.List path={path} />,
        permission: Shelf.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo",
        key: "/ecommerce/vitrines/new",
        route: "/ecommerce/vitrines/new",
        component: <Shelf.Create />,
        permission: Shelf.Permissions.create,
      },
      {
        type: "hide",
        name: "Ver",
        key: `/ecommerce/vitrines/:id`,
        route: `/ecommerce/vitrines/:id`,
        component: <Shelf.Update />,
        permission: Shelf.Permissions.read,
        //   },
        // ],
      },
      {
        // type: "collapse",
        name: "Template de Invest.",
        // key: "ecomerce - Template de Investimento",
        // href: `${TemplateInvestments.path}`,
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: `${TemplateInvestments.path}`,
        route: `${TemplateInvestments.path}`,
        component: <TemplateInvestments.List path={path} />,
        permission: TemplateInvestments.Permissions.list,
      },
      {
        type: "hide",
        name: " Ver",
        key: `${TemplateInvestments.path}/:id`,
        route: `${TemplateInvestments.path}/:id`,
        component: <TemplateInvestments.Update />,
        permission: TemplateInvestments.Permissions.read,
        //   },
        // ],
      },
      {
        type: "hide",
        name: " Novo Template Investimento",
        key: `${TemplateInvestments.path}/new`,
        route: `${TemplateInvestments.path}/new`,
        component: <TemplateInvestments.Create />,
        permission: TemplateInvestments.Permissions.create,
        //   },
        // ],
      },
      {
        // type: "collapse",
        name: "Orientações Financeiras",
        // key: "ecomerce - Orientações Financeiras",
        // href: `${InvestmentInfos.path}`,
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: `${InvestmentInfos.path}`,
        route: `${InvestmentInfos.path}`,
        component: <InvestmentInfos.List path={path} />,
        permission: InvestmentInfos.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo",
        key: `${InvestmentInfos.path}/new`,
        route: `${InvestmentInfos.path}/new`,
        component: <InvestmentInfos.Create />,
        permission: InvestmentInfos.Permissions.create,
      },
      {
        type: "hide",
        name: " Ver",
        key: `${InvestmentInfos.path}/:id`,
        route: `${InvestmentInfos.path}/:id`,
        component: <InvestmentInfos.Update />,
        permission: InvestmentInfos.Permissions.read,
        //   },
        // ],
      },
      {
        // type: "collapse",
        name: "Formas de Ingresso",
        // key: "ecomerce - Formas de Ingresso",
        // href: `${AdmissionWays.path}`,
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: `${AdmissionWays.path}`,
        route: `${AdmissionWays.path}`,
        component: <AdmissionWays.List path={path} />,
        permission: AdmissionWays.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo",
        key: `${AdmissionWays.path}/new`,
        route: `${AdmissionWays.path}/new`,
        component: <AdmissionWays.Create />,
        permission: AdmissionWays.Permissions.create,
      },
      {
        type: "hide",
        name: " Ver",
        key: `${AdmissionWays.path}/:id`,
        route: `${AdmissionWays.path}/:id`,
        component: <AdmissionWays.Update />,
        permission: AdmissionWays.Permissions.read,
        //   },
        // ],
      },
      {
        // type: "collapse",
        name: "Planos",
        // key: "ecomerce - Planos",
        // href: `${PlanAdmissionWays.path}`,
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: `${PlanAdmissionWays.path}`,
        route: `${PlanAdmissionWays.path}`,
        component: <PlanAdmissionWays.List path={path} />,
        permission: PlanAdmissionWays.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo",
        key: `${PlanAdmissionWays.path}/new`,
        route: `${PlanAdmissionWays.path}/new`,
        component: <PlanAdmissionWays.Create />,
        permission: PlanAdmissionWays.Permissions.create,
      },
      {
        type: "hide",
        name: " Ver",
        key: `${PlanAdmissionWays.path}/:id`,
        route: `${PlanAdmissionWays.path}/:id`,
        component: <PlanAdmissionWays.Update />,
        permission: PlanAdmissionWays.Permissions.read,
        //   },
        // ],
      },
      {
        // type: "collapse",
        name: "Investimentos",
        // key: "ecomerce - Investimentos",
        // href: `${CoursesFinancial.path}`,
        // collapse: [
        //   {
        //     name: " Ver Todos",
        key: `${CoursesFinancial.path}`,
        route: `${CoursesFinancial.path}`,
        component: <CoursesFinancial.List path={path} />,
      },
      {
        type: "hide",
        name: " Editar",
        key: `${CoursesFinancial.path}/:id`,
        route: `${CoursesFinancial.path}/:id`,
        component: <CoursesFinancial.Update />,
      },
      {
        type: "hide",
        name: " Listar Preço",
        key: `${Prices.path}`,
        route: `${Prices.path}`,
        component: <Navigate replace to={CoursesFinancial.path} />,
        permission: Prices.Permissions.list,
      },
      {
        type: "hide",
        name: " Editar Preço",
        key: `${Prices.path}/:id`,
        route: `${Prices.path}/:id`,
        component: <Prices.Update />,
        permission: Prices.Permissions.read,
      },
      {
        type: "hide",
        name: " Criar Preço",
        key: `${Prices.path}/new/:courseId`,
        route: `${Prices.path}/new/:courseId`,
        component: <Prices.Create />,
        permission: Prices.Permissions.create,
      },
      {
        type: "hide",
        name: " Ver Todos",
        key: `${Inventorys.path}`,
        route: `${Inventorys.path}`,
        component: <Navigate replace to={CoursesFinancial.path} />,
        permission: Inventorys.Permissions.listAndRead,
      },
      {
        type: "hide",
        name: " Editar Estoque",
        key: `${Inventorys.path}/:id`,
        route: `${Inventorys.path}/:id`,
        component: <Inventorys.Update />,
        permission: Inventorys.Permissions.listAndRead,
        //   },
        // ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Upload CSV",
    key: "UploadCSV",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "CSV Cursos",
        key: `${ReadCSVCourses.path}`,
        route: `${ReadCSVCourses.path}`,
        component: <ReadCSVCourses.Page />,
        permission: ReadCSVCourses.Permissions.uploadCsv,
      },
      {
        name: "CSV Corpo Docente",
        key: `${ReadCSVFacultyMembers.path}`,
        route: `${ReadCSVFacultyMembers.path}`,
        component: <ReadCSVFacultyMembers.Page />,
        permission: ReadCSVFacultyMembers.Permissions.uploadCsv,
      },
      {
        name: "CSV Preços",
        key: `${ReadCSVPriceCourses.path}`,
        route: `${ReadCSVPriceCourses.path}`,
        component: <ReadCSVPriceCourses.Page />,
        permission: ReadCSVPriceCourses.Permissions.uploadCsv,
      },
    ],
  },
  {
    type: "collapse",
    name: "Integração Totvs",
    key: "integrationGetERP",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      //Coligadas
      {
        name: "Coligadas",
        key: `${Colligates.path}`,
        route: `${Colligates.path}`,
        component: <Colligates.List path={path} />,
        permission: Colligates.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo",
        key: `${Colligates.path}/new`,
        route: `${Colligates.path}/new`,
        component: <Colligates.Create />,
        permission: Colligates.Permissions.create,
      },
      {
        type: "hide",
        name: " Ver",
        key: `${Colligates.path}/:params`, //`${databaseName}%2F${idColligate}`
        route: `${Colligates.path}/:params`,
        component: <Colligates.Update />,
        permission: Colligates.Permissions.read,
      },
      {
        type: "collapse",
        name: "Conversão Modalidade",
        // key: "modalityConversion",
        icon: <Icon fontSize="medium">image</Icon>,
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/config/conversao-modalidade",
        route: "/config/conversao-modalidade",
        component: <ModalityConversion.List />,
        permission: ModalityConversion.Permissions.list,
      },
      {
        type: "hide",
        name: "Ver",
        key: `/config/conversao-modalidade/:id`,
        route: `/config/conversao-modalidade/:id`,
        component: <ModalityConversion.Update />,
        permission: ModalityConversion.Permissions.read,
      },
      {
        type: "hide",
        name: " Nova",
        key: "/config/conversao-modalidade/new",
        route: "/config/conversao-modalidade/new",
        component: <ModalityConversion.Create />,
        permission: ModalityConversion.Permissions.create,
      },
      // ],
      // },
      {
        type: "collapse",
        name: "Conversão Área",
        // key: "TeachingAreaConversion",
        icon: <Icon fontSize="medium">image</Icon>,
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/config/conversao-area-de-ensino",
        route: "/config/conversao-area-de-ensino",
        component: <TeachingAreaConversion.List />,
        permission: TeachingAreaConversion.Permissions.list,
      },
      {
        type: "hide",
        name: "Ver",
        key: `/config/conversao-area-de-ensino/:id`,
        route: `/config/conversao-area-de-ensino/:id`,
        component: <TeachingAreaConversion.Update />,
        permission: TeachingAreaConversion.Permissions.read,
      },
      {
        type: "hide",
        name: " Nova",
        key: "/config/conversao-area-de-ensino/new",
        route: "/config/conversao-area-de-ensino/new",
        component: <TeachingAreaConversion.Create />,
        permission: TeachingAreaConversion.Permissions.create,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Conversão Unidade",
        // key: "EducationalUnitConversion",
        icon: <Icon fontSize="medium">image</Icon>,
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/config/conversao-unidade",
        route: "/config/conversao-unidade",
        component: <EducationalUnitConversion.List />,
        permission: EducationalUnitConversion.Permissions.list,
      },
      {
        type: "hide",
        name: "Ver",
        key: `/config/conversao-unidade/:id`,
        route: `/config/conversao-unidade/:id`,
        component: <EducationalUnitConversion.Update />,
        permission: EducationalUnitConversion.Permissions.edit,
      },
      {
        type: "hide",
        name: " Nova",
        key: "/config/conversao-unidade/new",
        route: "/config/conversao-unidade/new",
        component: <EducationalUnitConversion.Create />,
        permission: EducationalUnitConversion.Permissions.create,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Conversão Nível de Ensino",
        // key: "CategoryConversion",
        icon: <Icon fontSize="medium">image</Icon>,
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/config/conversao-nivel-de-ensino",
        route: "/config/conversao-nivel-de-ensino",
        component: <CategoryConversion.List />,
        permission: CategoryConversion.Permissions.list,
      },
      {
        type: "hide",
        name: "Ver",
        key: `/config/conversao-nivel-de-ensino/:id`,
        route: `/config/conversao-nivel-de-ensino/:id`,
        component: <CategoryConversion.Update />,
        permission: CategoryConversion.Permissions.read,
      },
      {
        type: "hide",
        name: " Nova",
        key: "/config/conversao-nivel-de-ensino/new",
        route: "/config/conversao-nivel-de-ensino/new",
        component: <CategoryConversion.Create />,
        permission: CategoryConversion.Permissions.create,
      },
      // ],
      // },
      {
        // type: "collapse",
        name: "Habilitações Totvs",
        // key: "ProviderHabilitation",
        icon: <Icon fontSize="medium">image</Icon>,
        // collapse: [
        // {
        // name: " Ver Todos",
        key: `${ProviderHabilitation.path}`,
        route: `${ProviderHabilitation.path}`,
        component: <ProviderHabilitation.List />,
        permission: ProviderHabilitation.Permissions.list,
      },
      {
        type: "hide",
        name: " Sync Totvs",
        key: `${ProviderHabilitation.path}/sync-totvs`,
        route: `${ProviderHabilitation.path}/sync-totvs`,
        component: <ProviderHabilitation.Create />,
        permission: ProviderHabilitation.Permissions.sync,
      },
      // ],
      // },
    ],
  },
  {
    type: "collapse",
    name: "Filas APIs",
    key: "ReadQueues",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Fila Investimentos",
        key: `fila/investimentos`,
        route: `fila/investimentos`,
        component: <PageReadQueueFinancial />,
        permission: permissionsRequiredsReadQueue.ReadSQS,
      },
    ],
  },
  { type: "divider", key: "divider-ecommerceRoutes" },
];
