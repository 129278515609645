import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const EducationalGroups = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:EducationalGroup",
    read: "read:EducationalGroup",
    list: "list:EducationalGroup",
    update: "update:EducationalGroup",
    delete: "delete:EducationalGroup",
  },
};
