import { useEffect } from "react";
import { removeNullValues } from "utils/removeNullValues";
import { GET_ITEM_QUERY, GetItemGql, responseGetItemKey } from "../queries/getItem";
import { ApiRequest, SchemaData, UseGetItemProps } from "./types";
import { schemaJsonEdit } from "../schema/SchemaJson";
import UISchemaEdit from "../schema/UISchemaEdit.json";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const template = {
    schemaJson: schemaJsonEdit,
    UISchema: UISchemaEdit,
  };

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.[responseGetItemKey]) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = response.data.data?.[responseGetItemKey];
          setState({
            data: removeNullValues(data),
          });
          setTemplate(template);
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
