import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query CourseGroup($id: Int!) {
    CourseGroup(id: $id) {
      id
      name
      descriptionShort
      active
      slug
      keywords
      imageUrl
      categoryId
      partnerId
      teachingAreaId
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  CourseGroup: {
    id: number;
    name: string;
    descriptionShort: string;
    active: boolean;
    slug: string;
    keywords: string;
    imageUrl: string;
    categoryId: number;
    partnerId: number;
    teachingAreaId: number;
    createdAt: string;
    updatedAt: string;
  };
}
