import List from "./List";
import Update from "./Update";

export const OpenPolo = {
  List: List,
  Update: Update,
  Permissions: {
    read: "read:Form",
    list: "list:Form",
    delete: "delete:Form",
    update: "update:Form-slug-abra-um-polo",
  },
};
