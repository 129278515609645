import { JsonSchema, UISchemaElement } from "@jsonforms/core";

export type DataFormTypeDetails = {
  id: number;
  question: string;
  answerHtml: string;
  sort?: number;
  student?: boolean;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
  category?: number;
};

export type itemsCategory = {
  id?: number;
  name?: string;
};

// Add options to schema
export const addOptionsToSchema = (schema: JsonSchema, categoriesList: any): JsonSchema => {
  //Adding all Categories to List
  const categoriesNewList = categoriesList?.items?.map((item: itemsCategory) => ({
    const: item.id,
    title: item.name,
  })) || [{}];

  const category = categoriesNewList?.length
    ? {
        title: "Categorias",
        oneOf: categoriesNewList,
      }
    : {
        title: "Categorias",
      };

  console.log("addOptionsToSchema", {
    ...schema,
    properties: {
      ...schema.properties,
      details: {
        ...schema?.properties?.details,
        required: schema?.properties?.details?.required?.includes("category")
          ? schema?.properties?.details?.required
          : [...schema?.properties?.details?.required, "category"],
        properties: {
          ...schema?.properties?.details?.properties,
          category,
        },
      },
    },
  });

  return {
    ...schema,
    properties: {
      ...schema.properties,
      details: {
        ...schema?.properties?.details,
        required: schema?.properties?.details?.required?.includes("category")
          ? schema?.properties?.details?.required
          : [...schema?.properties?.details?.required, "category"],
        properties: {
          ...schema?.properties?.details?.properties,
          category,
        },
      },
    },
  } as JsonSchema;
};

export const addOptionsToUiSchema = (uiSchema: string): UISchemaElement => {
  const uischemaDetails = {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/category",
        label: "Categoria",
        options: {
          showUnfocusedDescription: true,
        },
      },
    ],
  };

  const newUiSchema = JSON.parse(uiSchema);

  //Add schemaDetails
  newUiSchema?.elements[0]?.elements[0]?.options?.detail?.elements?.push(uischemaDetails);

  return newUiSchema;
};
