import { useState, useEffect } from "react";

// Layout
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { JsonFormsComponent } from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { DataFormType, QueryTemplateSchemaResponse } from "../types";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import {
  QUERY_SCHOLAR_SHIP_AND_INCENTIVE,
  ResponseCategoriesScholarshipAndIncentive,
  ResponseModalitiesScholarshipAndIncentive,
  ResponseScholarshipAndIncentive,
} from "data/queries/ApiCourse/ScholarshipAndIncentive/QueryScholarshipAndIncentive";
import { MUTATION_UPDATE_SHIP_AND_INCENTIVE } from "data/queries/ApiCourse/ScholarshipAndIncentive/MutationUpdateScholarshipAndIncentive";
import { addOptionsToSchema } from "../utils/formatWithDetails";
import MDSnackbar, { MDSnackbarProps } from "components/MDSnackbar";
import ButtonDeleteScholarShip from "../ButtonDeleteScholarShip";
import { useNavigate } from "react-router-dom";

function FormUpdateScholarShip<ContentJsonType = any>({
  idScholarShip,
  inicialEditing = false,
  path = "",
}: {
  idScholarShip: number;
  inicialEditing?: boolean;
  path?: string;
}): JSX.Element {
  const [template, setTemplate] = useState<QueryTemplateSchemaResponse>();
  const [state, setState] = useState<JsonFormsStateProps<DataFormType & ContentJsonType>>();
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });

  const handleCloseSnackBar = () => {
    setSnackBarInfo(({ redirectid, props }) => {
      if (redirectid) setEditing(false);
      if (redirectid === -1) navigate(`${path}/scholarShips`);
      return { ...props, open: false, autoHideDuration: undefined };
    });
    setLoading(false);
  };

  const navigate = useNavigate();
  const [editing, setEditing] = useState(inicialEditing);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!!idScholarShip) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCourseRequest<{
        ScholarshipAndIncentive: ResponseScholarshipAndIncentive;
        Categories: ResponseCategoriesScholarshipAndIncentive;
        Modalities: ResponseModalitiesScholarshipAndIncentive;
      }>({
        query: QUERY_SCHOLAR_SHIP_AND_INCENTIVE,
        variables: { scholarshipAndIncentiveId: idScholarShip },
      })
        .then((response) => {
          if (!response?.data?.data?.ScholarshipAndIncentive) {
            throw new Error("Bolsa, Incentivo e Financiamento não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const { templateSchema, contentJson, categories, modalities, ...props } =
            response?.data?.data?.ScholarshipAndIncentive;

          //All Categories Course
          const allCategories = response?.data?.data?.Categories?.items;
          const allModalities = response?.data?.data?.Modalities?.items;

          setTemplate({
            ...templateSchema,
            schemaJson: addOptionsToSchema(
              templateSchema?.schemaJson,
              allCategories,
              allModalities
            ),
            UISchema: templateSchema?.UISchema,
          });

          const convertPropsNull = Object.fromEntries(
            Object.entries(props).map(([key, value]) => [key, value === null ? undefined : value])
          );

          //SetCategoriesList
          const updatedContentJson = JSON.parse(contentJson);

          // Add categoriesBlock
          if (!updatedContentJson.hasOwnProperty("categoriesBlock") && Array.isArray(categories)) {
            updatedContentJson.categoriesBlock = {
              categories: categories.map((item) => item.id),
            };
          }

          // Add ModalitiesBlock
          if (!updatedContentJson.hasOwnProperty("modalitiesBlock") && Array.isArray(modalities)) {
            updatedContentJson.modalitiesBlock = {
              modalities: modalities.map((item) => item.id),
            };
          }

          setState(({ data, ...prev }) => ({
            ...prev,
            data: { ...updatedContentJson, details: convertPropsNull },
          }));
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [idScholarShip]);

  const handleSave = async () => {
    const errors = [];
    if (!state?.data?.details?.title) {
      errors.push("Título é obrigatório");
    }
    if (!state?.data?.details?.imageUrl) {
      errors.push("Imagem de fundo é obrigatória");
    }
    if (!state?.data?.categoriesBlock?.categories?.length) {
      errors.push("Níveis de ensino deve conter pelo menos 1 item");
    }
    if (!state?.data?.modalitiesBlock?.modalities?.length) {
      errors.push("Modalidades deve conter pelo menos 1 item");
    }
    if (errors?.length) {
      throw {
        response: {
          data: {
            errors: errors.map((messageError) => ({
              extensions: { error: { stack: messageError } },
            })),
          },
        },
      };
    }

    const {
      details: { linkRegulation, title, active, imageUrl, startDate, endDate },
      categoriesBlock,
      modalitiesBlock,
      ...content
    } = state?.data;

    const categoriesList = state?.data?.categoriesBlock?.categories;
    const modalitiesList = state?.data?.modalitiesBlock?.modalities;

    const response = await ApiCourseRequest({
      query: MUTATION_UPDATE_SHIP_AND_INCENTIVE,
      variables: {
        data: {
          id: idScholarShip,
          contentJson: JSON.stringify(content),
          title,
          linkRegulation,
          imageUrl,
          active,
          startDate,
          endDate,
          categoriesIds: categoriesList,
          modalitiesIds: modalitiesList,
        },
      },
    });
    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data) {
      throw new Error("Ops. Sem resposta!");
    }
    return response;
  };

  if (!idScholarShip) return null;

  const handleDeleteError = (e: Error) => {
    setSnackBarInfo({
      open: true,
      color: "error",
      title: "Ops, algo deu errado",
      content: e?.message || "Ops. Algo Deu errado",
      autoHideDuration: null,
    });
  };

  const handleDeleteSuccess = () => {
    setEditing(false);
    setSnackBarInfo({
      open: true,
      color: "success",
      icon: "check",
      autoHideDuration: null,
      title: "Bolsa, Incentivo e Financiamento deletado com sucesso",
      redirectid: -1,
    });

    setTimeout(function () {
      handleCloseSnackBar();
    }, 5000);
  };

  return (
    <JsonFormsComponent template={template} state={state} onChange={setState} onSave={handleSave}>
      <>
        {editing && (
          <ButtonDeleteScholarShip
            deleteScholarshipAndIncentiveId={idScholarShip}
            onDeleteError={handleDeleteError}
            onDeleteSuccess={handleDeleteSuccess}
            disabled={!!snackBarInfo?.redirectid}
          />
        )}

        <MDSnackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          {...snackBarInfo}
          title={snackBarInfo.title}
          close={handleCloseSnackBar}
          onClose={handleCloseSnackBar}
        />
      </>
    </JsonFormsComponent>
  );
}

export default FormUpdateScholarShip;
