import useCreate from "./hooks/useCreate";
import schemaJson from "./schema/SchemaJson.json";
import UISchemaJson from "./schema/UISchema.json";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";

function Create() {
  return (
    // <GenericMethods.Create<SchemaData, CreateGql>
    //   siteId={Sites.UNIFTEC.id}
    //   sitePath={Sites.UNIFTEC.path}
    //   useCreate={useCreate}
    //   template={{
    //     schemaJson: schemaJson,
    //     UISchema: UISchemaJson,
    //   }}
    // />
    // O Create precisa listar o nome dos arquivos presentes na pasta no servidor, será preciso criar um enpoint para listar isso evitando erros
    // Alem disso deveria ser possivel editar o nome do arquivo e o jsonDataExample que no momento nao é possivel
    <></>
  );
}

export default Create;
