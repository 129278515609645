import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Category($id: Int!) {
    Category(id: $id) {
      active
      contentJson
      createdAt
      departmentId
      description
      id
      keywords
      level
      name
      showPartinerFilter
      slug
      templateSchemaName
      updatedAt
      urlContract
      parentId
    }
  }
`);

export interface GetItemGql {
  Category: {
    active: boolean;
    contentJson: string;
    createdAt: string;
    departmentId: number;
    description: string;
    id: number;
    keywords: string;
    level: number;
    name: string;
    showPartinerFilter: boolean;
    parentId: number;
    slug: string;
    templateSchemaName: string;
    updatedAt: string;
    urlContract: string;
  };
}
