import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import useAdmissionWays from "../../AdmissionWays/hooks/useAdmissionWays";
import { schemaJson as schemaJsonDefault } from "../schema/SchemaJson";
import UISchemaCreate from "../schema/UISchema.json";
import UISchemaEdit from "../schema/UISchemaEdit.json";

export default function useTemplate(create?: boolean) {
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const { AdmissionWays } = useAdmissionWays();

  useEffect(() => {
    if (!AdmissionWays?.length) return;

    const admissionWayId = {
      type: "integer",
      oneOf: [
        ...AdmissionWays.map(({ id, name, templateInvestmentName }) => ({
          title: `${templateInvestmentName} - ${name}`,
          const: id,
        })),
      ],
    };
    setTemplate({
      schemaJson: {
        ...schemaJsonDefault,
        properties: { ...schemaJsonDefault.properties, admissionWayId },
      },
      UISchema: !create ? UISchemaEdit : UISchemaCreate,
    });
  }, [AdmissionWays]);

  return {
    template,
  };
}
