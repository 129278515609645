/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC, ReactNode, forwardRef } from "react";

// @mui material components
import { ButtonProps, CircularProgress, SxProps } from "@mui/material";

// Custom styles for MDButton
import MDButtonRoot from "components/MDButton/MDButtonRoot";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";
import { Link } from "react-router-dom";

// Declaring props types for MDButton
export interface MDButtonProps extends Omit<ButtonProps, "color" | "variant"> {
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "default";
  variant?: "text" | "contained" | "outlined" | "gradient";
  size?: "small" | "medium" | "large";
  circular?: boolean;
  iconOnly?: boolean;
  children?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

const disableInfo: SxProps = {
  transition: "all 0.3s ease-in",
  "&:disabled": {
    color: "#344767 !important",
    border: "1px solid #344767 !important",
    background: "white !important",
  },
};

export const MDButton: FC<MDButtonProps> = forwardRef(
  (
    { color, variant, size, circular, iconOnly, children, loading, disabled, href, ...rest },
    ref
  ) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    if (href) {
      return (
        <Link to={href}>
          <MDButtonRoot
            sx={rest.sx ?? color === "info" ? disableInfo : undefined}
            {...rest}
            ref={ref}
            color="primary"
            variant={variant === "gradient" ? "contained" : variant}
            size={size}
            href={undefined}
            ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
            disabled={loading || disabled}
          >
            {children}
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "inherit",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDButtonRoot>
        </Link>
      );
    }

    return (
      <MDButtonRoot
        sx={rest.sx ?? color === "info" ? disableInfo : undefined}
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
        disabled={loading || disabled}
      >
        {children}
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: "inherit",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDButtonRoot>
    );
  }
);

// Declaring default props for MDButton
MDButton.defaultProps = {
  color: "white",
  variant: "contained",
  size: "medium",
  circular: false,
  iconOnly: false,
  loading: false,
  disabled: false,
};

export default MDButton;
