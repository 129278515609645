import { createContext, useContext, useCallback, useState, useEffect } from "react";
import { ApiAuthRequest } from "data/ApiAuth";
import { MUTATION_LOGIN, ResponseLogin } from "data/queries/ApiAuth/Login/MutationLogin";
import { QUERY_GET_ME, ResponseFindMe, User } from "data/queries/ApiAuth/UserData/QueryGetMe";
import {
  Address,
  QUERY_GET_ADDRESSES,
  ResponseGetAddresses,
} from "data/queries/ApiAuth/Addresse/QueryGetAddresses";

import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  QUERY_GET_SESSIONS,
  ResponseFindmanySessions,
} from "data/queries/ApiAuth/Sessions/QuerySessions";
import {
  MUTATION_USER_NAVIGATE,
  ResponseSendUserNavigate,
} from "data/queries/ApiAuth/UserNavigate/MutationUserNavigate";

const TOKEN_KEY = "UserToken";
const LOGIN_PATH = "/auth/login";
const RECOVER_PASSWORD_PATH = "/auth/recuperar-senha";
const VALID_ROUTES_WITH_NO_LOGIN = [LOGIN_PATH, RECOVER_PASSWORD_PATH];
interface ContextUser {
  sigInFunction: (userIdentificator: string, password: string) => Promise<void>;
  user: User;
  addresses: Address[];
  tokenUser: string;
  countSession: number;
}

const Auth = createContext<ContextUser>({} as ContextUser);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>();
  const [addresses, setAddresses] = useState<Address[]>();
  const location = useLocation();
  const [token, setToken] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const [countSession, setCountSession] = useState<number>(0);
  const [pathUser, setPath] = useState<string>();
  const PATH_USER_NOW = "UserPathNowPainel";

  console.log(user, token);
  const getLSToken = useCallback(() => localStorage.getItem(TOKEN_KEY), []);

  const redirectToLogin = useCallback(() => {
    if (!VALID_ROUTES_WITH_NO_LOGIN.includes(location.pathname)) {
      navigate(LOGIN_PATH);
    }
  }, []);

  useEffect(() => {
    setToken(getLSToken());
  }, []);

  useEffect(() => {
    if (!user && !getLSToken()) redirectToLogin();
  }, [location.pathname, user, getLSToken, redirectToLogin]);

  const sigInFunction = async (userIdentificator: string, password: string) => {
    return new Promise<void>((resolve) => {
      const NO_PERMISSION_MESSAGE = "Email ou senha incorreto";
      ApiAuthRequest<ResponseLogin>({
        query: MUTATION_LOGIN,
        variables: {
          password,
          userIdentificator,
        },
      })
        .then(({ data }) => {
          if (!data?.data?.login?.user?.role) {
            throw new Error(NO_PERMISSION_MESSAGE);
          }

          if (data?.data?.login?.user) {
            handleUser(data.data.login.user);
            localStorage.setItem(TOKEN_KEY, data.data.login.accessToken);
            setToken(data.data.login.accessToken);
            navigate("/dashboards/analises");
          }
        })
        .catch((e: any) => {
          setErrorMessage(
            e?.message === NO_PERMISSION_MESSAGE
              ? NO_PERMISSION_MESSAGE
              : "Email ou senha incorretos."
          );
        })
        .finally(() => resolve());
    });
  };

  const handleUser = (user: User) =>
    setUser({
      ...user,
      permissions: [...(user?.permissions ?? []), ...(user?.role?.permissions ?? [])].filter(
        (permission, index, self) => index === self.findIndex((p) => p.id === permission.id)
      ),
    });

  const GetUserByToken = () => {
    if (!getLSToken()) return;

    ApiAuthRequest<ResponseFindMe>({
      query: QUERY_GET_ME,
    })
      .then(({ data }) => {
        if (data?.data?.Me) {
          handleUser(data.data.Me);
        } else {
          localStorage.removeItem(TOKEN_KEY);
          setErrorMessage("Sessão invalida, faça login novamente.");
          setTimeout(() => {
            redirectToLogin();
          }, 2000);
        }
      })
      .catch((e: any) => {
        localStorage.removeItem(TOKEN_KEY);
        setErrorMessage("Sessão invalida, faça login novamente.");

        setTimeout(() => {
          redirectToLogin();
        }, 2000);
      });
  };

  const GetAddressesById = (id: string) => {
    ApiAuthRequest<ResponseGetAddresses>({
      query: QUERY_GET_ADDRESSES,
      variables: {
        userId: id,
      },
    })
      .then(({ data }) => {
        console.log(data);
        setAddresses(data?.data?.Addresses?.items);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const GetSessions = () => {
    ApiAuthRequest<ResponseFindmanySessions>({
      query: QUERY_GET_SESSIONS,
      variables: {
        typeSession: "LOGIN",
      },
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(({ data }) => {
        setCountSession(data.data.sessions);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const saveLocalStorage = (keyName: string, valor: string) => {
    if (typeof window !== "undefined") {
      localStorage.setItem(keyName, valor);
    }
  };

  const getLocalStorage = useCallback((keyName: string) => {
    if (typeof window !== "undefined") return localStorage.getItem(keyName) ?? undefined;
  }, []);

  useEffect(() => setPath(getLocalStorage(PATH_USER_NOW)), [getLocalStorage]);

  const SendUserNavigate = (path: string) => {
    if (token) {
      ApiAuthRequest<ResponseSendUserNavigate>({
        query: MUTATION_USER_NAVIGATE,
        variables: { path },
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          saveLocalStorage("UserPathNowPainel", path);
          console.log(response.data);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    GetUserByToken();
  }, [token]);

  useEffect(() => {
    GetAddressesById(user?.id);
  }, [user]);

  useEffect(() => {
    GetSessions();
  }, []);

  useEffect(() => {
    if (pathUser && location.pathname && pathUser != location.pathname)
      SendUserNavigate(location.pathname);
  }, [pathUser, location]);

  return (
    <Auth.Provider
      value={{
        sigInFunction,
        user,
        addresses,
        tokenUser: token,
        countSession,
      }}
    >
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        message={errorMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setErrorMessage(undefined)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {!user && !VALID_ROUTES_WITH_NO_LOGIN.includes(location.pathname) ? (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: 9999,
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </Auth.Provider>
  );
};

export const useAuth = (): ContextUser => {
  const context = useContext(Auth);

  return context;
};
