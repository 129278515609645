import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";
import { Colligate } from "../types";

export const GET_ALL_QUERY = print(gql`
  query TeachingAreaConversions($search: String, $perPage: Int, $page: Int) {
    TeachingAreaConversions(search: $search, perPage: $perPage, page: $page) {
      items {
        id
        colligate {
          id
          databaseName
          description
          active
          partnerVisibleName
          createdAt
          updatedAt
        }
        idColligate
        databaseName
        idArea
        teachingAreaName
        createdAt
        updatedAt
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface item {
  id: number;
  colligate: Colligate;
  databaseName: string;
  idColligate: number;
  idArea: string;
  teachingAreaName: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetAllGql {
  TeachingAreaConversions: {
    items: item[];
    paginationInfo: PaginationInfo;
  };
}
