import { useEffect } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { ApiAuthRequest } from "data/ApiForm";
import { removeNullValues } from "utils/removeNullValues";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiAuthRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { slug: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.Form) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          setTemplate({
            schemaJson: schemaJson,
            UISchema: UISchemaJson,
          });

          const data = response.data.data?.Form;

          console.log({ data });

          setState({
            data: {
              ...removeNullValues(data),
              formProperties: data?.formProperties?.map((property) => removeNullValues(property)),
            },
          });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
