import { useState, useEffect } from "react";
import { GET_ALL_QUERY, GetAllGql } from "../queries/getAll";
import { ApiFinancialRequest } from "data/ApiFinancial";

export const UseGetAllOrders = () => {
  const [responses, setResponses] = useState<any>(null);
  const [allFilds, setAllFilds] = useState<any>(null);

  const getAllUniqueKeys = (objects: any) => {
    const allKeys = objects.reduce((acc: any, obj: any) => {
      return acc.concat(Object.keys(obj));
    }, []);
    return Array.from(new Set(allKeys));
  };

  useEffect(() => {
    ApiFinancialRequest<GetAllGql>({
      query: GET_ALL_QUERY,
      variables: {
        page: 1,
        perPage: 1000,
      },
    })
      .then(({ data }) => {
        const ordersItens = data.data.Orders.list;
        console.log({ ordersItens });
        const getAllUniqueFilds = getAllUniqueKeys(ordersItens);
        setResponses(ordersItens);
        setAllFilds(getAllUniqueFilds);
      })
      .catch((e: any) => {
        console.log("Email ou senha incorretos.");
        setResponses(null); // Opcional: definir como null em caso de erro
      });
  }, []);

  return {
    responses,
    allFilds,
  };
};
