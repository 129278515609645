export const MUTATION_UPDATE_BANNER = `mutation Banner($data: UpdateBannerInput!) {
  updateBanner(data: $data) {
    id
    name
    title
    description
    url
    targetBlank
    active
    sort
    site {
      id
      name
    }
    mediaFileDesktop {
      id
      path
    }
    mediaFileMobile {
      id
      path
    }
  }
}
`;

export type ResponseUpdateBanner = {
  updateBanner: {
    id: number;
    name: string;
    title: string;
    description: string;
    url: string;
    targetBlank: boolean;
    active: boolean;
    sort: number;
    site: {
      id: number;
      name: string;
    };
    mediaFileDesktop: {
      id: number;
      path: string;
    };
    mediaFileMobile: {
      id: number;
      path: string;
    };
  };
};
