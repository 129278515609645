import { IsInt, IsNotEmpty, IsOptional, IsPositive, Length } from "class-validator";
import "reflect-metadata";

export class CreateBannerInput {
  @IsNotEmpty()
  @Length(3, 255)
  name: string;

  @IsNotEmpty()
  @Length(3, 255)
  title: string;

  @IsOptional()
  description?: string;

  @IsNotEmpty()
  url: string;

  targetBlank: boolean;

  active: boolean;

  @IsNotEmpty()
  @IsInt()
  sort: number;

  @IsPositive()
  @IsInt()
  idSite: number;

  @IsPositive()
  @IsInt()
  idMediaFileDesktop: number;

  @IsPositive()
  @IsInt()
  idMediaFileMobile: number;

  @IsOptional()
  locale?: string | null;
}
