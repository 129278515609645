import List from "./List";

export const BlogComments = {
  List: List,
  Permissions: {
    create: "create:Comment",
    read: "read:Comment",
    list: "list:Comment",
    update: "update:Comment",
    delete: "delete:Comment",
  },
};
