export type PromotionType = "progressive" | "combo" | "forThePriceOf" | "regular";

export const promotionTypeList = ["progressive", "combo", "forThePriceOf", "regular"];

export interface Regular {
  generalForm: {
    beginDate: string;
    endDate: string;
    useRecurrencyCrons?: boolean;
    recurrencyDays: "everyDay" | "especificDays";
    days?: string[];
    recurrencyTimes: "allDay" | "especificTimes";
    especificTimes?: { initTime: string; endTime: string }[];
    name: string;
    description?: string;
    isActive?: boolean;
    discountType: "nominalDiscountValue" | "percentualDiscountValue" | "maximumUnitPriceDiscount";
    discountValue: number;
    isFeatured?: boolean;
  };
  appliedForm: {
    defineEligbleProducts: "BenefitAppliedToAllProducts" | "BenefitAppliedToFollowingProducts";
    categorias?: string;
    categoriesAreInclusive?: boolean;
    productsAreInclusive?: boolean;
    products?: string;
    skusAreInclusive?: boolean;
    skus?: string;
  };
  conditionsForm: {
    minValue?: number;
    maxValue?: string;
    condition: "IncludeMatchedItems" | "ExcludeMatchedItems" | "AllItems";
    totalValuePurchase?: number;
    itemMinPrice?: number;
    itemMaxPrice?: number;
    compareListPriceAndPrice: boolean;
    tagOption?: "equals" | "not equals";
    tags?: string[];
    ceps?: {
      inclusive: boolean;
      from: string;
      to: string;
    }[];
    paymentsMethods?: string;
    minInstallment?: number;
    maxInstallment?: number;
    utm_source?: string[];
    utm_campaign?: string[];
    utmi_cp?: string[];
    isFirstBuy?: boolean;
  };
  restrictionsForm: RestrictionsForm;
}

export interface ForThePriceOf {
  generalForm: {
    beginDate: string;
    endDate: string;
    recurrencyCrons?: boolean;
    recurrencyDays: {
      recurrencyDays: "everyDay" | "especificDays";
      days?: string[];
    };
    recurrencyTimes: {
      recurrencyTimes: "allDay" | "especificTimes";
      especificTimes?: { initTime: string; endTime: string }[];
    };
    name: string;
    description?: string;
    isActive?: boolean;
    isFeatured?: boolean;
  };
  appliedForm: {
    minimumQuantityBuyTogether: number;
    listSku1BuyTogether: string;
    quantityToAffectBuyTogether: number;
    discountTypeMoreForLess: "discountof" | "maxPricePerItem";
    discountValueMoreForLess: number;
    enableBuyTogetherPerSku: boolean;
    isAppliedToMostExpensive: boolean;
    shouldDistributeDiscountAmongMatchedItems: boolean;
  };
  conditionsForm: {
    minValue?: number;
    maxValue?: string;
    condition: "IncludeMatchedItems" | "ExcludeMatchedItems" | "AllItems";
    compareListPriceAndPrice: boolean;
    paymentsMethods?: string;
    utm_source?: string[];
    utm_campaign?: string[];
  };
  restrictionsForm: RestrictionsForm;
}

export interface Combo {
  generalForm: {
    beginDate: string;
    endDate: string;
    recurrencyCrons?: boolean;
    recurrencyDays: {
      recurrencyDays: "everyDay" | "especificDays";
      days?: string[];
    };
    recurrencyTimes: {
      recurrencyTimes: "allDay" | "especificTimes";
      especificTimes?: { initTime: string; endTime: string }[];
    };
    name: string;
    description?: string;
    isActive?: boolean;
  };
  appliedForm: {
    listSku1BuyTogether: string;
    percentualDiscountValueList1: number;
    minimumQuantityBuyTogether: number;
    listSku2BuyTogether: string;
    percentualDiscountValueList2: number;
  };
  conditionsForm: {
    minValue?: number;
    maxValue?: string;
    condition: "IncludeMatchedItems" | "ExcludeMatchedItems" | "AllItems";
    paymentsMethods?: string;
    minInstallment?: number;
    maxInstallment?: number;
    utm_source?: string[];
    utm_campaign?: string[];
    utmi_cp?: string[];
    isFirstBuy?: boolean;
  };
  restrictionsForm: RestrictionsForm;
}

export interface Progressive {
  generalForm: {
    beginDate: string;
    endDate: string;
    recurrencyCrons?: boolean;
    recurrencyDays: {
      recurrencyDays: "everyDay" | "especificDays";
      days?: string[];
    };
    recurrencyTimes: {
      recurrencyTimes: "allDay" | "especificTimes";
      especificTimes?: { initTime: string; endTime: string }[];
    };
    name: string;
    description?: string;
    isActive?: boolean;
    isFeatured?: boolean;
  };
  appliedForm: {
    skus: string;
    items: {
      progressiveDiscountQuantity: number;
      progressiveDiscountPercent: number;
    }[];
  };
  conditionsForm: {
    ceps?: {
      inclusive: boolean;
      from: string;
      to: string;
    }[];
    paymentsMethods?: string;
    utm_source?: string[];
    utm_campaign?: string[];
  };
  restrictionsForm: RestrictionsForm;
}

interface RestrictionsForm {
  maxUsageUnlimited?: boolean;
  maxUsage?: number;
  maxUsagePerClientUnlimited?: boolean;
  maxUsagePerClient?: number;
  maxNumberOfAffectedItemsUnlimited?: boolean;
  maxNumberOfAffectedItems?: number;
  cumulative?: boolean;
  accumulateWithManualPrice?: boolean;
}
