import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeleteCalendarModality($id: Int!) {
    deleteCalendarModality(id: $id) {
      id
    }
  }
`);

export interface DeleteGql {
  deleteCalendarModality: {
    id?: number;
  };
}
