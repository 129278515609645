import { read, utils } from "xlsx";
import {
  CRPBannerInputDesk,
  CRPBannerLabelDesktop,
} from "components/CRPComponents/CRPAddBanner.style";
import MDBox from "components/MDBox";

function ReadExcelButton({
  onSuccessRead,
  setLoading,
  loading,
}: {
  onSuccessRead: (data: string[][]) => void | Promise<void>;
  setLoading?: (loading: boolean) => void;
  loading?: boolean;
}) {
  const readExcel = (file: File): Promise<string[][]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const resultData = utils.sheet_to_json<string[]>(sheet, { header: 1, blankrows: false });
        resolve(resultData);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const handleFileSelect = async (file: File) => {
    try {
      const response = await readExcel(file);
      console.log({ read: response });
      await onSuccessRead(response);
    } catch (error) {
      console.error("Error ReadingExcel", error);
    }
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading?.(true);
      handleFileSelect(file).finally(() => setLoading?.(false));
    }
  };
  // return <input type="file" onChange={handleFileUpload} />;
  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "16px",
        alignItems: "start",
        margin: "16px 0",
      }}
    >
      <CRPBannerInputDesk onChange={handleFileUpload} disabled={loading} />
      <CRPBannerLabelDesktop>{loading ? "Loading ..." : "Upload xls"}</CRPBannerLabelDesktop>
    </MDBox>
  );
}

export default ReadExcelButton;
