import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { getStatusCell } from "components/CRPComponents/Tables/GenericTable/utils/getStatusCell";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          status: null,
          siteId: props.idSite,
        }}
        useStatusString={true}
        path={`${props.path}/popups`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.PopUps.items}
        formatPagination={(data) => data.PopUps.paginationInfo}
        title="NOVO POPUP"
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/popups/${value}`} value={value} />
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }: any) => getStatusCell(value),
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
