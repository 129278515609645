import GenericTable from "../Tables/GenericTable";
import MDButton from "components/MDButton";
import DefaultCell from "../Tables/components/DefaultCell";
import { GET_ALL_QUERY, GetAllGql, GetAllItem } from "./queries/getAll";
import { Sites } from "data/utils/globals";
import ModalCustom from "../ModalCustom";
import { ModalTableSelect } from "../JsonForm/renderers/SelectMultiple/types";

const btnStyle = {
  border: "1px solid #7b809a",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

const btnStyleRemove = {
  ...btnStyle,
  border: "1px solid #F44335",
  color: "#F44335",
};

export default function ModalBlogTagsSelect({ ids, handleOpen, onChange, open }: ModalTableSelect) {
  return (
    <ModalCustom
      onClose={handleOpen}
      open={open}
      sx={{
        "& .head": {
          justifyContent: "center",
          width: "100%",
        },
      }}
    >
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 8,
          search: null,
        }}
        path={`/${Sites.UNIFTEC.path}/blog-tags`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Tags.items}
        formatPagination={(data) => data.Tags.paginationInfo}
        columnsTable={[
          {
            Header: "Ação",
            accessor: "id",
            Cell: ({ value, cell }: any) => (
              <MDButton
                sx={ids.includes(Number(value)) ? btnStyleRemove : btnStyle}
                onClick={() => onChange(cell.row.original as GetAllItem)}
              >
                {ids.includes(Number(value)) ? "Remover" : "Adicionar"}
              </MDButton>
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
    </ModalCustom>
  );
}
