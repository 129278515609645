import useGetItem from "./hooks/useGetItem";
import { useNavigate, useParams } from "react-router-dom";
import useUpdate from "./hooks/useUpdate";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateOrUpdateGql } from "./queries/createOrUpdate";
import MDButton from "components/MDButton";
import { useState } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { print } from "graphql";
import { gql } from "@apollo/client";

function Update() {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>();
  const couponCode = useParams()?.couponCode;
  const navigate = useNavigate();

  if (!couponCode) return null;

  const handleArchive = () => {
    ApiFinancialRequest({
      query: print(gql`
        mutation ArchiveCoupon($couponCode: String!, $archive: Boolean!) {
          archiveCoupon(couponCode: $couponCode, archive: $archive)
        }
      `),
      variables: {
        couponCode: state?.data?.couponCode,
        archive: !state?.data?.isArchived,
      },
    }).then(() => navigate("/ecommerce/cupoms"));
  };

  return (
    <GenericMethods.Update<SchemaData, CreateOrUpdateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath="/ecommerce/cupoms"
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={couponCode}
      hideDeleteButton
      state={state}
      setState={setState}
    >
      <MDButton
        onClick={handleArchive}
        sx={{
          border: `1px solid ${state?.data?.isArchived ? "green" : "red"}`,
          color: `${state?.data?.isArchived ? "green" : "red"} !important`,
        }}
      >
        {state?.data?.isArchived ? "Desarquivar" : "Arquivar"}
      </MDButton>
    </GenericMethods.Update>
  );
}

export default Update;
