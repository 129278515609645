import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query FeedbackProducts(
    $categoryId: Int
    $status: StatusEnum
    $page: Int
    $perPage: Int
    $courseId: Int
  ) {
    FeedbackProducts(
      categoryId: $categoryId
      status: $status
      page: $page
      perPage: $perPage
      courseId: $courseId
    ) {
      items {
        id
        title
        comment
        status
        rating
        course {
          id
          name
        }
        userId
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  title: string;
  comment: string;
  status: StatusEnum;
  rating: number;
  course: {
    id: number;
    name: string;
  };
  userId: string;
  createdAt: string;
}

export type StatusEnum = "ACTIVE" | "DRAFT" | "INACTIVE";

export interface GetAllGql {
  FeedbackProducts: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}

export interface CellProps {
  value: any;
  cell: {
    row: {
      original: GetAllItem;
    };
  };
}
