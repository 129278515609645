import { useCallback, useEffect, useState } from "react";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { ApiFinancialRequest } from "data/ApiFinancial";
import { print } from "graphql";
import { gql } from "@apollo/client";

const useGetItem = (id: string) => {
  const [data, setData] = useState<GetItemGql["Order"]>();
  const [loadingActions, setLoadingActions] = useState(false);

  const loadOrder = useCallback(() => {
    setLoadingActions(true);
    ApiFinancialRequest<GetItemGql>({
      query: GET_ITEM_QUERY,
      variables: { id },
    })
      .then((response) => {
        if (!response?.data?.data?.Order) {
          throw new Error("Não encontrado", {
            cause: { response: response?.data || response },
          });
        }

        const data = response.data.data?.Order;

        setData(data);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => setLoadingActions(false));
  }, []);

  useEffect(() => loadOrder(), [id]);

  const cancelOrder = useCallback(() => {
    setLoadingActions(true);
    ApiFinancialRequest<void>({
      query: print(gql`
        mutation CancelOrder($id: String!, $reason: String) {
          cancelOrder(id: $id, reason: $reason) {
            orderId
          }
        }
      `),
      variables: { id },
    })
      .catch((e) => console.log(e))
      .finally(() => {
        loadOrder();
        setLoadingActions(false);
      });
  }, []);

  const handleOrder = useCallback(() => {
    setLoadingActions(true);
    ApiFinancialRequest<void>({
      query: print(gql`
        mutation HandleOrder($id: String!) {
          handleOrder(id: $id)
        }
      `),
      variables: { id },
    })
      .catch((e) => console.log(e))
      .finally(() => {
        loadOrder();
        setLoadingActions(false);
      });
  }, []);

  return {
    data,
    loadOrder,
    cancelOrder,
    loadingActions,
    handleOrder,
  };
};

export default useGetItem;
