import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import {
  GET_ALL_RESPONSES,
  GetAllResponsesGql,
  GetAllResponsesItem,
  CellProps,
} from "./queries/getAllResponses";
import { ApiAuthWithSetData } from "data/ApiForm";
import { useState, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ClickAwayListener,
  Dialog,
  IconButton,
  TextField,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { AnalysisButton, Group, Key, Value } from "layouts/aplicacoes/BlogComments/styles";
import CloseIcon from "@mui/icons-material/Close";
import useCancelOrder from "./hooks/useCancelOrder";
import MDSnackbar, { MDSnackbarProps } from "components/MDSnackbar";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import { ListProps } from "./types";

function List({ slug, path }: ListProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState<GetAllResponsesItem>();
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });
  const [reason, setReason] = useState("");
  const [orderId, setOrderId] = useState("");
  const { handleCancelOrder } = useCancelOrder({ setSnackBarInfo });
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);

  const handleOpen = useCallback(() => setOpen((old) => !old), []);

  const cancelOrder = async () => {
    await handleCancelOrder({
      cancelOrderId: orderId,
      reason,
    });

    setModal(false);
    setOrderId("");
    setReason("");
    setCheckbox1(false);
    setCheckbox2(false);
  };

  const openModalCancelOrder = (data: any) => {
    const response = JSON.parse(data.response);

    setOrderId(response.OrderId);
    setModal(true);
  };

  const closeModalCancelOrder = () => {
    setModal(false);
    setOrderId("");
    setReason("");
    setCheckbox1(false);
    setCheckbox2(false);
  };

  const handleClick = (data: GetAllResponsesItem) => {
    setOpen(true);
    setContent(data);
  };

  const handleCloseSnackBar = () => {
    if (snackBarInfo.redirectid === -1) navigate(0);
  };

  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <GenericTable<GetAllResponsesGql>
          initialVariables={{ formSlug: slug }}
          path={`${path}/contato`}
          query={GET_ALL_RESPONSES}
          formatRows={(data) => data.FormResponses.items}
          formatPagination={(data) => data.FormResponses?.paginationInfo}
          ApiSetData={ApiAuthWithSetData}
          useDefaultFilter={false}
          columnsTable={[
            {
              Header: "id",
              accessor: "id",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "Criado em",
              accessor: "createdAt",
              Cell: ({ value }: any) => (
                <DefaultCell value={new Date(value).toLocaleDateString()} />
              ),
            },
            {
              Header: "Reposta",
              accessor: "",
              Cell: ({ value, cell }: CellProps) => (
                <AnalysisButton onClick={() => handleClick(cell.row.original)}>ver</AnalysisButton>
              ),
            },
            {
              Header: "Excluir",
              accessor: "",
              Cell: ({ value, cell }: CellProps) => (
                <AnalysisButton onClick={() => openModalCancelOrder(cell.row.original)}>
                  Cancelar pedido
                </AnalysisButton>
              ),
            },
          ]}
        />
        <Footer />
      </DashboardLayout>

      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "30vw",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleOpen}>
          <Stack>
            <IconButton
              aria-label="close"
              size="large"
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              onClick={handleOpen}
            >
              <CloseIcon />
            </IconButton>
            {content && (
              <Stack
                sx={{
                  padding: "30px",
                  gap: "15px",
                }}
              >
                <Stack>
                  {Object.entries(JSON.parse(content.response)).map(
                    ([key, value]: [string, any], index) => (
                      <Group key={index}>
                        <Key>{key}</Key>
                        <Value>{value}</Value>
                      </Group>
                    )
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </ClickAwayListener>
      </Dialog>

      <Dialog
        open={modal}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "30vw",
          },
        }}
      >
        <ClickAwayListener onClickAway={closeModalCancelOrder}>
          <Stack>
            <IconButton
              aria-label="close"
              size="large"
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              onClick={closeModalCancelOrder}
            >
              <CloseIcon />
            </IconButton>
            <Stack gap={"15px"} padding={"30px"}>
              <Stack>
                <Typography fontSize={"16px"}>Cancelar pedido #{orderId}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={"14px"}>
                  Esta ação é irreversível e cancela o pedido. Para continuar, confirme que está
                  ciente das implicações marcando os itens abaixo:
                </Typography>
              </Stack>
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox1}
                      onChange={(e) => setCheckbox1(e.target.checked)}
                    />
                  }
                  label="Esta ação é permanente."
                />
              </Stack>
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox2}
                      onChange={(e) => setCheckbox2(e.target.checked)}
                    />
                  }
                  label="Eu entendo o impacto no fluxo do pedido."
                />
              </Stack>
              <Stack>
                <TextField
                  label="Motivo do cancelamento"
                  variant="outlined"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Stack>
              <Stack direction={"row"}>
                <Button onClick={() => cancelOrder()} disabled={!checkbox1 || !checkbox2}>
                  Confimar
                </Button>
                <Button onClick={closeModalCancelOrder}>Cancelar operação</Button>
              </Stack>
            </Stack>
          </Stack>
        </ClickAwayListener>
      </Dialog>
      <MDSnackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        {...snackBarInfo}
        title={snackBarInfo.title}
        close={handleCloseSnackBar}
        onClose={handleCloseSnackBar}
      />
    </Fragment>
  );
}

export default List;
