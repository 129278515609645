import { withJsonFormsControlProps } from "@jsonforms/react";
import SelectMultiple from "../SelectMultiple";
import { CustomRendererProps } from "../types";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { print } from "graphql";
import { gql } from "@apollo/client";
import ModalCoursesSelect from "components/CRPComponents/ModalCoursesSelect";

const query = print(gql`
  query Course($id: Int!) {
    Course(id: $id) {
      name
      id
    }
  }
`);
interface ItemGql {
  Course: {
    name: string;
    id: number;
  };
}

const CourseSelectMultiple = (props: CustomRendererProps<number[] | undefined>) => {
  return (
    <SelectMultiple
      {...props}
      Modal={ModalCoursesSelect}
      formatItem={({ data }) => data.data.Course}
      requestItem={(id: number) => ApiCourseRequest<ItemGql>({ query, variables: { id } })}
    />
  );
};

export default withJsonFormsControlProps(CourseSelectMultiple, true);
