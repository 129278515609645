import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";
import { GET_ALL_QUERY, GetAllGql, GetAllItem } from "../queries/getAll";

export default function useGetAll() {
  const [categories, setCategories] = useState<null | GetAllItem[]>(null);

  useEffect(() => {
    ApiCourseRequest<GetAllGql>({
      query: GET_ALL_QUERY,
    }).then(({ data }) => setCategories(data?.data?.Categories?.items));
  }, []);

  return {
    categories,
  };
}
