export const QUERY_GET_ADDRESSES = `query Addresses($userId: String) {
  Addresses(userId: $userId) {
    items {
      id
      addressName
      city
      complement
      country
      postalCode
      reference
      state
      street
      neighborhood
      addressNumber
    }
  }
}`;

export interface Address {
  id: number;
  addressName: string;
  city: string;
  complement: string;
  country: string;
  postalCode: string;
  reference: string;
  state: string;
  street: string;
  neighborhood: string;
  addressNumber: string;
}

export type ResponseGetAddresses = {
  Addresses: {
    items: Address[];
  };
};
