import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query GlobalKey($key: String!) {
    GlobalKey(key: $key) {
      key
      value
    }
  }
`);

export interface GetItemGql {
  GlobalKey: {
    key: string;
    value: string;
  };
}
