import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation Create($data: CreateColligateInput!) {
    createColligate(data: $data) {
      id
      databaseName
    }
  }
`);

export const responseCreateKey = "createColligate";
export interface CreateGql {
  createColligate: {
    id: number;
    databaseName: string;
  };
}
