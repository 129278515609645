import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateCategory($data: CreateCategoryInput!) {
    createCategory(data: $data) {
      id
      name
      active
    }
  }
`);

export interface CreateGql {
  createCategory: {
    id: number;
    name: string;
    active: boolean;
  };
}
