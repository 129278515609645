import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import {
  GET_ALL_QUERY,
  GET_ALL_QUERY_ARCHIVED,
  GetAllArchivedGql,
  GetAllGql,
} from "./queries/getAll";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";
import { Type } from "./queries/getItem";
import { getStatusCell } from "components/CRPComponents/Tables/GenericTable/utils/getStatusCell";
import MDInput from "components/MDInput";
import { useState } from "react";
import { MenuItem } from "@mui/material";

function List(props: ListProps) {
  const [status, setStatus] = useState<"archived" | "all">("all");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql | GetAllArchivedGql>
        initialVariables={{
          search: null,
        }}
        path={`/ecommerce/promocoes`}
        query={status === "archived" ? GET_ALL_QUERY_ARCHIVED : GET_ALL_QUERY}
        formatRows={(data) =>
          (status === "archived"
            ? (data as GetAllArchivedGql)?.PromotionsArchived?.items
            : (data as GetAllGql)?.Promotions?.items) ?? []
        }
        formatPagination={() => [] as any}
        title="NOVA PROMOÇÃO"
        ApiSetData={ApiFinancialWithSetData}
        useDefaultFilter={false}
        childrenFilters={
          <MDInput
            value={status}
            label="Status"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setStatus(e.target.value as typeof status)
            }
            sx={{ width: "100px" }}
            select
          >
            <MenuItem value={"archived"}>Arquivados</MenuItem>
            <MenuItem value={"all"}>Todos</MenuItem>
          </MDInput>
        }
        columnsTable={[
          {
            Header: "id",
            accessor: "idCalculatorConfiguration",
            Cell: ({
              value,
              cell: {
                row: {
                  original: { type },
                },
              },
            }: any) => (
              <LinkCell href={`/ecommerce/promocoes/${value}?type=${type}`} value={value} />
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Tipo",
            accessor: "type",
            Cell: ({ value }: { value: keyof typeof Type }) => <DefaultCell value={Type[value]} />,
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }: any) => getStatusCell(value?.toUpperCase()),
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
