import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { useCallback, useState } from "react";
import { EventsRelatedProps } from "./types";
import { ApiCMSRequest } from "data/ApiCMS";

export const QUERY = print(gql`
  query RelatedEvents($slug: String!) {
    RelatedEvents(slugEvent: $slug) {
      id
    }
  }
`);

export interface QueryGql {
  RelatedEvents: Array<{
    id: number;
  }>;
}

export default function useEventsRelated() {
  const [eventsRelated, setEventsRelated] = useState<null | number[]>(null);

  const getEventsRelated = useCallback(({ slug }: EventsRelatedProps) => {
    if (eventsRelated) return;

    ApiCMSRequest<QueryGql>({
      query: QUERY,
      variables: { slug },
    })
      .then(({ data }) => setEventsRelated(data?.data?.RelatedEvents?.map(({ id }) => id)))
      .catch((err) => console.log(err));
  }, []);

  return {
    eventsRelated,
    getEventsRelated,
  };
}
