import { useState, useEffect } from "react";

// Layout
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { JsonFormsComponent } from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { DataFormType, QueryTemplateCreateSchemaResponse } from "../types";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import {
  QUERY_PROPS_SCHOLAR_SHIP_AND_INCENTIVE,
  ResponseCategoriesScholarshipAndIncentive,
  ResponseModalitiesScholarshipAndIncentive,
  ResponseTemplateSchema,
} from "data/queries/ApiCourse/ScholarshipAndIncentive/QueryScholarshipAndIncentive";
import { addOptionsToSchema } from "../utils/formatWithDetails";
import { MUTATION_CREATE_SHIP_AND_INCENTIVE } from "data/queries/ApiCourse/ScholarshipAndIncentive/MutationCreateScholarshipAndIncentive";
import { userPermissionsProps } from "hooks/types";
import { userPermissions } from "hooks/Permissions";

function FormCreateScholarShip<ContentJsonType = any>({
  actionsPermission,
}: {
  actionsPermission: userPermissionsProps;
}): JSX.Element {
  const [template, setTemplate] = useState<QueryTemplateCreateSchemaResponse>();
  const [state, setState] = useState<JsonFormsStateProps<DataFormType & ContentJsonType>>();
  const templateSchemaName = "ScholarShip";
  const permission = userPermissions(actionsPermission);

  useEffect(() => {
    setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
    ApiCourseRequest<{
      templateSchema: ResponseTemplateSchema;
      Categories: ResponseCategoriesScholarshipAndIncentive;
      Modalities: ResponseModalitiesScholarshipAndIncentive;
    }>({
      query: QUERY_PROPS_SCHOLAR_SHIP_AND_INCENTIVE,
      variables: { name: templateSchemaName },
    })
      .then((response) => {
        if (!response?.data?.data?.templateSchema) {
          throw new Error("Bolsa, Incentivo e Financiamento não encontrado", {
            cause: { response: response?.data || response },
          });
        }

        const { schemaJson, UISchema, ...props } = response?.data?.data?.templateSchema;

        //All Categories Course
        const allCategories = response?.data?.data?.Categories?.items;
        const allModalities = response?.data?.data?.Modalities?.items;

        setTemplate({
          schemaJson: addOptionsToSchema(schemaJson, allCategories, allModalities),
          UISchema: UISchema,
        });

        setState(({ data, ...prev }) => ({
          ...prev,
        }));
      })
      .catch((error) => {
        console.log({ error });

        setState((prev) => ({
          ...prev,
          criticalError: error?.response?.data?.errors?.[0] || error,
        }));
      });
  }, []);

  const handleSave = async () => {
    const errors = [];
    if (!state?.data?.details?.title) {
      errors.push("Título é obrigatório");
    }
    if (!state?.data?.details?.imageUrl) {
      errors.push("Imagem de fundo é obrigatória");
    }
    if (!state?.data?.categoriesBlock?.categories?.length) {
      errors.push("Níveis de ensino deve conter pelo menos 1 item");
    }
    if (!state?.data?.modalitiesBlock?.modalities?.length) {
      errors.push("Modalidades deve conter pelo menos 1 item");
    }

    if (errors?.length) {
      throw {
        response: {
          data: {
            errors: errors.map((messageError) => ({
              extensions: { error: { stack: messageError } },
            })),
          },
        },
      };
    }

    const {
      details: { linkRegulation, title, active, imageUrl, startDate, endDate },
      categoriesBlock,
      modalitiesBlock,
      ...content
    } = state?.data;

    const categoriesList = state?.data?.categoriesBlock?.categories;
    const modalitiesList = state?.data?.modalitiesBlock?.modalities;

    const response = await ApiCourseRequest({
      query: MUTATION_CREATE_SHIP_AND_INCENTIVE,
      variables: {
        data: {
          contentJson: JSON.stringify(content),
          title,
          linkRegulation,
          imageUrl,
          active,
          startDate,
          endDate,
          categoriesIds: categoriesList,
          modalitiesIds: modalitiesList,
          templateSchemaName,
        },
      },
    });
    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data) {
      throw new Error("Ops. Sem resposta!");
    }

    const responseContent: any = response.data;

    window.location.href = `/uniftec/scholarShips/${responseContent?.data?.createScholarshipAndIncentive?.id}`;

    return response;
  };

  return (
    <JsonFormsComponent
      template={template}
      state={state}
      onChange={setState}
      onSave={handleSave}
      permissions={permission}
    />
  );
}

export default FormCreateScholarShip;
