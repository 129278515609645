import { useCallback, useState, Fragment } from "react";
import MDButton, { MDButtonProps } from "components/MDButton";
import { ApiCMSRequest } from "data/ApiCMS";
import Modal from "components/Modal";
import MDTypography from "components/MDTypography";
import Row from "components/Row";
import Col from "components/Col";

export interface MidiaDeleteButtonProps extends Omit<MDButtonProps, "onClick"> {
  onDeleteSuccess?: (id: number) => void;
  onDeleteError?: (err: Error) => void;
  deleteMediaFileId?: number;
  disabled?: boolean;
}

export function MidiaDeleteButton({
  onDeleteSuccess,
  onDeleteError,
  deleteMediaFileId,
  disabled = false,
  ...props
}: MidiaDeleteButtonProps) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleDelete = useCallback(() => {
    ApiCMSRequest<{ deleteMediaFile: { id: number } }>({
      query: `mutation DeleteMediaFile($deleteMediaFileId: Int!) {
        deleteMediaFile(id: $deleteMediaFileId) {
          id
        }
      }`,
      variables: { deleteMediaFileId },
    })
      .then((response) => {
        if (!response?.data?.data?.deleteMediaFile) {
          if (response.data?.errors?.[0]) throw response.data?.errors?.[0];
          throw new Error(JSON.stringify(response?.data || "Something wrong"));
        }
        onDeleteSuccess?.(response.data.data.deleteMediaFile.id);
      })
      .catch((error) => {
        console.error(error);
        onDeleteError?.(error);
      });
  }, [deleteMediaFileId]);

  if (!deleteMediaFileId) return null;
  return (
    <Fragment>
      <MDButton {...props} onClick={handleOpenConfirm} disabled={disabled} />
      <Modal
        title="Tem certeza que deseja deletar?"
        open={openConfirm}
        onCloseButton={handleCloseConfirm}
      >
        <Col>
          <MDTypography>Essa operação não pode ser desfeita!</MDTypography>
          <Row width="100%" justifyContent="space-between" pt={2}>
            <MDButton color="success" onClick={handleCloseConfirm}>
              Cancelar
            </MDButton>
            <MDButton color="error" onClick={handleDelete}>
              Deletar
            </MDButton>
          </Row>
        </Col>
      </Modal>
    </Fragment>
  );
}

MidiaDeleteButton.defaultProps = {
  color: "error",
  children: "Deletar",
};

export default MidiaDeleteButton;
