import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import { GetItemGql } from "./queries/getItem";
import { formatPrice } from "utils/formatPrice";
import { groupBy } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

enum PaymentGroups {
  creditCard = "Cartão de crédito",
  giftCard = "Vale-presente",
  instantPayment = "Pix",
}

function Payments({ paymentData }: { paymentData: GetItemGql["Order"]["paymentData"] }) {
  const payments = paymentData?.transactions?.[0]?.payments;
  if (!payments?.length) return null;

  const paymentsGroups = Object.entries(groupBy(payments, "group"))?.map((group) => ({
    groupName: group[0],
    items: group[1],
  }));

  const getPriceWithInstallment = (price: number, installments: number) => {
    const pricePerInstallment = price / installments;
    if (installments > 1)
      return `${installments}x de ${formatPrice(pricePerInstallment / 100)} = ${formatPrice(
        price / 100
      )}`;

    return `1x de ${formatPrice(price / 100)}`;
  };

  return (
    <Stack>
      {paymentsGroups.map((item, i) => (
        <Accordion
          key={i}
          sx={{
            boxShadow: "none",
            border: "none",
            width: "100%",
            span: {
              fontSize: "0.75rem",
              lineHeight: 1.33333,
              letterSpacing: "0.001em",
              color: "rgb(84, 84, 84)",
              marginBlock: "4px",
            },
            "& p": {
              fontSize: "0.875rem",
              lineHeight: "1.42857",
              letterSpacing: "-0.0009em",
              color: "black",
              marginBlock: "0px 12px !important",
            },
            "& .MuiAccordionSummary-content, & .MuiButtonBase-root": {
              margin: 0,
              minHeight: "unset",
              height: "unset",
              marginBlock: "10px",
            },
          }}
          defaultExpanded={
            item.items.some((p) => p.connectorResponses.status === "approved") ||
            i === paymentsGroups.length - 1
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              fontSize: "16px",
              margin: "0px !important",
            }}
          >
            {PaymentGroups[item.groupName as keyof typeof PaymentGroups] ?? item.groupName}
          </AccordionSummary>
          <AccordionDetails>
            {item.items.map((payment, index) => (
              <Stack
                key={payment.id}
                paddingBlock={"6px"}
                borderTop={index === 0 ? "none" : "1px solid rgb(84, 84, 84)"}
              >
                <span>Método</span>
                <p>
                  {payment.paymentSystemName}
                  <br />
                  {getPriceWithInstallment(payment.value, payment.installments)}
                </p>
                {payment.connectorResponses?.acquirer && (
                  <>
                    <span>Adquirente</span>
                    <p>{payment.connectorResponses?.acquirer}</p>
                  </>
                )}
                {payment.connectorResponses?.message && (
                  <>
                    <span>Status</span>
                    <p>{payment.connectorResponses?.message}</p>
                  </>
                )}
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}

export default Payments;
