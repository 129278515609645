import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const DynamicPage = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    list: "list:Page",
    create: "create:Page",
    read: "read:Page",
    update: "update:Page",
    delete: "delete:Page",
  },
};
