export const MUTATION_DELETE_BANNER = `mutation Banner($deleteBannerId: Int!) {
  deleteBanner(id: $deleteBannerId) {
    id
  }
}`;

export type ResponseDeleteBanner = {
  deleteBanner: {
    id: number;
  };
};
