import { useState, useEffect } from "react";

// Layout
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { JsonFormsComponent } from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { DataFormType, QueryTemplateCreateSchemaResponse } from "../types";
import { addOptionsToSchema, addOptionsToUiSchema } from "../utils/formatWithDetails";
import MDSnackbar, { MDSnackbarProps } from "components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import { QUERY_FAQ, ResponseCategoryFaqs, ResponseFaq } from "data/queries/ApiCMS/Faq/QueryFaq";
import { ApiCMSRequest } from "data/ApiCMS";
import { schemaJsonFaqs, uischemaJsonFaqs } from "layouts/aplicacoes/uniftec/faqs/preferencesJson";
import { MUTATION_UPDATE_FAQ } from "data/queries/ApiCMS/Faq/MutationUpdateFaq";
import ButtonDeleteFaq from "../ButtonDeleteFaq";

function FormUpdateFaq<ContentJsonType = any>({
  idFaq,
  inicialEditing = false,
  path,
  siteId,
  includeCategory = false,
}: {
  idFaq: number;
  inicialEditing?: boolean;
  path?: string;
  siteId: number;
  includeCategory?: boolean;
}): JSX.Element {
  const [template, setTemplate] = useState<QueryTemplateCreateSchemaResponse>();
  const [state, setState] = useState<JsonFormsStateProps<DataFormType & ContentJsonType>>();
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });

  console.log(template, state);

  const handleCloseSnackBar = () => {
    setSnackBarInfo(({ redirectid, props }) => {
      if (redirectid) setEditing(false);
      if (redirectid === -1) navigate(`${path}/faqs`);
      return { ...props, open: false, autoHideDuration: undefined };
    });
    setLoading(false);
  };

  const navigate = useNavigate();
  const [editing, setEditing] = useState(inicialEditing);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!!idFaq) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCMSRequest<{ Faq: ResponseFaq; Categories: ResponseCategoryFaqs }>({
        query: QUERY_FAQ,
        variables: { faqId: idFaq },
      })
        .then((response: any) => {
          if (!response?.data?.data?.Faq) {
            throw new Error("Pergunta Frequente não encontrada", {
              cause: { response: response?.data || response },
            });
          }

          const {
            question,
            answer,
            sort,
            student,
            status,
            createdAt,
            updatedAt,
            categoryFaq,
            site,
          } = response?.data?.data?.Faq;
          const categoriesList = response?.data?.data?.CategoryFaqs;

          //Verify FAQ and Site
          if (site?.id != siteId) {
            throw new Error("Pergunta Frequente não pertence a esse site", {
              cause: { response: response?.data || response },
            });
          }

          let contentBaseJSON = {};

          if (includeCategory && categoryFaq?.id) {
            contentBaseJSON = {
              question: question,
              answerHtml: answer,
              sort: sort,
              student: student,
              status: status,
              createdAt: createdAt,
              updatedAt: updatedAt,
              category: categoryFaq?.id,
            };
          } else {
            contentBaseJSON = {
              question: question,
              answerHtml: answer,
              sort: sort,
              student: student,
              status: status,
              createdAt: createdAt,
              updatedAt: updatedAt,
            };
          }

          //TODO verificar tipagem category
          setTemplate({
            schemaJson: includeCategory
              ? addOptionsToSchema(schemaJsonFaqs, categoriesList)
              : schemaJsonFaqs,
            UISchema: includeCategory
              ? addOptionsToUiSchema(JSON.stringify(uischemaJsonFaqs))
              : uischemaJsonFaqs,
          });

          // SetCategoriesList
          const updatedContentJson = JSON.parse(JSON.stringify(contentBaseJSON));

          setState(({ data, ...prev }) => ({
            ...prev,
            data: { ...updatedContentJson, details: updatedContentJson },
          }));
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [idFaq]);

  const handleSave = async () => {
    const {
      details: { question, answerHtml, status, sort, student, category },
    } = state?.data;

    const response = await ApiCMSRequest({
      query: MUTATION_UPDATE_FAQ,
      variables: {
        data: {
          id: idFaq,
          question: question,
          answer: answerHtml,
          status: status,
          sort: sort ?? 100,
          idSite: siteId,
          student: student,
          categoryFaqId: category ?? null,
        },
      },
    });

    if (response?.data?.errors) {
      console.log(response?.data?.errors);
      throw response.data.errors[0];
    }
    if (!response?.data?.data) {
      throw new Error("Ops. Sem resposta!");
    }
    return response;
  };

  if (!idFaq) return null;

  const handleDeleteError = (e: Error) => {
    setSnackBarInfo({
      open: true,
      color: "error",
      title: "Ops, algo deu errado",
      content: e?.message || "Ops. Algo Deu errado",
      autoHideDuration: null,
    });
  };

  const handleDeleteSuccess = () => {
    setEditing(false);
    setSnackBarInfo({
      open: true,
      color: "success",
      icon: "check",
      autoHideDuration: null,
      title: "Pergunta Frequente deletada com sucesso",
      redirectid: -1,
    });

    setTimeout(function () {
      handleCloseSnackBar();
    }, 5000);
  };

  return (
    <JsonFormsComponent template={template} state={state} onChange={setState} onSave={handleSave}>
      <>
        {editing && (
          <ButtonDeleteFaq
            deleteFaqId={idFaq}
            onDeleteError={handleDeleteError}
            onDeleteSuccess={handleDeleteSuccess}
            disabled={!!snackBarInfo?.redirectid}
          />
        )}

        <MDSnackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          {...snackBarInfo}
          title={snackBarInfo.title}
          close={handleCloseSnackBar}
          onClose={handleCloseSnackBar}
        />
      </>
    </JsonFormsComponent>
  );
}

export default FormUpdateFaq;
