import { useState, useEffect } from "react";

// Layout
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { JsonFormsComponent } from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { DataFormType, QueryTemplateCreateSchemaResponse } from "../types";
import { addOptionsToSchema, addOptionsToUiSchema } from "../utils/formatWithDetails";
import { QUERY_PROPS_FAQ, ResponseCategoryFaqs } from "data/queries/ApiCMS/Faq/QueryFaq";
import { ApiCMSRequest } from "data/ApiCMS";
import { schemaJsonFaqs, uischemaJsonFaqs } from "layouts/aplicacoes/uniftec/faqs/preferencesJson";
import { MUTATION_CREATE_FAQ } from "data/queries/ApiCMS/Faq/MutationCreateFaq";
import { userPermissionsProps } from "hooks/types";
import { userPermissions } from "hooks/Permissions";

function FormCreateFaq<ContentJsonType = any>({
  siteId,
  includeCategory,
  path,
  actionsPermission,
}: {
  siteId: number;
  includeCategory?: boolean;
  path: string;
  actionsPermission: userPermissionsProps;
}): JSX.Element {
  const permission = userPermissions(actionsPermission);
  const [template, setTemplate] = useState<QueryTemplateCreateSchemaResponse>();
  const [state, setState] = useState<JsonFormsStateProps<DataFormType & ContentJsonType>>();

  useEffect(() => {
    setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
    ApiCMSRequest<{ CategoryFaqs: ResponseCategoryFaqs }>({
      query: QUERY_PROPS_FAQ,
      variables: {},
    })
      .then((response) => {
        //All Categories
        const categoriesList = response?.data?.data?.CategoryFaqs;

        setTemplate({
          schemaJson: includeCategory
            ? addOptionsToSchema(schemaJsonFaqs, categoriesList)
            : schemaJsonFaqs,
          UISchema: includeCategory
            ? addOptionsToUiSchema(JSON.stringify(uischemaJsonFaqs))
            : uischemaJsonFaqs,
        });

        setState(({ data, ...prev }) => ({
          ...prev,
        }));
      })
      .catch((error) => {
        console.log({ error });

        setState((prev) => ({
          ...prev,
          criticalError: error?.response?.data?.errors?.[0] || error,
        }));
      });
  }, []);

  const handleSave = async () => {
    const {
      details: { question, answerHtml, status, sort, student, category },
    } = state?.data;

    const response = await ApiCMSRequest({
      query: MUTATION_CREATE_FAQ,
      variables: {
        data: {
          question: question,
          answer: answerHtml,
          status: status,
          sort: sort ?? 1000,
          idSite: siteId,
          student: student,
          categoryFaqId: category ?? null,
        },
      },
    });
    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data) {
      throw new Error("Ops. Sem resposta!");
    }

    const responseContent: any = response.data;

    window.location.href = `${path}/faqs/${responseContent?.data?.createFaq?.id}`;

    return response;
  };

  return (
    <JsonFormsComponent
      template={template}
      state={state}
      onChange={setState}
      onSave={handleSave}
      permissions={permission}
    />
  );
}

export default FormCreateFaq;
