import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateGlobalFinancial($data: UpdateGlobalFinancialInput!) {
    updateGlobalFinancial(data: $data) {
      key
    }
  }
`);

export interface UpdateGql {
  updateGlobalFinancial: {
    key: string;
  };
}
