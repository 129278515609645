import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateSubject($data: CreateSubjectInput!) {
    createSubject(data: $data) {
      name
      id
    }
  }
`);

export interface CreateGql {
  createSubject: {
    id: number;
    name: string;
  };
}
