import { SchemaData, UseUpdateProps } from "./types";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiAuthRequest } from "data/ApiForm";
import { removeNullValues } from "utils/removeNullValues";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";

export default function useUpdate({ queryParam, setState }: UseUpdateProps<SchemaData>) {
  const handleGetItem = async (id: number) => {
    setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
    ApiAuthRequest<GetItemGql>({
      query: GET_ITEM_QUERY,
      variables: { id },
    })
      .then((response) => {
        if (!response?.data?.data?.Form) {
          throw new Error("Não encontrado", {
            cause: { response: response?.data || response },
          });
        }
        const data = response.data.data?.Form;

        setState({
          data: {
            ...removeNullValues(data),
            formProperties: data?.formProperties?.map((property) => removeNullValues(property)),
          },
        });
      })
      .catch((error) => {
        console.log({ error });

        setState((prev) => ({
          ...prev,
          criticalError: error?.response?.data?.errors?.[0] || error,
        }));
      });
  };
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const { ...data } = state?.data;
      delete data.schemaJson;
      delete data.UISchema;
      delete data.responses;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.isDefaultForm;
      delete data.formType;

      const response = await ApiAuthRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: { data },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data?.["updateFormWithProperties"]?.id) {
        throw new Error("Ops. Sem resposta!");
      }
      await handleGetItem(response.data.data["updateFormWithProperties"].id);
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
