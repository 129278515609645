import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateHighlightCourseShelf($data: UpdateHighlightCourseShelfInput!) {
    updateHighlightCourseShelf(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateHighlightCourseShelf: {
    id: number;
  };
}
