import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { useCallback, useState } from "react";
import { PostsRelatedProps } from "./types";
import { ApiCMSRequest } from "data/ApiCMS";

export const QUERY = print(gql`
  query RelatedPosts($slug: String!) {
    RelatedPosts(relatedSlugBlog: $slug) {
      id
    }
  }
`);

export interface QueryGql {
  RelatedPosts: Array<{
    id: number;
  }>;
}

export default function usePostsRelated() {
  const [postsRelated, setPostsRelated] = useState<null | number[]>(null);

  const getPostsRelated = useCallback(({ slug }: PostsRelatedProps) => {
    if (postsRelated) return;

    ApiCMSRequest<QueryGql>({
      query: QUERY,
      variables: { slug },
    })
      .then(({ data }) => setPostsRelated(data?.data?.RelatedPosts?.map(({ id }) => id)))
      .catch((err) => console.log(err));
  }, []);

  return {
    postsRelated,
    getPostsRelated,
  };
}
