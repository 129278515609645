import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation Update($data: UpdateTemplateInvestmentInput!) {
    updateTemplateInvestment(data: $data) {
      id
      active
      name
      allowPurchase
      allowGift
      title
      subtitle
      description
      orientationLabelButtonDefault
      orientationContentDefault
      maxAdmissionWays
      hideSelectAdmissionWays
      admissionWayLabel
      maxPlans
      hideSelectPlans
      planLabel
      maxAmountInstallmentsPrices
      installmentsPricesLabel
      hideSelectInstallmentsPrices
      createdAt
      updatedAt
      urlInscription
    }
  }
`);

export const responseUpdateKey = "updateTemplateInvestment";
export interface UpdateGql {
  updateTemplateInvestment: {
    id: number;
    active: boolean;
    name: string;
    allowPurchase: boolean;
    allowGift: boolean;
    urlInscription?: string;
    title: string;
    subtitle?: string;
    description?: string;
    orientationLabelButtonDefault: string;
    orientationContentDefault: string;
    maxAdmissionWays?: number;
    hideSelectAdmissionWays: boolean;
    admissionWayLabel: string;
    maxPlans?: number;
    hideSelectPlans: boolean;
    planLabel: string;
    maxAmountInstallmentsPrices?: number;
    installmentsPricesLabel: string;
    hideSelectInstallmentsPrices: boolean;
  };
}
