import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_RESPONSES = print(gql`
  query FormResponses($formSlug: String, $perPage: Int, $page: Int, $search: String) {
    FormResponses(formSlug: $formSlug, perPage: $perPage, page: $page, search: $search) {
      items {
        id
        formSlug
        response
        userId
        createdAt
        updatedAt
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface QueryArgs {
  formSlug: string;
  perPage?: number;
  page?: number;
  search?: string;
}

export interface GetAllResponsesItem {
  id: number;
  formSlug: string;
  response: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetAllResponsesGql {
  FormResponses: {
    items: GetAllResponsesItem[];
    paginationInfo: PaginationInfo;
  };
}

export interface CellProps {
  value: any;
  cell: {
    row: {
      original: GetAllResponsesItem;
    };
  };
}
