import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdatePopUp($data: UpdatePopUpInput!) {
    updatePopUp(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updatePopUp: {
    id: number;
  };
}
