interface Input {
  referenceData: number;
  comparedData: number;
}

export function calculatePercentage({ referenceData, comparedData }: Input): number {
  if (referenceData === 0) {
    return 0;
  }

  const percentage = ((referenceData - comparedData) / Math.abs(comparedData)) * 100;

  return percentage === Infinity ? 0 : parseFloat(percentage.toFixed(2));
}

export const getColor = (data: Input) => getColorRaw(calculatePercentage(data));

export const getColorRaw = (value: number) => (value >= 0 ? "success" : "error");

export const getPercentageText = (data: Input) => getPercentageTextRaw(calculatePercentage(data));

export const getPercentageTextRaw = (value: number) =>
  `${value >= 0 ? "+" : ""}${value.toString().replace(".", ",")}%`;
