export const schemaJson = {
  type: "object",
  properties: {
    categoryId: {
      type: "number",
    },
    partnerBrandId: {
      type: "number",
    },
    name: {
      type: "string",
    },
    description: {
      type: "string",
    },
    slug: {
      type: "string",
    },
    keywords: {
      type: "string",
      description: "SEPARADAS POR VIRGULA (,)",
    },
    active: {
      type: "boolean",
    },
    activateIfPossible: {
      type: "boolean",
      default: false,
    },
    imageUrl: {
      type: "string",
    },
    modalityName: {
      type: "string",
    },
    educationalUnitVisibleName: {
      type: "string",
    },
    state: {
      type: "string",
    },
    title: {
      type: "string",
    },
    courseLoadHours: {
      type: "number",
    },
    investmentInfoId: {
      type: "number",
    },
    eCommerceProductId: {
      type: "integer",
    },
    eCommerceProductLastUpdate: {
      type: "string",
      format: "date-time",
    },
    eCommerceControlSKUId: {
      type: "integer",
    },
    eCommerceControlSKULastUpdate: {
      type: "string",
      format: "date-time",
    },

    urlInscription: {
      type: "string",
      description: "Link de inscrição caso nao tenha cadastrado url no curso ou no investimento",
    },
    moodleId: {
      type: "string",
      description: "Id moodle",
    },
    urlInscriptionDefault: { type: "string" },

    category: {
      type: "object",
      properties: {
        department: {
          type: "object",
          properties: {
            templateInvestment: {
              type: "object",
              properties: {
                id: { type: "number" },
                name: { type: "string" },
                active: { type: "boolean" },
                allowPurchase: { type: "boolean" },
                urlInscription: { type: "string" },
              },
            },
          },
        },
      },
    },
  },
};
