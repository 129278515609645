import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { TypePropertyEnum } from "../hooks/types";

export const GET_ITEM_QUERY = print(gql`
  query FindOne($slug: String) {
    Form(slug: $slug) {
      id
      slug
      schemaJson
      UISchema
      extraJson
      status
      isDefaultForm
      subtitle
      title
      description
      formMessageHtml
      submitButtonText
      requiredUserId
      publishOn
      expiresIn
      keywords
      addSiteMap
      createdAt
      updatedAt
      formProperties {
        name
        typeProperty
        isRequired
        title
        description
        showUnfocusedDescription
        enum
        default
        isVisible
      }
      sendResponsesTo {
        email
      }
      responses {
        id
        formSlug
        response
        userId
        createdAt
        updatedAt
      }
    }
  }
`);

export interface GetItemGql {
  Form: {
    id: number;
    slug: string;
    schemaJson: string;
    extraJson?: string;
    UISchema: string;
    status: string;
    isDefaultForm: boolean;
    subtitle?: string;
    title: string;
    description?: string;
    formMessageHtml?: string;
    submitButtonText: string;
    requiredUserId: boolean;
    publishOn?: Date;
    expiresIn?: Date;
    keywords?: string;
    addSiteMap?: boolean;
    createdAt: Date;
    updatedAt: Date;
    formProperties: {
      name: string;
      typeProperty: TypePropertyEnum;
      isRequired: boolean;
      title?: string;
      description?: string;
      showUnfocusedDescription?: boolean;
      enum?: string[]; // only allow in list, radio and multipleChoise
      default?: string;
      isVisible?: boolean;
    }[];
    sendResponsesTo: {
      email: string;
    }[];
    responses: {
      id: number;
      formSlug: string;
      response: string;
      userId?: string;
      createdAt: Date;
      updatedAt: Date;
    }[];
  };
}
