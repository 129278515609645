import { Stack } from "@mui/material";
import { Group } from "./styles";
import useInvoices from "./hooks/useInvoices";
import { formatDate } from "./utils";
import { formatPrice } from "utils/formatPrice";
import MDButton from "components/MDButton";
import { OrderStatusType } from "./queries/getAll";
import { userPermissions } from "hooks/Permissions";
import { Orders } from ".";

function Invoices({ orderId, status }: { orderId: string; status: OrderStatusType }) {
  const { update } = userPermissions({ updatePermision: Orders.Permissions.update });
  const { invoices, confirmDelivery, loadingActions } = useInvoices(orderId);

  if (!invoices?.length) return null;

  return (
    <Stack
      sx={{
        background: "white",
        borderRadius: "6px",
      }}
    >
      <Group>
        <h3>Nota fiscal ({invoices?.length})</h3>
        <Stack gap={"6px"}>
          {invoices.map((invoice, i) => (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              key={i}
            >
              <Stack>
                <span>N.º da nota fiscal: {invoice.invoiceNumber}</span>
                <span>Data de emissão: {formatDate(invoice.issuanceDate)}</span>
                <span>Valor: {formatPrice(invoice.invoiceValue / 100)}</span>
                <span>Entregue: {invoice?.courierStatus?.finished ? "Sim" : "Não"}</span>
              </Stack>
              {status !== "ready-for-handling" &&
                status !== "canceled" &&
                !invoice?.courierStatus?.finished &&
                update && (
                  <MDButton
                    sx={{
                      width: "fit-content",
                      color: "rgb(41, 83, 178) !important",
                      background: "rgb(221, 229, 247)  !important",
                    }}
                    onClick={() => confirmDelivery(invoice.invoiceNumber)}
                    disabled={loadingActions}
                  >
                    Confirmar Entrega
                  </MDButton>
                )}
            </Stack>
          ))}
        </Stack>
      </Group>
    </Stack>
  );
}

export default Invoices;
