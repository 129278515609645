import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiRequest, SchemaData, UseUpdateProps } from "./types";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const data = state?.data;

      const [databaseName, id] = String(queryParam).split(/[\/]|%2F/g);
      const response = await ApiRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            ...data,
            id: Number(id),
            databaseName,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
