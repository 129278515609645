import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import usePartners from "./usePartners";
import schemaJsonCreate from "../schema/schemaJson";
import schemaJsonEdit from "../schema/schemaJsonEdit";
import UISchemaCreate from "../schema/UISchema.json";
import UISchemaEdit from "../schema/UISchemaEdit.json";

export default function useTemplate(create?: boolean) {
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const { partners } = usePartners();

  useEffect(() => {
    const schemaJson = create ? schemaJsonCreate : schemaJsonEdit;
    const UISchema = create ? UISchemaCreate : UISchemaEdit;
    if (!partners?.length) {
      setTemplate({
        schemaJson,
        UISchema,
      });
      return;
    }

    const partnerVisibleName = {
      type: "string",
      oneOf: [
        ...partners.map(({ visibleName }) => ({
          title: visibleName,
          const: visibleName,
        })),
      ],
    };
    setTemplate({
      schemaJson: {
        ...schemaJson,
        properties: { ...schemaJson.properties, partnerVisibleName },
      },
      UISchema,
    });
  }, [partners]);

  return {
    template,
  };
}
