import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Courses(
    $page: Int
    $perPage: Int
    $search: String
    $active: Boolean
    $hasECommerceProductId: Boolean
    $hasECommerceControlSkuId: Boolean
  ) {
    Courses(
      page: $page
      perPage: $perPage
      search: $search
      active: $active
      hasECommerceProductId: $hasECommerceProductId
      hasECommerceControlSKUId: $hasECommerceControlSkuId
      includeInactivePrices: true
    ) {
      items {
        id
        name
        slug
        investmentInfoId
        eCommerceControlSKUId
        eCommerceProductId
        category {
          name
          templateInvestmentName
          department {
            name
          }
        }
        prices {
          id
          active
          value
          valueWithoutDiscout
          installments
          eCommerceSkuId
          valueOtherInstallments
          planAdmissionWay {
            id
            title
            admissionWay {
              id
              name
            }
          }
        }
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface Price {
  id: number;
  active: boolean;
  value: number;
  valueWithoutDiscout?: number;
  valueOtherInstallments?: number;
  installments: number;
  eCommerceSkuId?: number;
  planAdmissionWay: {
    id: number;
    title: string;
    admissionWay: {
      id: number;
      name: string;
    };
  };
}
/** Type each item in response data*/
export interface GetAllItem {
  id: number;
  name: string;
  slug: string;
  investmentInfoId?: number;
  eCommerceControlSKUId?: number;
  eCommerceProductId?: number;
  prices: Price[];
}

export const responseGetAllKey = "Courses";

/** Type response data */
export interface GetAllGql {
  Courses: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
