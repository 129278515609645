import { useMemo, useState } from "react";
import { DataRequest, useDataTableProps } from "./types";

export default function useDataTable<T>({
  initialVariables,
  query,
  ApiSetData,
}: useDataTableProps) {
  const [variables, setVariables] = useState<any>();
  const [{ error, loading, data }, setData] = useState<DataRequest>({
    loading: true,
  });

  const handleChangePage = (page: number) => {
    setVariables((prev: any) => ({ ...prev, page }));
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = () => {
    setVariables((prev: any) => ({
      ...prev,
      page: searchValue !== prev.search ? 1 : prev.page,
      search: searchValue,
    }));
  };

  const handleChangeFilter = ({
    active,
    status,
  }: {
    active?: boolean | null;
    status?: "ACTIVE" | "INACTIVE" | "DRAFT" | null;
  }) => {
    setVariables((prev: any) => ({
      ...prev,
      page: active !== prev.active || status !== prev.status ? 1 : prev.page,
      active,
      status,
    }));
  };

  useMemo(() => setVariables(initialVariables), [initialVariables]);

  useMemo(() => {
    if (variables) {
      setData((old) => ({ ...old, loading: true }));
      setSearchValue(variables?.search || "");

      ApiSetData({
        query,
        variables,
        setData,
      });
    }
  }, [variables]);

  return {
    handleChangePage,
    handleChangeFilter,
    error,
    loading,
    data,
    handleSearch,
    searchValue,
    setSearchValue,
    variables,
  };
}
