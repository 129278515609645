import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateRole($name: String!, $description: String!, $permissionsIds: [Int!]) {
    createRole(name: $name, description: $description, permissionsIds: $permissionsIds) {
      id
    }
  }
`);

export interface CreateGql {
  createRole: {
    id: number;
  };
}
