import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import MDTypography from "components/MDTypography";
import { formatPrice } from "utils/formatPrice";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { QUERY_BEST_SELLERS, QueryBestSellersGql } from "./queries/bestSellers";
import Container from "./Container";
import { useData } from "./context/DataProvider";
import { getColor, getPercentageText } from "./utils";
import { Name, Price } from "./styles";

const options = [
  {
    label: "Produtos com maior receita",
    value: "REVENUE",
  },
  {
    label: "Produtos com mais unidades vendidas",
    value: "UNITS",
  },
];

function BestSellers() {
  const [variant, setVariant] = useState(options[0]);
  const [data, setData] = useState<QueryBestSellersGql["BestSellers"]>();
  const [loading, setLoading] = useState(false);
  const { dateSelected } = useData();

  useEffect(() => {
    setLoading(true);
    ApiFinancialRequest<QueryBestSellersGql>({
      query: QUERY_BEST_SELLERS,
      variables: {
        compareStartDate: dateSelected.from.value.toISOString(),
        startDate: dateSelected.from.value.toISOString(),
        compareEndDate: dateSelected.to.value.toISOString(),
        endDate: dateSelected.to.value.toISOString(),
        sort: variant.value,
      },
    })
      .then(({ data }) => setData(data.data.BestSellers))
      .catch(() => {
        setData([]);
      })
      .finally(() => setLoading(false));
  }, [dateSelected, variant]);

  const handleVariant = (evt: SelectChangeEvent<string>) => {
    const selected = options.find(({ label }) => label === evt.target.value);
    if (!selected) return;

    setVariant(selected);
  };

  return (
    <Container
      title="Mais vendidos"
      gap="20px"
      sx={{
        height: "fit-content",
        minWidth: "400px",
      }}
    >
      {!data || loading ? (
        <Stack alignItems={"center"}>
          <CircularProgress sx={{ color: "#344767", width: "30px", height: "30px" }} />
        </Stack>
      ) : (
        <Stack gap={2}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="variant">Filtro</InputLabel>
            <Select
              labelId="variant"
              id="variant"
              value={variant.label}
              label={variant.label}
              onChange={handleVariant}
            >
              {options.map((item) => (
                <MenuItem key={item.label} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack gap="5px" maxHeight="500px" overflow="auto" paddingRight="10px">
            {data?.map((product, index) => (
              <Stack key={index} direction="row" gap="20px" alignItems="center">
                <Box
                  component={"img"}
                  src={product.imageSrc}
                  alt={product.productName}
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: 2,
                    objectFit: "cover",
                  }}
                />
                <Stack width={"100%"}>
                  <Name>{product.productName}</Name>
                  <Stack
                    direction="row"
                    gap="10px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Price>
                      {variant.value === "UNITS"
                        ? product.unitValue
                        : formatPrice(product.revenueValue)}
                    </Price>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color={getColor({
                        comparedData:
                          variant.value === "UNITS"
                            ? product.unitComparedValue
                            : product.revenueComparedValue,
                        referenceData:
                          variant.value === "UNITS" ? product.unitValue : product.revenueValue,
                      })}
                      sx={{ fontSize: "0.9rem" }}
                    >
                      {getPercentageText({
                        comparedData:
                          variant.value === "UNITS"
                            ? product.unitComparedValue
                            : product.revenueComparedValue,
                        referenceData:
                          variant.value === "UNITS" ? product.unitValue : product.revenueValue,
                      })}
                    </MDTypography>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </Container>
  );
}

export default BestSellers;
