import { useEffect, useState } from "react";
import { HookData, UseDataProps } from "./types";

export default function useData<ItemGql>({ ids, requestItem, formatItem }: UseDataProps<ItemGql>) {
  const [selectedItems, setSelectedItems] = useState<HookData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!ids?.length || selectedItems?.length) return;
    setLoading(true);
    const request = ids.map((id: number | string) => requestItem(id));

    Promise.all(request)
      .then((res) => {
        const resData = res.map(formatItem).filter((item) => item);
        setSelectedItems(resData);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [ids]);

  return { selectedItems, setSelectedItems, loading };
}
