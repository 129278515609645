import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { Price } from "../queries/getAll";
import ActiveOrInactiveCell from "components/CRPComponents/Tables/components/ActiveOrInactiveCell";

export default function TablePrice({
  prices,
  PricesPath = "/ecommerce/investimento",
  AdmissionWaysPath = "/ecommerce/formas-de-ingresso",
  PlanAdmissionWaysPath = "/ecommerce/planos",
}: {
  prices?: Price[];
  PricesPath?: string;
  AdmissionWaysPath?: string;
  PlanAdmissionWaysPath?: string;
}) {
  if (!prices || !prices?.length) return;
  return (
    <>
      <TableRow>
        <DataTableHeadCell sorted={false} align="right">
          Id Preço
        </DataTableHeadCell>
        <DataTableHeadCell sorted={false} align="right">
          Forma de Ingresso
        </DataTableHeadCell>
        <DataTableHeadCell sorted={false} align="right">
          Plano
        </DataTableHeadCell>
        <DataTableHeadCell sorted={false} align="right">
          Parcela
        </DataTableHeadCell>
        <DataTableHeadCell sorted={false} align="right">
          Valor
        </DataTableHeadCell>
        <DataTableHeadCell sorted={false} align="right">
          Status
        </DataTableHeadCell>
      </TableRow>

      {prices.map((price: Price) => (
        <TableRow id={`price-${price.id}`}>
          <TableCell align="right">
            <Link children={String(price.id)} to={`${PricesPath}/${price.id}`} />
          </TableCell>
          <TableCell align="right">
            <Link to={`${AdmissionWaysPath}/${price.planAdmissionWay.admissionWay.id}`}>
              {price.planAdmissionWay.admissionWay.name}
            </Link>
          </TableCell>
          <TableCell align="right">
            <Link to={`${PlanAdmissionWaysPath}/${price.planAdmissionWay.id}`}>
              {price.planAdmissionWay.title}
            </Link>
          </TableCell>
          <TableCell align="right">
            {price.installments > 1 ? `${price.installments}x` : "A vista"}
          </TableCell>
          <TableCell align="right">
            <p>
              {price?.valueWithoutDiscout && <s>{`R$ ${price.valueWithoutDiscout}`}</s>}
              {"  "}
              <strong>{`R$ ${price.value}`}</strong>
              {!!price?.valueOtherInstallments &&
                price.installments > 1 &&
                ` + ${price.installments - 1}x de R$ ${price?.valueOtherInstallments}`}
            </p>
          </TableCell>
          <TableCell align="right">
            <ActiveOrInactiveCell value={price.active} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
