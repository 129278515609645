import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { Colligate } from "../types";

export const CREATE_QUERY = print(gql`
  mutation Mutation($data: CreateCategoryConversionInput!) {
    createCategoryConversion(data: $data) {
      id
      colligate {
        id
        databaseName
        description
        active
        partnerVisibleName
        createdAt
        updatedAt
      }
      idColligate
      databaseName
      idCourseType
      idBranch
      categorySlug
      createdAt
      updatedAt
    }
  }
`);
export interface CreateGql {
  createCategoryConversion: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;
    idCourseType: number;
    idBranch: number;
    categorySlug: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
