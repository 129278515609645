export const MUTATION_CREATE_FAQ = `mutation Mutation($data: CreateFaqInput!) {
  createFaq(data: $data) {
    id
    question
    answer
    sort
    student
    status
    createdAt
    updatedAt
  }
}`;

export type ResponseCreateFaq = {
  createScholarShip: {
    id: number;
    active: boolean;
  };
};
