import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query GetOne($id: String!) {
    Inventory(skuId: $id) {
      skuId
      availableQuantity
      isUnlimited
      totalQuantity
      reservedQuantity
      warehouseId
      dockId
      salesChannel
      keepSellingAfterExpiration
      leadTime
    }
  }
`);

export interface ItemGql {
  skuId: string;
  warehouseId: string;
  dockId: string;
  totalQuantity: number;
  reservedQuantity: number;
  availableQuantity: number;
  isUnlimited: boolean;
  keepSellingAfterExpiration: boolean;
  leadTime?: string | null;
}

export const responseGetItemKey = "Inventory";
export interface GetItemGql {
  Inventory: ItemGql;
}
