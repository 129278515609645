import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query FindManyUsers($search: String) {
    findManyUsers(search: $search) {
      userName
      email
      id
    }
  }
`);

export interface GetAllItem {
  id: string;
  userName: string;
  email: string;
}

export interface GetAllGql {
  findManyUsers: GetAllItem[];
}
