import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateCalendarModality($data: CreateCalendarModalityInput!) {
    createCalendarModality(data: $data) {
      id
    }
  }
`);

export interface CreateGql {
  createCalendarModality: {
    id: number;
  };
}
