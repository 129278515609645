import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query Coupons($archive: Boolean) {
    Coupons(archive: $archive) {
      couponCode
      utmCampaign
      utmSource
      isArchived
    }
  }
`);

export interface GetAllItem {
  couponCode: string;
  utmCampaign: string;
  utmSource: string;
  isArchived: boolean;
}

export interface GetAllGql {
  Coupons: GetAllItem[];
}
