import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Colligate($id: Int!, $databaseName: String!) {
    Colligate(id: $id, databaseName: $databaseName) {
      id
      databaseName
      description
      active
      partnerVisibleName
    }
  }
`);

export const responseGetItemKey = "Colligate";

export interface SchemaDataGetItem {
  id: number;
  databaseName: string;
  description?: string;
  partnerVisibleName: string;
  active: boolean;
}

export interface GetItemGql {
  [responseGetItemKey]: SchemaDataGetItem;
}
