import { useCallback, useState } from "react";
import DataTableControled from "examples/Tables/DataTableControled";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import ButtonCell from "components/CRPComponents/Tables/components/ButtonCell";
import ReadExcelButton from "./ReadExcelButton";
import MDButton from "components/MDButton";
import MDProgress from "components/MDProgress";
import { getErrorsList } from "./util";
import AlertDialog from "./AlertDialog";
import MDBox from "components/MDBox";

interface DataType {
  [key: string]: string;
}

interface ReadCSVComponentProps {
  sendToAPi: (data: DataType) => Promise<{ id: number }>;
  pathToSuccessSendToAPi?: string;
  editEachDataRead?: (data: DataType) => DataType;
  editAllDataRead?: (data: DataType[]) => DataType[];
}
const FAIL = "Falhou";
const SUCCESS = "Sucesso";

export function ReadCSVComponent({
  sendToAPi,
  pathToSuccessSendToAPi = "",
  editEachDataRead = (input) => input,
  editAllDataRead = (input) => input,
}: ReadCSVComponentProps) {
  const [columns, setColums] = useState<
    { Header: string; accessor: string; Cell: (prop: any) => JSX.Element }[]
  >([]);
  // Data to table
  const [excelData, setExcelData] = useState<DataType[]>([]);
  const [loadingTable, setLoadingTable] = useState(false);
  // infos about error or success
  const [arrayInfo, setArrayInfo] = useState<{ errors?: string[]; link?: string }[]>([]);
  const [progessProcess, setProgessProcess] = useState(0);
  const [show, setShow] = useState(false);
  const [dialogInfo, setDialogInfo] = useState<{
    index?: number;
    title?: string;
    errors?: string[];
    link?: string;
  }>({});

  const handleOpenDialog = useCallback(
    (index: number, title?: string) => {
      // console.log({ handleOpenDialog: index, title, arrayInfo: arrayInfo?.[index] });
      setDialogInfo(
        !arrayInfo?.[index]
          ? {}
          : {
              index,
              errors: arrayInfo[index]?.errors,
              link: arrayInfo[index]?.link,
              title,
            }
      );
      setShow(true);
    },
    [arrayInfo, setDialogInfo]
  );
  const handleCloseDialog = () => {
    setShow(false);
    setDialogInfo({});
  };

  const convertToObject = ([header, ...rows]: string[][]): Record<string, string>[] => {
    const allData = rows.map((values): DataType => {
      const obj: DataType = {};
      header.forEach((key, index) => {
        obj[key] = values?.[index] ?? "";
      });

      return editEachDataRead(obj);
    });

    return editAllDataRead(allData);
  };
  const handleFileUpload = (dataExcel: string[][]) => {
    if (!!excelData?.length) {
      setExcelData([]);
      setColums([]);
    }
    const [header, ...rest] = dataExcel;
    setExcelData(convertToObject([header.concat("statusSync"), ...rest])); //convertToObject calls functions to edit object
    setColums(
      [
        {
          Header: "Em Sync",
          accessor: "statusSync",
          Cell: ({ value, row }: any) =>
            !value ? (
              <DefaultCell value={""} />
            ) : (
              <ButtonCell
                value={value}
                color={value === FAIL ? "error" : "info"}
                onClick={() => handleOpenDialog(row?.index, `Linha ${row?.index + 2} - ${value}`)}
              />
            ),
        },
      ].concat(
        header.map((value, i) => ({
          Header: value,
          accessor: value,
          Cell: ({ value }: any) => <DefaultCell value={value} />,
        }))
      )
    );
  };

  const editStatusSyncRow = (index: number, statusSync: string) => {
    setExcelData((prevData) => {
      if (index >= 0 && index < prevData.length) {
        const updatedData = [...prevData];
        updatedData[index].statusSync = statusSync;
        return updatedData;
      }
      return prevData;
    });
  };

  const handleProcess = async () => {
    const total = excelData?.length || 1;
    for (const [idx, data] of excelData.entries()) {
      editStatusSyncRow(idx, "loading");

      try {
        const response = await sendToAPi(data);
        setArrayInfo((prev) => {
          prev[idx] = { link: `${pathToSuccessSendToAPi}/${response.id}` };
          return prev;
        });
        editStatusSyncRow(idx, SUCCESS);
        // console.log({ success: response });
      } catch (e: any) {
        const errorMessages: string[] = [];

        errorMessages.push(...getErrorsList(e));
        // console.log({ fail: e, errorMessages });

        setArrayInfo((prev) => {
          prev[idx] = { errors: errorMessages };
          return prev;
        });
        editStatusSyncRow(idx, FAIL);
      }

      setProgessProcess((100 * (idx + 1)) / total);
    }
  };

  const clearData = () => {
    setColums([]);
    setExcelData([]);
    setArrayInfo([]);
    setShow(false);
    setDialogInfo({});
    setProgessProcess(0);
  };

  const handleHiddenAction = () => {
    const colInfo = arrayInfo?.map((value, i) => ({
      linha: i + 2,
      valor: excelData?.[i]?.[columns?.[1]?.accessor],
      erros: value?.errors || [],
    }));
    // console.log({
    //   arrayInfo: colInfo,
    //   errors: colInfo.filter((value) => !!value?.erros?.length),
    //   excelData,
    // });
  };

  return (
    <div>
      {!excelData?.length ? (
        <ReadExcelButton onSuccessRead={handleFileUpload} setLoading={setLoadingTable} />
      ) : (
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "16px",
            alignItems: "start",
            margin: "16px 0",
          }}
        >
          <MDButton onClick={clearData} color="secondary">
            Limpar dados
          </MDButton>
          <MDButton onClick={handleProcess} color="info">
            Sincronizar
          </MDButton>
        </MDBox>
      )}
      {progessProcess > 0 && (
        <MDProgress
          variant="contained"
          color="info"
          value={Math.floor(progessProcess)}
          label
          onClick={handleHiddenAction}
        />
      )}
      <h2 onClick={handleHiddenAction}>Data:</h2>
      <DataTableControled
        table={{
          columns: columns,
          rows: excelData,
        }}
        loading={loadingTable}
        showTotalEntries
      />
      <AlertDialog
        open={show}
        onClose={handleCloseDialog}
        title={dialogInfo?.title}
        errors={dialogInfo?.errors}
        link={dialogInfo?.link}
      />
    </div>
  );
}

export default ReadCSVComponent;
