import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateTeachingArea($data: UpdateTeachingAreaInput!) {
    updateTeachingArea(data: $data) {
      active
      description
      id
      name
    }
  }
`);

export interface UpdateGql {
  UpdateTeachingArea: {
    id: number;
  };
}
