import { CategoryFaqGql } from "data/queries/ApiCMS/CategoriesFaq/QueryCategoryFaq";
import useCreate from "./hooks/useCreate";
import { schemaJson } from "./schema/SchemaJson";
import { UISchemaJson } from "./schema/UISchema";
import { GenericMethods } from "../GenericMethods";
import { CategoryFaqMutationCreateGql } from "data/queries/ApiCMS/CategoriesFaq/MutationCreate";
import { Sites } from "data/utils/globals";

function Create() {
  return (
    <GenericMethods.Create<CategoryFaqGql["CategoryFaq"], CategoryFaqMutationCreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      useCreate={useCreate}
      template={{
        schemaJson: JSON.stringify(schemaJson),
        UISchema: UISchemaJson,
      }}
    />
  );
}

export default Create;
