import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Banner = {
  List: List,
  Create: Create,
  Update: Update,
  Permissions: {
    list: "list:Banner",
    create: "create:Banner",
    read: "read:Banner",
    update: "update:Banner",
    delete: "delete:Banner",
  },
};
