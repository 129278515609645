import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const FacultyMembers = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:FacultyMember",
    read: "read:FacultyMember",
    list: "list:FacultyMember",
    update: "update:FacultyMember",
    delete: "delete:FacultyMember",
  },
};
