import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation Update($data: UpdatePriceInput!) {
    updatePrice(data: $data) {
      id
      active
      activateIfPossible
      value
      valueWithoutDiscout
      installments
      eCommerceSkuId
      eCommerceLastUpdate
      courseId
      commercialConditionId
      valueOtherInstallments
      urlInscription
    }
  }
`);

export const responseUpdateKey = "updatePrice";
export interface UpdateGql {
  updatePrice: {
    id: number;
    active: boolean;
    activateIfPossible: boolean;
    value: number;
    valueWithoutDiscout?: number;
    installments: number;
    eCommerceSkuId?: number;
    eCommerceLastUpdate?: Date;
    courseId: number;
    commercialConditionId?: number;
    valueOtherInstallments?: number;
    urlInscription?: string;
  };
}
