import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateAddress($data: CreateAddressInput!) {
    createAddress(data: $data) {
      id
      addressName
      city
      complement
      country
      postalCode
      reference
      state
      street
      neighborhood
      addressNumber
    }
  }
`);

export interface CreateGql {
  createAddress: {
    id: number;
    addressName: string;
    city: string;
    complement: string;
    country: string;
    postalCode: string;
    reference: string;
    state: string;
    street: string;
    neighborhood: string;
    addressNumber: string;
  };
}
