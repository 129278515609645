import { SelectOptionProp, Handler } from "../types";
import CourseGroupHandler from "./Methodology";
import EducationalUnitHandler from "./EducationalUnit";
import ModalityHandler from "./Modality";
import FacultyMemberHandler from "./FacultyMember";
import CommercialConditionHandler from "./CommercialCondition";

export const queryTypes = [
  "CourseGroup",
  "EducationalUnit",
  "Modality",
  "FacultyMember",
  "CommercialCondition",
];

export const getType = (queryType: string): Handler => {
  switch (queryType) {
    case "CourseGroup":
      return CourseGroupHandler;
    case "EducationalUnit":
      return EducationalUnitHandler;
    case "Modality":
      return ModalityHandler;
    case "FacultyMember":
      return FacultyMemberHandler;
    case "CommercialCondition":
      return CommercialConditionHandler;
    default:
      throw new Error(`Unsupported queryType: ${queryType}`);
  }
};

const getIdItem = async (handler: Handler, id?: number): Promise<SelectOptionProp | null> => {
  if (!id || !handler.queryId) return null;

  return handler
    .api({
      query: handler.queryId,
      variables: { id },
    })
    .then(({ data }) => {
      if (!handler?.formaterId) throw "return default";
      return handler?.formaterId(data.data);
    })
    .catch((e) => {
      console.log(e);
      return {
        id,
        label: `id ${id}`,
      };
    });
};

const getListItens = async (handler: Handler, search?: string): Promise<SelectOptionProp[]> => {
  return handler
    .api({
      query: handler.query,
      variables: { search: !search ? null : search },
    })
    .then(async ({ data }) => {
      return handler.formater(data.data);
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

export const getItems = async ({
  queryType,
  search,
  id,
  option0,
}: {
  queryType: string;
  search?: string;
  id?: number | null;
  option0?: string;
}): Promise<SelectOptionProp[]> => {
  const handler = getType(queryType);

  if (!handler) throw new Error(`Unsupported queryType: ${queryType}`);

  const listItens = await getListItens(handler, search);
  const selectedItem = listItens.find(({ id: idList }) => id === idList)
    ? null
    : await getIdItem(handler, id);

  const option0Prop: SelectOptionProp = !option0
    ? null
    : {
        id: 0,
        label: option0,
        active: true,
      };

  return [option0Prop, selectedItem].filter((op) => !!op?.label).concat(...listItens);
};
