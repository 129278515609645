export const Sites = {
  UNIFTEC: {
    id: 1,
    path: "/uniftec",
  },
  FTEC: {
    id: 2,
    path: "/ftec",
  },
  ECOINOVE: {
    id: 3,
    path: "/ecoinove",
  },
  IBGEN: {
    id: 4,
    path: "/ibgen",
  },
  POLYUNI: {
    id: 5,
    path: "/polyuni",
  },
  RED_HOUSE: {
    id: 6,
    path: "/red-house",
  },
};
