export type Course = {
  id: number;
  name: string;
  status: string;
  slug: string;
  keywords?: string;
  courseLoadHours: number;
  title?: string;
  description?: string;
  imageUrl?: string;
  modality: { name: string };
  educationalUnit: { visibleName: string; state: string };
  courseGroup: {
    descriptionShort: string;
    imageUrl: string;
    partner: {
      id: number;
      name: string;
      visibleName: string;
      description?: string;
      active: boolean;
    };
    category: {
      id: number;
      name: string;
      description: string;
      active: boolean;
      slug: string;
      keywords?: string;
      showPartinerFilter: boolean;
      templateSchemaName?: string;
      parentId?: number;
    };
  };
};

export type UpsertCourseInput = {
  id: number;
  name: string;
  description?: string;
  slug: string;
  keywords?: string;
  active: boolean;

  category: CreateCategoryInput;
  partnerBrand: CreatePartnerBrandInput;

  imageUrl?: string;
  modalityName?: string;
  educationalUnitVisibleName?: string;
  state?: string;
  title?: string;
  courseLoadHours?: number;
  investmentInfoId?: number;
  urlInscription?: string;
  moodleId?: string;
  eCommerceProductId?: number;

  eCommerceControlSKUId?: number;
  eCommerceProductLastUpdate?: Date;
  eCommerceControlSKULastUpdate?: Date;
};

export type CreateCategoryInput = {
  id: number; // Same Id as Course API
  name: string;
  parentId?: number;
  slug: string;
  description: string;
  keywords?: string;
  active: boolean;
  showPartinerFilter: boolean;
  templateInvestmentName?: string;

  eCommerceParentId?: number;
  eCommerceId?: number;
  eCommerceLastUpdate?: Date;
};

export type CreatePartnerBrandInput = {
  id: number;
  name: string;
  visibleName: string;
  description?: string;
  active: boolean;

  eCommerceId?: number;
  eCommerceLastUpdate?: Date;
};

export function convertCourseInUpsertCourseInput(
  course: Course,
  extra?: { urlInscription?: string; moodleId?: string; eCommerceProductId?: number }
): UpsertCourseInput {
  return {
    ...(extra || {}),
    id: course.id, //: number;
    name: course.name, //: string;
    slug: course.slug, //: string;
    keywords: course.keywords, //?: string;
    description: course?.description || course.courseGroup.descriptionShort, //?: string;
    active: course.status === "ACTIVE", //: boolean;

    category: {
      id: course.courseGroup.category.id,
      name: course.courseGroup.category.name,
      parentId: course.courseGroup.category?.parentId,
      slug: course.courseGroup.category.slug,
      description: course.courseGroup.category.description,
      keywords: course.courseGroup.category?.keywords,
      active: course.courseGroup.category.active,
      showPartinerFilter: course.courseGroup.category.showPartinerFilter,
      templateInvestmentName: course.courseGroup.category?.templateSchemaName,
    }, //: CreateCategoryInput;
    partnerBrand: {
      id: course.courseGroup.partner.id,
      visibleName: course.courseGroup.partner.visibleName,
      name: course.courseGroup.partner.name,
      description: course.courseGroup.partner?.description,
      active: course.courseGroup.partner.active,
    }, //: CreatePartnerBrandInput;

    imageUrl: course?.imageUrl || course.courseGroup.imageUrl, //?: string;
    modalityName: course.modality.name, //?: string;
    educationalUnitVisibleName: course.educationalUnit.visibleName, //?: string;
    state: course.educationalUnit.state, //?: string;
    title: course?.title, //?: string;
    courseLoadHours: course?.courseLoadHours, //?: number;
  };
}
