import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/user.png";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import { User } from "layouts/Profile/User";
import { Route } from "App";

export const userRoutes: Route[] = [
  {
    type: "collapse",
    name: "Usuario",
    key: "usuario-nome",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "Meu perfil",
        key: "meu-perfil",
        route: "/perfil/visao-geral",
        component: <ProfileOverview />,
      },
      {
        name: "Sair",
        key: "sair",
        route: "/auth/logout",
        component: <User.Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-userRoutes" },
];
