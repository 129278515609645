import { useNavigate } from "react-router-dom";
import { GenerictMethodsCreateProps } from "layouts/aplicacoes/GenericMethods/types";

export function getCourseStepper(
  activeStep: number,
  courseId?: number
): GenerictMethodsCreateProps<unknown, unknown>["stepper"] {
  const navigate = useNavigate();
  return {
    activeStep,
    steps: [
      {
        label: !courseId ? "Criar Curso" : "Editar Curso",
        onClick:
          !courseId || activeStep === 0 ? null : () => navigate(`/ecommerce/cursos/${courseId}`),
      },
      {
        label: "Editar Conteúdo do Curso",
        onClick:
          !courseId || activeStep === 1
            ? null
            : () => navigate(`/ecommerce/cursos/${courseId}/info`),
      },
      {
        label: "Editar Investimentos",
        onClick: !courseId
          ? null
          : () => {
              if (activeStep !== 2) {
                return navigate(`/ecommerce/cursos/${courseId}/investimentos`);
              }
              navigate(0);
            },
      },
    ],
  };
}
