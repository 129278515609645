import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback } from "react";
import { SchemaData } from "./types";
import { CreateOrUpdateGql, CREATE_OR_UPDATE_QUERY } from "../queries/createOrUpdate";
import { useNavigate } from "react-router-dom";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

export default function useCreate() {
  const navigate = useNavigate();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const { isArchived, ...data } = state?.data;

    const response = await ApiFinancialRequest<CreateOrUpdateGql>({
      query: CREATE_OR_UPDATE_QUERY,
      variables: {
        data,
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data || !response?.data?.data?.createOrUpdateCoupon.couponCode) {
      throw new Error("Ops. Sem resposta!");
    }

    navigate(
      `/ecommerce/cupoms/${response.data.data.createOrUpdateCoupon.couponCode}?isArchived=${response.data.data.createOrUpdateCoupon.isArchived}`
    );

    return response;
  }, []);

  return {
    handleSave,
  };
}
