import { styled } from "@mui/material/styles";

export const Title = styled("span")`
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.625;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #344767;
  font-weight: 700;
  letter-spacing: 0.0075em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #344767;
  font-weight: 700;
`;

export const Price = styled("span")`
  font-size: 0.9rem;
  line-height: 1.375;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #344767;
  font-weight: 700;
  letter-spacing: 0em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #344767;
  font-weight: 700;
`;

export const Name = styled("span")`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #7b809a;
  font-weight: 600;
`;
