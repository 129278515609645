import { useState, useEffect } from "react";
import { GET_ALL_QUERY, GetAllGql } from "../queries/getAll";
import { ApiCourseRequest } from "data/queries/ApiCourse";

export const UseGetAllCourses = () => {
  const [responses, setResponses] = useState<any>(null);
  const [allFilds, setAllFilds] = useState<any>(null);

  const getAllUniqueKeys = (objects: any) => {
    const allKeys = objects.reduce((acc: any, obj: any) => {
      return acc.concat(Object.keys(obj));
    }, []);
    return Array.from(new Set(allKeys));
  };

  useEffect(() => {
    ApiCourseRequest<GetAllGql>({
      query: GET_ALL_QUERY,
      variables: {
        perPage: 1000,
      },
    })
      .then(({ data }) => {
        const coursesItens = data.data.Courses.items;

        const transformedArray = coursesItens.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            active: item.active,
            courseGroupName: item.courseGroup.name,
            courseGroupCategoryName: item.courseGroup.category.name,
            modalityName: item.modality.name,
          };
        });
        const getAllUniqueFilds = getAllUniqueKeys(transformedArray);
        setResponses(transformedArray);
        setAllFilds(getAllUniqueFilds);
      })
      .catch((e: any) => {
        console.log("Email ou senha incorretos.");
        setResponses(null); // Opcional: definir como null em caso de erro
      });
  }, []);

  return {
    responses,
    allFilds,
  };
};
