import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const EducationalUnit = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:EducationalUnit",
    read: "read:EducationalUnit",
    list: "list:EducationalUnit",
    update: "update:EducationalUnit",
    delete: "delete:EducationalUnit",
  },
};
