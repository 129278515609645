import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query GetOne($id: Int!) {
    InvestmentInfo(id: $id) {
      id
      title
      subtitle
      description
      observation
      orientationContent
      orientationLabelButton
    }
  }
`);

export const responseGetItemKey = "InvestmentInfo";
export interface GetItemGql {
  InvestmentInfo: {
    id: number;
    title: string;
    subtitle?: string;
    description?: string;
    observation?: string;
    orientationContent?: string;
    orientationLabelButton?: string;
  };
}
