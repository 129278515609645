import { ReactNode } from "react";
import { JsonForms, JsonFormsInitStateProps, JsonFormsReactProps } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MDBox, MDBoxProps } from "components/MDBox";
import rendererWysiwyg from "./renderers/rendererWysiwyg";
import rendererWysiwygTester from "./renderers/rendererWysiwyg/tester";
import GalleryMediaId from "./renderers/GalleryMediaId";
import GalleryMidiaIdTest from "./renderers/GalleryMediaId/tester";
import GalleryMediaString from "./renderers/GalleryMidiaString";
import GalleryMediaStringTest from "./renderers/GalleryMidiaString/tester";
import CourseSelectMultipleTester from "./renderers/CourseSelectMultiple/tester";
import CourseSelectMultiple from "./renderers/CourseSelectMultiple";
import BlogPostsSelectMultiple from "./renderers/BlogPostsSelectMultiple";
import BlogPostsSelectMultipleTester from "./renderers/BlogPostsSelectMultiple/tester";
import BlogTagsSelectMultiple from "./renderers/BlogTagsSelectMultiple";
import BlogTagsSelectMultipleTester from "./renderers/BlogTagsSelectMultiple/tester";
import CustomColorPicker from "./renderers/CustomColorPicker";
import CustomColorPickerTester from "./renderers/CustomColorPicker/tester";
import EventsSelectMultiple from "./renderers/EventsSelectMultiple";
import EventsSelectMultipleTester from "./renderers/EventsSelectMultiple/tester";
import EcommerceTagsSelectMultiple from "./renderers/EcommerceTagsSelectMultiple";
import EcommerceTagsSelectMultipleTester from "./renderers/EcommerceTagsSelectMultiple/tester";
import SubjectsSelectMultiple from "./renderers/SubjectsSelectMultiple";
import SubjectsSelectMultipleTester from "./renderers/SubjectsSelectMultiple/tester";
import FacultyMemberSelectMultipleTester from "./renderers/FacultyMemberMultipleSelect/tester";
import FacultyMemberSelectMultiple from "./renderers/FacultyMemberMultipleSelect";
import SelectPermissions from "./renderers/SelectPermissions";
import SelectPermissionsTester from "./renderers/SelectPermissions/tester";
import SearchMultiple from "./renderers/SearchMultiple";
import SearchMultipleTester from "./renderers/SearchMultiple/tester";
import PriceInput from "./renderers/PriceInput";
import PriceInputTester from "./renderers/PriceInput/tester";
import UserCopySelectMultiple from "./renderers/UserCopySelectMultiple";
import UserCopySelectMultipleTester from "./renderers/UserCopySelectMultiple/tester";
import EventsFormType from "./renderers/EventsFormType";
import EventsFormTypeTester from "./renderers/EventsFormType/tester";
import SelectProviderTester from "./renderers/SelectProvider/tester";
import SelectProvider from "./renderers/SelectProvider";
import SelectIdQueryTester from "./renderers/SelectIdQuery/tester";
import SelectIdQuery from "./renderers/SelectIdQuery";

export interface JsonFormProps<T = any>
  extends Omit<
    JsonFormsInitStateProps,
    "schema" | "uischema" | "data" | "renderers" | "cells" | "onChange" | "readonly"
  > {
  data: T;
  onChange?: JsonFormsReactProps["onChange"];
  // Props changes
  schemaJson: string | JsonFormsInitStateProps["schema"];
  UISchema: string | JsonFormsInitStateProps["uischema"];
  loading?: boolean;
  error?: { message: string; [key: string]: any };
  disabled?: boolean;
  children?: ReactNode;
  boxProps?: Omit<MDBoxProps, "children">;
}

export function JsonForm<T = any>({
  schemaJson,
  UISchema,
  data,
  onChange,
  loading,
  error,
  disabled = false,
  children,
  boxProps = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    gap: 3,
    flexDirection: "row",
    mb: 2,
  },
  ...props
}: JsonFormProps<T>): JSX.Element {
  dayjs.locale("pt-br");

  if (error) {
    return <p>Error : {error.message}</p>;
  }

  const renderers = [
    ...materialRenderers,
    //register custom renderers
    { renderer: rendererWysiwyg, tester: rendererWysiwygTester },
    { renderer: GalleryMediaId, tester: GalleryMidiaIdTest },
    { renderer: GalleryMediaString, tester: GalleryMediaStringTest },
    { renderer: CourseSelectMultiple, tester: CourseSelectMultipleTester },
    { renderer: BlogPostsSelectMultiple, tester: BlogPostsSelectMultipleTester },
    { renderer: EcommerceTagsSelectMultiple, tester: EcommerceTagsSelectMultipleTester },
    { renderer: SubjectsSelectMultiple, tester: SubjectsSelectMultipleTester },
    { renderer: FacultyMemberSelectMultiple, tester: FacultyMemberSelectMultipleTester },
    { renderer: BlogTagsSelectMultiple, tester: BlogTagsSelectMultipleTester },
    { renderer: EventsSelectMultiple, tester: EventsSelectMultipleTester },
    { renderer: CustomColorPicker, tester: CustomColorPickerTester },
    { renderer: SelectPermissions, tester: SelectPermissionsTester },
    { renderer: SearchMultiple, tester: SearchMultipleTester },
    { renderer: PriceInput, tester: PriceInputTester },
    { renderer: UserCopySelectMultiple, tester: UserCopySelectMultipleTester },
    { renderer: EventsFormType, tester: EventsFormTypeTester },
    { renderer: SelectProvider, tester: SelectProviderTester },
    { renderer: SelectIdQuery, tester: SelectIdQueryTester },
  ];
  return (
    <MDBox my={3}>
      <MDBox {...boxProps}>{children}</MDBox>
      {!!schemaJson && (
        <JsonForms
          schema={
            typeof schemaJson === "string"
              ? (JSON.parse(schemaJson) as JsonFormsInitStateProps["schema"])
              : schemaJson
          }
          uischema={
            typeof UISchema === "string"
              ? (JSON.parse(UISchema) as JsonFormsInitStateProps["uischema"])
              : UISchema
          }
          data={data}
          renderers={renderers}
          cells={materialCells}
          onChange={!disabled ? onChange : undefined}
          readonly={disabled}
          i18n={{ locale: "pt-BR" }}
          {...props}
        />
      )}
      <Backdrop sx={{ color: "#fff" }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </MDBox>
  );
}
export default JsonForm;

// additionalErrors={[
//   {
//     dataPath: "/column1/menu_item/0/label",
//     message: "new message",
//     keyword: "",
//     schemaPath: "",
//     params: {},
//     // Added to validation errors of propertyNames keyword schema
//     propertyName: "",
//     // Excluded if messages set to false.
//   },
// ]}
