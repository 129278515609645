import React from "react";
import FormCreateBanner from "components/CRPComponents/Forms/FormBanner/FormCreateBanner";
import ContainerForm from "components/CRPComponents/ContainerForm";
import { CreateProps } from "./types";

function Create({ idSite, path, titleBox = "adicionar banner" }: CreateProps) {
  return (
    <ContainerForm
      componenteRender={<FormCreateBanner titleBox={titleBox} idSite={idSite} path={path} />}
    />
  );
}

export default Create;
