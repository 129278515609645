import DataProvider from "./context/DataProvider";
import Stack from "@mui/material/Stack";
import PickDate from "./PickDate";
import { Title } from "./styles";
import BestSellers from "./BestSellers";
import Container from "./Container";
import SalesFunnel from "./SalesFunnel";
import OrdersTedency from "./OrdersTedency";
import ConversionRate from "./ConversionRate";
import General from "./General";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function SalesAnalytics() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Title>Visão geral</Title>
          <PickDate />
        </Stack>
      </Container>

      <General />

      <Stack gap={3} marginBlock="20px">
        <Stack direction={"row"} gap="20px" width={"100%"}>
          <Stack gap="20px" width={"100%"}>
            <OrdersTedency />
            <ConversionRate />
          </Stack>
          <BestSellers />
        </Stack>
        <SalesFunnel />
      </Stack>
    </DashboardLayout>
  );
}

export default () => (
  <DataProvider>
    <SalesAnalytics />
  </DataProvider>
);
