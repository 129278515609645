// Layout
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormPage from "components/CRPComponents/Forms/FormPage";
import { Permissions } from "../permissions";

function PedagogicalPage(): JSX.Element {
  let slug = "pedagogicalproposal";
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!slug ? (
        <h1> Erro slug </h1>
      ) : (
        <FormPage
          pageName={slug}
          actionsPermission={{
            createPermision: Permissions.create,
            deletePermision: Permissions.delete,
            updatePermision: Permissions.update,
          }}
        />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default PedagogicalPage;
