import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const TeachingAreaConversion = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:TeachingAreaConversion",
    read: "read:TeachingAreaConversion",
    list: "list:TeachingAreaConversion",
    edit: "update:TeachingAreaConversion",
    delete: "delete:TeachingAreaConversion",
  },
};
