import { print } from "graphql";
import { gql } from "@apollo/client";

export const QUERY_ORDERS_TENDENCY = print(gql`
  query OrdersTrend($startDate: String!, $endDate: String!) {
    OrdersTrend(startDate: $startDate, endDate: $endDate) {
      date
      orders
      midConfidenceInterval
      highConfidenceInterval
      status
    }
  }
`);

export interface QueryOrdersTedencyGql {
  OrdersTrend: Array<{
    date: string;
    orders: number;
    midConfidenceInterval: number[];
    highConfidenceInterval: number[];
    status: string;
  }>;
}
