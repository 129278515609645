import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_PAYMENT_METHOD = print(gql`
  mutation DeletePaymentMethod($deletePaymentMethodId: String!) {
    deletePaymentMethod(id: $deletePaymentMethodId)
  }
`);

export interface deletePaymentMethodId {
  deletePaymentMethod: string;
}
