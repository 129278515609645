import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const COURSE_FRAGMENT = gql`
  fragment CourseFields on Course {
    id
    categoryId
    partnerBrandId
    name
    description
    slug
    keywords
    active
    activateIfPossible
    imageUrl
    modalityName
    educationalUnitVisibleName
    state
    title
    courseLoadHours
    investmentInfoId
    eCommerceControlSKUId
    eCommerceProductId
    eCommerceProductLastUpdate
    eCommerceControlSKULastUpdate
    urlInscription
    moodleId
    urlInscriptionDefault
    prices {
      id
      active
      value
      valueWithoutDiscout
      installments
      eCommerceSkuId
      valueOtherInstallments
      planAdmissionWay {
        id
        title
        admissionWay {
          id
          name
        }
      }
    }
    category {
      department {
        templateInvestment {
          id
          name
          active
          allowPurchase
          urlInscription
        }
      }
    }
  }
`;

export const GET_ITEM_QUERY = print(gql`
  query GetOne($id: Int!) {
    Course(id: $id, includeInactivePrices: true) {
      ...CourseFields
    }
  }
  ${COURSE_FRAGMENT}
`);

interface Price {
  id: number;
  active: boolean;
  value: number;
  valueWithoutDiscout?: number;
  valueOtherInstallments?: number;
  installments: number;
  eCommerceSkuId?: number;
  planAdmissionWay: {
    id: number;
    title: string;
    admissionWay: {
      id: number;
      name: string;
    };
  };
}
export interface Course {
  id: number;
  name: string;
  slug: string;
  keywords?: string;
  description?: string;
  active: boolean;
  activateIfPossible: boolean;

  categoryId: number;
  partnerBrandId: number;

  imageUrl?: string;
  modalityName?: string;
  educationalUnitVisibleName?: string;
  state?: string;
  title?: string;
  courseLoadHours?: number;

  investmentInfoId?: number;

  eCommerceProductId?: number;
  eCommerceProductLastUpdate?: Date;
  eCommerceControlSKUId?: number;
  eCommerceControlSKULastUpdate?: Date;

  urlInscription?: string;
  moodleId?: string;
  urlInscriptionDefault?: string;

  category: {
    department: {
      templateInvestment: {
        id: number;
        name: string;
        active: boolean;
        allowPurchase: boolean;
        urlInscription?: string;
      };
    };
  };

  prices: Price[];
}
export const responseGetItemKey = "Course";
export interface GetItemGql {
  Course: Course;
}
