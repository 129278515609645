import { print } from "graphql";
import { gql } from "@apollo/client";

export const QUERY_CONVERSION_RATE = print(gql`
  query ConversionRate(
    $startDate: String!
    $endDate: String!
    $compareStartDate: String!
    $compareEndDate: String!
  ) {
    ConversionRate(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      generalComparedConversionRate
      generalReferenceConversionRate
      data {
        referenceDate
        comparedDate
        referenceValue
        comparedValue
      }
    }
  }
`);

export interface QueryConversionRateGql {
  ConversionRate: {
    generalComparedConversionRate: number;
    generalReferenceConversionRate: number;
    data: Array<{
      referenceDate: null | string;
      comparedDate: null | string;
      referenceValue: number;
      comparedValue: number;
    }>;
  };
}
