import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Modalities = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:Modality",
    read: "read:Modality",
    list: "list:Modality",
    update: "update:Modality",
    delete: "delete:Modality",
  },
};
