import { SchemaData, UseUpdateProps } from "./types";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { removeNullValues } from "utils/removeNullValues";

export default function useUpdate({ queryParam, setState }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const { name, email, title, photoUrl, description, lattesUrl, active, isCoordinator } =
        state?.data;

      console.log("data", {
        name: name || null,
        email: email || null,
        title: title || null,
        photoUrl: photoUrl || null,
        description: description || null,
        lattesUrl: lattesUrl || null,
        active: active ?? null,
        isCoordinator: isCoordinator ?? null,
        id: queryParam,
      });

      const response = await ApiCourseRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            name: name || null,
            email: email || null,
            title: title || null,
            photoUrl: photoUrl || null,
            description: description || null,
            lattesUrl: lattesUrl || null,
            active: active ?? null,
            isCoordinator: isCoordinator ?? null,
            id: queryParam,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data?.updateFacultyMember) {
        throw new Error("Ops. Sem resposta!");
      }
      const data = removeNullValues(response.data.data?.updateFacultyMember);

      setState({
        data,
      });

      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
