import {
  RankedTester,
  and,
  isStringControl,
  optionIs,
  or,
  rankWith,
  scopeEndsWith,
} from "@jsonforms/core";

export const rendererWysiwygTester: RankedTester = rankWith(
  3, //increase rank as needed
  // This defines name of control and type of control to use this renderer
  and(isStringControl, or(scopeEndsWith("Html"), optionIs("htmlEditor", true)))
);

export default rendererWysiwygTester;
