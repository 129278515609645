import { print } from "graphql";
import { gql } from "@apollo/client";

export const QUERY_METRICS = print(gql`
  query Metrics(
    $startDate: String!
    $endDate: String!
    $compareStartDate: String!
    $compareEndDate: String!
  ) {
    Metrics(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      revenue {
        referenceData
        comparedData
      }
      orders {
        referenceData
        comparedData
      }
      sessions {
        referenceData
        comparedData
      }
      avgticket {
        referenceData
        comparedData
      }
    }

    SummaryOrders(startDate: $startDate, endDate: $endDate) {
      ordersLastHour
      paymentsInAuthorization
      productsWithoutStock
    }
  }
`);

export interface DataInfo {
  referenceData: number | null;
  comparedData: number | null;
}

export interface QueryMetricsGql {
  Metrics: {
    revenue: DataInfo;
    orders: DataInfo;
    sessions: DataInfo;
    avgticket: DataInfo;
  };

  SummaryOrders: {
    ordersLastHour: number;
    paymentsInAuthorization: number;
    productsWithoutStock: number;
  };
}
