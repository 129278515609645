import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentForm } from "components/CRPComponents/CRPAddBanner.style";
import {
  FormBanner,
  ErrorBannerInfos,
  BannerInfos,
} from "components/CRPComponents/Forms/FormBanner";
import { MDSnackbar, MDSnackbarProps } from "components/MDSnackbar";
import { CreateBannerInput } from "types/CMS/Banner";
import { validateSyncClass } from "components/CRPComponents/Forms/utils";
import { ApiCMSRequest } from "data/ApiCMS";
import {
  MUTATION_CREATE_BANNER,
  ResponseCreateBanner,
} from "data/queries/ApiCMS/Banner/MutationCreateBanner";

function FormCreateBanner({ titleBox, idSite, path = "" }: FormCreateBannerProps) {
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });
  const navigate = useNavigate();

  const handleCloseSnackBar = () => {
    setSnackBarInfo(({ redirectId, props }) => {
      if (redirectId) navigate(`${path}/banners/${snackBarInfo.redirectId}`);
      return { ...props, open: false };
    });
    setLoading(false);
  };

  const [errors, setErrors] = useState<ErrorBannerInfos>({});
  const [loading, setLoading] = useState(false);

  const isValid = (data: any): boolean => {
    const errors = validateSyncClass(CreateBannerInput, data);
    console.log({ errors });
    if (!Object?.keys(errors)?.length) {
      setErrors({});
      return true;
    }

    setErrors(errors);
    return false;
  };

  const handleIsValid = async (data: CreateBannerInput) => {
    const response = await ApiCMSRequest<ResponseCreateBanner>({
      query: MUTATION_CREATE_BANNER,
      variables: { data },
    });
    if (response?.data?.errors) {
      throw response.data.errors;
    }
    return response.data.data.createBanner;
  };

  const handleSaveBanner = async (data: BannerInfos) => {
    const banner: CreateBannerInput = {
      name: data?.name,
      title: data.title,
      description: data.description,
      url: data.url,
      targetBlank: data.targetBlank,
      active: data.active,
      sort: data.sort,
      idSite: idSite,
      idMediaFileDesktop: data?.mediaFileDesktop?.id,
      idMediaFileMobile: data?.mediaFileMobile?.id,
      locale: data?.locale ?? null,
    };
    if (!isValid(banner)) {
      setSnackBarInfo({
        open: true,
        color: "error",
        title: "Dados inválidos",
        content: "Verifique o formulário, algum dado não é válido!",
      });
      return;
    }

    //isValid === true
    try {
      setLoading(true);
      const response = await handleIsValid(banner);
      setLoading(false);
      setSnackBarInfo({
        open: true,
        color: "success",
        icon: "check",
        title: "Banner criado com sucesso",
        content: `Banner criado com id: ${response.id}`,
        redirectId: response.id,
      });
      return;
    } catch (e: any) {
      setLoading(false);
      const apolloErrors = e?.networkError?.result?.errors?.map(
        ({ message }: { message: string }) => message
      );

      console.log(e?.networkError?.result?.errors);
      const messageError: string = apolloErrors?.join(", ") || e?.message || "Ops. Algo Deu errado";
      setErrors((prev) => ({ ...prev, form: messageError }));
      setSnackBarInfo({
        open: true,
        color: "error",
        title: "Ops, algo deu errado",
        content: messageError,
      });
    }
  };

  return (
    <ContentForm>
      <FormBanner
        titleBox={titleBox}
        idSite={idSite}
        onClickButton={handleSaveBanner}
        errors={errors}
        loading={loading}
        disabled={loading}
      />
      <MDSnackbar
        {...snackBarInfo}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        title={snackBarInfo.title}
        close={handleCloseSnackBar}
        onClose={handleCloseSnackBar}
      />
    </ContentForm>
  );
}

export default FormCreateBanner;

export interface FormCreateBannerProps {
  titleBox: String;
  idSite: number;
  /**
   * navigate(`:path}/banners/:id`)
   */
  path?: string;
}
