import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Subjects = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:Subject",
    read: "read:Subject",
    list: "list:Subject",
    update: "update:Subject",
    delete: "delete:Subject",
  },
};
