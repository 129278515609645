import { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Container from "./Container";
import { QUERY_SALLES_FUNNEL, QuerySalesFunnelGql } from "./queries/salesFunnel";
import { useData } from "./context/DataProvider";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { getColorRaw, getPercentageTextRaw } from "./utils";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface SalesFunnelData {
  title: string;
  percent: number;
  value: number;
  vsAnt: number;
  compareAnt: {
    percent: number;
    vsAnt: number;
  };
}

function SalesFunnel() {
  const [data, setData] = useState<SalesFunnelData[]>();
  const [loading, setLoading] = useState(false);
  const { dateSelected } = useData();

  useEffect(() => {
    setLoading(true);
    ApiFinancialRequest<QuerySalesFunnelGql>({
      query: QUERY_SALLES_FUNNEL,
      variables: {
        compareStartDate: dateSelected.from.value.toISOString(),
        startDate: dateSelected.from.value.toISOString(),
        compareEndDate: dateSelected.to.value.toISOString(),
        endDate: dateSelected.to.value.toISOString(),
      },
    })
      .then(({ data }) => setData(formatData(data.data.SalesFunnel.salesFunnel)))
      .finally(() => setLoading(false));
  }, [dateSelected]);

  const formatData = useCallback((sales: QuerySalesFunnelGql["SalesFunnel"]["salesFunnel"]) => {
    const array = [
      {
        title: "Total de sessões",
        ...sales.totalSessions,
        value: sales.totalSessions.referenceData,
      },
      {
        title: "Sessões de página de produto",
        ...sales.productPageView,
        value: sales.productPageView.referenceData,
      },
      {
        title: "Sessões de carrinho",
        ...sales.cartPageView,
        value: sales.cartPageView.referenceData,
      },
      {
        title: "Sessões de envio",
        ...sales.shippingPageView,
        value: sales.shippingPageView.referenceData,
      },
      {
        title: "Sessões de pagamento",
        ...sales.paymentPageView,
        value: sales.paymentPageView.referenceData,
      },
      {
        title: "Pedidos",
        ...sales.ordersPlaced,
        value: sales.ordersPlaced.referenceData,
      },
    ];

    return array.map(({ title, referenceData, comparedData, value }, i) => ({
      title,
      percent: percentage(referenceData / array[0].referenceData, 1),
      value,
      vsAnt: percentage(
        referenceData / array[0].referenceData - comparedData / array[0].comparedData
      ),
      compareAnt:
        i <= 0
          ? undefined
          : {
              percent: percentage(referenceData / array[i - 1].referenceData, 1),
              vsAnt: percentage(
                referenceData / array[i - 1].referenceData -
                  comparedData / array[i - 1].comparedData
              ),
            },
    }));
  }, []);

  const percentage = (value: number, toFixed?: number): number =>
    parseFloat((value * 100).toFixed(toFixed ?? 2)) || 0;

  return (
    <Container title="Funil de vendas" gap="20px">
      {!data || loading ? (
        <Stack alignItems={"center"}>
          <CircularProgress sx={{ color: "#344767", width: "30px", height: "30px" }} />
        </Stack>
      ) : (
        <Grid
          sx={{
            gridTemplateColumns: "repeat(6, 1fr)",
            display: "grid",
            gap: "10px",
            "& .container": {
              borderRadius: "0px",
              boxShadow: "none",
              borderRight: "1px solid gray",
            },
            "& .container:last-child": {
              borderRight: "none",
            },
          }}
        >
          {data?.map(({ title, value, percent, vsAnt, compareAnt }, index) => (
            <Container className="container" key={index}>
              <MDBox mb={0.5} lineHeight={1}>
                <MDTypography
                  color="text"
                  sx={{
                    textAlign: "center",
                    fontSize: "0.875rem",
                    lineHeight: 1.42857,
                    letterSpacing: "-0.0009em",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  {title}
                </MDTypography>
              </MDBox>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      border: "1px solid gray",
                      "& .MuiTooltip-arrow": {
                        color: "#FFFFFF",
                        ":before": {
                          border: "1px solid gray",
                        },
                      },
                    },
                  },
                }}
                placement="left"
                title={
                  compareAnt?.percent && (
                    <Stack
                      gap="6px"
                      sx={{
                        padding: "10px",
                      }}
                    >
                      <MDTypography
                        sx={{
                          fontWeight: 400,
                          fontSize: "1rem",
                          lineHeight: 1.33333,
                          textAlign: "center",
                        }}
                      >
                        {getPercentageTextRaw(compareAnt.percent)}
                      </MDTypography>
                      <MDTypography
                        sx={{
                          fontWeight: 400,
                          fontSize: "0.8rem",
                          lineHeight: 1.33333,
                          textAlign: "center",
                        }}
                      >
                        conversão
                      </MDTypography>
                      <Stack gap="2px">
                        <MDTypography
                          color={getColorRaw(compareAnt.vsAnt)}
                          sx={{
                            fontSize: "0.875rem",
                            textAlign: "center",
                          }}
                        >
                          {getPercentageTextRaw(compareAnt.vsAnt)} p.p
                        </MDTypography>
                      </Stack>
                    </Stack>
                  )
                }
              >
                <MDBox lineHeight={1}>
                  <MDTypography
                    variant="h5"
                    sx={{
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      letterSpacing: "-0.0029em",
                      fontWeight: 400,
                      opacity: 0.7,
                      paddingBlock: "4px",
                      textAlign: "center",
                    }}
                  >
                    {value}
                  </MDTypography>
                  <Stack gap="6px">
                    <MDTypography
                      sx={{
                        fontWeight: 400,
                        fontSize: "1.5rem",
                        lineHeight: 1.33333,
                        textAlign: "center",
                      }}
                    >
                      {getPercentageTextRaw(percent)}
                    </MDTypography>
                    <Stack gap="2px">
                      <MDTypography
                        color={getColorRaw(vsAnt)}
                        sx={{
                          fontSize: "0.875rem",
                          textAlign: "center",
                        }}
                      >
                        {getPercentageTextRaw(vsAnt)} p.p
                      </MDTypography>
                      <MDTypography
                        sx={{
                          fontSize: "0.75rem",
                          lineHeight: "1.33333",
                          textAlign: "center",
                        }}
                      >
                        vs {dateSelected.to.label}
                      </MDTypography>
                    </Stack>
                  </Stack>
                </MDBox>
              </Tooltip>
            </Container>
          ))}
        </Grid>
      )}
    </Container>
  );
}

export default SalesFunnel;
