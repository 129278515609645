import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaymentMethod } from "./getAll";

export const GET_ITEM_QUERY = print(gql`
  query PaymentMethod($paymentMethodId: String!) {
    PaymentMethod(id: $paymentMethodId) {
      id
      name
      paymentSystem {
        name
        implementation
        id
      }
      connector {
        implementation
        affiliationId
      }
      issuer {
        name
      }
      antifraud {
        implementation
        affiliationId
      }
      installmentOptions {
        dueDateType
        interestRateMethod
        minimumInstallmentValue
        installments {
          ruleId
          quantity
          value
          interestRate
          isExternalInstallmentService
          interestTax
        }
      }
      enabled
      installmentsService
      isDefault
      isSelfAuthorized
      requiresAuthentication
      beginDate
      endDate
      condition {
        id
        pullRate
        name
      }
      multiMerchantList
      country {
        name
        isoCode
      }
      dateIntervals {
        start
        end
      }
      externalInterest
      minimumValue
      deadlines {
        days
        interestRate
      }
      paymentOptions {
        days
        interestRate
      }
      cobrand {
        name
      }
      cardLevel {
        name
      }
      excludedBinsRanges
      salesChannels {
        id
      }
    }
  }
`);

export interface GetItemGql {
  PaymentMethod: PaymentMethod;
}
