import { useParams } from "react-router-dom";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import useGetItem from "./hooks/useGetItem";
import useDelete from "./hooks/useDelete";
import useUpdate from "./hooks/useUpdate";
import { path, SchemaData } from "./hooks/types";
import { UpdateGql } from "./queries/update";
import { PlanAdmissionWays } from ".";

function Update() {
  const id = Number(useParams()?.id);

  if (!id) return null;

  return (
    <GenericMethods.Update<SchemaData, UpdateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath={path}
      useDelete={useDelete}
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={id}
      updatePermission={PlanAdmissionWays.Permissions.edit}
      deletePermission={PlanAdmissionWays.Permissions.delete}
    />
  );
}

export default Update;
