import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateHoliday($data: CreateHolidayInput!) {
    createHoliday(data: $data) {
      id
    }
  }
`);

export interface CreateGql {
  createHoliday: {
    id: number;
  };
}
