// @mui material components
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React TS components
import { MDButtonProps } from "components/MDButton";
import MDInput from "components/MDInput";

function FilterMenu({ variables, onChange, buttonProps }: FilterMenuProps): JSX.Element {
  // const [menu, setMenu] = useState(null);

  // const openMenu = (event: any) => setMenu(event.currentTarget);
  // const closeMenu = () => setMenu(null);

  const handleChangeSelectStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "true":
        onChange({ ...variables, active: true });
        break;

      case "false":
        onChange({ ...variables, active: false });
        break;
      case "all":
      default:
        onChange({ ...variables, active: null });
        break;
    }
  };

  return (
    <MDInput
      value={variables?.active?.toString() || "all"}
      label="Status"
      onChange={handleChangeSelectStatus}
      sx={{ width: "100px" }}
      select
    >
      <MenuItem value={"true"}>Ativo</MenuItem>
      <MenuItem value={"false"}>Inativo</MenuItem>
      <MenuItem value={"all"}>Todos</MenuItem>
    </MDInput>
  );
  // Como só tem uma opção de filtro troquei o retorno mas deixei salvo para reutilizar depois

  // return (
  //   <>
  //     <MDButton
  //       variant={menu ? "contained" : "outlined"}
  //       color="dark"
  //       onClick={openMenu}
  //       {...buttonProps}
  //     >
  //       Filtro&nbsp;
  //       <Icon>keyboard_arrow_down</Icon>
  //     </MDButton>
  //     <Menu
  //       anchorEl={menu}
  //       anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
  //       transformOrigin={{ vertical: "top", horizontal: "left" }}
  //       open={Boolean(menu)}
  //       onClose={closeMenu}
  //       keepMounted
  //     >
  //       <MenuItem onClick={closeMenu}>
  //         {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
  //         <MDInput
  //           value={variables?.active?.toString() || "all"}
  //           label="Status"
  //           onChange={handleChangeSelectStatus}
  //           sx={{ width: "100%" }}
  //           select
  //         >
  //           <MenuItem value={"true"}>Ativo</MenuItem>
  //           <MenuItem value={"false"}>Inativo</MenuItem>
  //           <MenuItem value={"all"}>Todos</MenuItem>
  //         </MDInput>
  //       </MenuItem>
  //     </Menu>
  //   </>
  // );
}
export interface FilterMenuProps<T = { active?: boolean | null }> {
  variables: T;
  onChange: (variables: T) => void;
  buttonProps?: Omit<MDButtonProps, "onClick" | "variant" | "color">;
}

export default FilterMenu;
