import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Addresses($search: String, $perPage: Int, $page: Int) {
    Addresses(search: $search, perPage: $perPage, page: $page) {
      items {
        id
        addressName
        city
        complement
        country
        postalCode
        reference
        state
        street
        neighborhood
        addressNumber
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  addressName: string;
  city: string;
  complement: string;
  country: string;
  postalCode: string;
  reference: string;
  state: string;
  street: string;
  neighborhood: string;
  addressNumber: string;
}

export interface GetAllGql {
  Tags: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
