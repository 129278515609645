import List from "./List";

export const LogDowload = {
  List: List,
  Permissions: {
    create: "create:logDownload",
    read: "read:logDownload",
    list: "list:logDownload",
  },
};
