import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateHighlightCourseShelf($data: CreateHighlightCourseShelfInput!) {
    createHighlightCourseShelf(data: $data) {
      id
    }
  }
`);

export interface CreateGql {
  createHighlightCourseShelf: {
    id: number;
  };
}
