import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { formatDate } from "./utils";
import { Group } from "./styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CircleIcon from "@mui/icons-material/Circle";
import { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GetItemGql } from "./queries/getItem";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type TimelineStep = GetItemGql["Order"]["timeline"][0];

enum StepNames {
  "creating" = "Criando",
  "authorizing" = "Autorizando",
  "approving-transaction" = "Aprovando a transação",
  "window-to-cancel" = "Janela para cancelamento",
  "handling" = "Preparando entrega",
  "invoice" = "Verificando nota fiscal",
  "invoiced" = "Faturado",
  "order-accepted" = "Pedido aceito",
  "on-order-completed-ffm" = "Finalizando pedido",
  "order-created" = "Pedido criado",
  "on-order-completed" = "Pedido concluído",
  "waiting-ffmt-authorization" = "Aguardando autorização para despachar",
  "authorize-fulfillment" = "Autorizar despacho",
  "approve-payment" = "Aprovar pagamento",
  "payment-approved" = "Pagamento aprovado",
  "ready-for-handling" = "Pronto para manuseio",
  "canceling" = "Cancelando",
  "cancel" = "Cancelamento",
  "accepting-cancel" = "Aceitando o cancelamento",
  "canceled" = "Cancelado",
}

const getIConLabel = (state: string, isSub?: boolean) => {
  const fontSize = isSub ? "small" : "medium";
  switch (state) {
    case "Done":
      return <CheckCircleIcon color="success" fontSize={fontSize} />;
    case "Doing":
      return <CheckCircleIcon color="success" fontSize={fontSize} />;
    case "ToDo":
      return <CircleIcon sx={{ fill: "grey" }} fontSize={fontSize} />;
    case "Error":
      return <ErrorIcon color="error" fontSize={fontSize} />;
    case "Cancelled":
      return <HighlightOffIcon sx={{ fill: "black" }} fontSize={fontSize} />;
    default:
      return <CircleIcon sx={{ fill: "grey" }} fontSize={fontSize} />;
  }
};

const StepCancel = ({
  step,
  cancellationData,
}: {
  step: TimelineStep;
  cancellationData: GetItemGql["Order"]["cancellationData"];
}) => {
  const reason = () => {
    if (cancellationData.requestedBySystem) return "Sistema";
    if (cancellationData.requestedByPaymentNotification) return "Pagamento recusado";
    if (cancellationData.requestedBySellerNotification) return "Vendedor";
    return "Usuário";
  };

  return (
    <Step active>
      <StepLabel>
        <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
          {getIConLabel(step.state)}
          <span>{StepNames[step?.step?.name as keyof typeof StepNames]}</span>
        </Stack>
      </StepLabel>
      <StepContent>
        {cancellationData && (
          <p>
            <span>{formatDate(cancellationData.cancellationDate)}</span>
            <br />
            <span>Solicitado por: {reason()}</span>
            <br />
            <span>
              Motivo do cancelamento: {cancellationData.reason ?? "Prefiro não informar."}
            </span>
          </p>
        )}
      </StepContent>
    </Step>
  );
};

const StepCustom = ({ step }: { step: TimelineStep }) => {
  const [open, setOpen] = useState(false);

  const hasSub = step?.step?.children?.length > 0;

  return (
    <Step active>
      <StepLabel>
        <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
          {getIConLabel(step.state)}
          <span>{StepNames[step?.step?.name as keyof typeof StepNames]}</span>
          {hasSub && (
            <IconButton onClick={() => setOpen((old) => !old)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </Stack>
      </StepLabel>
      <StepContent data-status={step.state}>
        {step.date && <span>{formatDate(step.date)}</span>}
        {hasSub && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stepper
              orientation="vertical"
              activeStep={-1}
              sx={{
                background: "white",
                boxShadow: "none",
              }}
            >
              {step?.step?.children?.map((subStep, i) => (
                <Step active key={i}>
                  <StepLabel icon={getIConLabel(step.state, true)}>
                    <span>{StepNames[subStep?.stepName as keyof typeof StepNames]}</span>
                  </StepLabel>
                  {subStep?.date && (
                    <StepContent>
                      <span>{formatDate(subStep.date)}</span>
                    </StepContent>
                  )}
                </Step>
              ))}
            </Stepper>
          </Collapse>
        )}
      </StepContent>
    </Step>
  );
};

type Props = {
  timeline: Array<TimelineStep>;
  cancellationData: GetItemGql["Order"]["cancellationData"];
};

function Status({ timeline, cancellationData }: Props) {
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "6px",
        "& .MuiStepConnector-line": {
          display: "none",
        },
        span: {
          textTransform: "capitalize",
        },
        "& .MuiStepContent-root[data-status=Doing], & .MuiStepContent-root[data-status=Done]": {
          borderColor: "green",
        },
      }}
    >
      <Group>
        <h3>Historico</h3>
        <Stepper
          orientation="vertical"
          activeStep={-1}
          sx={{
            background: "white",
            boxShadow: "none",
          }}
        >
          {timeline
            .filter((item) => item?.step?.name)
            .map((step, index) => {
              switch (step.state) {
                case "Cancelled":
                  return <StepCancel cancellationData={cancellationData} step={step} key={index} />;
                default:
                  return <StepCustom step={step} key={index} />;
              }
            })}
        </Stepper>
      </Group>
    </Box>
  );
}

export default Status;
