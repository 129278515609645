import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { print } from "graphql";
import { ApiAuthRequest } from "data/ApiAuth";

export const QUERY = print(gql`
  query FindManyPermissions {
    findManyPermissions {
      id
      name
      description
    }
  }
`);

export interface QueryGql {
  findManyPermissions: Array<{
    id: number;
    name: string;
    description: string;
  }>;
}

export default function useData() {
  const [permissions, setPermissions] = useState<QueryGql["findManyPermissions"]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ApiAuthRequest<QueryGql>({
      query: QUERY,
    })
      .then(({ data }) => setPermissions(data.data.findManyPermissions))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  return { permissions, loading };
}
