import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateGlobalKey($data: CreateGlobalKeyInput!) {
    createGlobalKey(data: $data) {
      key
    }
  }
`);

export interface CreateGql {
  createGlobalKey: {
    key: string;
  };
}
