import {
  CategoryFaqGql,
  GET_CATEGORY_FAQ_QUERY,
} from "data/queries/ApiCMS/CategoriesFaq/QueryCategoryFaq";
import { useEffect } from "react";
import { UseGetItemProps } from "./types";
import { ApiCMSRequest } from "data/ApiCMS";
import { UISchemaJson } from "../schema/UISchema";
import { schemaJson } from "../schema/SchemaJson";

const useGetItem = ({
  setState,
  setTemplate,
  queryParam,
}: UseGetItemProps<CategoryFaqGql["CategoryFaq"]>) => {
  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCMSRequest<CategoryFaqGql>({
        query: GET_CATEGORY_FAQ_QUERY,
        variables: { categoryFaqId: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.CategoryFaq) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          setTemplate({
            schemaJson: JSON.stringify(schemaJson),
            UISchema: UISchemaJson,
          });

          const { name, slug, sort } = response.data.data?.CategoryFaq;

          setState({
            data: {
              name,
              slug,
              sort,
            },
          });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
