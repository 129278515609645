import { useState } from "react";
import { upsertCourse } from "./hooks/UpsertCourse";
import {
  convertObjectToUpsertInput,
  editEachDataRead,
  UpsertCourseInput,
} from "./hooks/convertObjectToUpsertInput";
import ReadCSVComponent from "../ReadCSVComponent";
import useCategories from "./hooks/useCategories";
import SelectComponent, { SelectComponentProps } from "./Select";

function ReadCSVCourseComponent() {
  const [categorySlugSelected, setCategorySelectSlug] = useState<string>();
  const { categories } = useCategories();
  const handleChange: SelectComponentProps["onChange"] = (event) => {
    setCategorySelectSlug(event.target.value as string);
  };

  function findCategoryInList(selectedSlug: string): UpsertCourseInput["courseGroup"]["category"] {
    const cat = categories.find(({ slug }) => slug === selectedSlug);
    return {
      name: cat.name,
      description: cat.description,
      slug: cat.slug,
      active: cat.active,
    };
  }

  function sendToAPi(data: { [key: string]: string }) {
    const {
      courseGroup: { category, ...courseGroup },
      ...upsertCourseInput
    } = convertObjectToUpsertInput(data);
    return upsertCourse({
      courseGroup: {
        category:
          categorySlugSelected && !category?.slug
            ? findCategoryInList(categorySlugSelected)
            : category,
        ...courseGroup,
      },
      ...upsertCourseInput,
    });
  }
  return (
    <>
      <SelectComponent
        value={categorySlugSelected || ""}
        label="Categoria Default"
        onChange={handleChange}
        disabled={!categories?.length}
        options={categories?.map(({ slug, name, label }) => ({
          value: slug,
          label: label || name,
        }))}
      />
      <ReadCSVComponent
        pathToSuccessSendToAPi="/ecommerce/cursos"
        sendToAPi={sendToAPi}
        editEachDataRead={editEachDataRead} //this slugify all slugs
      />
    </>
  );
}

export default ReadCSVCourseComponent;
