import React from "react";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  GridGalerryImage,
  GalerryItemImage,
  GalleryImageContainer,
  CRPContainerVert,
} from "components/CRPComponents/CRPAddBanner.style";
import MDTypography from "components/MDTypography";
// import MDTypography from "components/MDTypography";

interface Midia {
  id: number;
  name: string;
  path: string;
  type: string;
  size?: number;
  uploadedAt?: Date | string;
}
export type MidiaAndUrl = Midia & { url: string };
export interface MidiaGalleryProps {
  urlPrePath?: string;
  midias: Midia[];
  onClickMidia?: (midia: MidiaAndUrl) => void;
}

export function MidiaGallery({ midias, onClickMidia, urlPrePath = "" }: MidiaGalleryProps) {
  const handleClickMidia = (midia: Midia) => {
    onClickMidia?.({ ...midia, url: `${urlPrePath}${midia.path}` });
  };
  return (
    <GridGalerryImage>
      {midias?.map((midia: Midia) => {
        if (midia.type.split("/")[0] !== "image") {
          return (
            <GalleryImageContainer key={`img-${midia.id}`}>
              <CRPContainerVert>
                <FilePresentIcon fontSize="large" onClick={() => handleClickMidia(midia)} />
                <MDTypography variant="caption">{midia.name}</MDTypography>
              </CRPContainerVert>
            </GalleryImageContainer>
          );
        }
        return (
          <GalleryImageContainer key={`img-${midia.id}`}>
            <GalerryItemImage
              onClick={() => handleClickMidia(midia)}
              src={`${urlPrePath}${midia.path}`}
              alt={midia.name}
            />
          </GalleryImageContainer>
        );
      })}
    </GridGalerryImage>
  );
}

export default MidiaGallery;
