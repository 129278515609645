import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LongTextCell from "components/CRPComponents/Tables/components/LongTextCell";
import TagCell from "components/CRPComponents/Tables/components/TagCell";
import DataTableControled from "examples/Tables/DataTableControled";

const columnsTable = [
  {
    Header: "ReceiptHandle",
    accessor: "message.ReceiptHandle",
    Cell: ({ value }: any) => <LongTextCell value={value} />,
  },
  {
    Header: "MessageId",
    accessor: "message.MessageId",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Resultado",
    accessor: "result",
    Cell: ({ value }: any) => (
      <TagCell
        value={value === "Successful" ? "Sucesso" : "Falhou"}
        color={value === "Successful" ? "success" : "error"}
      />
    ),
  },
  {
    Header: "Mensagem Deletada",
    accessor: "messageWasDeleted",
    Cell: ({ value }: any) => (
      <TagCell value={!value ? "Não" : "Sim"} color={!value ? "error" : "success"} />
    ),
  },
  {
    Header: "Se Falhou",
    accessor: "failedReason",
    Cell: ({ value }: any) => <DefaultCell value={value || ""} />,
  },
  {
    Header: "Erro",
    accessor: "errorMessage",
    Cell: ({ value }: any) => <DefaultCell value={value || ""} />,
  },
  {
    Header: "Body Message",
    accessor: "message.Body",
    Cell: ({ value }: any) => <LongTextCell value={value} />,
  },
];
export type Message = {
  result: "Failed" | "Successful";
  messageWasDeleted: boolean;
  failedReason?:
    | "Function Fail"
    | "No ReceiptHandle"
    | "Delete Message Fail"
    | "Message Expiration Rules"
    | "Action Invalid";
  errorMessage?: string;
  error?: string;
  message: {
    MessageId?: string;
    Body?: string;
    ReceiptHandle?: string;
  };
};
export function TableMessages({ messages, loading }: { messages: Message[]; loading?: boolean }) {
  return (
    <DataTableControled
      table={{
        columns: columnsTable,
        rows: messages,
      }}
      loading={loading}
      showTotalEntries
    />
  );
}
