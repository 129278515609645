import Create from "./Create";
import List from "./List";
import Update from "./Update";
import ListEntries from "./ListEntries";

export const Forms = {
  List: List,
  Update: Update,
  Create: Create,
  Entries: ListEntries,
  Permissions: {
    create: "create:Form",
    read: "read:Form",
    list: "list:Form",
    delete: "delete:Form",
    update: "update:Form-NOT-DEFAULT",
  },
};
