import {
  Stack,
  Box,
  FormHelperText,
  CircularProgress,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Grid,
  Button,
  SxProps,
  TextField,
  IconButton,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { CustomRendererProps } from "../types";
import useData from "./hooks/useData";
import { withJsonFormsControlProps } from "@jsonforms/react";
import ArrowRight from "@mui/icons-material/KeyboardArrowRightRounded";
import ArrowLeft from "@mui/icons-material/KeyboardArrowLeftRounded";
import CloseIcon from "@mui/icons-material/Close";

const btnStyle: SxProps = {
  color: "white !important",
  borderColor: "none !important",
  background: "#1A73E8 !important",
  transition: "all 0.3s ease-in",
  my: 0.5,
  svg: {
    width: 25,
    height: 25,
  },
  "&:disabled": {
    color: "#344767 !important",
    border: "1px solid #344767 !important",
    background: "white !important",
  },
};

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
  return [...a, ...not(b, a)];
}

function SelectPermissions({
  data: selectedPermissions,
  handleChange,
  path,
  label,
  required,
  errors,
  enabled,
}: CustomRendererProps<number[] | undefined>) {
  const { permissions, loading } = useData();
  const [search, setSearch] = useState({
    has: "",
    dont: "",
  });

  const [checked, setChecked] = useState<readonly number[]>([]);
  const [left, setLeft] = useState<readonly number[]>([]);
  const [right, setRight] = useState<readonly number[]>([]);

  useEffect(() => {
    if (!permissions) return;

    setRight(selectedPermissions ?? []);

    setLeft(permissions.filter(({ id }) => !selectedPermissions?.includes(id)).map(({ id }) => id));
  }, [permissions, selectedPermissions]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly number[]) => intersection(checked, items).length;

  const handleToggleAll = (items: readonly number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setChecked(not(checked, leftChecked));
    setData(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setChecked(not(checked, rightChecked));
    setData(not(right, rightChecked));
  };

  const setData = (selectedItems: number[]) => {
    handleChange(path, selectedItems.length ? selectedItems : undefined);
  };

  if (loading) {
    return (
      <Stack alignItems={"center"}>
        <CircularProgress sx={{ color: "#7b809a" }} />
      </Stack>
    );
  }

  const customList = (
    title: React.ReactNode,
    items: readonly number[],
    children: React.ReactNode,
    search: string
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={!enabled || items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={
          <Stack gap={"8px"}>
            <span>{`${numberOfChecked(items)}/${items.length} selecionadas`}</span>
            {children}
          </Stack>
        }
      />
      <Divider />
      <List
        sx={{
          width: "calc(100% - 20px)",
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {permissions
          .filter(
            ({ id, name }) =>
              items.includes(id) &&
              name.toLowerCase().includes(search.toLowerCase() ?? name.toLowerCase())
          )
          .map((item) => {
            const labelId = `transfer-list-all-item-${item.name}-label`;

            return (
              <ListItemButton
                disabled={!enabled}
                key={item.name}
                role="listitem"
                onClick={handleToggle(item.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={item.name} />
              </ListItemButton>
            );
          })}
      </List>
    </Card>
  );

  return (
    <Fragment>
      <Stack gap={1} margin={"10px 0"}>
        <Box
          component={"p"}
          sx={{
            fontFamily: "Roboto",
            fontSize: "0.9rem",
            fontWeight: "400",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            padding: 0,
            position: "relative",
            color: !!errors ? "#F44335" : "#7b809a",
          }}
        >
          {label}
          {required && " *"}
        </Box>
        <Box>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item sx={{ maxWidth: "330px", width: "100%" }}>
              {customList(
                "Não possui permissão para:",
                left,
                <TextField
                  label="Pesquise aqui..."
                  value={search.dont}
                  onChange={(evt) => setSearch((old) => ({ ...old, dont: evt.target.value }))}
                  InputProps={{
                    endAdornment: search.dont && (
                      <IconButton onClick={() => setSearch((old) => ({ ...old, dont: "" }))}>
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                />,
                search.dont
              )}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={btnStyle}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={!enabled || leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  <ArrowRight />
                </Button>
                <Button
                  sx={btnStyle}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={!enabled || rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  <ArrowLeft />
                </Button>
              </Grid>
            </Grid>
            <Grid item sx={{ maxWidth: "330px", width: "100%" }}>
              {customList(
                "Possui permissão para:",
                right,
                <TextField
                  label="Pesquise aqui..."
                  value={search.has}
                  onChange={(evt) => setSearch((old) => ({ ...old, has: evt.target.value }))}
                  InputProps={{
                    endAdornment: search.has && (
                      <IconButton onClick={() => setSearch((old) => ({ ...old, has: "" }))}>
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                />,
                search.has
              )}
            </Grid>
          </Grid>
        </Box>
        {!!errors && <FormHelperText error>{errors}</FormHelperText>}
      </Stack>
    </Fragment>
  );
}

export default withJsonFormsControlProps(SelectPermissions);
