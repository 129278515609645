import { gql } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import { print } from "graphql";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const QUERY = print(gql`
  mutation Logout($token: String!) {
    Logout(token: $token) {
      userId
    }
  }
`);

export interface QueryGql {
  Logout: {
    userId: string;
  };
}

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: implement logout mutation

    // const token = localStorage.getItem("UserToken");

    // ApiAuthRequest<QueryGql>({
    //   query: QUERY,
    //   variables: { token },
    // }).then(() => {
    //   localStorage.removeItem("UserToken");
    //   navigate("/auth/login");
    // });

    localStorage.removeItem("UserToken");
    navigate("/auth/login");
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 9999,
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default Logout;
