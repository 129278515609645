import { print } from "graphql";
import { gql } from "@apollo/client";

export const QUERY_BEST_SELLERS = print(gql`
  query BestSellers(
    $startDate: String!
    $compareStartDate: String!
    $compareEndDate: String!
    $sort: BestSellerSortEnum!
    $endDate: String!
  ) {
    BestSellers(
      startDate: $startDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
      sort: $sort
      endDate: $endDate
    ) {
      productName
      imageSrc
      revenueValue
      revenueComparedValue
      unitValue
      unitComparedValue
    }
  }
`);

export interface QueryBestSellersGql {
  BestSellers: Array<{
    productName: string;
    imageSrc: string;
    revenueValue: number;
    revenueComparedValue: number;
    unitValue: number;
    unitComparedValue: number;
  }>;
}
