import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query ModalityConversion($modalityConversionId: Int!) {
    ModalityConversion(id: $modalityConversionId) {
      id
      colligate {
        active
        partnerVisibleName
      }
      idColligate
      databaseName
      idModality
      modalityName
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  ModalityConversion: {
    id: number;
    colligate: {
      active: boolean;
      partnerVisibleName: string;
    };
    databaseName: string;
    idColligate: number;
    idModality: string;
    modalityName: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
