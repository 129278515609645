import { JsonSchema } from "@jsonforms/core";

export type DataFormTypeDetails = {
  id: number;
  active: boolean;
  imageUrl: string;
  title: string;
  linkRegulation?: string;
  startDate?: Date;
  endDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

// Add options to schema
export const addOptionsToSchema = (
  schema: string,
  categories: any,
  modalities: any
): JsonSchema => {
  //Adding all Categories to List
  const categoriesList = categories?.map((item: any) => ({ const: item.id, title: item.name }));

  //Adding all Categories to List
  const modalitiesList = modalities?.map((item: any) => ({ const: item.id, title: item.name }));

  const newSchema = JSON.parse(schema);

  newSchema.properties.categoriesBlock.properties.categories.items.oneOf = categoriesList;
  newSchema.properties.modalitiesBlock.properties.modalities.items.oneOf = modalitiesList;

  return newSchema;
};
