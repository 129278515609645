import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";
export const QUERY = print(gql`
  query CourseGroups($active: Boolean) {
    CourseGroups(perPage: -1, active: $active) {
      items {
        id
        name
        active
        category {
          templateSchemaDefault {
            UISchema
            schemaJson
          }
        }
      }
    }
  }
`);
export interface QueryGql {
  CourseGroups: {
    items: Array<{
      id: number;
      name: string;
      active: boolean;
      category: {
        templateSchemaDefault: {
          UISchema: string;
          schemaJson: string;
        };
      };
    }>;
  };
}
export default function useCourseGroups(active?: boolean) {
  const [courseGroups, setCourseGroups] = useState<null | QueryGql["CourseGroups"]["items"]>(null);
  useEffect(() => {
    ApiCourseRequest<QueryGql>({
      query: QUERY,
      variables: { active },
    }).then(({ data }) => setCourseGroups(data.data.CourseGroups.items));
  }, []);
  return {
    courseGroups,
    setCourseGroups,
  };
}
