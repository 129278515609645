import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query BlogCategory($id: Int!) {
    BlogCategory(id: $id) {
      id
      name
      slug
      backgroundColor
      textColor
      sort
    }
  }
`);

export interface GetItemGql {
  BlogCategory: {
    id: number;
    name: string;
    slug: string;
    backgroundColor: string;
    textColor: string;
    sort: number;
  };
}
