export const MUTATION_DELETE_FAQ = `mutation Mutation($deleteFaqId: Int!) {
  deleteFaq(id: $deleteFaqId) {
    id
    question
  }
}`;

export type ResponseDeleteFaq = {
  deleteFaq: {
    id: number;
    question: string;
  };
};
