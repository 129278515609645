import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query TemplateEmail($name: String!) {
    TemplateEmail(name: $name) {
      id
      name
      fileName
      sendCopyTo
      subjectDefault
      jsonDataExample
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  TemplateEmail: {
    id: 1;
    name: string;
    fileName: string;
    sendCopyTo: string[];
    subjectDefault: string;
    jsonDataExample: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
