import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const ModalityConversion = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:ModalityConversion",
    read: "read:ModalityConversion",
    list: "list:ModalityConversion",
    edit: "update:ModalityConversion",
    delete: "delete:ModalityConversion",
  },
};
