import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { SitesEnum } from "types/sitesEnum";

export const GET_ITEM_QUERY = print(gql`
  query EducationalUnit($id: Int!) {
    EducationalUnit(id: $id) {
      id
      name
      visibleName
      description
      imageUrl
      active
      city
      neighborhood
      state
      street
      number
      complement
      postalCode
      addressString
      phone
      otherPhones
      education
      institution
      mapsUrlEmbed
      email
      educationalGroupId
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  EducationalUnit: {
    id: number;
    name: string;
    visibleName: string;
    description: string;
    imageUrl: string;
    active: boolean;
    city: string;
    neighborhood: string;
    state: string;
    street: string;
    number: string;
    complement: string;
    postalCode: string;
    addressString: string;
    phone: string;
    otherPhones: string;
    education: string;
    institution: SitesEnum;
    educationalGroupId: number;
    createdAt: string;
    updatedAt: string;
    mapsUrlEmbed: string;
    email: string;
  };
}
