import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import schemaJson from "../schema/SchemaJson";
import UISchema from "../schema/UISchema.json";
import useModalities from "./useModalities";

export default function useTemplate(create?: boolean) {
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const { modalities } = useModalities();

  useEffect(() => {
    if (!modalities?.length) {
      setTemplate({
        schemaJson,
        UISchema,
      });
      return;
    }

    const modalityName = {
      type: "string",
      oneOf: [
        ...modalities.map(({ name }) => ({
          title: name,
          const: name,
        })),
      ],
    };
    setTemplate({
      schemaJson: {
        ...schemaJson,
        properties: { ...schemaJson.properties, modalityName },
      },
      UISchema,
    });
  }, [modalities]);

  return {
    template,
  };
}
