import { useCallback, useEffect, useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import JsonForm from "../index";
import { AdditionalErrors, JsonFormsComponentProps, JsonFormsStateProps } from "./types";
import { FormHelperText } from "@mui/material";
import { ErrorTranslator, createAjv } from "@jsonforms/core";
import { handleTranslateErrors } from "data/utils/handleTranslateErrors";

export function JsonFormsComponent<T = any, R = any>({
  template,
  state,
  onChange,
  disabled,
  children,
  boxProps = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    flexDirection: "row",
    gap: 3,
  },
  buttonProps = {
    children: "Salvar",
    variant: "outlined",
    color: "info",
  },
  onSave,
  extraValidation,
  messageSuccess,
  messageFail,
  onSuccessSave,
  onFailSave,
  permissions,
  afterForm,
  hideAfterButtons,
  ...props
}: JsonFormsComponentProps<T, R>): JSX.Element {
  const [additionalErrors, setAdditionalErrors] = useState<AdditionalErrors>([]);
  const [internalLoading, setInternalLoading] = useState(false);
  const [failMessageDefault, setFailMessageDefault] = useState<{ title: string; content?: string }>(
    { title: "Ops! algo deu errado!" }
  );
  const handleDefaultsAjv = createAjv({ useDefaults: true });

  const onChangeForm = (props: Partial<JsonFormsStateProps>) => {
    onChange({ ...state, ...props });
  };

  useEffect(() => console.log(state?.data, state?.errors), [state?.data]);

  const hasErrors = (state: JsonFormsStateProps<T>): boolean => {
    return state?.errors?.length > 0 || !!state?.criticalError || !!state?.messageError;
  };

  /* handle with message success or Error */
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [showMessageFail, setShowMessageFail] = useState(false);
  const handleSuccessSave = (response: R) => {
    if (onSuccessSave) {
      onSuccessSave(response);
    }
    setShowMessageSuccess(true);
  };
  const handleFailSave = (e: any) => {
    console.log("handleFailSave", e);
    const messageError =
      e?.response?.data?.errors
        ?.map((error: any) => {
          // error?.extensions?.error?.stack?.split("\n")?.[0]
          if (typeof error?.extensions?.error?.stack === "string") {
            const message: string = error?.extensions?.error?.stack;
            return message.includes("\n") ? message.split("\n")[0] : message;
          }
          return (
            error?.extensions?.error?.stack?.message ||
            error?.extensions?.error?.message ||
            error?.message
          );
        })
        .join(",") || "Ops! algo deu errado!";
    setFailMessageDefault({
      title: "Ops! algo deu errado!",
      content: messageError,
    });
    if (onFailSave) {
      onFailSave(e);
    }
    setShowMessageFail(true);
  };
  const handleCloseMessageSuccess = useCallback(() => {
    setShowMessageSuccess(false);
    if (messageSuccess?.onClose) {
      messageSuccess.onClose();
    }
  }, [messageSuccess]);

  const handleCloseMessageFail = useCallback(() => {
    setShowMessageFail(false);
    setFailMessageDefault({
      title: "Ops! algo deu errado!",
    });
    if (messageFail?.onClose) {
      messageFail.onClose();
    }
  }, [messageFail]);

  const showErrors = (): string | undefined => {
    // let errorsArray: string[] = [];
    // if (state?.messageError) {
    //   errorsArray.push(state?.messageError);
    // }
    // if (state?.errors?.length > 0) {
    //   errorsArray.push(
    //     ...state.errors.map(({ instancePath, message }) => `Erro em ${instancePath}: ${message}`)
    //   );
    // }
    // if (additionalErrors?.length > 0) {
    //   errorsArray.push(
    //     ...additionalErrors.map(({ keyword, message }) => `Erro em ${keyword}: ${message}`)
    //   );
    // }

    const errorMessage = additionalErrors?.find(({ keyword }) => keyword === "generalError");

    if (!errorMessage) return;

    return errorMessage.message;
  };

  /** Handle with validation and save */
  /**
   * throw if error
   * @returns state.data
   */
  const validate = async (): Promise<T> => {
    if (!state?.data) throw "No data!";
    if (hasErrors(state)) throw "Has Errors in form!";
    if (extraValidation) {
      try {
        const errors = await extraValidation(state?.data);
        setAdditionalErrors(errors || []);
        if (errors?.length > 0) throw "Has Errors in validation!";
      } catch (e) {
        console.error({ validationError: e });
        setAdditionalErrors([]);
        throw "Error in extraValidation function!";
      }
    }

    return state.data;
  };

  function findKeyPath(obj: any, targetKey: string, currentPath = "") {
    try {
      for (const key in obj) {
        if (key === targetKey) {
          return currentPath + "/" + key;
        }

        if (obj[key] !== null && typeof obj[key] === "object") {
          const path: string = findKeyPath(obj[key], targetKey, currentPath + "/" + key);
          if (path) {
            return path.replace("/properties", "");
          }
        }
      }

      `/${targetKey}`;
    } catch (_) {
      return `/${targetKey}`;
    }
  }

  const handleAdditionalErrors = (e: any) => {
    try {
      const errors = JSON.parse(e.response.request.response);

      if (errors.errors && Array.isArray(errors.errors)) {
        const newErrors = errors.errors.flatMap((error: any) => {
          if (
            error?.extensions &&
            error?.extensions?.exception &&
            error?.extensions?.exception?.validationErrors
          ) {
            return error.extensions.exception.validationErrors.flatMap((validationError: any) => {
              const key: string = validationError.property;
              const messages = Object.values(validationError?.constraints || []);

              const keyPath = findKeyPath(template.schemaJson, key) ?? `/${key}`;

              return messages.map((message: any) => ({
                instancePath: keyPath,
                message: message?.replace(key, "") ?? "",
                schemaPath: keyPath,
                keyword: key,
                params: {},
              }));
            });
          }
          return [];
        });

        const uniqueErrorMessages = new Set<string>();

        const errorsMessage = errors.errors.reduce((text: string, item: any) => {
          console.log(item);
          if (
            !item.extensions.exception?.validationErrors &&
            !uniqueErrorMessages.has(item.message) &&
            item.message.includes("Invalid param")
          ) {
            uniqueErrorMessages.add(item.message);

            return text + item.message + "\n";
          }
          return text;
        }, "");

        setAdditionalErrors((old) => {
          const existingMessages = new Set(old.map((error) => error.message));
          const filteredNewErrors = newErrors.filter(
            (error: any) => !existingMessages.has(error.message)
          );

          return [
            ...old,
            ...filteredNewErrors,
            {
              keyword: "generalError",
              instancePath: "",
              message: errorsMessage,
              schemaPath: "",
              params: {},
            },
          ];
        });
      }
    } catch (_) {}
  };

  const handleClickSave = async () => {
    setInternalLoading(true);
    validate()
      .then((data) => {
        onSave(data)
          .then((response) => {
            handleSuccessSave(response);
            setInternalLoading(false);
            setAdditionalErrors([]);
          })
          .catch((e) => {
            try {
              console.error({
                SaveError: e,
                responseError: JSON.parse(e?.response?.request ?? ""),
              });
            } catch (_) {}
            handleFailSave(e);
            setInternalLoading(false);
            handleAdditionalErrors(e);
          });
      })
      .catch((e) => {
        if (e?.message !== "Error in extraValidation function!") {
          console.error({ validationError: e, state });
        }
        handleFailSave(e);
        setInternalLoading(false);
      });
  };

  return (
    <>
      <JsonForm
        schemaJson={template?.schemaJson}
        UISchema={template?.UISchema}
        ajv={handleDefaultsAjv}
        data={state?.data || {}}
        onChange={onChangeForm}
        error={state?.criticalError}
        additionalErrors={additionalErrors}
        loading={state?.loading || internalLoading || (!template && !state?.criticalError)}
        disabled={disabled || state?.loading || internalLoading}
        i18n={{
          translateError: handleTranslateErrors as unknown as ErrorTranslator,
        }}
        boxProps={{
          width: "100%",
          display: "block",
        }}
        {...props}
      >
        {!disabled && (
          <MDBox {...boxProps}>
            {children}
            <MDBox>
              <MDButton
                {...buttonProps}
                onClick={handleClickSave}
                loading={buttonProps?.loading || state?.loading || internalLoading}
                disabled={
                  buttonProps?.disabled ||
                  buttonProps?.loading ||
                  (permissions?.create !== undefined ? !permissions.create : false)
                }
                sx={{
                  "&:disabled": {
                    opacity: 0.8,
                    color: "#ccc",
                    borderColor: "#ccc",
                  },
                }}
              />
            </MDBox>
          </MDBox>
        )}

        <FormHelperText error sx={{ textAlign: "end", paddingRight: 1, paddingBottom: 1 }}>
          {state?.messageError}
        </FormHelperText>

        <MDSnackbar
          open={showMessageSuccess}
          title={messageSuccess?.title || "Salvo com successo"}
          content={messageSuccess?.content}
          autoHideDuration={5000}
          onClose={handleCloseMessageSuccess}
          close={handleCloseMessageSuccess}
          color="success"
          icon="check"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
        <MDSnackbar
          open={showMessageFail}
          title={messageFail?.title || failMessageDefault.title}
          content={messageFail?.content || showErrors() || failMessageDefault?.content}
          autoHideDuration={5000}
          // onClose={messageFail?.onClose}
          // close={messageFail?.close || messageFail?.onClose}
          onClose={handleCloseMessageFail}
          close={handleCloseMessageFail}
          color="error"
          icon="priority_high"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
      </JsonForm>
      {afterForm}
      {!disabled &&
      !hideAfterButtons &&
      !(state?.loading || internalLoading || (!template && !state?.criticalError)) ? (
        <MDBox paddingBottom={2} {...boxProps}>
          {children}
          <MDBox>
            <MDButton
              {...buttonProps}
              onClick={handleClickSave}
              loading={buttonProps?.loading || state?.loading || internalLoading}
              disabled={
                buttonProps?.disabled ||
                buttonProps?.loading ||
                (permissions?.create !== undefined ? !permissions.create : false)
              }
              sx={{
                "&:disabled": {
                  opacity: 0.8,
                  color: "#ccc",
                  borderColor: "#ccc",
                },
              }}
            />
          </MDBox>
        </MDBox>
      ) : (
        <></>
      )}
    </>
  );
}

// default export
export default JsonFormsComponent;
