import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { Colligate } from "../types";

export const GET_ITEM_QUERY = print(gql`
  query CategoryConversion($id: Int!) {
    CategoryConversion(id: $id) {
      id
      colligate {
        id
        databaseName
        description
        active
        partnerVisibleName
        createdAt
        updatedAt
      }
      idColligate
      databaseName
      idCourseType
      idBranch
      categorySlug
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  CategoryConversion: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;
    idCourseType: number;
    idBranch: number;
    categorySlug: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
