import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const READ_QUEUE_QUERY = print(gql`
  mutation ReadSQS($data: ReadQueueInput) {
    ReadSQS(data: $data) {
      result
      messageWasDeleted
      failedReason
      errorMessage
      error
      message {
        Body
        MessageId
        ReceiptHandle
      }
    }
  }
`);
export type Message = {
  result: "Failed" | "Successful";
  messageWasDeleted: boolean;
  failedReason?:
    | "Function Fail"
    | "No ReceiptHandle"
    | "Delete Message Fail"
    | "Message Expiration Rules"
    | "Action Invalid";
  errorMessage?: string;
  error?: string;
  message: {
    MessageId?: string;
    Body?: string;
    ReceiptHandle?: string;
  };
};
export const responseReadQueueKey = "ReadSQS";
export interface ReadQueueGql {
  ReadSQS: Message[];
}
