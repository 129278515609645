import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query CourseGroups($page: Int, $perPage: Int, $search: String, $active: Boolean) {
    CourseGroups(page: $page, perPage: $perPage, search: $search, active: $active) {
      items {
        id
        name
        active
        category {
          name
        }
        partner {
          name
        }
        teachingArea {
          name
        }
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  name: string;
  category: {
    name: string;
  };
  partner: {
    name: string;
  };
  teachingArea: {
    name: string;
  };
  active: boolean;
}

export interface GetAllGql {
  CourseGroups: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
