import { useState, useEffect } from "react";
import { ApiCMSRequest } from "data/ApiCMS";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { JsonFormsComponent } from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { userPermissionsProps } from "hooks/types";
import { userPermissions } from "hooks/Permissions";

type TemplateSchema = {
  id: number;
  name: string;
  schemaJson: string;
  UISchema: string;
};

type QueryComponentResponse = {
  id: number;
  name: string;
  contentJSON: string;
  templateSchema: TemplateSchema;
};

function FormCMSComponent<ContentJsonType = any>({
  nameComponent,
  actionsPermission = {},
}: {
  nameComponent: string;
  actionsPermission?: userPermissionsProps;
}): JSX.Element {
  const [template, setTemplate] = useState<TemplateSchema>();
  const [state, setState] = useState<JsonFormsStateProps<ContentJsonType>>();
  const permission = userPermissions(actionsPermission);

  const handleSave = async () => {
    const response = await ApiCMSRequest({
      query: `mutation UpdateComponent($data: UpdateComponentInput!) {
        updateComponent(data: $data) {
          contentJSON
          id
          name
        }
      }`,
      variables: {
        data: {
          contentJSON: JSON.stringify(state?.data),
          name: nameComponent,
        },
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data) {
      throw new Error("Ops. Sem resposta!");
    }

    const contentJson = response.data.data?.updateComponent?.contentJSON;
    if (contentJson !== undefined) {
      setState(({ data, ...prev }) => ({ ...prev, data: JSON.parse(contentJson) }));
    }
    return response;
  };

  useEffect(() => {
    if (!!nameComponent) {
      ApiCMSRequest<{ component: QueryComponentResponse }>({
        query: `query Component($name: String!) {
        component(name: $name) {
          id
          name
          contentJSON
          templateSchema {
            id
            name
            schemaJson
            UISchema
          }
        }
      }`,
        variables: { name: nameComponent },
      })
        .then((response) => {
          setState((prev) => ({ ...prev, criticalError: undefined })); //remove error
          if (!response?.data?.data?.component) {
            throw new Error("Component not found", {
              cause: { response: response?.data || response },
            });
          }
          const { templateSchema, contentJSON } = response?.data?.data?.component;
          // Get template and content from backend
          setTemplate(templateSchema);
          setState(({ data, ...prev }) => ({ ...prev, data: JSON.parse(contentJSON) }));
        })
        .catch((error) => {
          console.error({ saveErro: error });
          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [nameComponent]);

  if (!nameComponent) return null;

  return (
    <JsonFormsComponent
      template={template}
      state={state}
      onChange={setState}
      onSave={handleSave}
      permissions={permission}
    />
  );
}

export default FormCMSComponent;
