import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useState } from "react";
import JsonFormsComponent from "components/CRPComponents/JsonForm/JsonFormsComponent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { GenerictMethodsCreateProps } from "./types";
import StepperComponent from "./Stepper";

function Create<IJsonFormState, CreateGql>({
  useCreate,
  template,
  siteId,
  sitePath,
  showNavBar = true,
  onSuccess,
  state: controlledState,
  setState: controlledSetState,
  children,
  buttonSaveLabel,
  stepper,
  afterForm,
  hideAfterButtons,
}: GenerictMethodsCreateProps<IJsonFormState, CreateGql>) {
  const [state, setState] = useState<JsonFormsStateProps<IJsonFormState>>();

  const { handleSave, messageSuccess } = useCreate({ siteId, sitePath, onSuccess });

  return (
    <DashboardLayout>
      {showNavBar && <DashboardNavbar />}
      {!!stepper && <StepperComponent {...stepper} />}
      <JsonFormsComponent
        template={template}
        state={controlledState ?? state}
        onChange={controlledSetState ?? setState}
        onSave={() => handleSave(controlledState ?? state)}
        buttonProps={
          !buttonSaveLabel
            ? undefined
            : { children: buttonSaveLabel, variant: "outlined", color: "info" }
        }
        messageSuccess={messageSuccess}
        afterForm={afterForm}
        hideAfterButtons={hideAfterButtons}
      >
        {children}
      </JsonFormsComponent>
      {showNavBar && <Footer />}
    </DashboardLayout>
  );
}

export default Create;
