import { useMemo } from "react";

// react-table components
import { useTable } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "../DataTable/DataTableHeadCell";
import DataTableBodyCell from "../DataTable/DataTableBodyCell";
import MDTypography from "components/MDTypography";
import Skeleton from "@mui/material/Skeleton";

function DataTableControled({
  table,
  pagination,
  onChangePage,
  sortedProps,
  onSorted,
  showTotalEntries,
  loading,
}: Props): JSX.Element {
  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows }: any = tableInstance;

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    onChangePage(newPage);
  };
  return (
    <TableContainer sx={{ boxShadow: "none" }} className="table-container">
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups?.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column: any, key: any) => (
                <DataTableHeadCell
                  key={key}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={column.isSorted ? sortedProps[column.id] || "none" : false}
                  onClick={!onSorted ? undefined : () => onSorted(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {loading
            ? [...Array(10)].map((e: any, i) => (
                <TableRow key={`skeletonRow${i}`} sx={{ padding: "10px" }}>
                  {[...Array(headerGroups?.[0]?.headers?.length || 10)].map((e: any, i: number) => (
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={({
                        palette: { light },
                        typography: { size },
                        borders: { borderWidth },
                      }: Theme) => ({
                        fontSize: size.sm,
                        borderBottom: `${borderWidth[1]} solid ${light.main}`,
                      })}
                      key={`skeletonCell${i}`}
                    >
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </MDBox>
                  ))}
                </TableRow>
              ))
            : rows?.map((row: any, key: any) => {
                prepareRow(row);
                return (
                  <TableRow key={key} {...row.getRowProps()}>
                    {row.cells?.map((cell: any, key: any) => (
                      <DataTableBodyCell
                        key={key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={
          showTotalEntries && pagination?.totalData > -1 ? "space-between" : "flex-end"
        }
        alignItems={{ xs: "flex-start", sm: "center" }}
        padding="10px 20px"
      >
        {showTotalEntries && pagination?.totalData > -1 && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              {pagination.totalData === 1
                ? `Foi encontrado 1 registro!`
                : `Foram encontrados ${pagination.totalData} registros!`}
            </MDTypography>
          </MDBox>
        )}
        {pagination?.totalPages > 1 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            onChange={handleChangePage}
          />
        )}
      </MDBox>
    </TableContainer>
  );
}

// Declaring props types for DataTable
interface Props {
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  pagination?: {
    page: number;
    perPage: number;
    totalData: number;
    totalPages: number;
  };
  showTotalEntries?: boolean;
  onChangePage?: (page: number) => void;
  sortedProps?: {
    [columnAcessor: string]: "none" | "asce" | "desc";
  };
  onSorted?: (prop: { id: string; [key: string]: any }) => void;
  loading?: boolean;
}

// Declaring default props for DataTable
DataTableControled.defaultProps = {
  showTotalEntries: true,
  sortedProps: {},
  loading: false,
};

export default DataTableControled;
