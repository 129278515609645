import { path } from "./hooks/types";
import Create from "./Create";
import List from "./List";

export const ProviderHabilitation = {
  path: path,
  List: List,
  Create: Create,
  Permissions: {
    sync: "sync:ProviderCourse",
    list: "list:ProviderHabilitation",
    // findManyProviderCourses: 'list:ProviderCourse',
    // findOneProviderCourse: 'read:ProviderCourse',
    // findOneProviderHabilitation: 'read:ProviderHabilitation',
  },
};
