import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateBlog($data: UpdateBlogInput!) {
    updateBlog(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateBlog: {
    id: number;
  };
}
