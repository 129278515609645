import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useCallback, useEffect, useState } from "react";

export const QUERY_ALL = print(gql`
  query TemplateSchemas {
    templateSchemas(typeTemplateSchema: COURSE, perPage: -1) {
      items {
        name
      }
    }
  }
`);

export interface QueryAllGql {
  templateSchemas: {
    items: Array<{
      name: string;
    }>;
  };
}

export const QUERY = print(gql`
  query TemplateSchema($name: String!) {
    templateSchema(name: $name) {
      UISchema
      schemaJson
    }
  }
`);

export interface QueryGql {
  templateSchema: {
    UISchema: string;
    schemaJson: string;
  };
}

export default function useTemplates() {
  const [templates, setTemplates] = useState<null | QueryAllGql["templateSchemas"]["items"]>(null);

  useEffect(() => {
    ApiCourseRequest<QueryAllGql>({
      query: QUERY_ALL,
    })
      .then(({ data }) => setTemplates(data.data.templateSchemas.items))
      .catch((e) => console.log(e));
  }, []);

  const getTemplate = useCallback(
    async (name: string) =>
      ApiCourseRequest<QueryGql>({
        query: QUERY,
        variables: { name },
      }),
    []
  );

  return {
    templates,
    setTemplates,
    getTemplate,
  };
}
