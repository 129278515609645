import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_OR_UPDATE_QUERY = print(gql`
  mutation CreateOrUpdatePromotion($data: CreateOrUpdatePromotionInput!) {
    createOrUpdatePromotion(data: $data) {
      idCalculatorConfiguration
    }
  }
`);

export interface CreateOrUpdateGql {
  createOrUpdatePromotion: {
    idCalculatorConfiguration: string;
  };
}
