import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiAuthWithSetData } from "data/ApiAuth";

function List() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          search: null,
        }}
        path={`/configuracoes/permissoes`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.findManyRoles}
        formatPagination={() => [] as any}
        title="NOVA PERMISSÃO"
        ApiSetData={ApiAuthWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`/configuracoes/permissoes/${value}`} value={value} />
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Descrição",
            accessor: "description",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
