import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateEvent($data: UpdateEventInput!) {
    updateEvent(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateEvent: {
    id: number;
  };
}
