import { useParams } from "react-router-dom";
import FormUpdateBanner from "components/CRPComponents/Forms/FormBanner/FormUpdateBanner";
import ContainerForm from "components/CRPComponents/ContainerForm";
import { UpdateProps } from "./types";

function Update({ idSite, path, titleBox = "adicionar banner +", inicialEditing }: UpdateProps) {
  const { id } = useParams();
  return (
    <ContainerForm
      componenteRender={
        <FormUpdateBanner
          titleBox={titleBox}
          idSite={idSite}
          bannerId={Number(id)}
          inicialEditing={inicialEditing}
          path={path}
        />
      }
    />
  );
}

export default Update;
