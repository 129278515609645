/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React TS SideBarMenu
import { SideBarMenu } from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useAuth } from "context/useUserAuth";

export interface Route {
  collapse?: Route[];
  route?: string;
  component?: ReactElement<any, string | JSXElementConstructor<any>>;
  key: Key;
  Permission?: string;
  permission?: string;
  type?: string;
  name?: string;
  title?: string;
  icon?: ReactElement<any, string | JSXElementConstructor<any>>;
  href?: string;
  noCollapse?: boolean;
}

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { user } = useAuth();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: Route[]): JSX.Element[] => {
    return allRoutes
      .flatMap((route: Route) => {
        const { route: path, component, key, collapse, ...rest } = route;
        const routePermission = rest.permission ?? rest.Permission;
        if (path) {
          if (routePermission && !user?.permissions?.some(({ name }) => name === routePermission)) {
            return null;
          }
          return <Route path={path} element={component} key={key} />;
        }

        if (collapse) {
          return getRoutes(collapse);
        }

        return null;
      })
      .filter(Boolean);
  };

  const getSideBarMenu = (allRoutes: Route[]): any => {
    const filterRoutes = (routes: Route[]): Route[] => {
      return routes.reduce((filtered, route) => {
        const routePermission = route.permission ?? route.Permission;
        if (route?.type === "collapse" && route.collapse) {
          const collapsedRoutes = filterRoutes(route.collapse);
          if (collapsedRoutes.length > 0) {
            filtered.push({ ...route, collapse: collapsedRoutes });
          }
        } else if (
          !routePermission ||
          user?.permissions?.some(({ name }) => name === routePermission)
        ) {
          filtered.push(route);
        }
        return filtered;
      }, [] as Route[]);
    };

    return filterRoutes(allRoutes);
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName=""
            routes={getSideBarMenu(SideBarMenu)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(SideBarMenu)}
        {/* {getRoutes(ExtraRoutes)} */}
        <Route path="*" element={<Navigate to="/perfil/visao-geral" />} />
      </Routes>
    </ThemeProvider>
  );
}
