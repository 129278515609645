import List from "./List";
import Update from "./Update";

export const GlobalKeys = {
  List: List,
  Update: Update,
  Permissions: {
    create: "create:Global",
    read: "read:Global",
    list: "list:Global",
    update: "update:Global",
    delete: "delete:Global",
  },
};
