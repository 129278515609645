import { removeNullValues } from "utils/removeNullValues";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiRequest, SchemaData, UseUpdateProps } from "./types";

export default function useUpdate({ queryParam, setState }: UseUpdateProps<SchemaData>) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const {
        prices,
        id,
        category,
        urlInscription,
        urlInscriptionDefault,
        active,
        activateIfPossible,
        ...data
      } = state?.data;

      const response = await ApiRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            ...data,
            urlInscription:
              category?.department?.templateInvestment?.allowPurchase === false
                ? urlInscription
                : null,
            activateIfPossible,
            id: queryParam,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      setState((old) => ({ ...old, data: removeNullValues(response.data.data.updateCourse) }));
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
