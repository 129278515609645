import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Prices($page: Int, $perPage: Int, $search: String) {
    Prices(page: $page, perPage: $perPage, search: $search) {
      items {
        id
        courseId
        value
        valueWithoutDiscout
        active
        installments
        eCommerceSkuId
        courseId
        commercialConditionId
        valueOtherInstallments
        urlInscription
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

/** Type each item in response data*/
export interface GetAllItem {
  id: number;
  active: boolean;
  value: number;
  valueWithoutDiscout?: number;
  installments: number;
  eCommerceSkuId?: number;
  courseId: number;
  commercialConditionId?: number;
  valueOtherInstallments?: number;
  urlInscription?: string;
}

export const responseGetAllKey = "Prices";

/** Type response data */
export interface GetAllGql {
  Prices: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
