import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdatePartner($data: UpdatePartnerInput!) {
    updatePartner(data: $data) {
      id
      name
      visibleName
      description
      imageUrl
      active
      cnpj
      phone
      email
      site
      createdAt
      updatedAt
    }
  }
`);

export interface UpdateGql {
  updatePartner: {
    id: number;
  };
}
