import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { MDSnackbarProps } from "components/MDSnackbar";
import { ShemaJsonTemplate } from "types/SchemaJson";
import { ApiGetCouseERPRequest, ApiGetCouseERPWithSetData } from "data/queries/ApiERP";

export interface UseGetItemProps<T> {
  setTemplate: React.Dispatch<React.SetStateAction<ShemaJsonTemplate>>;
  setState: React.Dispatch<React.SetStateAction<JsonFormsStateProps<T>>>;
  queryParam: number | string;
}

export interface UseDeleteProps {
  queryParam: number | string;
  setSnackBarInfo: React.Dispatch<React.SetStateAction<Omit<MDSnackbarProps, "close" | "onClose">>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UseUpdateProps {
  queryParam: number | string;
}

export const ApiRequest = ApiGetCouseERPRequest;
export const ApiWithSetData = ApiGetCouseERPWithSetData;

export interface SchemaData {
  id: number;
  databaseName: string;
  description?: string;
  partnerVisibleName: string;
  active: boolean;
}

export const path = "/integracao/coligada";
