import Icon from "@mui/material/Icon";
import { Route } from "App";
import MidiasList from "layouts/midias";
import { Permissions } from "layouts/midias/permissions";

export const mediasRoutes: Route[] = [
  { type: "title", title: "Midias", key: "Midias", permission: Permissions.list },
  {
    type: "collapse",
    name: "Midias",
    key: "midias",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/midia",
    component: <MidiasList />,
    noCollapse: true,
    permission: Permissions.list,
  },
  { type: "divider", key: "divider-mediasRoutes" },
];
