import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback } from "react";
import { SchemaData, UseCreateProps } from "./types";
import { CreateGql, CREATE_QUERY } from "../queries/create";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useNavigate } from "react-router-dom";
import { removeNullValues } from "utils/removeNullValues";

export default function useCreate(props: UseCreateProps) {
  const navigate = useNavigate();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const { isSubCategory, ...data } = state?.data;

    const response = await ApiCourseRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data: removeNullValues({
          ...data,
          parentId: isSubCategory ? data?.parentId : undefined,
          templateSchemaName: isSubCategory ? undefined : data?.templateSchemaName,
        }),
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data || !response?.data?.data?.createCategory?.id) {
      throw new Error("Ops. Sem resposta!");
    }

    if (props?.onSuccess) {
      props.onSuccess(response.data.data);
    } else {
      navigate(`/ecommerce/nivel-de-ensino/${response.data.data.createCategory.id}`);
    }

    return response;
  }, []);

  return {
    handleSave,
  };
}
