import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query PopUps($page: Int, $perPage: Int, $search: String, $siteId: Int, $status: String) {
    PopUps(page: $page, perPage: $perPage, search: $search, siteId: $siteId, status: $status) {
      items {
        id
        name
        status
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  name: string;
  status: string;
}

export interface GetAllGql {
  PopUps: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
