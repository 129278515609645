import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { MDSnackbarProps } from "components/MDSnackbar";
import { ShemaJsonTemplate } from "types/SchemaJson";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { GetItemGql } from "../queries/getItem";

export interface UseGetItemProps<T> {
  setTemplate: React.Dispatch<React.SetStateAction<ShemaJsonTemplate>>;
  setState: React.Dispatch<React.SetStateAction<JsonFormsStateProps<T>>>;
  queryParam: number | string;
}

export interface UseDeleteProps {
  queryParam: number | string;
  setSnackBarInfo: React.Dispatch<React.SetStateAction<Omit<MDSnackbarProps, "close" | "onClose">>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UseUpdateProps<T> {
  queryParam: number | string;
  setState: React.Dispatch<React.SetStateAction<JsonFormsStateProps<T>>>;
}

export const ApiRequest = ApiFinancialRequest;

export type SchemaData = GetItemGql["Course"];

export const path = "/ecommerce/produtos";
