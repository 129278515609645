import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

function ContainerForm({ componenteRender, title, label }: any): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="#f8f9fa" py={3} mb={20} borderRadius="lg" height="100%" padding="0 16px">
        <MDBox
          bgColor="#f8f9fa"
          py={3}
          mb={20}
          borderRadius="lg"
          height="auto"
          marginBottom="0px"
          display="flex"
          flexDirection="column"
        >
          <Step>{title}</Step>
          <MDBox
            bgColor="#f8f9fa"
            py={3}
            mb={20}
            borderRadius="lg"
            height="auto"
            marginBottom="0px"
          >
            <StepLabel>{label}</StepLabel>
            {componenteRender}
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ContainerForm;
