import { useEffect, useState } from "react";
import schemaJson from "../schema/SchemaJson.json";
import useCategories from "./useCategories";
import useTemplates from "./useTemplates";

export default function useTemplate() {
  const [templateSchema, setTemplateSchema] = useState<string>();
  const { categories } = useCategories();
  const { templates, getTemplate } = useTemplates();

  useEffect(() => {
    if (!categories || !templates) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.parentId = {
      ...schemaJsonParsed.properties.parentId,
      ...(categories.length
        ? {
            oneOf: categories.map(({ name, id }) => ({
              title: name,
              const: id,
            })),
          }
        : { readOnly: true }),
    };

    schemaJsonParsed.properties.templateSchemaName = {
      ...schemaJsonParsed.properties.templateSchemaName,
      ...(templates.length
        ? {
            enum: templates.map(({ name }) => name),
          }
        : { readOnly: true }),
    };

    setTemplateSchema(JSON.stringify(schemaJsonParsed));
  }, [categories]);

  return {
    templateSchema,
    setTemplateSchema,
    getTemplate,
    categories,
  };
}
