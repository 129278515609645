import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { default as MuiModal, ModalProps as MuiModalProps } from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Row from "components/Row";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "95%",
  maxHeight: "95%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
export interface ModalProps extends MuiModalProps {
  title: string;
  onCloseButton?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export function Modal({
  title,
  children,
  onClose,
  onCloseButton = (e) => onClose(e, "backdropClick"),
  ...props
}: ModalProps) {
  return (
    <MuiModal
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <Box sx={style}>
        <Row justifyContent="space-between">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {title}
          </Typography>
          <IconButton type="button" onClick={onCloseButton}>
            <CloseIcon />
          </IconButton>
        </Row>
        {children}
      </Box>
    </MuiModal>
  );
}

export default Modal;
