import { print } from "graphql";
import { gql } from "@apollo/client";

export const GET_ALL_QUERY = print(gql`
  query Forms($formType: FormTypeEnum, $perPage: Int) {
    Forms(formType: $formType, perPage: $perPage) {
      items {
        id
        slug
      }
    }
  }
`);

export interface QueryArgs {
  status: StatusEnum;
  formType: FormTypeEnum;
  perPage: number;
}

export type FormTypeEnum = "DINAMIC" | "EVENT";

export type StatusEnum = "ACTIVE" | "DRAFT" | "INACTIVE";

export interface GetAllGql {
  Forms: {
    items: {
      id: number;
      slug: string;
    }[];
  };
}
