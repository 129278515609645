import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback } from "react";
import { SchemaData, UseCreateProps } from "./types";
import { CREATE_PAYMENT_METHOD, createPaymentMethod } from "../queries/createPaymentMethod";
import { useNavigate } from "react-router-dom";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

export default function useCreate(props: UseCreateProps) {
  const navigate = useNavigate();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const data: any = {
      ...state.data,
      name: state.data.name,
      enabled: Boolean(Number(state.data.enabled)),
      paymentSystem: state.data.paymentSystem,
      connector: {
        affiliationId: state.data.connector,
      },
      installmentsService: state.data.installmentsService === "À vista" ? false : true,
      externalInterest: state.data.externalInterest === "Inativo" ? false : true,
    };

    delete data.hasInstallmentTax;

    if (state.data.installmentsService === "À vista") {
      delete data.installmentOptions;
    }

    const response = await ApiFinancialRequest<createPaymentMethod>({
      query: CREATE_PAYMENT_METHOD,
      variables: {
        data,
      },
    });

    if (response.data.data.createPaymentMethod.id) {
      navigate("/ecommerce/metodos-de-pagamentos");
      return "Criado com sucesso!";
    }

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
  }, []);

  return {
    handleSave,
  };
}
