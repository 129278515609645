import useCreate from "./hooks/useCreate";
import schemaJson from "./schema/SchemaJson.json";
import UISchemaJson from "./schema/UISchema.json";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import useEducationalGroups from "./hooks/useEducationalGroups";
import { ShemaJsonTemplate } from "types/SchemaJson";
import { useEffect, useState } from "react";
import { CreateProps } from "./types";

function Create(props: CreateProps) {
  const { educationalGroups } = useEducationalGroups();
  const [template, setTemplate] = useState<ShemaJsonTemplate | null>(null);

  useEffect(() => {
    if (!educationalGroups) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.educationalGroupId = {
      ...schemaJsonParsed.properties.educationalGroupId,
      oneOf: educationalGroups.map(({ id, title }) => ({
        const: id,
        title,
      })),
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });
  }, [educationalGroups]);

  if (!template) return null;

  return (
    <GenericMethods.Create<SchemaData, CreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      useCreate={useCreate}
      template={template}
      {...props}
    />
  );
}

export default Create;
