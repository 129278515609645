import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeleteModalityConversion($deleteModalityConversionId: Int!) {
    deleteModalityConversion(id: $deleteModalityConversionId) {
      id
    }
  }
`);

export interface DeleteGql {
  deleteModalityConversion: {
    id: number;
  };
}
