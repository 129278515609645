import { useCallback, useState } from "react";
import { UseDeleteProps } from "./types";
import { DELETE_QUERY, DeleteGql } from "../queries/delete";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

const useDelete = ({ queryParam, setSnackBarInfo, setEditing }: UseDeleteProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteError = (e: Error) => {
    setSnackBarInfo({
      open: true,
      color: "error",
      title: "Ops, algo deu errado",
      content: e?.message || "Ops. Algo Deu errado",
      autoHideDuration: null,
    });
  };

  const handleDeleteSuccess = () => {
    setEditing(false);
    setSnackBarInfo({
      open: true,
      color: "success",
      icon: "check",
      autoHideDuration: null,
      title: "Deletado com sucesso",
      redirectid: -1,
    });
  };

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiFinancialRequest<DeleteGql>({
        query: DELETE_QUERY,
        variables: { id: queryParam },
      });

      if (!response?.data?.data?.deletePartnerCompany?.id) {
        if (response.data?.errors?.[0]) throw response.data?.errors?.[0];
        throw new Error(JSON.stringify(response?.data || "Algo deu errado!"));
      }

      handleDeleteSuccess();
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      handleDeleteError(error);
    }
  }, [queryParam]);

  return {
    handleDelete,
    handleDeleteSuccess,
    handleDeleteError,
  };
};

export default useDelete;
