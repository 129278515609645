import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation UpsertPrice($data: CreatePriceWithAdmissionWaInput!) {
    upsertPrice(data: $data) {
      id
      courseId
    }
  }
`);

export const responseCreateKey = "upsertPrice";
export interface CreateGql {
  upsertPrice: {
    id: number;
    courseId: number;
  };
}
