export function removeNullValues(obj: any): any {
  // Verifica se o objeto é um array ou objeto simples
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNullValues(item));
  } else if (typeof obj === "object" && obj !== null) {
    const newObj: any = {};

    for (const key in obj) {
      // Verifica se o valor não é nulo
      if (obj[key] !== null) {
        // Verifica se o valor é um objeto ou array e faz chamada recursiva
        newObj[key] = removeNullValues(obj[key]);
      }
    }

    return newObj;
  }

  // Caso seja um valor primitivo, retorna diretamente
  return obj;
}
