import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { Handler } from "../types";

type EducationalUnitsReponse = {
  EducationalUnits: {
    items: Array<{
      id: number;
      visibleName: string;
      active: boolean;
    }>;
  };
};
type EducationalUnitReponse = {
  EducationalUnit: {
    id: number;
    visibleName: string;
    active: boolean;
  };
};
const EducationalUnitHandler: Handler<EducationalUnitsReponse, EducationalUnitReponse> = {
  query: print(gql`
    query EducationalUnits($search: String) {
      EducationalUnits(search: $search, active: null) {
        items {
          id
          visibleName
          active
        }
      }
    }
  `),
  formater: (data) =>
    data.EducationalUnits.items.map((item) => ({
      id: item.id,
      label: item.visibleName,
      active: item.active,
    })),
  api: ApiCourseRequest,
  queryId: print(gql`
    query EducationalUnit($id: Int!) {
      EducationalUnit(id: $id) {
        id
        visibleName
        active
      }
    }
  `),
  formaterId: (data) => ({
    id: data.EducationalUnit.id,
    label: data.EducationalUnit.visibleName,
    active: data.EducationalUnit.active,
  }),
};

export default EducationalUnitHandler;
