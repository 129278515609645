import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query FacultyMember($id: Int!) {
    FacultyMember(id: $id) {
      id
      name
      description
      photoUrl
      active
      title
      email
      lattesUrl
      idInternMember
      isCoordinator
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  FacultyMember: {
    id: number;
    name: string;
    description: string;
    photoUrl: string;
    title: string;
    email: string;
    lattesUrl: string;
    idInternMember: string;
    createdAt: string;
    updatedAt: string;
    isCoordinator: boolean;
    active: boolean;
  };
}
