import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormUpdateFaq from "components/CRPComponents/Forms/FormFaq/FormUpdateFaq";

function UpdateFaqUniftec({ inicialEditing = false }: { inicialEditing?: boolean }): JSX.Element {
  let { id } = useParams();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!id ? (
        <h1> Erro Id </h1>
      ) : (
        <FormUpdateFaq
          idFaq={Number(id)}
          inicialEditing={true}
          path="/uniftec"
          siteId={1}
          includeCategory={true}
        />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateFaqUniftec;
