import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateHoliday($data: UpdateHolidayInput!) {
    updateHoliday(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateHoliday: {
    id: number;
  };
}
