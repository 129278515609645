import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query FindOneRole($id: Int!) {
    findOneRole(id: $id) {
      id
      name
      description
      permissions {
        id
      }
    }
  }
`);

export interface GetItemGql {
  findOneRole: {
    id: number;
    name: string;
    description: string;
    permissions: Array<{
      id: number;
    }>;
  };
}
