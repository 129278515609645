import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Redirect($id: Int!) {
    Redirect(id: $id) {
      id
      urlFrom
      urlTo
      redirectCode
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  Redirect: {
    id: number;
    urlFrom: string;
    urlTo: string;
    redirectCode: number;
    createdAt: Date;
    updatedAt: Date;
  };
}
