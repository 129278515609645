import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query InvestmentInfos($page: Int, $perPage: Int, $search: String) {
    InvestmentInfos(page: $page, perPage: $perPage, search: $search) {
      items {
        id
        title
        subtitle
        description
        observation
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

/** Type each item in response data*/
export interface GetAllItem {
  id: number;
  title: string;
  subtitle?: string;
  description?: string;
  observation?: string;
}

export const responseGetAllKey = "InvestmentInfos";

/** Type response data */
export interface GetAllGql {
  InvestmentInfos: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
