import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ApiFinancialRequest } from "data/ApiFinancial";
import { Sites } from "data/utils/globals";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { groupBy } from "lodash";
import { Fragment, useEffect, useState } from "react";

import AddCardIcon from "@mui/icons-material/AddCard";
import { Card, Grid } from "@mui/material";

import useCreate from "./hooks/useCreate";
import { createPaymentMethod } from "./queries/createPaymentMethod";
import { GET_PAYMENT_PROVIDERS, PaymentProvidersGQL } from "./queries/paymentsProvides";

import schemaJson from "./schemas/SchemaJson.json";
import UISchemaJson from "./schemas/UISchema.json";

import type { SchemaData } from "./hooks/types";
import type { CreateProps, IHandleSetPayment, PaymentMethod, PaymentMethods } from "./types";

function Create(props: CreateProps) {
  const [paymentsProviders, setPaymentsProviders] = useState<PaymentMethods>({} as PaymentMethods);
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>({});

  const handleSetPayment = ({ id, name, implementation }: IHandleSetPayment) => {
    setState({
      data: {
        paymentSystem: {
          id,
          name,
          implementation,
        },
        name: "",
        enabled: "",
        connector: "",
        installmentsService: "",
        externalInterest: "",
      },
    });
  };

  useEffect(() => {
    ApiFinancialRequest<PaymentProvidersGQL>({
      query: GET_PAYMENT_PROVIDERS,
    })
      .then((response) => {
        if (!response?.data?.data?.PaymentProviders) {
          throw new Error("Não encontrado", {
            cause: { response: response?.data || response },
          });
        }
        if (response.data.data?.PaymentProviders) {
          const data = groupBy(response.data.data?.PaymentProviders, "groupName");
          setPaymentsProviders(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);

  if (!state?.data?.paymentSystem?.id) {
    return (
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography variant="h6" gutterBottom sx={{ textAlign: "center", width: "100%" }}>
            Adicionar nova condição de pagamento para...
          </MDTypography>
        </MDBox>

        {Object.entries(paymentsProviders)?.map(([key, values]: [string, PaymentMethod[]]) => (
          <Fragment key={key}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <MDTypography variant="h6" gutterBottom sx={{ textAlign: "center", width: "100%" }}>
                {key}
              </MDTypography>
            </MDBox>

            <Grid container spacing={3} justifyContent="center">
              {values.map((value) => (
                <Grid key={value.id} item xs={12} md={6} lg={4} justifyContent="center">
                  <MDBox
                    mb={1.5}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleSetPayment({
                        id: value.id,
                        name: value.name,
                        implementation: value.implementation,
                      })
                    }
                  >
                    <Card>
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={2}
                        pb={0}
                      >
                        <AddCardIcon />
                      </MDBox>
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={2}
                        pb={1}
                      >
                        <MDBox>
                          <MDTypography variant="h6" gutterBottom>
                            {value.name}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </Fragment>
        ))}
      </DashboardLayout>
    );
  }

  return (
    <Fragment>
      <GenericMethods.Create<SchemaData, createPaymentMethod>
        siteId={Sites.UNIFTEC.id}
        sitePath={Sites.UNIFTEC.path}
        useCreate={useCreate}
        template={{
          schemaJson: schemaJson,
          UISchema: UISchemaJson,
        }}
        state={state}
        setState={setState}
        {...props}
      />
    </Fragment>
  );
}

export default Create;
