export const schemaJson = {
  type: "object",
  properties: {
    title: {
      type: "string",
    },
    tag: {
      type: "string",
    },
    admissionWayId: {
      type: "integer",
    },
  },
  required: ["title", "admissionWayId"],
};

export const schemaJsonEdit = {
  type: "object",
  properties: {
    title: {
      type: "string",
    },
    tag: {
      type: "string",
    },
    admissionWayId: {
      type: "integer",
    },
  },
  required: ["title"],
};
