import { gql } from "@apollo/client";
import { ApiCMSRequest, getImageCmsURL } from "data/ApiCMS";
import { print } from "graphql";
import { useEffect, useState } from "react";

const query = print(gql`
  query Query($id: Int!) {
    mediaFile(id: $id) {
      path
    }
  }
`);

interface ItemGql {
  mediaFile: {
    path: string;
  };
}

export default function useMediaGallery(id: number | undefined) {
  const [url, setUrl] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!id || (id && url)) return;
    setLoading(true);

    ApiCMSRequest<ItemGql>({ query, variables: { id } })
      .then(({ data }) => {
        if (data.data.mediaFile.path) setUrl(getImageCmsURL(data.data.mediaFile.path));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [id]);

  return { url, setUrl, loading };
}
