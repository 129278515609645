import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiAuthWithSetData } from "data/ApiAuth";
import ActiveOrInactiveCell from "components/CRPComponents/Tables/components/ActiveOrInactiveCell";
import UserListTable from "components/CRPComponents/Tables/GenericTable copy";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <UserListTable<GetAllGql>
        initialVariables={{
          search: null,
        }}
        path={`/configuracoes/usuarios`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.findManyUsers}
        formatPagination={() => [] as any}
        title="NOVO USUÁRIO"
        ApiSetData={ApiAuthWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`/configuracoes/usuarios/${value}`} value={value} />
            ),
          },
          {
            Header: "Nome",
            accessor: "firstName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Sobrenome",
            accessor: "lastName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "UserName",
            accessor: "userName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Email",
            accessor: "email",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Função",
            accessor: "role",
            Cell: ({ value }: any) => <DefaultCell value={value?.name} />,
          },
          {
            Header: "Ativo?",
            accessor: "active",
            Cell: ({ value }: any) => <ActiveOrInactiveCell value={value === "ACTIVE"} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
