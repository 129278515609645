import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiRequest } from "./types";

export const QUERY = print(gql`
  query TemplateInvestments {
    TemplateInvestments(perPage: -1) {
      items {
        id
        name
      }
    }
  }
`);

export interface QueryGql {
  TemplateInvestments: {
    items: {
      id: number;
      name: string;
    }[];
  };
}

export default function useTemplatesInvestments() {
  const [TemplatesInvestments, setStateData] = useState<
    null | QueryGql["TemplateInvestments"]["items"]
  >(null);

  useEffect(() => {
    ApiRequest<QueryGql>({
      query: QUERY,
    })
      .then(({ data }) => setStateData(data.data.TemplateInvestments.items))
      .catch((e) => console.log(e));
  }, []);

  return {
    TemplatesInvestments,
  };
}
