import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query LogDownloads($userId: String, $perPage: Int, $page: Int, $fileName: String) {
    LogDownloads(userId: $userId, perPage: $perPage, page: $page, fileName: $fileName) {
      items {
        id
        fileName
        description
        user {
          userName
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  fileName: string;
  description: string;
  user: {
    userName: string;
  };
}

export type GetAllGql = {
  LogDownloads: {
    items: GetAllItem[];
    paginationInfo: {
      hasMore: boolean;
      page: number;
      perPage: number;
      totalData: number;
      totalPages: number;
      nextPage?: null | {
        page: number;
        perPage: number;
      };
    };
  };
};
