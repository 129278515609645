import React from "react";
// Layout
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormCMSComponent from "components/CRPComponents/Forms/FormCMSComponents";
import { Permissions } from "./permissions";

type DataFormType = {
  column1: {
    menu_item: [
      {
        label: string;
        url?: string;
        targetBlank?: boolean;
        typeOfMenu: "Submenu" | "Link";
        submenu?: [
          {
            label: string;
            targetBlank?: boolean;
            url: string;
          }
        ];
      }
    ][];
  };
  column2: {
    menu_item: [
      {
        label: string;
        url?: string;
        targetBlank?: boolean;
        typeOfMenu: "Submenu" | "Link";
        submenu?: [
          {
            label: string;
            targetBlank?: boolean;
            url: string;
          }
        ];
      }
    ][];
  };
  column3: {
    menu_item: [
      {
        label: string;
        url?: string;
        targetBlank?: boolean;
        typeOfMenu: "Submenu" | "Link";
        submenu?: [
          {
            label: string;
            targetBlank?: boolean;
            url: string;
          }
        ];
      }
    ][];
  };
  column4: {
    redesSociais: [
      {
        socialNetwork: string;
        urlRede: string;
      }
    ][];
    consultarCadastro: {
      urlEMEC: string;
      linkEMEC: string;
    };
    imageLinks: [
      {
        titlePay: string;
        urlImgPay: string;
      }
    ][];
  };
  copyright: string;
};

const NAME_COMPONENT = "footer";

function FooterPage(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FormCMSComponent<DataFormType>
        nameComponent={NAME_COMPONENT}
        actionsPermission={{
          createPermision: Permissions.create,
          updatePermision: Permissions.update,
          deletePermision: Permissions.delete,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default FooterPage;
