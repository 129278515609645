export default {
  type: "object",
  properties: {
    id: {
      type: "integer",
    },
    databaseName: {
      type: "string",
    },
    partnerVisibleName: {
      type: "string",
    },
    description: {
      type: "string",
    },
    active: {
      type: "boolean",
    },
  },
  required: ["id", "databaseName", "partnerVisibleName"],
};
