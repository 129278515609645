import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateModalityConversion($data: CreateModalityConversionInput!) {
    createModalityConversion(data: $data) {
      id
    }
  }
`);
export interface CreateGql {
  createModalityConversion: {
    id: number;
  };
}
