import List from "./List";
import Update from "./Update";

export const Orders = {
  List: List,
  Update: Update,
  Permissions: {
    list: "list:Order",
    read: "read:Order",
    update: "update:Order",
  },
};
