import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import { ListProps } from "./types";
import { CellProps, GET_ALL_QUERY, GetAllGql, GetAllItem } from "./queries/getAll";
import { Fragment, useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  IconButton,
  MenuItem,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AnalysisButton,
  ApproveButton,
  Comment,
  Group,
  Key,
  ReprovedButton,
  Value,
} from "./styles";
import { ApiCoursesRequest } from "data/ApiCMS";
import { UPDATE_QUERY, UpdateGql } from "./queries/update";
import StatusCell from "components/CRPComponents/Tables/components/StatusCell";
import MDInput from "components/MDInput";
import useCategories from "./queries/getCategories";
import { ApiCourseWithSetData } from "data/queries/ApiCourse";

function List(props: ListProps) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<GetAllItem>();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<GetAllItem[]>([]);
  const { categories } = useCategories();
  const [initialVariables, setInitialVariables] = useState({
    categoryId: null,
    status: null,
    page: null,
    perPage: null,
    courseId: null,
  });

  const handleClick = (data: GetAllItem) => {
    setLoading(false);
    setOpen(true);
    setContent(data);
  };

  const handleOpen = useCallback(() => setOpen((old) => !old), []);

  const formatStatus = (status: GetAllItem["status"]) => {
    switch (status) {
      case "DRAFT":
        return <StatusCell icon="replay" color="dark" status="PENDENTE" />;
      case "ACTIVE":
        return <StatusCell icon="done" color="success" status="APROVADO" />;
      case "INACTIVE":
        return <StatusCell icon="close" color="error" status="REPROVADO" />;
      default:
        return <StatusCell icon="replay" color="dark" status="PENDENTE" />;
    }
  };

  const handleUpdate = (id: number, status: GetAllItem["status"]) => {
    setLoading(true);
    ApiCoursesRequest<UpdateGql>({
      query: UPDATE_QUERY,
      variables: {
        data: {
          id,
          status,
        },
      },
    })
      .then(({ data }) => {
        if (data.data.updateFeedbackProduct.id) {
          setContent((old) => ({ ...old, status }));
          setRows((old) => old.map((item) => (item.id === id ? { ...item, status } : item)));
          setOpen(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <GenericTable<GetAllGql>
          initialVariables={initialVariables}
          hiddenSearch={true}
          path={`${props.path}/avaliacoes`}
          query={GET_ALL_QUERY}
          formatRows={() => rows ?? []}
          formatPagination={(data) => data.FeedbackProducts?.paginationInfo}
          hnadleResponse={(data) => setRows(data?.FeedbackProducts?.items)}
          ApiSetData={ApiCourseWithSetData}
          useDefaultFilter={false}
          childrenFilters={
            <Fragment>
              <MDInput
                value={initialVariables.status}
                label="Status"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInitialVariables((old) => ({ ...old, status: e.target.value }))
                }
                sx={{ width: "130px" }}
                select
              >
                <MenuItem value={null}>Todos</MenuItem>
                <MenuItem value={"ACTIVE"}>Aprovado</MenuItem>
                <MenuItem value={"INACTIVE"}>Reprovado</MenuItem>
                <MenuItem value={"DRAFT"}>Pendente</MenuItem>
              </MDInput>

              <MDInput
                value={initialVariables.categoryId}
                label="Categories"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInitialVariables((old) => ({ ...old, categoryId: e.target.value }))
                }
                sx={{ width: "130px" }}
                select
              >
                <MenuItem value={null}>Todos</MenuItem>
                {categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </MDInput>

              <MDInput
                type="text"
                label="ID do Curso"
                value={initialVariables.courseId}
                sx={{ width: "130px" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInitialVariables((old) => ({
                    ...old,
                    courseId: e.target.value ? Number(e.target.value) : null,
                  }))
                }
              />
            </Fragment>
          }
          columnsTable={[
            {
              Header: "ID",
              accessor: "id",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "Título",
              accessor: "title",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "Comentário",
              accessor: "comment",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "Nota",
              accessor: "rating",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "curso",
              accessor: "course.name",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "ID do curso",
              accessor: "course.id",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: ({ value, cell }: CellProps) =>
                cell.row.original.status === "DRAFT" ? (
                  <AnalysisButton onClick={() => handleClick(cell.row.original)}>
                    Analisar
                  </AnalysisButton>
                ) : (
                  <Stack
                    sx={{
                      ".seeContent": {
                        display: "none",
                      },
                      "&:hover": {
                        ".seeContent": {
                          display: "block",
                        },
                        ".status": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <Box className="status">{formatStatus(value)}</Box>
                    <AnalysisButton
                      className="seeContent"
                      onClick={() => handleClick(cell.row.original)}
                    >
                      Ver
                    </AnalysisButton>
                  </Stack>
                ),
            },
          ]}
        />
        <Footer />
      </DashboardLayout>
      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "30vw",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleOpen}>
          <Stack>
            <IconButton
              aria-label="close"
              size="large"
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              onClick={handleOpen}
            >
              <CloseIcon />
            </IconButton>
            {content && (
              <Stack
                sx={{
                  padding: "30px",
                  gap: "15px",
                }}
              >
                <Stack>
                  <Group>
                    <Key>ID do Curso:</Key>
                    <Value>{content.course.id}</Value>
                  </Group>
                  <Group>
                    <Key>Curso:</Key>
                    <Value>{content.course.name}</Value>
                  </Group>
                  <Group>
                    <Key>Título:</Key>
                    <Value>{content.title}</Value>
                  </Group>
                  <Group>
                    <Key>Nota de avaliação:</Key>
                    <Value>{content.rating}</Value>
                  </Group>
                </Stack>
                <Stack>
                  <Stack>
                    <Key>Comentário:</Key>
                    <Comment>{content.comment}</Comment>
                  </Stack>
                </Stack>
                {loading ? (
                  <Stack alignItems={"center"}>
                    <CircularProgress sx={{ color: "#7b809a" }} />
                  </Stack>
                ) : (
                  <Stack direction={"row"} alignContent={"center"} gap={"10px"}>
                    <ReprovedButton onClick={() => handleUpdate(content.id, "INACTIVE")}>
                      Reprovar
                    </ReprovedButton>
                    <ApproveButton onClick={() => handleUpdate(content.id, "ACTIVE")}>
                      Aprovar
                    </ApproveButton>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </ClickAwayListener>
      </Dialog>
    </Fragment>
  );
}

export default List;
