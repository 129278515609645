import { ApiCourseRequest } from "data/queries/ApiCourse";
import { UPDATE_QUERY, UpdateGql } from "../queries/UpsertCourse";
import { UpsertCourseInput } from "./convertObjectToUpsertInput";
import { removeNullValues } from "utils/removeNullValues";

export const upsertCourse = async (data: UpsertCourseInput): Promise<{ id: number }> => {
  const res = await ApiCourseRequest<UpdateGql>({
    query: UPDATE_QUERY,
    variables: {
      data: removeNullValues(data),
    },
  });
  return res?.data?.data?.upsertCourse;
};
