import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { convertCourseInUpsertCourseInput } from "layouts/ecommerce/CoursesFinancial/hooks/convertCourseInUpsertCourseInput";

export const GET_ITEM_QUERY = print(gql`
  query Course($id: Int!) {
    Course(id: $id) {
      id
      name
      description
      status
      active
      slug
      imageUrl
      keywords
      modality {
        id
        name
      }
      courseGroup {
        id
        descriptionShort
        imageUrl
        partner {
          id
          name
          visibleName
          description
          active
        }
        category {
          id
          name
          description
          active
          slug
          keywords
          showPartinerFilter
          templateSchemaName
          parentId
          templateSchemaDefault {
            UISchema
            schemaJson
          }
        }
      }
      tags {
        id
      }
      educationalUnit {
        id
        visibleName
        state
      }
      coordinator {
        id
      }
      facultyMembers {
        id
      }
      subjects {
        id
      }
      createdAt
      updatedAt
      openForSubscriptions
      courseLoadHours
      semesters
      title
      shift
      startDate
      immediateStart
      info {
        contentJson
      }
    }
  }
`);

export interface GetItemGql {
  Course: {
    id: number;
    name: string;
    descriptionShort: string;
    status: string;
    active: boolean;
    slug: string;
    imageUrl: string;
    keywords: string;
    modality: {
      id: number;
      name: string;
    };
    courseGroup: {
      id: number;
      descriptionShort: string;
      imageUrl: string;
      partner: {
        id: number;
        name: string;
        visibleName: string;
        description?: string;
        active: boolean;
      };
      category: {
        id: number;
        name: string;
        description: string;
        active: boolean;
        slug: string;
        keywords?: string;
        showPartinerFilter: boolean;
        templateSchemaName?: string;
        parentId?: number;
        templateSchemaDefault: {
          UISchema: string;
          schemaJson: string;
        };
      };
    };
    tags: Array<{
      id: number;
    }>;
    educationalUnit: {
      id: number;
      visibleName: string;
      state: string;
    };
    coordinator: {
      id: number;
    };
    facultyMembers: Array<{
      id: number;
    }>;
    subjects: Array<{
      id: number;
    }>;
    createdAt: string;
    updatedAt: string;
    openForSubscriptions: boolean;
    courseLoadHours: number;
    semesters: number;
    title: string;
    shift: string;
    startDate: string;
    immediateStart: boolean;
    info: {
      contentJson: string | null;
    };
  };
}

export function convertCourse(course: GetItemGql["Course"]) {
  return convertCourseInUpsertCourseInput(course);
}
