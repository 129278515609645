import { print } from "graphql";
import { gql } from "@apollo/client";

export const GET_ALL_QUERY = print(gql`
  query PaymentAffiliantion {
    PaymentAffiliantion {
      implementation
      id
      isConfigured
      isdelivered
      name
    }
  }
`);

export interface GetAllGql {
  PaymentAffiliantion: PaymentGateway[];
}

export interface PaymentGateway {
  id: string;
  implementation: string;
  name: string;
  isdelivered: boolean;
  isConfigured: boolean;
}
