import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query GetOne($id: Int!) {
    Price(id: $id) {
      id
      active
      activateIfPossible
      value
      valueWithoutDiscout
      installments
      eCommerceSkuId
      eCommerceLastUpdate
      planAdmissionWay {
        title
      }
      commercialConditionId
      valueOtherInstallments
      urlInscription
    }
  }
`);

export const responseGetItemKey = "Price";
export interface GetItemGql {
  Price: {
    id: number;
    active: boolean;
    activateIfPossible: boolean;
    value: number;
    valueWithoutDiscout?: number;
    installments: number;
    eCommerceSkuId?: number;
    eCommerceLastUpdate?: Date;
    planAdmissionWay: {
      title: string;
    };
    commercialConditionId?: number;
    valueOtherInstallments?: number;
    urlInscription?: string;
  };
}
