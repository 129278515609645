import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const GlobalKeys = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:GlobalKey",
    read: "read:GlobalKey",
    list: "list:GlobalKey",
    update: "update:GlobalKey",
    delete: "delete:GlobalKey",
  },
};
