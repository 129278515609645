import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { useCallback } from "react";

import { UPDATE_PAYMENT_METHOD, updatePaymentMethod } from "../queries/updatePaymentMethod";
import { SchemaData, UseUpdateProps } from "./types";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const data: any = {
        ...state.data,
        enabled: Boolean(Number(state.data.enabled)),
        paymentSystem: {
          ...state.data.paymentSystem,
        },
        connector: {
          affiliationId: state.data.connector,
        },
        installmentsService: state.data.installmentsService === "À vista" ? false : true,
        externalInterest: state.data.externalInterest === "Inativo" ? false : true,
      };

      delete data.hasInstallmentTax;

      if (state.data.installmentsService === "À vista") {
        delete data.installmentOptions;
      }

      const response = await ApiFinancialRequest<updatePaymentMethod>({
        query: UPDATE_PAYMENT_METHOD,
        variables: {
          data,
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data.updatePaymentMethod.id) {
        throw new Error("Ops. Sem resposta!");
      }

      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
