import Container from "./Container";
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconMui from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import TooltipMui from "@mui/material/Tooltip";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { QUERY_ORDERS_TENDENCY, QueryOrdersTedencyGql } from "./queries/ordersTedency";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { Fragment, useEffect, useState } from "react";
import { useData } from "./context/DataProvider";

function OrdersTedency() {
  const [data, setData] = useState<QueryOrdersTedencyGql["OrdersTrend"]>();
  const [loading, setLoading] = useState(false);
  const { dateSelected } = useData();

  useEffect(() => {
    setLoading(true);
    ApiFinancialRequest<QueryOrdersTedencyGql>({
      query: QUERY_ORDERS_TENDENCY,
      variables: {
        startDate: dateSelected.from.value.toISOString(),
        endDate: dateSelected.to.value.toISOString(),
      },
    })
      .then(({ data }) => setData(data.data.OrdersTrend))
      .finally(() => setLoading(false));
  }, [dateSelected]);

  const getHour = (dateStr: string) => {
    const date = new Date(dateStr);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Container
          sx={{
            width: "fit-content",
            height: "auto",
            borderRadius: 0,
            span: {
              fontWeight: 400,
              fontSize: "0.8rem",
            },
          }}
        >
          <Box component={"span"}>Pedido: {data.orders}</Box>
          <Box component={"span"}>{data.hour}</Box>
        </Container>
      );
    }

    return null;
  };

  return (
    <Container
      title="Análise das tendências de pedidos"
      sx={{
        width: "100%",
        ".recharts-text tspan": {
          fontSize: "1rem",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          color: "#344767",
          fontWeight: 400,
        },
        ".recharts-default-legend": {
          display: "none",
        },
      }}
      gap={"20px"}
    >
      {!data || loading ? (
        <Stack alignItems={"center"}>
          <CircularProgress sx={{ color: "#344767", width: "30px", height: "30px" }} />
        </Stack>
      ) : (
        <Fragment>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox display="flex" ml={-1}>
              <MDBadgeDot size="md" badgeContent="Pedidos" sx={{ i: { background: "#1A73E8" } }} />
            </MDBox>
            <MDBox>
              <TooltipMui
                title="Análise histórica do número de pedidos realizados pelos usuários na loja no período selecionado"
                placement="left"
                arrow
              >
                <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                  <IconMui>priority_high</IconMui>
                </MDButton>
              </TooltipMui>
            </MDBox>
          </MDBox>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart
              data={data.map((item) => ({
                ...item,
                hour: getHour(item.date),
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="hour"
                label={{ value: "Horas", position: "insideBottomRight", dy: 10 }}
              />
              <YAxis label={{ value: "%", position: "insideLeft", dy: -10 }} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Area
                type="monotone"
                dataKey="midConfidenceInterval[1]"
                fill="#DEE6F7"
                stroke="#DEE6F7"
                activeDot={false}
              />
              <Area
                type="monotone"
                dataKey="highConfidenceInterval[1]"
                fill="#F5F5F5"
                stroke="#F5F5F5"
                activeDot={false}
              />
              <Area
                type="monotone"
                dataKey="orders"
                stroke="#1A73E8"
                fillOpacity={0}
                strokeWidth={2}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Fragment>
      )}
    </Container>
  );
}

export default OrdersTedency;
