import { withJsonFormsControlProps } from "@jsonforms/react";
import SelectMultiple from "../SelectMultiple";
import { CustomRendererProps } from "../types";
import { print } from "graphql";
import { gql } from "@apollo/client";
import { ApiAuthRequest } from "data/ApiAuth";
import ModalUserCopySelect from "components/CRPComponents/ModalUserCopySelect";

const query = print(gql`
  query FindOneUser($id: ID!) {
    findOneUser(id: $id) {
      userName
      id
    }
  }
`);
interface ItemGql {
  findOneUser: {
    userName: string;
    id: string;
  };
}

const UserCopySelectMultiple = (props: CustomRendererProps<string[] | undefined>) => {
  return (
    <SelectMultiple
      {...props}
      Modal={ModalUserCopySelect}
      formatItem={({ data }) => ({
        name: data.data.findOneUser.userName,
        id: data.data.findOneUser.id,
      })}
      requestItem={(id: string) => ApiAuthRequest<ItemGql>({ query, variables: { id } })}
    />
  );
};

export default withJsonFormsControlProps(UserCopySelectMultiple, true);
