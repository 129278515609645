import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query PartnerCompany($id: Int!) {
    PartnerCompany(id: $id) {
      id
      name
      description
      active
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  PartnerCompany: {
    id: number;
    name: string;
    description: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  };
}
