export const getKeyNumber = (key: string): string => {
  // Expressão regular para verificar o padrão "1- Nome Grupo de Curso*"
  var padrao1 = /^(\d{1,5})-\s.+$/;

  // Expressão regular para verificar o padrão "Nome_Grupo_de_Curso_1"
  var padrao2 = /^.+_(\d{1,5})$/;

  if (padrao1.test(key)) {
    return key.match(padrao1)[1];
  }
  if (padrao2.test(key)) {
    return key.match(padrao2)[1];
  }

  return key;
};

const fixString = (value: any) => {
  if (typeof value !== "string") return value;
  return value.replace(/^\s+/, "");
};
const convertKeys = (data: Record<string, string>): Record<string, string> => {
  const responseData: Record<string, string> = {};

  Object.entries(data).map(([key, value]) => (responseData[getKeyNumber(key)] = fixString(value)));

  return responseData;
};

const JsonParseOrUndefined = (data?: string) => {
  try {
    const json = JSON.parse(data);
    return json;
  } catch (error) {
    if (data) console.log({ FailJsonParseOrUndefined: data, error });
    return undefined;
  }
};

export const slugfy = (slug: string): string => {
  return slug
    .trim()
    .toLowerCase()
    .normalize("NFD") // Normaliza para decomposição de caracteres
    .replace(/[\u0300-\u036f]/g, "") // Remove os diacríticos
    .replace(/[^a-z0-9-]+/g, "-") // Substitui caracteres indesejados por "-"
    .replace(/-+/g, "-") // Reduz múltiplos hifens a um único
    .replace(/^-+|-+$/g, ""); // Remove "-" do início ou fim da string
};

export const cleanEmail = (email: string): string => {
  return email.trim().toLowerCase().replace(/\s/g, "");
};

export const editEachDataRead = (data: Record<string, string>): Record<string, string> => {
  const clone = { ...data };
  for (const [key, value] of Object.entries(clone)) {
    const keyNumber = getKeyNumber(key);
    if (keyNumber === "7" || keyNumber === "2" || keyNumber === "31") {
      clone[key] = slugfy(value);
    }
  }
  return clone;
};

export const convertObjectToUpsertInput = (data: Record<string, string>): UpsertCourseInput => {
  const convertedData = convertKeys(data);
  return {
    name: convertedData?.["6"],
    slug: convertedData?.["7"],
    courseLoadHours: Number(convertedData?.["12"]) ?? undefined,
    status: convertedData?.["18"] === "ACTIVE" ? "ACTIVE" : "DRAFT",
    keywords: convertedData?.["10"] || undefined,
    openForSubscriptions:
      String(convertedData?.["11"]) === "true" || String(convertedData?.["11"]) === "VERDADEIRO",
    semesters: Number(convertedData?.["13"]) || undefined,
    title: convertedData?.["14"] || undefined,
    shift: convertedData?.["15"] || undefined,
    startDate: new Date(convertedData?.["16"]) || undefined,
    immediateStart:
      String(convertedData?.["17"]) === "true" || String(convertedData?.["17"]) === "VERDADEIRO",
    description: convertedData?.["8"] || undefined,
    imageUrl: convertedData?.["9"] || undefined,
    releaseDate: new Date(convertedData?.["19"]) || undefined,
    urlInscription: convertedData?.["87"] || undefined,
    moodleId: String(convertedData?.["98"]) || undefined,
    eCommerceProductId: Number(convertedData?.["97"]) || undefined,
    contentJson:
      JSON?.stringify({
        aboutCourse: {
          cards: JsonParseOrUndefined(convertedData?.["75"]) || undefined,
          cardsTitle: convertedData?.["74"] || undefined,
          descriptionLong: convertedData?.["77"] || undefined,
          descriptionLongTitle: convertedData?.["76"] || undefined,
          descriptionShort: convertedData?.["73"] || undefined,
        },
        aditionalInfos: {
          duration: convertedData?.["89"] || undefined,
          includes: convertedData?.["94"] || undefined,
          prerequired: convertedData?.["88"] || undefined,
          time: convertedData?.["90"] || undefined,
        },
        curricularMatrix: {
          description: convertedData?.["79"] || undefined,
          urlDownloadMatrix: convertedData?.["80"] || undefined,
        },
        facultyMemberBlock: {
          description: convertedData?.["81"] || undefined,
          postDescription: convertedData?.["83"] || undefined,
          urlDownloadFacultyMembers: convertedData?.["82"] || undefined,
        },
        frenquentAskQuestions: {
          description: convertedData?.["95"] || undefined,
          list: convertedData?.["96"] || undefined,
        },
        courseGroup: {
          complement: convertedData?.["86"] || undefined,
          description: convertedData?.["84"] || undefined,
          steps: JsonParseOrUndefined(convertedData?.["85"]) || undefined,
        },
        requirements: {
          complement: convertedData?.["93 "] || undefined,
          description: convertedData?.["91"] || undefined,
          list: convertedData?.["92 "] || undefined,
        },
        videoURL: convertedData?.["78"] || undefined,
      }) || undefined,
    courseGroup: {
      name: convertedData?.["1"],
      descriptionShort: convertedData?.["3"],
      active:
        String(convertedData?.["20"]) === "true" || String(convertedData?.["20"]) === "VERDADEIRO",
      slug: convertedData?.["2"],
      imageUrl: convertedData?.["5"],
      keywords: convertedData?.["4"] || undefined,
      partner: {
        name: convertedData?.["21"],
        visibleName: convertedData?.["22"],
        description: convertedData?.["23"] || undefined,
        imageUrl: convertedData?.["24"] || undefined,
        active:
          String(convertedData?.["29"]) === "true" ||
          String(convertedData?.["29"]) === "VERDADEIRO",
        cnpj: convertedData?.["25"] || undefined,
        email: convertedData?.["27"] || undefined,
        phone: String(convertedData?.["26"]) || undefined,
        site: convertedData?.["28"] || undefined,
      },
      teachingArea: {
        name: convertedData?.["38"],
        active:
          String(convertedData?.["40"]) === "true" ||
          String(convertedData?.["40"]) === "VERDADEIRO",
        description: convertedData?.["39"] || undefined,
      },
      category: {
        name: convertedData?.["30"],
        description: convertedData?.["32"],
        slug: convertedData?.["31"],
        active:
          String(convertedData?.["33"]) === "true" ||
          String(convertedData?.["33"]) === "VERDADEIRO",
        keywords: convertedData?.["34"] || undefined,
        showPartinerFilter:
          String(convertedData?.["35"]) === "true" ||
          String(convertedData?.["35"]) === "VERDADEIRO",
        urlContract: convertedData?.["36"] || undefined,

        parentSlug: convertedData?.["37"] || undefined,
      },
    },
    modality: {
      name: convertedData?.["41"],
      active:
        String(convertedData?.["43"]) === "true" || String(convertedData?.["43"]) === "VERDADEIRO",
      description: convertedData?.["42"] || undefined,
    },
    educationalUnit: {
      name: convertedData?.["45"],
      visibleName: convertedData?.["46"],
      description: convertedData?.["47"] || undefined,
      imageUrl: convertedData?.["48"] || undefined,
      postalCode: convertedData?.["49"],
      street: convertedData?.["50"],
      number: String(convertedData?.["51"]),
      complement: String(convertedData?.["52"]) || undefined,
      neighborhood: convertedData?.["53"],
      city: convertedData?.["54"],
      state: convertedData?.["55"],
      addressString: `${convertedData?.["50"] || ""}, ${convertedData?.["51"] || ""}, ${
        convertedData?.["52"] || ""
      }, ${convertedData?.["53"] || ""}, ${convertedData?.["54"] || ""} - ${
        convertedData?.["55"] || ""
      }`,
      institution: convertedData?.["61"],
      educationalGroupId: Number(convertedData?.["62"]),
      active:
        String(convertedData?.["63"]) === "true" || String(convertedData?.["63"]) === "VERDADEIRO",
      phone: String(convertedData?.["56"]) || undefined,
      otherPhones: String(convertedData?.["57"]) || undefined,
      education: convertedData?.["58"] || undefined,
      email: convertedData?.["59"] || undefined,
      mapsUrlEmbed: convertedData?.["60"] || undefined,
    },

    coordinator: {
      name: convertedData?.["64"],
      email: convertedData?.["65"],
      description: convertedData?.["66"] || undefined,
      photoUrl: convertedData?.["67"] || undefined,
      idInternMember: String(convertedData?.["70"]) || undefined,
      active:
        String(convertedData?.["71"]) === "true" || String(convertedData?.["71"]) === "VERDADEIRO",
      title: convertedData?.["68"] || undefined,
      lattesUrl: convertedData?.["69"] || undefined,
      isCoordinator: true,
    },
    facultyMembers:
      JsonParseOrUndefined(convertedData?.["72"])?.map((email: string) => ({
        name: email,
        email,
      })) || undefined,
    tags: JsonParseOrUndefined(convertedData?.["44"])?.map((name: string) => ({ name })) || [],
  };
};

export interface UpsertCourseInput {
  name: string;
  slug: string;
  courseLoadHours: number;
  status: "ACTIVE" | "DRAFT" | "INACTIVE";
  keywords?: string;
  openForSubscriptions?: boolean;
  semesters?: number;
  title?: string;
  shift?: string;
  startDate?: Date;
  immediateStart?: boolean;
  description?: string;
  imageUrl?: string;
  releaseDate?: Date;
  urlInscription?: string; //faltando
  contentJson?: string;
  moodleId?: string;
  eCommerceProductId?: number;

  courseGroup: {
    name: string;
    descriptionShort: string;
    active: boolean;
    slug: string;
    imageUrl: string;
    keywords?: string;
    partner: {
      name: string;
      visibleName: string;
      description: string;
      imageUrl: string;
      active: boolean;
      cnpj?: string;
      phone?: string;
      email?: string;
      site?: string;
    };
    teachingArea: {
      name: string;
      active: boolean;
      description?: string;
    };
    category: {
      name: string;
      description: string;
      slug: string;
      active: boolean;
      keywords?: string;
      showPartinerFilter?: boolean;
      urlContract?: string;
      templateSchemaName?: string;
      contentJson?: string;

      parentSlug?: string;
    };
  };
  modality: {
    name: string;
    active: boolean;
    description?: string;
  };
  educationalUnit: {
    name: string;
    visibleName: string;
    description?: string;
    imageUrl?: string;
    postalCode: string;
    street: string;
    number: string;
    complement?: string;
    neighborhood: string;
    city: string;
    state: string;
    addressString: string;
    institution: string;
    educationalGroupId: number;
    active: boolean;
    phone?: string;
    otherPhones?: string;
    education?: string;
    email?: string;
    mapsUrlEmbed?: string;
  };
  coordinator?: {
    name: string;
    email: string;
    description?: string;
    photoUrl?: string;
    idInternMember?: string;
    active?: boolean;
    title?: string;
    lattesUrl?: string;
    isCoordinator?: boolean;
  };
  facultyMembers?: {
    name: string;
    email: string;
    description?: string;
    photoUrl?: string;
    active?: boolean;
    title?: string;
    lattesUrl?: string;
    idInternMember?: string;
    isCoordinator?: boolean;
  }[];
  subjects?: {
    id?: number;
    name: string;
    description?: string;
    hours: number;
    group: string;
    position: number;
    ref?: string;
  }[];
  tags?: { name: string }[];
}
