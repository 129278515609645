import Create from "./Create";
import { path } from "./hooks/types";
import List from "./List";
import Update from "./Update";

export const InvestmentInfos = {
  List: List,
  Update: Update,
  Create: Create,
  path: path,
  Permissions: {
    read: "read:InvestmentInfo",
    create: "create:InvestmentInfo",
    list: "list:InvestmentInfo",
    delete: "delete:InvestmentInfo",
    edit: "update:InvestmentInfo",
  },
};
