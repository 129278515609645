import { useCallback, useEffect, useState } from "react";
import { ApiFinancialRequest } from "data/ApiFinancial";
import {
  Annotation,
  AnnotationsGql,
  CREATE_ANNOTATION,
  GET_ANNOTATIONS,
} from "../queries/OrderAnnotations";

const useAnnotations = (id: string) => {
  const [annotations, setAnnotations] = useState<Annotation[]>();
  const [loading, setLoading] = useState(false);

  const loadAnnotations = useCallback(() => {
    setLoading(true);
    ApiFinancialRequest<AnnotationsGql>({
      query: GET_ANNOTATIONS,
      variables: { id },
    })
      .then((response) => {
        const items = response?.data?.data?.OrderAnnotations.items;
        if (items) {
          setAnnotations(items);
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => loadAnnotations(), []);

  const addComment = useCallback((comment: string) => {
    setLoading(true);
    ApiFinancialRequest<void>({
      query: CREATE_ANNOTATION,
      variables: {
        id,
        annotation: {
          url: "",
          description: comment,
        },
      },
    })
      .then(() => loadAnnotations())
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    annotations,
    addComment,
    loading,
  };
};

export default useAnnotations;
