import Icon from "@mui/material/Icon";
import SignIn from "components/CRPComponents/sign-in/basic";
import RecoverPassword from "components/CRPComponents/sign-in/recoverPassword";
import { Sites } from "data/utils/globals";
import { Address } from "layouts/Profile/Address";
import { User } from "layouts/Profile/User";
import { AdminUser } from "layouts/config/AdminUser";
import { Permissions } from "layouts/config/Permissions";
import { TemplateEmail } from "layouts/mailer/TemplateEmail";
import { LogDowload } from "layouts/aplicacoes/LogDowload";
import Teste from "layouts/pages/teste";
import TestSchema from "utils/testSchema";
import { Route } from "App";

const { path } = Sites.UNIFTEC;

export const configRoutes: Route[] = [
  { type: "title", title: "Configurações", key: "title-pages" },
  {
    type: "collapse",
    name: "Configurações",
    key: "configuraçoes",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Usuários",
        // key: "Usuários",
        icon: <Icon fontSize="medium">image</Icon>,
        // href: "/configuracoes/usuarios",
        // collapse: [
        // {
        // name: " Ver todas",
        key: "/configuracoes/usuarios",
        route: "/configuracoes/usuarios",
        component: <AdminUser.List path={path} />,
        permission: AdminUser.Permissions.list,
      },
      {
        type: "hide",
        name: " Nova",
        key: "/configuracoes/usuarios/new",
        route: "/configuracoes/usuarios/new",
        component: <AdminUser.Create />,
        permission: AdminUser.Permissions.create,
      },
      {
        name: " Editar",
        key: "/configuracoes/usuarios/edit",
        route: "/configuracoes/usuarios/:id",
        type: "hide",
        component: <AdminUser.Update />,
        permission: AdminUser.Permissions.read,
        //   },
        // ],
      },
      {
        name: "Permissões",
        // key: "Permissões",
        icon: <Icon fontSize="medium">image</Icon>,
        // href: "/configuracoes/permissoes",
        // collapse: [
        // {
        // name: " Ver todas",
        key: "/configuracoes/permissoes",
        route: "/configuracoes/permissoes",
        component: <Permissions.List />,
        permission: Permissions.Permissions.list,
      },
      {
        type: "hide",
        name: " Nova",
        key: "/configuracoes/permissoes/new",
        route: "/configuracoes/permissoes/new",
        component: <Permissions.Create />,
        permission: Permissions.Permissions.create,
      },
      {
        name: " Editar",
        key: "/configuracoes/permissoes/edit",
        route: "/configuracoes/permissoes/:id",
        type: "hide",
        component: <Permissions.Update />,
        permission: Permissions.Permissions.read,
        // },
        // ],
      },
      { name: "Logs", key: "logs", route: "/configuracoes/logs", component: <Teste /> },
      {
        name: "Templates Email",
        // key: "templateEmail",
        icon: <Icon fontSize="medium">image</Icon>,
        // collapse: [
        // {
        // name: " Ver Todos",
        key: "/configuracoes/templates-de-email",
        route: "/configuracoes/templates-de-email",
        component: <TemplateEmail.List />,
        permission: TemplateEmail.Permission.list,
      },
      // {
      //   type: "hide",
      //   name: "Criar",
      //   key: `/configuracoes/templates-de-email/new`,
      //   route: `/configuracoes/templates-de-email/new`,
      //   component: <TemplateEmail.Create />,
      //   permission: TemplateEmail.Permission.create,
      //   // },
      //   // ],
      // },
      {
        type: "hide",
        name: "Ver",
        key: `/configuracoes/templates-de-email/:name`,
        route: `/configuracoes/templates-de-email/:name`,
        component: <TemplateEmail.Update />,
        permission: TemplateEmail.Permission.update,
        // },
        // ],
      },
      // {
      //   name: "Downloads",
      //   key: "Downloads",
      //   route: "/configuracoes/Downloads",
      //   component: <LogDowload.List />,
      //   permission: LogDowload.Permissions.list,
      // },
    ],
  },
  {
    name: "Downloads",
    key: "Downloads",
    route: "/configuracoes/Downloads",
    component: <LogDowload.List />,
    permission: LogDowload.Permissions.list,
  },
  { type: "divider", key: "divider-configRoutes" },
  {
    name: " Testar Schema",
    type: "hide",
    key: "/schema/test",
    route: "/schema/test",
    component: <TestSchema />,
  },
  {
    name: "Login",
    type: "hide",
    key: "/auth/login",
    route: "/auth/login",
    component: <SignIn />,
  },
  {
    type: "hide",
    name: "Recuperar senha",
    key: "Recuperar senha",
    route: "/auth/recuperar-senha",
    component: <RecoverPassword />,
  },
  {
    name: "Meu perfil",
    key: "meu-perfil",
    route: "/paginas/perfil/Edit",
    component: <User.Update />,
  },
  {
    name: "Meu perfil",
    key: "meu-perfil",
    route: "/paginas/perfil/Endereco/edit/:id",
    component: <Address.Update />,
  },
];
