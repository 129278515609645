import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { Handler } from "../types";

type ModalitiesReponse = {
  Modalities: {
    items: Array<{
      id: number;
      name: string;
      active: boolean;
    }>;
  };
};
type ModalityReponse = {
  Modality: {
    id: number;
    name: string;
    active: boolean;
  };
};
const ModalityHandler: Handler<ModalitiesReponse, ModalityReponse> = {
  query: print(gql`
    query Modalities($search: String) {
      Modalities(search: $search, active: null) {
        items {
          id
          name
          active
        }
      }
    }
  `),
  formater: (data) =>
    data.Modalities.items.map((item) => ({
      id: item.id,
      label: item.name,
      active: item.active,
    })),
  api: ApiCourseRequest,
  queryId: print(gql`
    query Query($id: Int!) {
      Modality(id: $id) {
        id
        name
        active
      }
    }
  `),
  formaterId: (data) => ({
    id: data.Modality.id,
    label: data.Modality.name,
    active: data.Modality.active,
  }),
};

export default ModalityHandler;
