import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Divider, Icon, Menu, MenuItem } from "@mui/material";

import type { PaymentMethod } from "../queries/getAll";
interface ICardPaymentMethod {
  paymentMethod: PaymentMethod;
}

export const CardPaymentMethod = ({ paymentMethod }: ICardPaymentMethod) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }: any) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2} pb={1}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            {paymentMethod.name}
          </MDTypography>
        </MDBox>
        <MDBox color="text">
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        <Menu
          id="simple-menu"
          anchorEl={menu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menu)}
          onClose={closeMenu}
        >
          <MenuItem
            onClick={() => navigate(`/ecommerce/metodos-de-pagamentos/${paymentMethod.id}`)}
          >
            Editar
          </MenuItem>
        </Menu>
      </MDBox>
      <MDBox>
        <MDBox px={2}>
          <MDTypography component="p" variant="button" color="text" display="flex">
            <MDTypography component="span" variant="button" color={"#333"}>
              Status:&nbsp; {paymentMethod.enabled ? "ATIVO" : "INATIVO"}
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox pb={2} px={2}>
          {paymentMethod?.installmentOptions?.installments.length > 1 ? (
            <MDTypography component="p" variant="button" color="text" display="flex">
              <MDTypography component="span" variant="button" fontWeight="bold" color={"#333"}>
                Parcelado em:
              </MDTypography>
              &nbsp; 1x - {paymentMethod?.installmentOptions?.installments.length}x
            </MDTypography>
          ) : (
            <MDTypography component="p" variant="button" color="text" display="flex">
              <MDTypography component="span" variant="button" fontWeight="bold" color={"#333"}>
                À vista
              </MDTypography>
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
};
