import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query FindOneUser($id: ID!) {
    findOneUser(id: $id) {
      id
      firstName
      email
      role {
        id
      }
      permissions {
        id
      }
      lastName
      userName
      phone
      cpf
      rg
      birthDate
      residentialPhone
      active
      academicEmail
    }
  }
`);

export interface GetItemGql {
  findOneUser: {
    id: number;
    firstName: string;
    email: string;
    role: {
      id: number;
    };
    permissions: Array<{
      id: number;
    }>;
    lastName: string;
    userName: string;
    phone: string;
    cpf: string;
    rg: string;
    birthDate: string;
    residentialPhone: string;
    active: string;
    academicEmail: string;
  };
}
