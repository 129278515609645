import React from "react";
import {
  ContentForm,
  CRPContainerHoriz,
  GalerryItemImage,
} from "components/CRPComponents/CRPAddBanner.style";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { getImageCmsURL } from "data/ApiCMS";
import { FormHelperText, InputLabel } from "@mui/material";
import ModalMidiaFile from "./ModalMediaFileSelect";

export type BannerFieldProps = {
  value?: { id: number; path: string };
  onChange: (value: { id: number; path: string }) => void;
  fileType?: "IMAGES" | "DOCUMENTS" | "SOUNDS" | "VIDEOS";
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
  buttonLabel?: string;
};

const INITIAL_SEARCH = {
  page: 1,
  perPage: 12,
};

export function MediaFileField({
  value,
  onChange,
  fileType = "IMAGES",
  disabled,
  errorMessage,
  buttonLabel = "Adicionar imagem",
  label,
}: BannerFieldProps) {
  const [showGallery, setShowGallery] = React.useState<boolean>(false);

  return (
    <ContentForm>
      <InputLabel size="small" sx={{ transform: "none", position: "relative" }}>
        {label}
      </InputLabel>
      <CRPContainerHoriz $justifyContent="space-around">
        {!value?.path ? (
          <MDBox
            sx={{
              border: "solid 1px",
              borderColor: errorMessage ? "red" : "black",
              color: errorMessage ? "red" : "black",
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: "150px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={disabled ? undefined : () => setShowGallery(true)}
          >
            {buttonLabel}
          </MDBox>
        ) : (
          <MDBox
            sx={{
              borderColor: "black",
              borderWidth: "2px",
              padding: "3px",
              width: "100%",
              height: "300px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <GalerryItemImage
              onClick={disabled ? undefined : () => setShowGallery(true)}
              src={getImageCmsURL(value.path)}
              alt="clique para trocar"
            />
            {/* <MDTypography
              sx={{ position: "absolute", top: "50%", width: "100%", textAlign: "center" }}
            >
              Trocar Imagem
            </MDTypography> */}
          </MDBox>
        )}
      </CRPContainerHoriz>
      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      <ModalMidiaFile
        fileType={fileType}
        onClickMidia={(midia) => {
          setShowGallery(false);
          onChange(midia);
        }}
        open={showGallery}
        onClose={() => setShowGallery(false)}
      />
    </ContentForm>
  );
}

export default MediaFileField;
