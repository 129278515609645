import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query TeachingArea($id: Int!) {
    TeachingArea(id: $id) {
      id
      name
      description
      active
    }
  }
`);

export interface GetItemGql {
  TeachingArea: {
    id: number;
    name: string;
    description: string;
    active: boolean;
  };
}
