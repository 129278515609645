import { useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { ImageResize } from "quill-image-resize-module-ts";
import "react-quill/dist/quill.snow.css";
import ModalMidiaFile from "../Forms/components/ModalMediaFileSelect";

Quill.register("modules/imageResize", ImageResize);

export function Editor({
  value,
  onChange,
  basic = false,
  placeholder,
  enabled = true,
}: {
  value: string;
  onChange: (value: string) => void;
  ref?: any;
  basic?: boolean;
  placeholder?: string;
  enabled?: boolean;
}) {
  const emptyStringArray: string[] = [];
  const quillRef = useRef<ReactQuill>(null);
  const [open, setOpen] = useState(false);

  const handleImage = () => {
    setOpen(true);
    return;
  };

  const handleSelectImage = ({ url, name }: { url: string; name?: string }) => {
    if (url) {
      const editor = quillRef.current?.getEditor();

      if (editor) {
        const range = editor.getSelection(true);

        // Montar o HTML da imagem
        const imageHtml = `<img src="${url}" alt="${name || "Imagem"}" />`;

        // Colar o HTML no editor
        editor.clipboard.dangerouslyPasteHTML(range?.index || 0, imageHtml, "user");
      }
      setOpen(false);
    }
  };

  // useMemo required using handlers
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: emptyStringArray }],
          [{ size: emptyStringArray }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            {
              color: emptyStringArray,
            },
          ],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          basic ? ["link"] : ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: handleImage,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      imageResize: {
        modules: ["Resize", "DisplaySize"],
      }, // Adicione o módulo imageResize
    }),
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "align",
    "link",
    "image",
    "video",
    "color",
  ];

  return (
    <>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        readOnly={!enabled}
        style={{ maxWidth: "1068px" }}
      />
      <br />
      {enabled && (
        <ModalMidiaFile
          fileType="IMAGES"
          onClickMidia={handleSelectImage}
          onClose={() => setOpen(false)}
          open={open}
        />
      )}
      {/* <div>
        <div dangerouslySetInnerHTML={{ __html: value }} />
      </div>
      <hr />
      <div>
        <pre style={{ maxWidth: "100%" }}>{value}</pre>
      </div> */}
    </>
  );
}
