import { ChangeEvent, useCallback, SyntheticEvent, useEffect, useState } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { throttle } from "lodash";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getItems, queryTypes } from "./hooks/queries";
import { SelectOptionProp } from "./hooks/types";

export interface SelectIdQueryProps<UischemaType = any> {
  data: number;
  handleChange(path: string, value: number): void;
  path: string;
  errors: string;
  required?: boolean;
  label: string;
  uischema?: UischemaType;
  enabled?: boolean;
}

export const SelectIdQuery = ({
  data,
  handleChange,
  path,
  errors,
  required,
  uischema,
  label,
  enabled = true,
}: SelectIdQueryProps) => {
  const [options, setOptions] = useState<SelectOptionProp[]>([]);
  const [loading, setLoading] = useState(false);

  const selectedOption = options.find((option) => option?.id === data) || null;

  const handleOnChange = (
    event: ChangeEvent<{}>,
    newValue: { id: number; label: string; active?: boolean } | null
  ) => {
    handleChange(path, newValue ? newValue?.id : null);
  };

  const handleInputChange = useCallback(
    throttle((event: SyntheticEvent, value: string, reason: string) => {
      if (reason === "reset") return;
      console.log("handleInputChange", { value, reason });
      const queryType = uischema?.options?.queryType;
      if (!queryTypes.includes(queryType)) throw new Error(`queryType: "${queryType}" unsupported`);
      setLoading(true);
      getItems({ search: value, queryType, option0: uischema?.options?.option0 })
        .then((newOptions) => {
          setOptions(newOptions);
        })
        .finally(() => setLoading(false));
    }, 3000),
    []
  );

  useEffect(() => {
    console.log("useEffect SelectIdQuery");
    const queryType = uischema?.options?.queryType;
    if (!queryTypes.includes(queryType)) throw new Error(`queryType: "${queryType}" unsupported`);
    setLoading(true);
    getItems({ id: data, queryType, option0: uischema?.options?.option0 })
      .then((newOptions) => {
        setOptions(newOptions);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <FormControl
      variant="standard"
      fullWidth
      required={required}
      error={!!errors}
      sx={{ marginBottom: "10px" }}
    >
      <Autocomplete
        multiple={false}
        options={options}
        getOptionLabel={(option) => option?.label ?? "null"}
        getOptionKey={(option) => option?.id ?? "null"}
        getOptionDisabled={(option) => option?.active === false}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        value={selectedOption}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        loading={loading}
        onOpen={(e) => handleInputChange(e, "", "onOpen")}
        placeholder="Digite para pesquisar...."
        disabled={!enabled}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            label={label.concat(required ? " *" : "")}
            placeholder="Digite para pesquisar...."
            error={!!errors}
            helperText={errors}
          />
        )}
      />
    </FormControl>
  );
};

export default withJsonFormsControlProps(SelectIdQuery, true);
