import useCreate from "./hooks/useCreate";
import UISchemaJson from "./schema/UISchema.json";
import templateSchema from "./schema/SchemaJson.json";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { Fragment, useCallback, useEffect, useState } from "react";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { CourseGroups } from "../Methodologies";
import ModalCustom from "components/CRPComponents/ModalCustom";
import { removeNullValues } from "utils/removeNullValues";
import MDSnackbar from "components/MDSnackbar";
import { EducationalUnit } from "../EducationalUnit";
import { Modalities } from "../Modalities";
import MDButton from "components/MDButton";
import { Box, SxProps } from "@mui/material";
import JsonFormsComponent from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { ShemaJsonTemplate } from "types/SchemaJson";
import useCourseGroups from "./hooks/useMethodology";
import { getCourseStepper } from "./hooks/stepperCourseCreate";

const defaultModalSx = {
  "& .css-aim41u": {
    margin: "0 !important",
  },
};

const editButtonSx: SxProps = {
  color: "white !important",
  borderColor: "none !important",
  background: "#1A73E8 !important",
  transition: "all 0.3s ease-in",
  "&:disabled": {
    color: "#344767 !important",
    border: "1px solid #344767 !important",
    background: "white !important",
  },
};

function Create() {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>(); // State to send to create Course
  const [errorMessage, setErrorMessage] = useState<string>(); //Error message
  const { courseGroups, setCourseGroups } = useCourseGroups(null);
  const [open, setModal] = useState(false);
  const [infoTemplate, setInfoTemplate] = useState<ShemaJsonTemplate>(); //Template from contentJson form
  const [infoState, setInfoState] = useState<JsonFormsStateProps<any>>({}); //State from contentJson

  const handleModal = useCallback(() => setModal((prev) => !prev), []);

  useEffect(() => {
    try {
      const data = JSON.parse(state?.data?.contentJson || "");
      setInfoState((prev) => ({ ...prev, data }));
    } catch (error) {
      setInfoState({});
    }
  }, [state?.data?.contentJson, open]);

  useEffect(() => {
    const template = courseGroups?.find(({ id }) => id === state?.data?.courseGroupId);
    setInfoTemplate(template?.category.templateSchemaDefault || undefined);

    handlecontentJson(undefined);
  }, [state?.data?.courseGroupId, courseGroups]);

  const handlecontentJson = (data?: string) => {
    setState((prev) => {
      if (prev && infoState?.data) {
        return {
          ...prev,
          data: {
            ...prev.data,
            contentJson: data,
          },
        };
      }
      return prev;
    });
  };

  const saveInfo = async () => {
    handlecontentJson(JSON.stringify(infoState.data));

    handleModal();
  };

  const updateData = (key: keyof SchemaData, value: any) => {
    setState((old) => {
      const newData = {
        ...old.data,
        [key]: value,
      };

      return {
        ...old,
        data: removeNullValues(newData),
      };
    });
  };

  if (!templateSchema) return null;

  return (
    <Fragment>
      <MDSnackbar
        open={!!errorMessage}
        title={errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        close={() => setErrorMessage(undefined)}
        color="error"
        icon="error"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <GenericMethods.Create<SchemaData, CreateGql>
        siteId={Sites.UNIFTEC.id}
        sitePath={Sites.UNIFTEC.path}
        useCreate={useCreate}
        template={{
          schemaJson: templateSchema,
          UISchema: UISchemaJson,
        }}
        state={state}
        setState={setState}
        stepper={getCourseStepper(0)}
      >
        <MDButton disabled={!!!infoTemplate} sx={editButtonSx} onClick={handleModal}>
          Editar Conteúdo
        </MDButton>
      </GenericMethods.Create>
      <ModalCustom
        sx={defaultModalSx}
        open={state?.data.courseGroupId === 0}
        onClose={() => updateData("courseGroupId", undefined)}
      >
        <CourseGroups.Create
          showNavBar={false}
          onSuccess={(state) => {
            setCourseGroups((prev) => prev.concat(state.createCourseGroup));
            if (state.createCourseGroup.active) {
              updateData("courseGroupId", state.createCourseGroup.id);
            } else {
              updateData("courseGroupId", undefined);
              setErrorMessage("Não é possivel selecionar um grupo de curso inativo");
            }
          }}
        />
      </ModalCustom>
      <ModalCustom
        sx={defaultModalSx}
        open={state?.data.educationalUnitId === 0}
        onClose={() => updateData("educationalUnitId", undefined)}
      >
        <EducationalUnit.Create
          showNavBar={false}
          onSuccess={(state) => {
            if (state.createEducationalUnit.active) {
              updateData("educationalUnitId", state.createEducationalUnit.id);
            } else {
              updateData("educationalUnitId", undefined);
              setErrorMessage("Não é possivel selecionar um Polo inativo");
            }
          }}
        />
      </ModalCustom>
      <ModalCustom
        sx={defaultModalSx}
        open={state?.data.modalityId === 0}
        onClose={() => updateData("modalityId", undefined)}
      >
        <Modalities.Create
          showNavBar={false}
          onSuccess={(state) => {
            if (state.createModality.active) {
              updateData("modalityId", state.createModality.id);
            } else {
              updateData("modalityId", undefined);
              setErrorMessage("Não é possivel selecionar uma Modalidade inativa");
            }
          }}
        />
      </ModalCustom>
      <ModalCustom open={open} onClose={handleModal}>
        {infoTemplate && (
          <Box margin={3}>
            <JsonFormsComponent
              template={infoTemplate}
              state={infoState}
              onChange={setInfoState}
              onSave={saveInfo}
            />
          </Box>
        )}
      </ModalCustom>
    </Fragment>
  );
}

export default Create;
