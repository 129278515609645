import { withJsonFormsControlProps } from "@jsonforms/react";
import { Box, Stack } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import MDBox from "components/MDBox";
import { Fragment, memo, useMemo, useState } from "react";
import lodash from "lodash";
import { Color, ColorPicker, useTranslate } from "material-ui-color";
import { CustomRendererProps } from "../types";

const CustomColorPicker = ({
  data,
  handleChange,
  path,
  label,
  required,
  errors,
  enabled,
}: CustomRendererProps<string>) => {
  const [color, setColor] = useState(data);

  const throttledHandleChange = lodash.throttle((_color: Color) => {
    if (_color?.format === "unknown") return;
    if (_color?.css?.backgroundColor) setColor(_color.css.backgroundColor);
  }, 1000);

  useMemo(() => handleChange(path, color), [color]);

  const translate = (value: string) => {
    // let valueTranslated;
    // if (value && language === 'frFR') valueTranslated = frFR[value];
    // return valueTranslated || value;
    if (value === "Set") return "Selecionar";
    return value;
  };
  useTranslate(() => ({ i18n: { language: "ptBR" }, t: translate }));

  return (
    <Fragment>
      <MDBox>
        <Stack gap={1} margin={"10px 0"}>
          <Box
            component={"p"}
            sx={{
              fontFamily: "Roboto",
              fontSize: "0.9rem",
              fontWeight: "400",
              lineHeight: "1.4375em",
              letterSpacing: "0.00938em",
              padding: 0,
              position: "relative",
              color: !!errors ? "#F44335" : "#7b809a",
            }}
          >
            {label}
            {required && " *"}
          </Box>
          <Box>
            <ColorPicker
              value={color}
              onChange={(prop) => (enabled ? throttledHandleChange(prop) : {})}
              disableTextfield
              deferred
            />
          </Box>
        </Stack>
        {!!errors && <FormHelperText error>{errors}</FormHelperText>}
      </MDBox>
    </Fragment>
  );
};

export default withJsonFormsControlProps(memo(CustomColorPicker), true);
