import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { MDSnackbarProps } from "components/MDSnackbar";
import { ShemaJsonTemplate } from "types/SchemaJson";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

export interface UseGetItemProps<T> {
  setTemplate: React.Dispatch<React.SetStateAction<ShemaJsonTemplate>>;
  setState: React.Dispatch<React.SetStateAction<JsonFormsStateProps<T>>>;
  queryParam: number | string;
}

export interface UseDeleteProps {
  queryParam: number | string;
  setSnackBarInfo: React.Dispatch<React.SetStateAction<Omit<MDSnackbarProps, "close" | "onClose">>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UseUpdateProps<T> {
  queryParam: number | string;
  setState: React.Dispatch<React.SetStateAction<JsonFormsStateProps<T>>>;
}

export const ApiRequest = ApiFinancialRequest;

export interface SchemaData {
  id: number;
  active: boolean;
  name: string;
  allowPurchase: boolean;
  allowGift: boolean;
  urlInscription?: string;
  title: string;
  subtitle?: string;
  description?: string;
  orientationLabelButtonDefault: string;
  orientationContentDefault: string;
  maxAdmissionWays?: number;
  hideSelectAdmissionWays: boolean;
  admissionWayLabel: string;
  maxPlans?: number;
  hideSelectPlans: boolean;
  planLabel: string;
  maxAmountInstallmentsPrices?: number;
  installmentsPricesLabel: string;
  hideSelectInstallmentsPrices: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export const path = "/ecommerce/template-de-investimento";
