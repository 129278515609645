import { useParams } from "react-router-dom";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import useGetItem from "./hooks/useGetItem";
import useDelete from "./hooks/useDelete";
import useUpdate from "./hooks/useUpdate";
import { path, SchemaData } from "./hooks/types";
import { UpdateGql } from "./queries/update";
import { Colligates } from ".";

function Update() {
  const params = useParams()?.params;

  if (!params) return null;

  const [databaseName, id] = String(params).split(/[\/]|%2F/g);

  if (!databaseName || !id) return <div>Ops!</div>;

  return (
    <GenericMethods.Update<SchemaData, UpdateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath={path}
      useDelete={useDelete}
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={params}
      updatePermission={Colligates.Permissions.edit}
      deletePermission={Colligates.Permissions.delete}
    />
  );
}

export default Update;
