import { useCallback, useState } from "react";
import { DeleteButton, DeleteButtonProps } from "../components/DeleteButton";
import {
  MUTATION_DELETE_SCHOLAR_SHIP,
  ResponseDeleteScholarship,
} from "data/queries/ApiCourse/ScholarshipAndIncentive/MutationDeleteScholarshipAndIncentive";
import { ApiCourseRequest } from "data/queries/ApiCourse";

export interface ButtonDeleteScholarShipProps
  extends Omit<DeleteButtonProps<number>, "propDelete" | "handleDelete"> {
  onDeleteSuccess?: (id: number) => void;
  onDeleteError?: (err: Error) => void;
  deleteScholarshipAndIncentiveId: number;
}

export function ButtonDeleteScholarShip({
  onDeleteSuccess,
  onDeleteError,
  deleteScholarshipAndIncentiveId,
  ...props
}: ButtonDeleteScholarShipProps) {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiCourseRequest<ResponseDeleteScholarship>({
        query: MUTATION_DELETE_SCHOLAR_SHIP,
        variables: { deleteScholarshipAndIncentiveId },
      });

      if (!response?.data?.data?.deleteScholarshipAndIncentive?.id) {
        if (response.data?.errors?.[0]) throw response.data?.errors?.[0];
        throw new Error(JSON.stringify(response?.data || "Something wrong"));
      }

      onDeleteSuccess?.(response.data.data.deleteScholarshipAndIncentive?.id);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      onDeleteError?.(error);
    }
  }, [deleteScholarshipAndIncentiveId]);

  if (!deleteScholarshipAndIncentiveId) return null;
  return (
    <DeleteButton
      {...props}
      propDelete={deleteScholarshipAndIncentiveId}
      handleDelete={handleDelete}
      loading={loading}
    />
  );
}

export default ButtonDeleteScholarShip;
