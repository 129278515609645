import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeleteCalendar($id: Int!) {
    deleteCalendar(id: $id) {
      id
    }
  }
`);

export interface DeleteGql {
  deleteCalendar: {
    id?: number;
  };
}
