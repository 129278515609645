import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query EducationalGroups {
    EducationalGroups(perPage: -1) {
      items {
        id
        title
      }
    }
  }
`);

export interface QueryGql {
  EducationalGroups: {
    items: Array<{
      id: number;
      title: string;
    }>;
  };
}

export default function useEducationalGroups() {
  const [educationalGroups, setEducationalGroups] = useState<
    null | QueryGql["EducationalGroups"]["items"]
  >(null);

  useEffect(() => {
    ApiCourseRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => setEducationalGroups(data.data.EducationalGroups.items));
  }, []);

  return {
    educationalGroups,
  };
}
