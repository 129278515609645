import { BuyAsGiftCustomAppFields, GetItemGql } from "./queries/getItem";

export function generateDateRange(interval: "1 Day" | "7 Day" | "1 Month" | "1 Year"): string {
  const endDate = new Date();
  const startDate = new Date(endDate);

  switch (interval) {
    case "1 Day":
      startDate.setDate(startDate.getDate() - 1);
      break;
    case "7 Day":
      startDate.setDate(startDate.getDate() - 7);
      break;
    case "1 Month":
      startDate.setMonth(startDate.getMonth() - 1);
      break;
    case "1 Year":
      startDate.setFullYear(startDate.getFullYear() - 1);
      break;
    default:
      throw new Error("Intervalo inválido");
  }

  const formattedStartDate = startDate.toISOString();
  const formattedEndDate = endDate.toISOString();

  return `${formattedStartDate} TO ${formattedEndDate}`;
}

export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

export const getBuyAsGiftProducts = (
  customData: GetItemGql["Order"]["customData"]
): BuyAsGiftCustomAppFields | undefined => {
  try {
    const buyAsGiftCustomApp = customData?.customApps.find(({ id }) => id === "buyasgift");
    return JSON.parse(buyAsGiftCustomApp?.fields?.buyAsGiftJSON) as BuyAsGiftCustomAppFields;
  } catch {
    return undefined;
  }
};
