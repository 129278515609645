import React, { useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  MidiaGalleryWithUpload,
  VariablesProps,
} from "components/CRPComponents/Forms/MidiaGalleryWithUpload";
import Modal from "components/Modal";
import { MidiaViewWithDelete } from "components/CRPComponents/Forms/MidiaView";
import { MidiaDeleteButton } from "components/CRPComponents/Forms/MidiaDeleteButton";
import Row from "components/Row";
import MDSnackbar from "components/MDSnackbar";
import { userPermissions } from "hooks/Permissions";
import { Permissions } from "./permissions";

interface Midia {
  id: number;
  name: string;
  path: string;
  type: string;
  size?: number;
  uploadedAt?: Date | string;
}
const INITIAL_SEARCH = {
  page: 1,
  perPage: 12,
};
export default function MidiasList(): JSX.Element {
  const [variablesSearch, setVariablesSearch] = useState<VariablesProps>(INITIAL_SEARCH);
  const permission = userPermissions({
    createPermision: Permissions.create,
    deletePermision: Permissions.delete,
    updatePermision: Permissions.update,
  });

  const [midia, setMidia] = React.useState<Midia | undefined>(undefined);
  const [messageDelete, setMessageDelete] = React.useState<string>("");
  const handleCloseModal = () => setMidia(undefined);
  const handleCloseSnackbar = () => setMessageDelete("");
  const handleSuccessDelete = (id: number) => {
    setMessageDelete(`Mídia id ${id} deletada com sucesso!`);
    setVariablesSearch({ ...INITIAL_SEARCH });
    handleCloseModal();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MidiaGalleryWithUpload
          onClickMidia={setMidia}
          onChangeVariables={setVariablesSearch}
          variables={variablesSearch}
          hideUploadButton={!permission.create}
        />
        <Modal open={!!midia?.id} onClose={handleCloseModal} title={midia?.name || "Mídia"}>
          <MDBox>
            <MidiaViewWithDelete midia={midia} />
            <Row justifyContent="center">
              <MidiaDeleteButton
                deleteMediaFileId={midia?.id}
                onDeleteSuccess={handleSuccessDelete}
                disabled={!permission.delete}
              />
            </Row>
          </MDBox>
        </Modal>
        <MDSnackbar
          color="success"
          icon="check"
          title={messageDelete}
          open={!!messageDelete}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          close={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
