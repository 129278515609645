import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation Update($data: UpdateInvestmentInfoInput!) {
    updateInvestmentInfo(data: $data) {
      id
    }
  }
`);

export const responseUpdateKey = "updateInvestmentInfo";
export interface UpdateGql {
  updateInvestmentInfo: {
    id: number;
  };
}
