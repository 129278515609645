import { RankedTester, and, rankWith, optionIs, schemaMatches, isControl } from "@jsonforms/core";

export const SearchMultipleTester: RankedTester = rankWith(
  10,
  and(
    isControl,
    optionIs("SearchMultiple", true),
    schemaMatches((schema: any) => {
      const properties = schema?.items?.properties;
      const type = schema?.items?.type;
      const rootType = schema?.type;

      return (
        rootType === "array" &&
        type === "object" &&
        properties?.id?.type === "string" &&
        properties?.name?.type === "string"
      );
    })
  )
);

export default SearchMultipleTester;
