import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import {
  CellProps,
  FormTypeEnum,
  GET_ALL_QUERY,
  GetAllGql,
  GetAllItem,
  QueryArgs,
  StatusEnum,
} from "./queries/getAll";
import { ApiAuthWithSetData } from "data/ApiForm";
import { Fragment, useState } from "react";
import MDInput from "components/MDInput";
import { MenuItem } from "@mui/material";
import { AnalysisButton } from "../BlogComments/styles";
import { useNavigate } from "react-router-dom";

function List(props: ListProps) {
  const navigate = useNavigate();
  const [initalVariables, setInitalVariables] = useState<QueryArgs>({
    status: null,
    isDefaultForm: false,
    formType: "DINAMIC",
    page: null,
    perPage: null,
    search: null,
  });

  const formatStatus = (status: StatusEnum) => {
    switch (status) {
      case "DRAFT":
        return "Rascunho";
      case "ACTIVE":
        return "Ativo";
      case "INACTIVE":
        return "Inativo";
      default:
        return "";
    }
  };

  const formtFormType = (formType: FormTypeEnum) => {
    switch (formType) {
      case "DINAMIC":
        return "DINÂMICO";
      case "EVENT":
        return "Evento";
      default:
        return "";
    }
  };

  const handleNavigateToEntries = (data: GetAllItem) => {
    navigate(`${props.path}/forms/${data.slug}/entries`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={initalVariables}
        path={`${props.path}/forms`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Forms.items}
        formatPagination={(data) => data.Forms?.paginationInfo}
        title="NOVO FORMULÁRIO"
        ApiSetData={ApiAuthWithSetData}
        useDefaultFilter={false}
        childrenFilters={
          <Fragment>
            <MDInput
              value={initalVariables.status}
              label="Status"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInitalVariables((old) => {
                  return {
                    ...old,
                    status: e.target.value,
                  } as QueryArgs;
                })
              }
              sx={{ width: "130px" }}
              select
            >
              <MenuItem value={null}>Todos</MenuItem>
              <MenuItem value={"ACTIVE"}>Ativos</MenuItem>
              <MenuItem value={"INACTIVE"}>Inativos</MenuItem>
              <MenuItem value={"DRAFT"}>Rascunho</MenuItem>
            </MDInput>

            <MDInput
              value={initalVariables.formType}
              label="Tipo de formulário"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInitalVariables((old) => {
                  return {
                    ...old,
                    formType: e.target.value,
                  } as QueryArgs;
                })
              }
              sx={{ width: "200px" }}
              select
            >
              <MenuItem value={"DINAMIC"}>Dinamico</MenuItem>
              <MenuItem value={"EVENT"}>Eventos</MenuItem>
            </MDInput>
          </Fragment>
        }
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/forms/${value}`} value={value} />
            ),
          },
          {
            Header: "Slug",
            accessor: "slug",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Título",
            accessor: "title",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }: any) => <DefaultCell value={formatStatus(value)} />,
          },
          {
            Header: "Tipo de formulário",
            accessor: "formType",
            Cell: ({ value }: any) => <DefaultCell value={formtFormType(value)} />,
          },
          {
            Header: "Repostas",
            accessor: "",
            Cell: ({ value, cell }: CellProps) => (
              <AnalysisButton onClick={() => handleNavigateToEntries(cell.row.original)}>
                Visualizar
              </AnalysisButton>
            ),
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
