import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query ProviderHabilitations(
    $page: Int
    $perPage: Int
    $search: String
    $isValityCourse: Boolean
  ) {
    ProviderHabilitations(
      page: $page
      perPage: $perPage
      search: $search
      isValityCourse: $isValityCourse
    ) {
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
      items {
        id
        valityCourseErrors
        name
        idCourse
        idAppliedMatrice
        idHabilitation
        databaseName
        idColligate
        idCourseType
        categoryConversionId
        category {
          id
          categorySlug
        }
        idBranch
        educationalUnitConversionId
        educationalUnit {
          id
          educationalUnitVisibleName
        }

        idGrade
        idShift
        activeOnProvider
        courseLoadHours
        totalCredits
        maxCredPeriod
        minCredPeriod
        recModifiedOn_CM
        excludesCreditCardPayment
        decree
        description
        idHabilitationType
        recModifiedOn
        imageURL
        slug
        slugCourseGroup
        slugSugestion
        slugCourseGroupSugestion
        createdAt
        updatedAt
        providerCourse {
          id
          name
          idCourse
          idSchool
          inPersonOrDistance
          idModality
          recModifiedOn
          descriptionShort
          idArea
          teachingAreaConversionId
          teachingArea {
            id
            teachingAreaName
          }
          modalityConversionId
          modality {
            id
            modalityName
          }
          createdAt
          updatedAt
          colligate {
            id
            partnerVisibleName
          }
        }
      }
    }
  }
`);

export interface item {
  id: number;
  valityCourseErrors?: string[];
  name: string;
  idCourse: string;
  idAppliedMatrice: number;
  idHabilitation: string;
  databaseName: string;
  idColligate: number;
  idCourseType: number;
  categoryConversionId?: number;
  category?: {
    id: number;
    categorySlug: string;
    idCourseType: number;
  };
  idBranch: number;
  educationalUnitConversionId?: number;
  educationalUnit?: {
    id: number;
    idBranch: number;
    educationalUnitVisibleName: string;
  };
  idGrade: string;
  idShift: number;
  activeOnProvider: string;
  courseLoadHours: number;
  totalCredits?: number;
  maxCredPeriod?: number;
  minCredPeriod?: number;
  recModifiedOn_CM?: Date;
  excludesCreditCardPayment?: string;
  decree?: string;
  description?: string;
  idHabilitationType?: string;
  recModifiedOn?: Date;
  imageURL?: string;
  slug?: string;
  slugCourseGroup?: string;
  slugSugestion?: string;
  slugCourseGroupSugestion?: string;
  createdAt: Date;
  updatedAt: Date;
  providerCourse: {
    id: number;
    name: string;
    idCourse: string;
    idSchool?: number;
    inPersonOrDistance?: string;
    idModality?: string;
    recModifiedOn?: Date;
    descriptionShort?: string;
    idArea?: number;
    teachingAreaConversionId?: number;
    teachingArea?: {
      id: number;
      idArea: number;
      teachingAreaName: string;
    };
    modalityConversionId?: number;
    modality?: {
      id: number;
      inPersonOrDistance: string;
      idModality: string;
      modalityName: string;
    };
    createdAt: Date;
    updatedAt: Date;
    colligate: {
      id: number;
      databaseName: string;
      active: boolean;
      partnerVisibleName: string;
    };
  };
}

export interface GetAllGql {
  ProviderHabilitations: {
    items: item[];
    paginationInfo: PaginationInfo;
  };
}
