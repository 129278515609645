import { useEffect, useState } from "react";
import { SchemaDataContentJson, UseGetItemProps } from "./types";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import { ApiCourseRequest } from "data/queries/ApiCourse";

const useGetItemInfo = ({
  setState,
  setTemplate,
  queryParam,
}: UseGetItemProps<SchemaDataContentJson>) => {
  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCourseRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.Course) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = response.data.data?.Course;
          const schemaJson = data.courseGroup.category?.templateSchemaDefault?.schemaJson || "{}";
          const UISchema = JSON.stringify({
            type: "VerticalLayout",
            elements: [
              {
                type: "Label",
                text: data.name,
              },
              JSON.parse(data.courseGroup.category?.templateSchemaDefault?.UISchema || "{}"),
            ],
          });

          // console.log("useGetItemInfo", {
          //   state: removeNullValues({ data: JSON.parse(data?.info?.contentJson || "{ }") }),
          //   template: { UISchema, schemaJson },
          //   data: data,
          // });
          setState(removeNullValues({ data: JSON.parse(data?.info?.contentJson || "{ }") }));
          setTemplate({ UISchema, schemaJson });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItemInfo;
