import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Blogs($perPage: Int, $search: String, $page: Int, $status: StatusEnum) {
    Blogs(perPage: $perPage, search: $search, page: $page, status: $status) {
      items {
        title
        name
        id
        autor
        status
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  title: string;
  name: string;
  autor: string;
  status: string;
}

export interface GetAllGql {
  Blogs: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
