import { useParams } from "react-router-dom";
import { useState } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import useCreate from "./hooks/useCreate";
import { SchemaDataCreate } from "./hooks/types";
import { CreateGql } from "./queries/create";
import useTemplate from "./hooks/useTemplate";

function Create() {
  const courseId = Number(useParams()?.courseId);
  if (!courseId) return null;

  const { template, state, setState } = useTemplate(true, courseId);
  if (!template) return null;

  return (
    <GenericMethods.Create<SchemaDataCreate, CreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      useCreate={useCreate}
      template={template}
      state={state}
      setState={setState}
    />
  );
}

export default Create;
