import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Subjects($id: Int!) {
    Subject(id: $id) {
      id
      name
      description
      hours
      group
      position
      ref
    }
  }
`);

export interface GetItemGql {
  Subject: {
    id: number;
    name: string;
    description: string;
    hours: number;
    group: string;
    position: number;
    ref: string;
  };
}
