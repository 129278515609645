import { useParams } from "react-router-dom";
import FormUpdateScholarShip from "components/CRPComponents/Forms/FormScholarShip/FormUpdateScholarShip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function UpdateScholarShipUniftec({
  inicialEditing = false,
}: {
  inicialEditing?: boolean;
}): JSX.Element {
  let { id } = useParams();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!id ? (
        <h1> Erro Id </h1>
      ) : (
        <FormUpdateScholarShip idScholarShip={Number(id)} inicialEditing={true} path="/uniftec" />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateScholarShipUniftec;
