import Chip from "@mui/material/Chip";

// Declaring props types for TagCell
interface TagCellProps {
  value: string;
  color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

function TagCell({ value, color }: TagCellProps): JSX.Element {
  return <Chip color={color} label={value} variant="outlined" sx={{ borderRadius: 1 }} />;
}

export default TagCell;
