export const MUTATION_CREATE_SHIP_AND_INCENTIVE = `mutation Mutation($data: CreateScholarshipAndIncentiveInput!) {
  createScholarshipAndIncentive(data: $data) {
    title
    id
  }
}`;

export type ResponseCreateScholarShip = {
  createScholarShip: {
    id: number;
    active: boolean;
  };
};
