import { IsInt, IsNotEmpty, Length, IsOptional, IsPositive } from "class-validator";

export class UpdateBannerInput {
  @IsInt()
  @IsNotEmpty()
  id: number;

  @IsOptional()
  @IsNotEmpty()
  @Length(3, 255)
  name?: string;

  @IsOptional()
  @IsNotEmpty()
  @Length(3, 255)
  title?: string;

  @IsOptional()
  description?: string;

  @IsOptional()
  @IsNotEmpty()
  url?: string;

  @IsOptional()
  @IsNotEmpty()
  targetBlank?: boolean;

  @IsOptional()
  @IsNotEmpty()
  active?: boolean;

  @IsOptional()
  @IsNotEmpty()
  @IsInt()
  sort?: number;

  @IsOptional()
  @IsNotEmpty()
  @IsPositive()
  @IsInt()
  idSite?: number;

  @IsOptional()
  @IsNotEmpty()
  @IsPositive()
  @IsInt()
  idMediaFileDesktop?: number;

  @IsOptional()
  @IsNotEmpty()
  @IsPositive()
  @IsInt()
  idMediaFileMobile?: number;

  @IsOptional()
  locale?: string | null;
}
