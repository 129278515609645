import Create from "./Create";
import List from "./List";
import Update from "./Update";
import UpdateInfo from "./UpdateInfo";
import CreateCourseFinancial from "./CreateCourseFinancial";

export const Courses = {
  List: List,
  Create: Create,
  Update: Update,
  UpdateInfo: UpdateInfo,
  CreateCourseFinancial: CreateCourseFinancial,
  Permissions: {
    create: "create:Course",
    read: "read:Course",
    list: "list:Course",
    update: "update:Course",
    delete: "delete:Course",
  },
};
