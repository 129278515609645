export const schemaJson = {
  type: "object",
  properties: {
    name: {
      type: "string",
    },
    content: {
      type: "string",
    },
    templateInvestmentName: {
      type: "string",
    },
  },
  required: ["name", "templateInvestmentName"],
};
