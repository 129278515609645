import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateEducationalGroup($data: UpdateEducationalGroupInput!) {
    updateEducationalGroup(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateEducationalGroup: {
    id: number;
  };
}
