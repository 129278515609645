import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query GetOne($id: Int!) {
    PlanAdmissionWay(id: $id) {
      id
      title
      tag
      admissionWayId
    }
  }
`);

export const responseGetItemKey = "PlanAdmissionWay";
export interface GetItemGql {
  PlanAdmissionWay: {
    id: number;
    title: string;
    tag?: string;
    admissionWayId: number;
  };
}
