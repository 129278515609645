import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { StatusEnum } from "types/core";

export const GET_ITEM_QUERY = print(gql`
  query Event($id: Int) {
    Event(id: $id) {
      id
      title
      slug
      descriptionContentHTML
      programationJSON
      registration
      localization
      address
      hourInit
      formId
      createdAt
      updatedAt
      publishOn
      expiresIn
      dateInit
      dateFinish
      status
      mediaFileDesktop {
        id
      }
      mediaFileMobile {
        id
      }
      modality
    }
  }
`);

export interface GetItemGql {
  Event: {
    formId: string;
    localization: string;
    address: string;
    createdAt: string;
    dateFinish: string;
    dateInit: string;
    descriptionContentHTML: string;
    expiresIn: string;
    hourInit: string;
    id: number;
    mediaFileDesktop: {
      id: number;
    };
    mediaFileMobile: {
      id: number;
    };
    modality: EventModalityEnum;
    programationJSON: string;
    publishOn: string;
    registration: string;
    slug: string;
    status: StatusEnum;
    title: string;
    updatedAt: string;
  };
}

export type EventModalityEnum = "ONLINE" | "PRESENCIAL";
