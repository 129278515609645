import { useState, useEffect, useCallback, Fragment } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import MDButton from "components/MDButton";
import ModalCustom from "components/CRPComponents/ModalCustom";
import JsonFormsComponent from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { UpdateGql } from "./queries/update";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import useGetItem from "./hooks/useGetItem";
import useUpdate from "./hooks/useUpdate";
import useCourseGroups from "./hooks/useMethodology";
import { ShemaJsonTemplate } from "types/SchemaJson";
import { SchemaData } from "./hooks/types";
import { getCourseStepper } from "./hooks/stepperCourseCreate";

function Update() {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>();
  const [open, setModal] = useState(false);
  const [infoTemplate, setInfoTemplate] = useState<ShemaJsonTemplate | undefined>();
  const [infoState, setInfoState] = useState<JsonFormsStateProps<any>>({});
  const { courseGroups } = useCourseGroups(null);
  const id = Number(useParams()?.id);

  const handleModal = useCallback(() => setModal((prev) => !prev), []);

  useEffect(() => {
    try {
      const data = JSON.parse(state?.data?.contentJson || "");
      setInfoState((prev) => ({ ...prev, data }));
    } catch (error) {
      setInfoState({});
    }
  }, [state?.data?.contentJson]);

  useEffect(() => {
    const template = courseGroups?.find(({ id }) => id === state?.data?.courseGroupId);
    setInfoTemplate(template?.category.templateSchemaDefault || undefined);

    // handlecontentJson(undefined);
  }, [state?.data?.courseGroupId, courseGroups]);

  const handlecontentJson = (data?: string) => {
    setState((prev) => {
      if (prev && infoState?.data) {
        return {
          ...prev,
          data: {
            ...prev.data,
            contentJson: data,
          },
        };
      }
      return prev;
    });
  };

  const saveInfo = async () => {
    handlecontentJson(JSON.stringify(infoState.data));

    handleModal();
  };

  if (!id) return null;

  return (
    <Fragment>
      <GenericMethods.Update<SchemaData, UpdateGql>
        siteId={Sites.UNIFTEC.id}
        sitePath={Sites.UNIFTEC.path}
        redirectPath="/ecommerce/cursos"
        useGetItem={useGetItem}
        useUpdate={useUpdate}
        queryParam={id}
        state={state}
        setState={setState}
        hideDeleteButton
        stepper={getCourseStepper(0, id)}
      >
        {state?.data?.courseGroupId && (
          <MDButton
            variant="outlined"
            color="secondary"
            href={`/ecommerce/grupo-de-cursos/${state?.data?.courseGroupId}`}
          >
            Ver Grupo de Curso
          </MDButton>
        )}
        <MDButton
          disabled={!infoTemplate}
          sx={{
            color: "white !important",
            borderColor: "none !important",
            background: "#1A73E8 !important",
            transition: "all 0.3s ease-in",
            "&:disabled": {
              color: "#344767 !important",
              border: "1px solid #344767 !important",
              background: "white !important",
            },
          }}
          onClick={handleModal}
        >
          Editar Conteúdo
        </MDButton>
      </GenericMethods.Update>
      <ModalCustom open={open && !!infoTemplate} onClose={handleModal}>
        {infoTemplate && (
          <Box margin={3}>
            <JsonFormsComponent
              template={infoTemplate}
              state={infoState}
              onChange={setInfoState}
              onSave={saveInfo}
            />
          </Box>
        )}
      </ModalCustom>
    </Fragment>
  );
}

export default Update;
