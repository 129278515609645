import { MDButton, MDButtonProps } from "components/MDButton";
import { MDTypography, MDTypographyProps } from "components/MDTypography";

export function ButtonTwoLines({
  title,
  titleProps,
  children,
  typographyProps,
  color = "info",
  size = "large",
  sx = {},
  ...props
}: ButtonTwoLinesProps) {
  return (
    <MDButton color={color} size={size} sx={{ flexDirection: "column", ...sx }} {...props}>
      <MDTypography variant="h2" color="inherit" {...titleProps}>
        {title}
      </MDTypography>
      <MDTypography color="inherit" {...typographyProps}>
        {children}
      </MDTypography>
    </MDButton>
  );
}

export interface ButtonTwoLinesProps extends Omit<MDButtonProps, "children"> {
  title?: MDTypographyProps["children"];
  children: MDTypographyProps["children"];
  titleProps?: Omit<MDTypographyProps, "children">;
  typographyProps?: Omit<MDTypographyProps, "children">;
}

export default ButtonTwoLines;
