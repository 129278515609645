import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query FindManyUsers($search: String) {
    findManyUsers(search: $search) {
      active
      id
      role {
        name
      }
      email
      userName
      firstName
      lastName
    }
  }
`);

export interface GetAllItem {
  active: string;
  id: string;
  role: {
    name: string;
  };
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
}

export interface GetAllGql {
  findManyUsers: GetAllItem[];
}
