import Container from "./Container";
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconMui from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import TooltipMui from "@mui/material/Tooltip";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useData } from "./context/DataProvider";
import { Fragment, useEffect, useState } from "react";
import { QUERY_CONVERSION_RATE, QueryConversionRateGql } from "./queries/conversionRate";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";

function ConversionRate() {
  const [data, setData] = useState<QueryConversionRateGql["ConversionRate"]>();
  const [loading, setLoading] = useState(false);
  const { dateSelected } = useData();

  useEffect(() => {
    setLoading(true);
    ApiFinancialRequest<QueryConversionRateGql>({
      query: QUERY_CONVERSION_RATE,
      variables: {
        compareStartDate: dateSelected.from.value.toISOString(),
        startDate: dateSelected.from.value.toISOString(),
        compareEndDate: dateSelected.to.value.toISOString(),
        endDate: dateSelected.to.value.toISOString(),
      },
    })
      .then(({ data }) => setData(data.data.ConversionRate))
      .finally(() => setLoading(false));
  }, [dateSelected]);

  const getHour = (dateStr: string) => {
    const date = new Date(dateStr);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const formatToPercentage = (value: number) => {
    return parseFloat((value * 100).toFixed(2));
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Container
          sx={{
            width: "fit-content",
            height: "auto",
            borderRadius: 0,
            span: {
              fontWeight: 400,
              fontSize: "0.8rem",
            },
          }}
        >
          <Stack gap={"5px"}>
            <Stack>
              <Box component={"span"} color={"GrayText"}>
                {dateSelected.from.label}, {data.hour}
              </Box>
              <Box component={"span"}>{data.referenceValue}%</Box>
            </Stack>
          </Stack>
          <Box component={"span"} color={"GrayText"}>
            {dateSelected.to.label}, {data.hour}
          </Box>
          <Box component={"span"}>{data.comparedValue}%</Box>
        </Container>
      );
    }

    return null;
  };

  return (
    <Container
      title="Taxa de conversão"
      gap={"20px"}
      sx={{
        width: "100%",
        ".recharts-text tspan": {
          fontSize: "1rem",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          color: "#344767",
          fontWeight: 400,
        },
        ".recharts-default-legend": {
          display: "none",
        },
      }}
    >
      {!data || loading ? (
        <Stack alignItems={"center"}>
          <CircularProgress sx={{ color: "#344767", width: "30px", height: "30px" }} />
        </Stack>
      ) : (
        <Fragment>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox display="flex">
              <MDBadgeDot
                sx={{
                  i: {
                    background: "#1A73E8 !important",
                  },
                }}
                size="md"
                badgeContent={dateSelected.from.label}
              />
              <MDBadgeDot
                sx={{
                  i: {
                    background: "#7b809a !important",
                  },
                }}
                size="md"
                badgeContent={dateSelected.to.label}
              />
            </MDBox>
            <MDBox>
              <TooltipMui
                title="Análise histórica do total de pedidos/total de sessões no período selecionado"
                placement="left"
                arrow
              >
                <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                  <IconMui>priority_high</IconMui>
                </MDButton>
              </TooltipMui>
            </MDBox>
          </MDBox>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              data={data.data.map((item) => ({
                ...item,
                hour: getHour(item.referenceDate),
                referenceValue: formatToPercentage(item.referenceValue),
                comparedValue: formatToPercentage(item.comparedValue),
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="hour"
                label={{ value: "Horas", position: "insideBottomRight", dy: 10 }}
              />
              <YAxis label={{ value: "%", position: "insideLeft", dy: -55 }} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line
                type="monotone"
                dataKey="referenceValue"
                name={dateSelected.from.label}
                stroke="#1A73E8"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="comparedValue"
                name={dateSelected.to.label}
                stroke="#7b809a"
                strokeWidth={1}
              />
            </LineChart>
          </ResponsiveContainer>
        </Fragment>
      )}
    </Container>
  );
}

export default ConversionRate;
