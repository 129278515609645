import Create from "./Create";
import { path } from "./hooks/types";
import List from "./List";
import Update from "./Update";

export const AdmissionWays = {
  List: List,
  Update: Update,
  Create: Create,
  path: path,
  Permissions: {
    read: "read:AdmissionWay",
    create: "create:AdmissionWay",
    delete: "delete:AdmissionWay",
    list: "list:AdmissionWay",
    edit: "update:AdmissionWay",
  },
};
