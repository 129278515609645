import CircularProgress from "@mui/material/CircularProgress";
import DescriptionIcon from "@mui/icons-material/Description";
import MDButton from "components/MDButton";
import exportFromJSON from "export-from-json";
import { useState } from "react";

type ProsType = {
  dowloadSvgJson?: object | object[];
  allFildsSvgh?: Record<string, string> | string[];
};

export const DowloadCSV = ({ allFildsSvgh, dowloadSvgJson }: ProsType) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleclick = () => {
    setLoading(true);
    try {
      exportFromJSON({
        data: dowloadSvgJson,
        fileName: "download",
        exportType: exportFromJSON.types.csv,
        fields: allFildsSvgh,
        withBOM: true,
      });
      setTimeout(() => setLoading(false), 1000);
    } catch {
      setError(true);
    }
  };
  return (
    <>
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <MDButton onClick={handleclick} variant="outlined" color="dark">
          <DescriptionIcon />
          &nbsp;export csv
        </MDButton>
      )}
    </>
  );
};
