import { useEffect, useState } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import { ApiCMSRequest } from "data/ApiCMS";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import useCalendarModalities from "./useCalendarModalities";
import { removeNullValues } from "utils/removeNullValues";
import { DATE_FORMATTERS } from "utils/formatDate";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const { modalities } = useCalendarModalities();
  const [responseData, setResponseData] = useState<GetItemGql["Calendar"]>();

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCMSRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.Calendar) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          setResponseData(response.data.data?.Calendar);
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (!modalities || !responseData) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.calendarModalitiesIds = {
      ...schemaJsonParsed.properties.calendarModalitiesIds,
      items: {
        oneOf: modalities.map(({ id, title }) => ({
          const: id,
          title,
        })),
        type: "number",
      },
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });

    const { calendarModalities, ...data } = responseData;

    const newData = removeNullValues({
      ...data,
      startDate: DATE_FORMATTERS.removeTime(data.startDate),
      endDate: DATE_FORMATTERS.removeTime(data.endDate),
      calendarModalitiesIds: calendarModalities.map(({ id }) => id),
    });

    setState({
      data: newData,
    });
  }, [modalities, responseData]);
};

export default useGetItem;
