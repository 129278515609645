import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { SchemaDataContentJson, UseUpdateProps } from "./types";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const navigate = useNavigate();
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaDataContentJson>) => {
      const contentJson = state?.data;

      const response = await ApiCourseRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            contentJson: JSON.stringify(contentJson),
            id: queryParam,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data?.updateCourse?.id) {
        throw new Error("Ops. Sem resposta!");
      }
      navigate(`/ecommerce/cursos/${response.data.data.updateCourse.id}/investimentos`);
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
