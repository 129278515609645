import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiRequest } from "./types";

export const QUERY = print(gql`
  query Query($courseId: Int) {
    AdmissionWays(perPage: -1, courseId: $courseId) {
      items {
        id
        name
        templateInvestmentName
        plans {
          id
          title
        }
      }
    }
  }
`);

export interface QueryGql {
  AdmissionWays: {
    items: {
      id: number;
      name: string;
      templateInvestmentName: string;
      plans: { id: number; title: string }[];
    }[];
  };
}

export default function useAdmissionWays(courseId?: number) {
  const [AdmissionWays, setStateData] = useState<null | QueryGql["AdmissionWays"]["items"]>(null);

  useEffect(() => {
    ApiRequest<QueryGql>({
      query: QUERY,
      variables: { courseId },
    })
      .then(({ data }) => setStateData(data.data.AdmissionWays.items))
      .catch((e) => console.log(e));
  }, []);

  return {
    AdmissionWays,
  };
}
