import HeaderPage from "layouts/aplicacoes/uniftec/appearance/header";
import FooterPage from "layouts/aplicacoes/uniftec/appearance/footer";
import AboutPage from "layouts/aplicacoes/uniftec/pages/static/about";
import HomePage from "layouts/aplicacoes/uniftec/pages/static/home";
import PublicationsPage from "layouts/aplicacoes/uniftec/pages/static/publications";
import PedagogicalsPage from "layouts/aplicacoes/uniftec/pages/static/pedagogicalProposal";
import EvaluationCommittee from "layouts/aplicacoes/uniftec/pages/static/evaluationCommittee";
import WorkWithUs from "layouts/aplicacoes/uniftec/pages/static/workWithUs";
import ConsultRegistrationPage from "layouts/aplicacoes/uniftec/pages/static/consultRegistrationAndMEC";
import EnadePage from "layouts/aplicacoes/uniftec/pages/static/enade";
import PoliciesPage from "layouts/aplicacoes/uniftec/pages/static/policies";
import VirtualTourPage from "layouts/aplicacoes/uniftec/pages/static/virtualTour";
import FormsOfEntryPage from "layouts/aplicacoes/uniftec/pages/static/formsofentry";
import InternationalRelationsPage from "layouts/aplicacoes/uniftec/pages/static/internationalRelations";
import ScholarShipsListPage from "layouts/aplicacoes/uniftec/scholarShips";
import UpdateScholarShipUniftec from "layouts/aplicacoes/uniftec/scholarShips/[id]";
import CreateScholarShipUniftec from "layouts/aplicacoes/uniftec/scholarShips/create";
import { Banner } from "layouts/aplicacoes/Banners";
import { Sites } from "data/utils/globals";
import { CategoriesFaq } from "layouts/aplicacoes/CategoriesFaq";
import FaqsUniftecListPage from "layouts/aplicacoes/uniftec/faqs";
import UpdateFaqUniftec from "layouts/aplicacoes/uniftec/faqs/[id]";
import CreateFaqUniftec from "layouts/aplicacoes/uniftec/faqs/create";
import { DynamicPage } from "layouts/aplicacoes/DynamicPage";
import { Redirects } from "layouts/aplicacoes/Redirects";
import { Popups } from "layouts/aplicacoes/Popups";
import { Holidays } from "layouts/aplicacoes/Holidays";
import { CalendarModality } from "layouts/aplicacoes/CalendarModality";
import { Calendar } from "layouts/aplicacoes/Calendar";
import { GlobalKeys } from "layouts/aplicacoes/GlobalKeys";
import Icon from "@mui/material/Icon";
import { Blog } from "layouts/aplicacoes/Blog";
import { BlogTags } from "layouts/aplicacoes/BlogTags";
import { BlogComments } from "layouts/aplicacoes/BlogComments";
import { BlogCategories } from "layouts/aplicacoes/BlogCategories";
import { Events } from "layouts/aplicacoes/Events";
import { Forms } from "layouts/aplicacoes/Forms";
import { Contact } from "layouts/aplicacoes/Contact";
import { ScheduleYourVisit } from "layouts/aplicacoes/ScheduleYourVisit";
import { Permissions as HeaderPermissions } from "layouts/aplicacoes/uniftec/appearance/header/permissions";
import { Permissions as FooterPermissions } from "layouts/aplicacoes/uniftec/appearance/footer/permissions";
import { Permissions as FaqPermissions } from "layouts/aplicacoes/uniftec/faqs/permissions";
import { Permissions as PagePermissions } from "layouts/aplicacoes/uniftec/pages/static/permissions";
import { Permissions as ScholarShipsPermissions } from "layouts/aplicacoes/uniftec/scholarShips/permissions";
import { Route } from "App";
import { OpenPolo } from "layouts/aplicacoes/FormOpenCampus";
import { PerformYourEvent } from "layouts/aplicacoes/FormPerformYourEvent";

const { id, path } = Sites.UNIFTEC;

export const uniftecRoutes: Route[] = [
  {
    type: "collapse",
    name: "UNIFTEC",
    key: "UNIFTEC",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Aparência",
        key: "uniftec/appearance",
        collapse: [
          {
            name: " Header",
            key: "/uniftec/appearance/header",
            route: "/uniftec/appearance/header",
            component: <HeaderPage />,
            permission: HeaderPermissions.list,
          },
          {
            name: " Footer",
            key: "/uniftec/appearance/footer",
            route: "/uniftec/appearance/footer",
            component: <FooterPage />,
            permission: FooterPermissions.list,
          },
          {
            type: "collapse",
            name: " Banners",
            // key: "Uniftec - banner - menu",
            // collapse: [
            //   {
            // name: " Todos os Banners",
            key: `${path}/banners`,
            route: `${path}/banners`,
            component: <Banner.List idSite={id} path={path} />,
            permission: Banner.Permissions.list,
          },
          {
            type: "hide",
            name: " Novo Banner",
            key: `${path}/banners/new`,
            route: `${path}/banners/new`,
            component: <Banner.Create idSite={id} path={path} />,
            permission: Banner.Permissions.create,
          },
          {
            type: "hide",
            name: " Ver Banner",
            key: `${path}/banners/:id`,
            route: `${path}/banners/:id`,
            component: <Banner.Update idSite={id} path={path} />,
            permission: Banner.Permissions.read,
          },
          {
            type: "hide",
            name: " Editar Banner",
            key: `${path}/banners/edit/:id`,
            route: `${path}/banners/edit/:id`,
            component: <Banner.Update inicialEditing idSite={id} path={path} />,
            permission: Banner.Permissions.update,
          },
          {
            name: " Popups",
            key: `${path}/popups`,
            route: `${path}/popups`,
            component: <Popups.List idSite={id} path={path} />,
            permission: Popups.Permissions.list,
          },
          {
            type: "hide",
            name: " Novo Popups",
            key: `${path}/popups/new`,
            route: `${path}/popups/new`,
            component: <Popups.Create idSite={id} path={path} />,
            permission: Popups.Permissions.create,
          },
          {
            name: "Atualizar Popups",
            type: "hide",
            key: `${path}/popups/:id`,
            route: `${path}/popups/:id`,
            component: <Popups.Update idSite={id} path={path} />,
            permission: Popups.Permissions.update,
          },
        ],
      },

      {
        type: "collapse",
        name: "Formulários",
        key: "Uniftec - formularios",
        collapse: [
          {
            name: " Dinâmicos / Eventos",
            key: `${path}/forms`,
            route: `${path}/forms`,
            component: <Forms.List path={path} />,
            permission: Forms.Permissions.list,
          },
          {
            type: "hide",
            name: " Novo",
            key: `${path}/forms/new`,
            route: `${path}/forms/new`,
            component: <Forms.Create />,
            permission: Forms.Permissions.create,
          },
          {
            type: "hide",
            name: " Ver formulário",
            key: `${path}/forms/:id`,
            route: `${path}/forms/:id`,
            component: <Forms.Update />,
            permission: Forms.Permissions.read,
          },
          {
            type: "hide",
            name: " Ver formulário",
            key: `${path}/forms/:slug/entries`,
            route: `${path}/forms/:slug/entries`,
            component: <Forms.Entries />,
            permission: Forms.Permissions.read,
          },
          {
            name: " Abra um polo",
            key: `${path}/abra-um-polo`,
            route: `${path}/abra-um-polo`,
            component: <OpenPolo.List slug="abra-um-polo" path={Sites.UNIFTEC.path} />,
            permission: OpenPolo.Permissions.list,
          },
          {
            type: "hide",
            name: " Editar formulário Abra um polo",
            key: `${path}/abra-um-polo/editar`,
            route: `${path}/abra-um-polo/editar`,
            component: (
              <OpenPolo.Update
                slug={"abra-um-polo"}
                path={Sites.UNIFTEC.path}
                siteId={Sites.UNIFTEC.id}
              />
            ),
            permission: OpenPolo.Permissions.update,
          },
          {
            name: " Realize seu Evento",
            key: `${path}/realize-seu-evento`,
            route: `${path}/realize-seu-evento`,
            component: (
              <PerformYourEvent.List slug="realize-seu-evento" path={Sites.UNIFTEC.path} />
            ),
            permission: PerformYourEvent.Permissions.list,
          },
          {
            type: "hide",
            name: " Editar formulário Realize seu evento",
            key: `${path}/realize-seu-evento/editar`,
            route: `${path}/realize-seu-evento/editar`,
            component: (
              <PerformYourEvent.Update
                slug={"realize-seu-evento"}
                path={Sites.UNIFTEC.path}
                siteId={Sites.UNIFTEC.id}
              />
            ),
            permission: PerformYourEvent.Permissions.update,
          },
          {
            name: " Contato",
            key: `${path}/contato`,
            route: `${path}/contato`,
            component: <Contact.List slug="contato" path={Sites.UNIFTEC.path} />,
            permission: Contact.Permissions.list,
          },
          {
            type: "hide",
            name: " Editar formulário Contato",
            key: `${path}/contato/editar`,
            route: `${path}/contato/editar`,
            component: (
              <Contact.Update
                slug={"contato"}
                path={Sites.UNIFTEC.path}
                siteId={Sites.UNIFTEC.id}
              />
            ),
            permission: Contact.Permissions.update,
          },
          {
            name: " Agenda de visitas",
            key: `${path}/agenda-de-visitas`,
            route: `${path}/agenda-de-visitas`,
            component: (
              <ScheduleYourVisit.List slug="agende-sua-visita" path={Sites.UNIFTEC.path} />
            ),
            permission: ScheduleYourVisit.Permissions.list,
          },
          {
            type: "hide",
            name: " Editar formulário Agenda de visitas",
            key: `${path}/agenda-de-visitas/editar`,
            route: `${path}/agenda-de-visitas/editar`,
            component: (
              <ScheduleYourVisit.Update
                slug={"agende-sua-visita"}
                path={Sites.UNIFTEC.path}
                siteId={Sites.UNIFTEC.id}
              />
            ),
            permission: ScheduleYourVisit.Permissions.read,
          },
        ],
      },
      {
        type: "collapse",
        name: "Páginas Estáticas",
        key: "uniftec/pages/static",
        collapse: [
          {
            name: " Bolsas e Incentivos",
            key: "/uniftec/scholarShips",
            route: "/uniftec/scholarShips",
            component: <ScholarShipsListPage />,
            permission: ScholarShipsPermissions.list,
          },
          {
            name: " Atualizar",
            type: "hide",
            key: "/uniftec/scholarShips/:id",
            route: "/uniftec/scholarShips/:id",
            component: <UpdateScholarShipUniftec />,
            permission: ScholarShipsPermissions.update,
          },
          {
            name: " Novo",
            type: "hide",
            key: "/uniftec/scholarShips/create",
            route: "/uniftec/scholarShips/create",
            component: <CreateScholarShipUniftec />,
            permission: ScholarShipsPermissions.create,
          },

          {
            name: " Comissão Própria",
            key: "/uniftec/pages/static/evaluationCommittee",
            route: "/uniftec/pages/static/evaluationCommittee",
            component: <EvaluationCommittee />,
            permission: PagePermissions.list,
          },
          {
            name: " Cadastro e_MEC",
            key: "/uniftec/pages/static/consultRegistrationAndMEC",
            route: "/uniftec/pages/static/consultRegistrationAndMEC",
            component: <ConsultRegistrationPage />,
            permission: PagePermissions.list,
          },
          {
            name: " Enade",
            key: "/uniftec/pages/static/enade",
            route: "/uniftec/pages/static/enade",
            component: <EnadePage />,
            permission: PagePermissions.list,
          },
          {
            name: " Formas de Ingresso",
            key: "/uniftec/pages/static/formsofentry",
            route: "/uniftec/pages/static/formsofentry",
            component: <FormsOfEntryPage />,
            permission: PagePermissions.list,
          },
          {
            name: " Home Page",
            key: "/uniftec/pages/static/home",
            route: "/uniftec/pages/static/home",
            component: <HomePage />,
            permission: PagePermissions.list,
          },
          {
            name: " Políticas e termos",
            key: "/uniftec/pages/static/policies",
            route: "/uniftec/pages/static/policies",
            component: <PoliciesPage />,
            permission: PagePermissions.list,
          },
          {
            name: " Proposta Pedagógica",
            key: "/uniftec/pages/static/proposalPedagogical",
            route: "/uniftec/pages/static/proposalPedagogical",
            component: <PedagogicalsPage />,
            permission: PagePermissions.list,
          },
          {
            name: " Publicações",
            key: "/uniftec/pages/static/publications",
            route: "/uniftec/pages/static/publications",
            component: <PublicationsPage />,
            permission: PagePermissions.list,
          },
          {
            name: " Quem Somos",
            key: "/uniftec/pages/static/about",
            route: "/uniftec/pages/static/about",
            component: <AboutPage />,
            permission: PagePermissions.list,
          },
          {
            name: "Relações Interna...",
            key: "/uniftec/pages/static/internationalRelations",
            route: "/uniftec/pages/static/internationalRelations",
            component: <InternationalRelationsPage />,
            permission: PagePermissions.list,
          },
          {
            name: " Trabalhe Conosco",
            key: "/uniftec/pages/static/workWithUs",
            route: "/uniftec/pages/static/workWithUs",
            component: <WorkWithUs />,
            permission: PagePermissions.list,
          },
          {
            name: " Tour Virtual",
            key: "/uniftec/pages/static/virtualTour",
            route: "/uniftec/pages/static/virtualTour",
            component: <VirtualTourPage />,
            permission: PagePermissions.list,
          },
        ],
      },
      {
        name: "Páginas Dinâmicas",
        key: `${path}/paginas-dinamicas`,
        route: `${path}/paginas-dinamicas`,
        component: <DynamicPage.List path={path} />,
        permission: DynamicPage.Permissions.list,
      },
      {
        type: "hide",
        name: " Nova Página Dinâmica",
        key: `${path}/paginas-dinamicas/new`,
        route: `${path}/paginas-dinamicas/new`,
        component: <DynamicPage.Create />,
        permission: DynamicPage.Permissions.create,
      },
      {
        name: "Update  Página Dinâmica",
        type: "hide",
        key: `${path}/paginas-dinamicas/:slug`,
        route: `${path}/paginas-dinamicas/:slug`,
        component: <DynamicPage.Update />,
        permission: DynamicPage.Permissions.update,
      },
      {
        type: "collapse",
        name: "Calendário Acadêmico",
        key: "Uniftec - calendar",
        href: "/calendario",
        collapse: [
          {
            name: " Eventos",
            key: `${path}/calendarios`,
            route: `${path}/calendarios`,
            component: <Calendar.List path={path} />,
            permission: Calendar.Permissions.list,
          },
          {
            type: "hide",
            name: " Novo Evento",
            key: `${path}/calendarios/new`,
            route: `${path}/calendarios/new`,
            component: <Calendar.Create />,
            permission: Calendar.Permissions.create,
          },
          {
            name: "Atualizar Calendario",
            type: "hide",
            key: `${path}/calendarios/:id`,
            route: `${path}/calendarios/:id`,
            component: <Calendar.Update />,
            permission: Calendar.Permissions.update,
          },
          {
            name: " Feriados",
            key: `${path}/feriados`,
            route: `${path}/feriados`,
            component: <Holidays.List path={path} />,
            permission: Holidays.Permissions.list,
          },
          {
            type: "hide",
            name: " Novo Feriado",
            key: `${path}/feriados/new`,
            route: `${path}/feriados/new`,
            component: <Holidays.Create />,
            permission: Holidays.Permissions.create,
          },
          {
            name: "Atualizar Feriado",
            type: "hide",
            key: `${path}/feriados/:id`,
            route: `${path}/feriados/:id`,
            component: <Holidays.Update />,
            permission: Holidays.Permissions.update,
          },
          {
            name: " Modalidades",
            key: `${path}/modalidades-de-calendario`,
            route: `${path}/modalidades-de-calendario`,
            component: <CalendarModality.List path={path} />,
            permission: CalendarModality.Permissions.list,
          },
          {
            type: "hide",
            name: " Nova Modalidade",
            key: `${path}/modalidades-de-calendario/new`,
            route: `${path}/modalidades-de-calendario/new`,
            component: <CalendarModality.Create />,
            permission: CalendarModality.Permissions.create,
          },
          {
            name: "Atualizar Modalidade",
            type: "hide",
            key: `${path}/modalidades-de-calendario/:id`,
            route: `${path}/modalidades-de-calendario/:id`,
            component: <CalendarModality.Update />,
            permission: CalendarModality.Permissions.update,
          },
        ],
      },
      {
        type: "collapse",
        name: "Faqs",
        key: "Uniftec - Faqs",
        href: "/uniftec/faqs",
        collapse: [
          {
            name: " Categorias Faq",
            key: `${path}/faq-categorias`,
            route: `${path}/faq-categorias`,
            component: <CategoriesFaq.List path={path} />,
            permission: CategoriesFaq.Permissions.list,
          },
          {
            type: "hide",
            name: " Nova Categoria Faq",
            key: `${path}/faq-categorias/new`,
            route: `${path}/faq-categorias/new`,
            component: <CategoriesFaq.Create />,
            permission: CategoriesFaq.Permissions.create,
          },
          {
            name: "Atualizar Categorias Faq",
            type: "hide",
            key: `${path}/faq-categorias/:id`,
            route: `${path}/faq-categorias/:id`,
            component: <CategoriesFaq.Update />,
            permission: CategoriesFaq.Permissions.update,
          },
          {
            name: " Faqs",
            key: "/uniftec/faqs/",
            route: "/uniftec/faqs/",
            component: <FaqsUniftecListPage />,
            permission: FaqPermissions.list,
          },
          {
            type: "hide",
            name: " Novo Faq",
            key: "/uniftec/faqs/create",
            route: "/uniftec/faqs/create",
            component: <CreateFaqUniftec />,
            permission: FaqPermissions.create,
          },
          {
            name: " Atualizar",
            type: "hide",
            key: "/uniftec/faqs/:id",
            route: "/uniftec/faqs/:id",
            component: <UpdateFaqUniftec />,
            permission: FaqPermissions.update,
          },
        ],
      },
      {
        name: "Redirecionamentos",
        key: `${path}/redirects`,
        route: `${path}/redirects`,
        component: <Redirects.List path={path} />,
        permission: Redirects.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo Redirecionamento",
        key: `${path}/redirects/new`,
        route: `${path}/redirects/new`,
        component: <Redirects.Create />,
        permission: Redirects.Permissions.create,
      },
      {
        name: "Atualizar Redirecionamento",
        type: "hide",
        key: `${path}/redirects/:id`,
        route: `${path}/redirects/:id`,
        component: <Redirects.Update />,
        permission: Redirects.Permissions.update,
      },

      {
        name: "Chaves Globais",
        key: `${path}/chaves-globais`,
        route: `${path}/chaves-globais`,
        component: <GlobalKeys.List path={path} />,
        permission: GlobalKeys.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo Chaves Globais",
        key: `${path}/chaves-globais/new`,
        route: `${path}/chaves-globais/new`,
        component: <GlobalKeys.Create />,
        permission: GlobalKeys.Permissions.create,
      },
      {
        name: "Atualizar Chaves Globais",
        type: "hide",
        key: `${path}/chaves-globais/:key`,
        route: `${path}/chaves-globais/:key`,
        component: <GlobalKeys.Update />,
        permission: GlobalKeys.Permissions.update,
      },

      {
        type: "collapse",
        name: "Blog",
        key: "Uniftec - blog",
        href: "/blog",
        collapse: [
          {
            name: " Posts",
            key: `${path}/blog-posts`,
            route: `${path}/blog-posts`,
            component: <Blog.List path={path} />,
            permission: Blog.Permissions.list,
          },
          {
            type: "hide",
            name: " Novo Post",
            key: `${path}/blog-posts/new`,
            route: `${path}/blog-posts/new`,
            component: <Blog.Create />,
            permission: Blog.Permissions.create,
          },
          {
            name: "Atualizar",
            type: "hide",
            key: `${path}/blog-posts/:id`,
            route: `${path}/blog-posts/:id`,
            component: <Blog.Update />,
            permission: Blog.Permissions.update,
          },
          {
            name: " Categorias do Blog",
            key: `${path}/blog-categorias`,
            route: `${path}/blog-categorias`,
            component: <BlogCategories.List path={path} />,
            permission: BlogCategories.Permissions.list,
          },
          {
            type: "hide",
            name: " Nova Categoria",
            key: `${path}/blog-categorias/new`,
            route: `${path}/blog-categorias/new`,
            component: <BlogCategories.Create />,
            permission: BlogCategories.Permissions.create,
          },
          {
            name: "Atualizar",
            type: "hide",
            key: `${path}/blog-categorias/:id`,
            route: `${path}/blog-categorias/:id`,
            component: <BlogCategories.Update />,
            permission: BlogCategories.Permissions.update,
          },
          {
            name: " Tags do Blog",
            key: `${path}/blog-tags`,
            route: `${path}/blog-tags`,
            component: <BlogTags.List path={path} />,
            permission: BlogTags.Permissions.list,
          },
          {
            type: "hide",
            name: " Nova Tag",
            key: `${path}/blog-tags/new`,
            route: `${path}/blog-tags/new`,
            component: <BlogTags.Create />,
            permission: BlogTags.Permissions.create,
          },
          {
            name: "Atualizar Tag",
            type: "hide",
            key: `${path}/blog-tags/:id`,
            route: `${path}/blog-tags/:id`,
            component: <BlogTags.Update />,
            permission: BlogTags.Permissions.update,
          },
          {
            name: " Comentários",
            key: `${path}/blog-comentarios`,
            route: `${path}/blog-comentarios`,
            component: <BlogComments.List path={path} />,
            permission: BlogComments.Permissions.list,
          },
        ],
      },
      {
        name: "Eventos",
        key: `${path}/eventos`,
        route: `${path}/eventos`,
        component: <Events.List path={path} />,
        permission: Events.Permissions.list,
      },
      {
        type: "hide",
        name: " Novo Evento",
        key: `${path}/eventos/new`,
        route: `${path}/eventos/new`,
        component: <Events.Create />,
        permission: Events.Permissions.create,
      },
      {
        name: "Atualizar Evento",
        type: "hide",
        key: `${path}/eventos/:id`,
        route: `${path}/eventos/:id`,
        component: <Events.Update />,
        permission: Events.Permissions.update,
      },
    ],
  },
];
