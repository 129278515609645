import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { AxiosResponse } from "axios";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { useCallback, useEffect, useState } from "react";

interface Data {
  id: string;
  name: string;
}

interface Handler<T = any> {
  query: string;
  formater: (data: T) => Data[];
  api: ({
    query,
    variables,
  }: {
    query: string;
    variables?: {
      [key: string]: any;
    };
  }) => Promise<
    AxiosResponse<{
      data?: T;
      errors?: Error[];
    }>
  >;
}

const queryTypes = ["Category", "Course", "Sku", "Payment"];

export const CATEGORY = print(gql`
  query Categories($search: String) {
    Categories(search: $search) {
      items {
        eCommerceId
        name
      }
    }
  }
`);

type Category = {
  Categories: {
    items: Array<{
      eCommerceId: number;
      name: string;
    }>;
  };
};

export const SKU = print(gql`
  query Prices($search: String) {
    Prices(search: $search) {
      items {
        eCommerceSkuName
        eCommerceSkuId
      }
    }
  }
`);

type Sku = {
  Prices: {
    items: Array<{
      eCommerceSkuName: string;
      eCommerceSkuId: number;
    }>;
  };
};

export const COURSE = print(gql`
  query Courses($search: String) {
    Courses(search: $search) {
      items {
        eCommerceProductId
        name
      }
    }
  }
`);

type Course = {
  Courses: {
    items: Array<{
      eCommerceProductId: number;
      name: string;
    }>;
  };
};

export const PAYMENT = print(gql`
  query PaymentProviders {
    PaymentProviders {
      id
      name
    }
  }
`);

type Payment = {
  PaymentProviders: Array<{
    id: number;
    name: string;
  }>;
};

export default function useData({ queryType }: { queryType: string }) {
  const [options, setOptions] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!queryTypes.includes(queryType)) throw new Error(`queryType: "${queryType}" unsupported`);
  }, []);

  const getType = useCallback((): Handler => {
    switch (queryType) {
      case "Category":
        return {
          query: CATEGORY,
          formater: (data: Category) =>
            data.Categories.items.map((item) => ({
              id: String(item.eCommerceId),
              name: item.name,
            })),
          api: ApiFinancialRequest,
        };

      case "Course":
        return {
          query: COURSE,
          formater: (data: Course) =>
            data.Courses.items.map((item) => ({
              id: String(item.eCommerceProductId) as string,
              name: item.name,
            })),
          api: ApiFinancialRequest,
        };

      case "Sku":
        return {
          query: SKU,
          formater: (data: Sku) =>
            data.Prices.items.map((sku) => ({
              name: sku.eCommerceSkuName,
              id: String(sku.eCommerceSkuId),
            })),
          api: ApiFinancialRequest,
        };

      case "Payment":
        return {
          query: PAYMENT,
          formater: (data: Payment) =>
            data.PaymentProviders.map((item) => ({
              id: String(item.id) as string,
              name: item.name,
            })),
          api: ApiFinancialRequest,
        };
      default:
        throw new Error(`Unsupported queryType: ${queryType}`);
    }
  }, []);

  const getItems = (search: string) => {
    const handler = getType();
    if (search.length < 3) return;

    if (!handler) return;

    setLoading(true);

    handler
      .api({
        query: handler.query,
        variables: { search },
      })
      .then(({ data }) =>
        setOptions(handler.formater(data.data).map((item) => ({ ...item, id: String(item.id) })))
      )
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  return {
    options,
    loading,
    getItems,
  };
}
