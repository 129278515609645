import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          ...props.initialVariables,
        }}
        useDefaultFilter={false}
        path={`/ecommerce/chaves-globais`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.GlobalsFinancial.items}
        formatPagination={(data) => data.GlobalsFinancial.paginationInfo}
        title=""
        ApiSetData={ApiFinancialWithSetData}
        columnsTable={[
          {
            Header: "Chave",
            accessor: "key",
            Cell: ({ value }: any) => (
              <LinkCell href={`/ecommerce/chaves-globais/${value}`} value={value} />
            ),
          },
          {
            Header: "Descrição",
            accessor: "description",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
      {props.showNavBar && <Footer />}
    </DashboardLayout>
  );
}

export default List;
