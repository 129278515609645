export const MUTATION_UPDATE_SHIP_AND_INCENTIVE = `mutation Mutation($data: UpdateScholarshipAndIncentiveInput!) {
  updateScholarshipAndIncentive(data: $data) {
    id
    active
    imageUrl
    title
    linkRegulation
    startDate
    endDate
    contentJson
    templateSchema {
      id
      name
    }
    categories {
      id
      name
    }
    modalities {
      id
      name
    }
    createdAt
    updatedAt
  }
}
`;

export type ResponseUpdateScholarShip = {
  updateScholarShip: {
    id: number;
    active: boolean;
    imageUrl: string;
    title: string;
    linkRegulation?: string;
    startDate?: Date;
    endDate?: Date;
    templateSchemaName: string;
    contentJson: string;
    templateSchema: {
      id: number;
      name: string;
      schemaJson: string;
      UISchema: string;
    };
    categories?: {
      id: number;
      name: string;
      slug: string;
      active: boolean;
    }[];
    modalities?: {
      id: number;
      name: string;
      slug: string;
      active: boolean;
    }[];
    createdAt?: Date;
    updatedAt?: Date;
  };
};
