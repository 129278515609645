import { useCallback, useState } from "react";
import { ApiCMSRequest } from "data/ApiCMS";
import { DeleteButton, DeleteButtonProps } from "../components/DeleteButton";
import {
  MUTATION_DELETE_BANNER,
  ResponseDeleteBanner,
} from "data/queries/ApiCMS/Banner/MutationDeleteBanner";

export interface ButtonDeleteBannerProps
  extends Omit<DeleteButtonProps<number>, "propDelete" | "handleDelete"> {
  onDeleteSuccess?: (id: number) => void;
  onDeleteError?: (err: Error) => void;
  deleteBannerId: number;
}

export function ButtonDeleteBanner({
  onDeleteSuccess,
  onDeleteError,
  deleteBannerId,
  ...props
}: ButtonDeleteBannerProps) {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiCMSRequest<ResponseDeleteBanner>({
        query: MUTATION_DELETE_BANNER,
        variables: { deleteBannerId },
      });
      if (!response?.data?.data?.deleteBanner?.id) {
        if (response.data?.errors?.[0]) throw response.data?.errors?.[0];
        throw new Error(JSON.stringify(response?.data || "Something wrong"));
      }
      onDeleteSuccess?.(response.data.data.deleteBanner.id);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      onDeleteError?.(error);
    }
  }, [deleteBannerId]);

  if (!deleteBannerId) return null;
  return (
    <DeleteButton
      {...props}
      propDelete={deleteBannerId}
      handleDelete={handleDelete}
      loading={loading}
    />
  );
}

export default ButtonDeleteBanner;
