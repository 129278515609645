import { SchemaData, UseUpdateProps } from "./types";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiAuthRequest } from "data/ApiForm";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const data = state?.data;
      delete data.schemaJson;
      delete data.UISchema;
      delete data.responses;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.isDefaultForm;

      const response = await ApiAuthRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            ...data,
            extraJson: JSON.stringify(data.extraJson),
          },
        },
      });

      if (response?.data?.errors) {
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
