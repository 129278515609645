import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query CalendarModality($id: Int!) {
    CalendarModality(id: $id) {
      title
      id
    }
  }
`);

export interface GetItemGql {
  CalendarModality: {
    id: number;
    title: string;
  };
}
