import { useEffect, useState } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import useCategories from "./useCategories";
import usePartners from "./usePartners";
import useTeachingAreas from "./useTeachingAreas";
import { ApiCourseRequest } from "data/queries/ApiCourse";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const [responseData, setResponseData] = useState<GetItemGql["CourseGroup"]>();
  const { categories } = useCategories();
  const { partners } = usePartners();
  const { teachingAreas } = useTeachingAreas();

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCourseRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.CourseGroup) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = response.data.data?.CourseGroup;

          setResponseData(data);
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (!categories || !responseData || !partners || !teachingAreas) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.categoryId = {
      ...schemaJsonParsed.properties.categoryId,
      oneOf: categories.map(({ name, id }) => ({
        title: name,
        const: id,
      })),
      readOnly: true,
    };

    schemaJsonParsed.properties.partnerId = {
      ...schemaJsonParsed.properties.partnerId,
      oneOf: partners.map(({ name, id }) => ({
        title: name,
        const: id,
      })),
    };

    schemaJsonParsed.properties.teachingAreaId = {
      ...schemaJsonParsed.properties.teachingAreaId,
      oneOf: teachingAreas.map(({ name, id }) => ({
        title: name,
        const: id,
      })),
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });

    setState({
      data: removeNullValues(responseData),
    });
  }, [categories, responseData, teachingAreas, partners]);
};

export default useGetItem;
