import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeleteGlobalKey($key: String!) {
    deleteGlobalKey(key: $key) {
      key
    }
  }
`);

export interface DeleteGql {
  deleteGlobalKey: {
    key?: string;
  };
}
