import type { ErrorObject } from "ajv";

export function handleTranslateErrors(error: ErrorObject): string {
  switch (error.keyword) {
    case "required":
      return "Campo Obrigatório";
    case "oneOf":
      return "Deve ser igual a um das opções";
    default:
      return error.message;
  }
}
