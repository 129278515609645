export const schemaJson = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Nome",
    },
    slug: {
      type: "string",
      title: "Slug",
    },
    sort: {
      type: "number",
      title: "Sort",
    },
  },
  required: ["name", "slug", "sort"],
};
