import axios, { AxiosResponse } from "axios";
import { getUserToken } from "utils/getToken";

export const ApiCMSGraphql = process.env.REACT_APP_API_AUTH_URL;

export const ApiAuthInstance = axios.create({
  baseURL: ApiCMSGraphql,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: getUserToken(),
  },
  method: "POST",
  timeout: 5000,
});

export async function ApiAuthRequest<responseDataType = any>({
  query,
  variables,
  headers,
}: {
  query: string;
  variables?: { [key: string]: any };
  headers?: { [key: string]: string }; // Adicione a opção de cabeçalhos como um parâmetro
}): Promise<AxiosResponse<{ data?: responseDataType; errors?: Error[] }>> {
  return ApiAuthInstance.request<{
    data?: responseDataType;
    errors?: Error[];
  }>({
    data: {
      query,
      variables,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem("UserToken"),
    }, // Passe os cabeçalhos para a requisição
  });
}

export interface DataState<DataType = any> {
  loading?: boolean;
  error?: Error;
  data?: DataType;
}

export async function ApiAuthWithSetData<T = any>({
  query,
  variables,
  setData,
  headers, // Adicione a opção de cabeçalhos como um parâmetro
}: {
  query: string;
  variables?: { [key: string]: any };
  setData: (data: DataState<T>) => void;
  headers?: { [key: string]: string }; // Adicione a opção de cabeçalhos como um parâmetro
}): Promise<DataState<T>> {
  return ApiAuthRequest<T>({ query, variables, headers })
    .then((response) => {
      if (!response?.data?.data) {
        const respError: DataState<T> = {
          loading: false,
          error: response?.data?.errors?.[0] || new Error("mediaFiles not found"),
          data: undefined,
        };
        setData(respError);
        return respError;
      }
      const respSuccess: DataState<T> = {
        error: undefined,
        loading: false,
        data: response.data.data,
      };
      setData(respSuccess);
      return respSuccess;
    })
    .catch((error) => {
      console.error(error);
      const respError: DataState<T> = {
        loading: false,
        error,
        data: undefined,
      };
      setData(respError);
      return respError;
    });
}
