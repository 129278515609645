import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { getStatusCell } from "components/CRPComponents/Tables/GenericTable/utils/getStatusCell";

export const columnsTable = [
  {
    Header: "id",
    accessor: "id",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Nome",
    accessor: "name",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "author",
    accessor: "author",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "title",
    accessor: "title",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }: any) => getStatusCell(value),
  },
];

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          dynamicPage: true,
          status: null,
        }}
        useStatusString={true}
        path={`${props.path}/paginas-dinamicas`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Pages.items}
        formatPagination={(data) => data.Pages.paginationInfo}
        title="NOVA PÁGINA DINAMICA"
        columnsTable={[
          {
            Header: "slug",
            accessor: "slug",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/paginas-dinamicas/${value}`} value={value} />
            ),
          },
          ...columnsTable,
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
