import { withJsonFormsControlProps } from "@jsonforms/react";
import SelectMultiple from "../SelectMultiple";
import { CustomRendererProps } from "../types";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { print } from "graphql";
import { gql } from "@apollo/client";
import ModalSubjectsSelect from "components/CRPComponents/ModalSubjectsSelect";

const query = print(gql`
  query Subject($id: Int!) {
    Subject(id: $id) {
      name
      id
    }
  }
`);

interface ItemGql {
  Subject: {
    id: number;
    name: string;
  };
}

const SubjectsSelectMultiple = (props: CustomRendererProps<number[] | undefined>) => {
  return (
    <SelectMultiple
      {...props}
      Modal={ModalSubjectsSelect}
      formatItem={({ data }) => data.data.Subject}
      requestItem={(id: number) => ApiCourseRequest<ItemGql>({ query, variables: { id } })}
    />
  );
};

export default withJsonFormsControlProps(SubjectsSelectMultiple, true);
