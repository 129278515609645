import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Promotions = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:Promotion",
    read: "read:Promotion",
    list: "list:Promotion",
    update: "update:Promotion",
    delete: "delete:Promotion",
  },
};
