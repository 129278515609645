import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql, responseUpdateKey } from "../queries/update";
import { ApiRequest, SchemaData, UseUpdateProps, path } from "./types";
import { removeNullValues } from "utils/removeNullValues";
import { path as pathCourse } from "../../CoursesFinancial/hooks/types";

export default function useUpdate({ queryParam, setState }: UseUpdateProps<SchemaData>) {
  const navigate = useNavigate();
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const {
        activateIfPossible,
        installments,
        value,
        valueWithoutDiscout,
        eCommerceSkuId,
        eCommerceLastUpdate,
        commercialConditionId,
        valueOtherInstallments,
        urlInscription,
      } = state?.data;

      const response = await ApiRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            activateIfPossible,
            installments,
            value,
            valueWithoutDiscout: valueWithoutDiscout || null,
            eCommerceSkuId,
            eCommerceLastUpdate,
            id: queryParam,
            commercialConditionId: commercialConditionId || null,
            valueOtherInstallments: valueOtherInstallments || null,
            urlInscription: urlInscription || null,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data?.[responseUpdateKey]) {
        throw new Error("Ops. Sem resposta!");
      }
      setState(({ data, ...old }) => ({
        ...old,
        data: removeNullValues({ ...data, ...response.data.data[responseUpdateKey] }),
      }));

      // navigate(`${path}/${response.data.data.[responseUpdateKey].id}`);
      navigate(`${pathCourse}/${response.data.data[responseUpdateKey].courseId}`);

      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
