import { useEffect, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import schemaJson from "../schema/SchemaJson";
import UISchema from "../schema/UISchema.json";
import useModalities from "./useTeachingArea";

export default function useTemplate(create?: boolean) {
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const { TeachingAreas } = useModalities();

  useEffect(() => {
    if (!TeachingAreas?.length) {
      setTemplate({
        schemaJson,
        UISchema,
      });
      return;
    }

    const teachingAreaName = {
      type: "string",
      oneOf: [
        ...TeachingAreas.map(({ name }) => ({
          title: name,
          const: name,
        })),
      ],
    };
    setTemplate({
      schemaJson: {
        ...schemaJson,
        properties: { ...schemaJson.properties, teachingAreaName },
      },
      UISchema,
    });
  }, [TeachingAreas]);

  return {
    template,
  };
}
