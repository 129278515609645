import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query Partners {
    Partners(perPage: -1, active: true) {
      items {
        name
        id
      }
    }
  }
`);

export interface QueryGql {
  Partners: {
    items: Array<{
      id: number;
      name: string;
    }>;
  };
}

export default function usePartners() {
  const [partners, setPartners] = useState<null | QueryGql["Partners"]["items"]>(null);

  useEffect(() => {
    ApiCourseRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => setPartners(data.data.Partners.items));
  }, []);

  return {
    partners,
  };
}
