import { useState, useEffect } from "react";
import { GET_ALL_RESPONSES, GetAllResponsesGql } from "../queries/getAllResponses";
import { ApiAuthWithSetData } from "data/ApiForm";

export const UseGetAllResponses = (formSlug: string) => {
  const [responses, setResponses] = useState<any>(null);
  const [allFilds, setAllFilds] = useState<any>(null);

  const getAllUniqueKeys = (objects: any) => {
    const allKeys = objects.reduce((acc: any, obj: any) => {
      return acc.concat(Object.keys(obj));
    }, []);
    return Array.from(new Set(allKeys));
  };

  const extractResponses = (array: any) => {
    return array.map((item: any) => JSON.parse(item.response));
  };

  useEffect(() => {
    ApiAuthWithSetData<GetAllResponsesGql>({
      query: GET_ALL_RESPONSES,
      variables: { formSlug },
      setData: (old) => ({ ...old, loading: true }),
    })
      .then(({ data }) => {
        console.log({ data });
        const extractedResponses = extractResponses(data.FormResponses.items);
        const getAllUniqueFilds = getAllUniqueKeys(extractedResponses);
        setResponses(extractedResponses);
        setAllFilds(getAllUniqueFilds);
      })
      .catch((e: any) => {
        console.log("Email ou senha incorretos.");
        setResponses(null); // Opcional: definir como null em caso de erro
      });
  }, [formSlug]);

  return {
    responses,
    allFilds,
  };
};
