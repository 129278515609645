import React from "react";
import {
  MidiaGalleryWithUpload,
  MidiaGalleryWithUploadProps,
  VariablesProps,
} from "components/CRPComponents/Forms/MidiaGalleryWithUpload";
import { Modal, ModalProps } from "components/Modal";

export interface ModalMidiaFileProps extends Omit<ModalProps, "children" | "title" | "onClose"> {
  title?: string;
  fileType: "IMAGES" | "DOCUMENTS" | "SOUNDS" | "VIDEOS" | undefined;
  onClickMidia: MidiaGalleryWithUploadProps["onClickMidia"];
  onClose: ModalProps["onClose"];
}

const INITIAL_SEARCH = {
  page: 1,
  perPage: 6,
};

export function ModalMidiaFile({
  fileType,
  onClickMidia,
  title = "Selecione uma mídia",
  keepMounted = true,
  ...props
}: ModalMidiaFileProps) {
  const [variablesSearch, setVariablesSearch] = React.useState<VariablesProps>({
    ...INITIAL_SEARCH,
    fileType,
  });
  return (
    <Modal title={title} {...props}>
      <MidiaGalleryWithUpload
        variables={variablesSearch}
        hideFileTypeSelect={!!fileType}
        onChangeVariables={setVariablesSearch}
        onClickMidia={onClickMidia}
        sx={{
          height: "80vh",
          width: "80vw",
          overflowY: "auto",
        }}
      />
    </Modal>
  );
}

export default ModalMidiaFile;
