import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateAddress($data: UpdateAddressInput!) {
    updateAddress(data: $data) {
      id
      addressName
      city
      complement
      country
      postalCode
      reference
      state
      street
      neighborhood
      addressNumber
    }
  }
`);

export interface UpdateGql {
  updateAddress: {
    id: number;
    addressName?: string;
    city?: string;
    complement?: string;
    country?: string;
    postalCode?: string;
    reference?: string;
    state?: string;
    street?: string;
    neighborhood?: string;
    addressNumber?: string;
  };
}
