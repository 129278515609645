import { createContext, useContext, useState } from "react";
import { DataItem, DateInfo } from "./types";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

const last7Days = new Date(today);
last7Days.setDate(today.getDate() - 7);

const last28Days = new Date(today);
last28Days.setDate(today.getDate() - 28);

const datesFrom: DataItem[] = [
  {
    label: "Hoje",
    value: today,
  },
  {
    label: "Ontem",
    value: yesterday,
  },
  {
    label: "Últimos 7 dias",
    value: last7Days,
  },
  {
    label: "Últimos 28 dias",
    value: last28Days,
  },
];

const datesTo: DataItem[] = [
  {
    label: "Ontem",
    value: yesterday,
  },
  {
    label: "7 dias atrás",
    value: last7Days,
  },
  {
    label: "28 dias atrás",
    value: last28Days,
  },
];

interface ContextData {
  dateSelected: DateInfo;
  datesFrom: DataItem[];
  datesTo: DataItem[];
  handleDate: (key: keyof DateInfo, label: string) => void;
}

const DataContext = createContext<ContextData>({} as ContextData);

export default function DataProvider({ children }: any) {
  const [date, setDate] = useState<DateInfo>({
    from: datesFrom[0],
    to: datesTo[0],
  });

  console.log(date);

  const handleDate = (key: keyof typeof date, label: string) => {
    const dates = key === "from" ? datesFrom : datesTo;
    const value = dates.find((item) => label === item.label);
    if (!value) return;

    setDate((old) => ({
      ...old,
      [key]: value,
    }));
  };

  return (
    <DataContext.Provider
      value={{
        dateSelected: date,
        datesFrom,
        datesTo,
        handleDate,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export const useData = (): ContextData => useContext(DataContext);
