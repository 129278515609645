import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Popups = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    list: "list:PopUp",
    create: "create:PopUp",
    read: "read:PopUp",
    update: "update:PopUp",
    delete: "delete:PopUp",
  },
};
