/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";

// Data

// Images
import { useAuth } from "context/useUserAuth";
import AddressInfoCard from "examples/Cards/InfoCards/AddressInfoCard";
import { DATE_FORMATTERS } from "utils/formatDate";

function Overview(): JSX.Element {
  const { user, addresses } = useAuth();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <ProfileInfoCard
            title="informações do perfil"
            info={{
              name: `${user?.firstName} ${user?.lastName}`,
              cpf: user?.cpf,
              "data De Nascimento": DATE_FORMATTERS.formatDateRaw(user?.birthDate),
              celular: user?.phone,
              telefone: user?.residentialPhone,
              email: user?.email,
            }}
            action={{ route: "", tooltip: "Edit Profile" }}
            shadow={false}
          />

          {addresses?.length > 0 && <MDTypography variant="h5">ENDEREÇO</MDTypography>}

          {addresses?.map((item: any) => (
            <AddressInfoCard
              title={`informações do Endereço : ${item.addressName}`}
              key={item.id}
              idaddress={item.id}
              info={{
                name: item.addressName,
                cep: item.postalCode,
                rua: item.street,
                numero: item.addressNumber,
                bairro: item.neighborhood,
                cidade: item.city,
                estado: item.state,
                pais: item.country,
                referencia: item.reference,
                complemento: item.complement,
              }}
              action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
          ))}
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
