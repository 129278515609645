// import { MenuItem } from "@mui/material";
// import { InputControledForm, InputControledFormProps } from "./InputControledForm";
// import React from "react";
// import { FC, forwardRef } from "react";
// import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";
// import MDInputRoot from "components/MDInput/MDInputRoot";

// // Declaring props types for MDInput

// export interface OptionsType {
//   value: string | number | undefined;
//   label: React.ReactNode;
// }
// export interface SelectInputControledFormProps
//   extends Omit<
//     OutlinedTextFieldProps | StandardTextFieldProps,
//     "variant" | "onChange" | "value" | "name" | "select" | "options"
//   > {
//   options: OptionsType[];
//   variant?: "standard" | "outlined";
//   error?: boolean;
//   success?: boolean;
//   disabled?: boolean;
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Required
//   name: string; // Required
//   formData: { [key: string]: any }; // Required
//   errorsForm?: { [key: string]: any };
// }

// export const SelectInputControledForm: FC<SelectInputControledFormProps> = forwardRef(
//   (
//     {
//       error,
//       success,
//       disabled,
//       helperText,
//       formData,
//       errorsForm,
//       name,
//       onChange,
//       options,
//       ...rest
//     }: SelectInputControledFormProps,
//     ref
//   ) => (
//     <MDInputRoot
//       fullWidth
//       name={name}
//       value={formData?.[name] || ""}
//       error={!!errorsForm?.[name] || error}
//       helperText={errorsForm?.[name] || helperText}
//       onChange={onChange}
//       {...rest}
//       ref={ref}
//       ownerState={{ error, disabled, success: !errorsForm?.[name] ? success : false }}
//       select
//     >
//       {options.map(({ value, label }: OptionsType) => (
//         <MenuItem value={value}>{label}</MenuItem>
//       ))}
//     </MDInputRoot>
//   )
// );

// SelectInputControledForm.defaultProps = {
//   // Declaring default props for MDInput
//   error: false,
//   success: false,
//   disabled: false,
//   errorsForm: {},
//   formData: {},
//   options: [],
// };

import { MenuItem } from "@mui/material";
import { InputControledForm, InputControledFormProps } from "./InputControledForm";
import React from "react";
import { FC, forwardRef } from "react";
import { MenuItemProps } from "@mui/material";

// Declaring props types for MDInput

export interface OptionsType {
  value: string | number | undefined;
  label: React.ReactNode;
  props?: Omit<MenuItemProps, "value">;
}
export interface SelectInputControledFormProps
  extends Omit<InputControledFormProps, "select" | "options"> {
  options: OptionsType[];
}

export const SelectInputControledForm: FC<SelectInputControledFormProps> = forwardRef(
  ({ options, onChange, ...rest }: SelectInputControledFormProps, ref) => (
    <InputControledForm onChange={(e) => onChange(e)} {...rest} ref={ref} select>
      {options.map(({ value, label, props }: OptionsType) => (
        <MenuItem {...props} value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </InputControledForm>
  )
);

SelectInputControledForm.defaultProps = {
  options: [],
};
