import { withJsonFormsControlProps } from "@jsonforms/react";
import { ApiAuthRequest } from "data/ApiForm";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";

import { useEffect, useState } from "react";
import { IEventSeletFormType, IOptions } from "./types";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";

const EventSeletFormType = (props: IEventSeletFormType) => {
  const { data, handleChange, path, errors, required } = props;
  const [options, setOptions] = useState<IOptions[]>([]);

  const handleOnChange: SelectProps["onChange"] = (event) => {
    const newValue = event.target.value;
    handleChange(path, newValue);
  };

  useEffect(() => {
    ApiAuthRequest<GetAllGql>({
      query: GET_ALL_QUERY,
      variables: {
        formType: "EVENT",
        status: "ACTIVE",
        perPage: -1,
      },
    })
      .then((response) => {
        if (!response?.data?.data?.Forms) {
          throw new Error("Não encontrado", {
            cause: { response: response?.data || response },
          });
        }

        const data = response.data.data?.Forms;

        const newOptions: IOptions[] = data.items.map(({ id, slug }) => ({
          label: slug,
          value: id,
        }));

        setOptions(newOptions);
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);

  return (
    <FormControl
      variant="standard"
      fullWidth
      required={required}
      error={!!errors}
      sx={{ marginBottom: "10px" }}
    >
      <InputLabel>Formulário para o evento</InputLabel>
      <Select value={data} label="Formulário para o evento" onChange={handleOnChange}>
        <MenuItem value="">Selecione...</MenuItem>
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={`${value}`}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {errors && <FormHelperText>{errors}</FormHelperText>}
    </FormControl>
  );
};

export default withJsonFormsControlProps(EventSeletFormType, true);
