export const MUTATION_CREATE_BANNER = `mutation CreateBanner($data: CreateBannerInput!) {
  createBanner(data: $data) {
    id
  }
}`;

export type ResponseCreateBanner = {
  createBanner: {
    id: number;
  };
};
