import styled from "styled-components";

export const ContentForm = styled.div<{ $primary?: boolean }>`
  width: 100%;
  padding: 0px;
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.$primary ? "#dee2e6" : "#dee2e6")};
  border-radius: 0.5rem;
  text-transform: uppercase;
  max-width: 100%;
  padding: 16px;
  height: auto;
  height: auto;
`;
export const CRPTitleOne = styled.span`
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const CRPContainerVert = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
export const CRPContainerHoriz = styled.div<{ $justifyContent: string }>`
  display: flex;
  justify-content: ${(props) => (props.$justifyContent ? props.$justifyContent : "")};
  gap: 16px;
  // margin-bottom: 12px;
  align-items: center;
  width: 100%;
`;
export const CRPBannerDesktop = styled.div`
  border: solid 1px;
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 150px;
  border-radius: 10px;

  .bannerPopUpDesktop {
    display: none;
  }
  .bannerPopUpDesktopOn {
    display: flex !important;
  }
`;
export const CRPBannerLabelDesktop = styled.label.attrs({ htmlFor: "input-banner-desk" })`
  border: solid 1px;
  text-align: center;
  color: #2271b1;
  border-color: #2271b1;
  border-radius: 6px;
  padding: 2px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background-color: #dee2e6;
  }
`;
export const CRPBannerMobile = styled.div`
  border: solid 1px;
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 150px;
  border-radius: 10px;
`;
export const CRPBannerLabelMobile = styled.label.attrs({ htmlFor: "input-banner-mob" })`
  border: solid 1px;
  text-align: center;
  color: #2271b1;
  border-color: #2271b1;
  border-radius: 6px;
  padding: 2px 24px;

  :hover {
    background-color: #dee2e6;
  }
`;
export const CRPBannerInputDesk = styled.input.attrs({ type: "file", id: "input-banner-desk" })`
  display: none;
`;
export const CRPBannerInputMob = styled.input.attrs({ type: "file", id: "input-banner-mob" })`
  display: none;
`;
export const GridGalerryImage = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  padding: 0 24px;
  gap: 8px;
`;
export const GalleryImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 250px;
  max-height: 250px;
`;
export const GalerryItemImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
