import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useState } from "react";
import JsonFormsComponent from "components/CRPComponents/JsonForm/JsonFormsComponent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SchemaJson from "./schema/SchemaJson.json";
import UISchema from "./schema/UISchema.json";

function TestSchema() {
  const [state, setState] = useState<JsonFormsStateProps<any>>();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <JsonFormsComponent
        template={{
          UISchema: UISchema,
          schemaJson: SchemaJson,
        }}
        state={state}
        onChange={setState}
        onSave={async () => console.log(state)}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default TestSchema;
