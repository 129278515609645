import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateUser(
    $firstName: String!
    $email: String!
    $password: String!
    $lastName: String!
    $phone: String!
    $cpf: String!
    $rg: String!
    $birthDate: DateTime!
    $roleId: Int
    $permissionsIds: [Int!]
    $residentialPhone: String
    $active: StatusEnum
    $academicEmail: String
  ) {
    createUser(
      firstName: $firstName
      email: $email
      password: $password
      lastName: $lastName
      phone: $phone
      cpf: $cpf
      rg: $rg
      birthDate: $birthDate
      roleId: $roleId
      permissionsIds: $permissionsIds
      residentialPhone: $residentialPhone
      active: $active
      academicEmail: $academicEmail
    ) {
      id
    }
  }
`);

export interface CreateGql {
  createUser: {
    id: string;
  };
}
