import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateTag($data: UpdateTagInput!) {
    updateTag(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  updateTag: {
    id: number;
  };
}
