import useCreate from "./hooks/useCreate";
import UISchemaJson from "./schema/UISchema.json";
import ContentUISchema from "./schema/ContentUISchema.json";
import ContentSchemaJson from "./schema/ContentSchemaJson.json";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { CreateProps } from "./types";
import useTemplate from "./hooks/useTemplate";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { Fragment, useCallback, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import ModalCustom from "components/CRPComponents/ModalCustom";
import JsonFormsComponent from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { Box, SxProps } from "@mui/material";

const editButtonSx: SxProps = {
  color: "white !important",
  borderColor: "none !important",
  background: "#1A73E8 !important",
  transition: "all 0.3s ease-in",
  "&:disabled": {
    color: "#344767 !important",
    border: "1px solid #344767 !important",
    background: "white !important",
  },
};

function Create(props: CreateProps) {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>();
  const [infoState, setInfoState] = useState<JsonFormsStateProps<any>>({});
  const { templateSchema } = useTemplate();
  const [open, setModal] = useState(false);

  useEffect(() => {
    try {
      const data = JSON.parse(state?.data?.contentJson || "");
      setInfoState((prev) => ({ ...prev, data }));
    } catch (error) {
      setInfoState({});
    }
  }, [state?.data?.contentJson, open]);

  const handleModal = useCallback(() => setModal((old) => !old), []);

  const handlecontentJson = (data?: string) => {
    setState((prev) => {
      if (prev && infoState?.data) {
        return {
          ...prev,
          data: {
            ...prev.data,
            contentJson: data,
          },
        };
      }
      return prev;
    });
  };

  const saveInfo = async () => {
    handlecontentJson(JSON.stringify(infoState.data));

    handleModal();
  };

  if (!templateSchema) return null;

  return (
    <Fragment>
      <GenericMethods.Create<SchemaData, CreateGql>
        siteId={Sites.UNIFTEC.id}
        sitePath={Sites.UNIFTEC.path}
        useCreate={useCreate}
        template={{
          schemaJson: templateSchema,
          UISchema: UISchemaJson,
        }}
        state={state}
        setState={setState}
        {...props}
      >
        <MDButton sx={editButtonSx} onClick={handleModal}>
          Editar Conteúdo
        </MDButton>
      </GenericMethods.Create>
      <ModalCustom open={open} onClose={handleModal}>
        <Box margin={3}>
          <JsonFormsComponent
            template={{
              schemaJson: ContentSchemaJson,
              UISchema: ContentUISchema,
            }}
            state={infoState}
            onChange={setInfoState}
            onSave={saveInfo}
          />
        </Box>
      </ModalCustom>
    </Fragment>
  );
}

export default Create;
