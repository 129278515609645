import { withJsonFormsControlProps } from "@jsonforms/react";
import ModalEcommerceTagsSelect from "components/CRPComponents/ModalEcommerceTagsSelect";
import SelectMultiple from "../SelectMultiple";
import { CustomRendererProps } from "../types";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { print } from "graphql";
import { gql } from "@apollo/client";

interface ItemGql {
  Tag: {
    name: string;
    id: number;
  };
}

const query = print(gql`
  query Tag($id: Int!) {
    Tag(id: $id) {
      name
      id
    }
  }
`);

const EcommerceTagsSelectMultiple = (props: CustomRendererProps<number[] | undefined>) => {
  return (
    <SelectMultiple
      {...props}
      Modal={ModalEcommerceTagsSelect}
      formatItem={({ data }) => data.data.Tag}
      requestItem={(id: number) => ApiCourseRequest<ItemGql>({ query, variables: { id } })}
    />
  );
};

export default withJsonFormsControlProps(EcommerceTagsSelectMultiple, true);
