import { useEffect, useState } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import { ApiCMSRequest } from "data/ApiCMS";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import useCategories from "./useCategories";
import usePostsRelated from "./usePostsRelated";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const { categories } = useCategories();
  const { getPostsRelated, postsRelated } = usePostsRelated();
  const [responseData, setResponseData] = useState<GetItemGql["Blog"]>();

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCMSRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.Blog) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = response.data.data?.Blog;

          setResponseData(data);
          getPostsRelated({ slug: data.slug });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (!categories || !responseData || !postsRelated) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.relatedBlogCategorySlug = {
      ...schemaJsonParsed.properties.relatedBlogCategorySlug,
      oneOf: categories.map(({ slug, name }) => ({
        const: slug,
        title: name,
      })),
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });

    const { tags, mediaFileDesktop, mediaFileMobile, categoryBlog, ...data } = responseData;

    const newData = removeNullValues({
      ...data,
      tagsIds: tags.map(({ id }) => id),
      idMediaFileDesktop: mediaFileDesktop.id,
      idMediaFileMobile: mediaFileMobile.id,
      relatedPostsIds: postsRelated,
      relatedBlogCategorySlug: categoryBlog.slug,
    });

    setState({
      data: newData,
    });
  }, [categories, responseData, postsRelated]);
};

export default useGetItem;
