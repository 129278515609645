const getGroup = (date: string) => {
  const dataObj = new Date(date);

  const day = Number(String(dataObj.getUTCDate()).padStart(2, "0"));
  const month = Number(String(dataObj.getUTCMonth() + 1).padStart(2, "0"));
  const year = Number(dataObj.getUTCFullYear());

  return {
    day,
    month,
    year,
  };
};

function formatDateRaw(date: string) {
  const { day, month, year } = getGroup(date);

  return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
}

export function formatDateStr(date: string) {
  const { day, month, year } = getGroup(date);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(year, month, day).toLocaleDateString("pt-br", options);
}

const removeTime = (date: string) => date.split("T")[0];

export const DATE_FORMATTERS = {
  removeTime,
  formatDateStr,
  getGroup,
  formatDateRaw,
};
