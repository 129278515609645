import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Form(
    $isDefaultForm: Boolean
    $status: StatusEnum
    $formType: FormTypeEnum
    $search: String
    $perPage: Int
    $page: Int
  ) {
    Forms(
      isDefaultForm: $isDefaultForm
      status: $status
      formType: $formType
      search: $search
      perPage: $perPage
      page: $page
    ) {
      items {
        id
        slug
        status
        formType
        isDefaultForm
        title
        requiredUserId
        publishOn
        expiresIn
        responses {
          id
        }
        sendResponsesTo {
          id
          name
          active
          email
        }
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface QueryArgs {
  isDefaultForm?: boolean;
  status?: StatusEnum;
  formType?: FormTypeEnum;
  search?: string;
  perPage?: number;
  page?: number;
}

export type FormTypeEnum = "DINAMIC" | "EVENT";

export type StatusEnum = "ACTIVE" | "DRAFT" | "INACTIVE";

export interface GetAllItem {
  id: number;
  slug: string;
  status: string;
  isDefaultForm: boolean;
  formType: string;
  title: string;
  requiredUserId: boolean;
  publishOn: Date;
  expiresIn: Date;
  responses: {
    id: number;
  }[];
  sendResponsesTo: {
    id: number;
    name: string;
    active: boolean;
    email: string;
  }[];
}

export interface GetAllGql {
  Forms: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}

export interface CellProps {
  value: any;
  cell: {
    row: {
      original: GetAllItem;
    };
  };
}
