export default {
  type: "object",
  properties: {
    databaseName: {
      type: "string",
    },
    idColligate: {
      type: "number",
    },
    idModality: {
      type: "string",
    },
    modalityName: {
      type: "string",
    },
  },
  required: ["databaseName", "idColligate", "idModality", "modalityName"],
};
