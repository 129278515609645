import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiCourseWithSetData } from "data/queries/ApiCourse";
import ActiveOrInactiveCell from "components/CRPComponents/Tables/components/ActiveOrInactiveCell";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { UseGetAllCourses } from "./hooks/UseGetAllCourses";

function List(props: ListProps) {
  const useGetAllCourses = UseGetAllCourses();

  console.log({ useGetAllCourses });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <MDButton variant="outlined" color="dark">
          filters&nbsp;
          <Icon>keyboard_arrow_down</Icon>
        </MDButton>
        {/* <MDBox ml={1}>
          <MDButton variant="outlined" color="dark">
            <Icon>description</Icon>
            &nbsp;export csv
          </MDButton>
        </MDBox> */}
      </MDBox>
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          status: null,
        }}
        useStatusString
        dowloadSvgJson={useGetAllCourses.responses}
        allFildsSvgh={useGetAllCourses.allFilds}
        path={`/ecommerce/cursos`}
        query={GET_ALL_QUERY}
        formatRows={(data) =>
          data.Courses.items.map((item) => ({
            ...item,
            categoryName: item.courseGroup.category.name,
            courseGroupName: item.courseGroup.name,
            modalityName: item.modality.name,
          }))
        }
        formatPagination={(data) => data.Courses.paginationInfo}
        title="NOVO CURSO"
        ApiSetData={ApiCourseWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`/ecommerce/cursos/${value}`} value={value} />
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Nível de ensino",
            accessor: "categoryName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Grupo de Curso",
            accessor: "courseGroupName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Modalidade",
            accessor: "modalityName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Ativo",
            accessor: "active",
            Cell: ({ value }: any) => <ActiveOrInactiveCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
