import { withJsonFormsControlProps } from "@jsonforms/react";
import SelectMultiple from "../SelectMultiple";
import { CustomRendererProps } from "../types";
import { print } from "graphql";
import { gql } from "@apollo/client";
import ModalEventsSelect from "components/CRPComponents/ModalEventsSelect";
import { ApiCMSRequest } from "data/ApiCMS";

const query = print(gql`
  query Event($id: Int) {
    Event(id: $id) {
      id
      title
    }
  }
`);

interface ItemGql {
  Event: {
    title: string;
    id: number;
  };
}

const EventsSelectMultiple = (props: CustomRendererProps<number[] | undefined>) => {
  return (
    <SelectMultiple
      {...props}
      Modal={ModalEventsSelect}
      formatItem={({ data }) => ({
        ...data.data.Event,
        name: data.data.Event.title,
      })}
      requestItem={(id: number) => ApiCMSRequest<ItemGql>({ query, variables: { id } })}
    />
  );
};

export default withJsonFormsControlProps(EventsSelectMultiple, true);
