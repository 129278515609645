import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ANNOTATIONS = print(gql`
  query OrderAnnotations($id: String!) {
    OrderAnnotations(orderId: $id) {
      items {
        description
        lastUpdate
        createdBy {
          name
        }
      }
    }
  }
`);

export const CREATE_ANNOTATION = print(gql`
  mutation AddOrderAnnotation($id: String!, $annotation: Annotation!) {
    addOrderAnnotation(orderId: $id, annotation: $annotation) {
      description
    }
  }
`);

export interface Annotation {
  description: string;
  lastUpdate: string;
  createdBy: {
    name: string;
  };
}

export interface AnnotationsGql {
  OrderAnnotations: {
    items: Annotation[];
  };
}
