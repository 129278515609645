import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ListProps } from "./types";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import { QUERY_BANNERS, ResponseBanners } from "data/queries/ApiCMS/Banner/QueryBanners";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import ActiveOrInactiveCell from "components/CRPComponents/Tables/components/ActiveOrInactiveCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";

export const columnsTable = [
  {
    Header: "Nome",
    accessor: "name",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Título",
    accessor: "title",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Descrição",
    accessor: "description",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "URL",
    // isSorted: true,
    accessor: "url",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Ordem",
    // isSorted: true,
    accessor: "sort",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Ativo",
    accessor: "active",
    Cell: ({ value }: any) => <ActiveOrInactiveCell value={value} />,
  },
];

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<ResponseBanners>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          active: null,
          siteId: props.idSite,
        }}
        path={`${props.path}/banners`}
        query={QUERY_BANNERS}
        formatRows={(data) => data.banners.items}
        formatPagination={(data) => data.banners.paginationInfo}
        title="NOVO BANNER"
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/banners/${value}`} value={value} />
            ),
          },
          ,
          ...columnsTable,
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
