import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Address($addressId: Int!) {
    Address(id: $addressId) {
      id
      addressName
      city
      complement
      country
      postalCode
      reference
      state
      street
      neighborhood
      addressNumber
    }
  }
`);

export interface GetItemGql {
  Address: {
    id: number;
    addressName: string;
    city: string;
    complement: string;
    country: string;
    postalCode: string;
    reference: string;
    state: string;
    street: string;
    neighborhood: string;
    addressNumber: string;
  };
}
