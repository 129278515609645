import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const MUTATION_DELETE_CATEGORY_FAQ = print(gql`
  mutation CategoryFaqs($deleteCategoryFaqId: Int!) {
    deleteCategoryFaq(id: $deleteCategoryFaqId) {
      id
    }
  }
`);

export interface CategoryFaqMutationDeleteGql {
  deleteCategoryFaq: {
    id: number;
  };
}
