import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql, responseGetAllKey } from "./queries/getAll";
import { ApiWithSetData, path } from "./hooks/types";
import ActiveOrInactiveCell from "components/CRPComponents/Tables/components/ActiveOrInactiveCell";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
        }}
        hiddenSearch
        path={path}
        query={GET_ALL_QUERY}
        formatRows={(data) => data[responseGetAllKey].items}
        formatPagination={(data) => data[responseGetAllKey].paginationInfo}
        title="Criar Novo"
        ApiSetData={ApiWithSetData}
        columnsTable={[
          {
            Header: "Banco de Dados",
            accessor: "databaseName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Id Coligada",
            accessor: "id",
            Cell: ({ value, row }: any) =>
              !row?.original?.databaseName ? (
                <DefaultCell value={value} />
              ) : (
                <LinkCell
                  href={`${path}/${row.original.databaseName.concat("%2F", String(value))}`}
                  value={value}
                />
              ),
          },
          {
            Header: "Parceiro",
            accessor: "partnerVisibleName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Descrição",
            accessor: "description",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Ativo",
            accessor: "active",
            Cell: ({ value }: any) => <ActiveOrInactiveCell value={value} />,
          },
          {
            Header: "Criado em",
            accessor: "createdAt",
            Cell: ({ value }: any) => <DefaultCell value={new Date(value).toLocaleDateString()} />,
          },
          {
            Header: "Editado em",
            accessor: "updatedAt",
            Cell: ({ value }: any) => <DefaultCell value={new Date(value).toLocaleDateString()} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
