import useCreate from "./hooks/useCreate";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateOrUpdateGql } from "./queries/createOrUpdate";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Fragment, useEffect, useState } from "react";
import { CreateProps } from "./types";
import { PromotionType, promotionTypeList } from "./schemas/types";
import { Stack, SxProps } from "@mui/material";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import { getSchemaJsonWithRequired } from "./schemas/format";

const buttonSx: SxProps = {
  color: "white !important",
  borderColor: "none !important",
  background: "#1A73E8 !important",
  transition: "all 0.3s ease-in",
  width: "100%",
};

function Create(props: CreateProps) {
  const [type, setType] = useState<PromotionType>();
  const location = useLocation();

  useEffect(() => {
    const type = new URLSearchParams(location.search).get("type") as PromotionType;

    if (!type || !promotionTypeList.includes(type)) {
      setType(undefined);
      return;
    }

    setType(type);
  }, [location.search]);

  if (!type) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          sx={{
            border: "0 solid rgba(0, 0, 0, 0.125)",
            borderRadius: "6px",
            boxShadow:
              "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
            background: "white",
            padding: 5,
            margin: "5rem auto 20rem auto",
            width: "fit-content",
            height: "auto",
          }}
        >
          <Stack gap="10px" justifyContent={"center"} alignItems={"center"}>
            <span>Escolha um tipo de promoção:</span>
            <Stack gap={"7px"}>
              <MDButton href={"?type=regular"} sx={buttonSx}>
                Regular
              </MDButton>
              <MDButton href={"?type=combo"} sx={buttonSx}>
                Compre Junto
              </MDButton>
              <MDButton sx={buttonSx} href={"?type=forThePriceOf"}>
                Leve Mais Por Menos
              </MDButton>
              <MDButton href={"?type=progressive"} sx={buttonSx}>
                Desconto progressivo
              </MDButton>
            </Stack>
          </Stack>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <Fragment>
      <GenericMethods.Create<SchemaData, CreateOrUpdateGql>
        siteId={Sites.UNIFTEC.id}
        sitePath={Sites.UNIFTEC.path}
        useCreate={useCreate}
        template={{
          schemaJson: getSchemaJsonWithRequired(type),
          UISchema: require(`./schemas/${type}/UISchema.json`),
        }}
        {...props}
      />
    </Fragment>
  );
}

export default Create;
