import { Fragment, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import SimpleCard from "./SimpleCard";
import { useData } from "./context/DataProvider";
import { QUERY_METRICS, QueryMetricsGql } from "./queries/metrics";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { formatPrice } from "utils/formatPrice";
import { getColor, getPercentageText } from "./utils";
import Container from "./Container";

function General() {
  const { dateSelected } = useData();
  const [data, setData] = useState<QueryMetricsGql["Metrics"] & QueryMetricsGql["SummaryOrders"]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ApiFinancialRequest<QueryMetricsGql>({
      query: QUERY_METRICS,
      variables: {
        compareStartDate: dateSelected.from.value.toISOString(),
        startDate: dateSelected.from.value.toISOString(),
        compareEndDate: dateSelected.to.value.toISOString(),
        endDate: dateSelected.to.value.toISOString(),
      },
    })
      .then(({ data }) => {
        const metrics = data.data.Metrics;
        const formattedData = {
          revenue: {
            referenceData: metrics?.revenue.referenceData || 0,
            comparedData: metrics?.revenue.comparedData || 0,
          },
          orders: {
            referenceData: metrics?.orders.referenceData || 0,
            comparedData: metrics?.orders.comparedData || 0,
          },
          sessions: {
            referenceData: metrics?.sessions.referenceData || 0,
            comparedData: metrics?.sessions.comparedData || 0,
          },
          avgticket: {
            referenceData: metrics?.avgticket.referenceData || 0,
            comparedData: metrics?.avgticket.comparedData || 0,
          },
          ...data.data.SummaryOrders,
        };
        setData(formattedData);
      })
      .finally(() => setLoading(false));
  }, [dateSelected]);

  return (
    <Fragment>
      {!data || loading ? (
        <Container
          sx={{
            width: "100%",
            maxWidth: "100%",
            marginBlock: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "#344767", width: "30px", height: "30px" }} />
        </Container>
      ) : (
        <Grid
          sx={{
            gridTemplateColumns: "repeat(6, 1fr)",
            display: "grid",
            gap: "10px",
            marginBlock: "20px",
          }}
        >
          <Grid item>
            <SimpleCard
              title="Receita"
              count={`${formatPrice(data.revenue.referenceData)}`}
              percentage={{
                color: getColor(data.revenue),
                value: getPercentageText(data.revenue),
              }}
            />
          </Grid>
          <Grid item>
            <SimpleCard
              title="Ticket médio"
              count={`${formatPrice(data.avgticket.referenceData)}`}
              percentage={{
                color: getColor(data.avgticket),
                value: getPercentageText(data.avgticket),
              }}
            />
          </Grid>
          <Grid item>
            <SimpleCard
              title="Pedidos"
              count={data.orders.referenceData}
              percentage={{
                color: getColor(data.orders),
                value: getPercentageText(data.orders),
              }}
            />
          </Grid>
          <Grid item>
            <SimpleCard
              title="Sessões"
              count={data.sessions.referenceData}
              percentage={{
                color: getColor(data.sessions),
                value: getPercentageText(data.sessions),
              }}
            />
          </Grid>
          <Grid item>
            <SimpleCard
              title="Pedidos com pagamento em autorização"
              count={data.paymentsInAuthorization}
            />
          </Grid>
          <Grid item>
            <SimpleCard title="Pedidos na última hora" count={data.ordersLastHour} />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default General;
