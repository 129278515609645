import { GET_ALL_QUERY, GetAllGql } from "../queries/getCourseFinancialDependencies";
import { ApiRequest } from "./types";

export default async function getCourseFinancialDependencies(): Promise<GetAllGql | null> {
  try {
    const { data } = await ApiRequest<GetAllGql>({
      query: GET_ALL_QUERY,
      variables: {
        perPage: -1,
        hasECommerceId: true,
      },
    });
    return data?.data ?? null;
  } catch {
    return null;
  }
}

type ResponseGet = {};
