import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useData } from "./context/DataProvider";
import { DATE_FORMATTERS } from "utils/formatDate";

export default function PickDate() {
  const { dateSelected, datesFrom, datesTo, handleDate } = useData();

  const spanStyles = {
    margin: 0,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "0.875rem",
    // fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "0.02857em",
    opacity: 1,
    verticalAlign: "unset",
    textDecoration: "none",
    color: "#7b809a",
    fontWeight: 500,
  };

  return (
    <Stack direction="row" alignItems="center" gap="20px">
      <span style={spanStyles}>{`Periodo selecionado: ${DATE_FORMATTERS.formatDateRaw(
        String(dateSelected.from.value)
      )} até ${DATE_FORMATTERS.formatDateRaw(String(dateSelected.to.value))}`}</span>
      <Stack direction="row" alignItems="center" gap="10px">
        <span style={spanStyles}>Periodo:</span>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="date-from">Periodo</InputLabel>
            <Select
              labelId="date-from"
              id="date-from-select"
              value={dateSelected.from.label}
              label={dateSelected.from.label}
              onChange={(evt) => handleDate("from", evt.target.value)}
            >
              {datesFrom.map((item) => (
                <MenuItem key={item.label} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" gap="10px">
        <span style={spanStyles}>Comparar com:</span>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="date-to">Comparar</InputLabel>
            <Select
              labelId="date-to"
              id="date-to-select"
              value={dateSelected.to.label}
              label={dateSelected.to.label}
              onChange={(evt) => handleDate("to", evt.target.value)}
            >
              {datesTo.map((item) => (
                <MenuItem key={item.label} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Stack>
  );
}
