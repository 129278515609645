import { Fragment, SyntheticEvent, useCallback, useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/pagesExamples/authentication/components/BasicLayout";

// Images
import CloseIcon from "@mui/icons-material/Close";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useAuth } from "context/useUserAuth";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ModalCustom from "components/CRPComponents/ModalCustom";
import { ApiAuthRequest } from "data/ApiAuth";
import { print } from "graphql";
import { gql } from "@apollo/client";

const initialState: {
  email: string | undefined;
  error: boolean;
} = {
  email: undefined,
  error: false,
};

function SignIn(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [data, setData] = useState({
    userIdentificator: "",
    password: "",
  });

  const [recoverPasswordData, setRecoverPasswordData] = useState(initialState);

  const userData = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleOpen = useCallback(() => {
    setRecoverPasswordData(initialState);
    setOpen((old) => !old);
  }, []);

  const recoverPassword = () => {
    setLoading(true);
    ApiAuthRequest<{ passwordRecovery: { email: string } }>({
      query: print(gql`
        mutation PasswordRecovery($userIdentificator: String) {
          passwordRecovery(userIdentificators: $userIdentificator) {
            email
          }
        }
      `),
      variables: { userIdentificator: data.userIdentificator },
    })
      .then(({ data }) => {
        setRecoverPasswordData({ email: data.data.passwordRecovery.email, error: false });
        setOpen(false);
      })
      .catch(() => setRecoverPasswordData({ email: undefined, error: true }))
      .finally(() => setLoading(false));
  };

  const handleLogin = (evt: SyntheticEvent<HTMLDivElement>) => {
    evt?.preventDefault();

    setLoading(true);
    userData.sigInFunction(data.userIdentificator, data.password).finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Login
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleLogin}>
              <MDBox mb={2}>
                <TextField
                  onChange={(e: any) => {
                    setData((old) => ({ ...old, userIdentificator: e.target.value }));
                  }}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Email ou CPF"
                  variant="standard"
                  value={data.userIdentificator}
                />
              </MDBox>
              <MDBox mb={2}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e: any) => {
                      setData((old) => ({ ...old, password: e.target.value }));
                    }}
                    value={data.password}
                    sx={{ width: "100%" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Mostrar Senha"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                  sx={{
                    "& .MuiCircularProgress-root": {
                      color: "white !important",
                      width: "22px !important",
                      height: "auto !important",
                    },
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : <span>Entrar</span>}
                </MDButton>
              </MDBox>

              <Box
                component={"button"}
                type="button"
                onClick={handleOpen}
                sx={{
                  border: "none",
                  background: "transparent",
                  textTransform: "none",
                  fontWeight: 400,
                  width: "fit-content",
                  padding: 0,
                  height: "unset",
                  cursor: "pointer",
                  fontSize: "0.8rem",
                  color: "#344767",
                  textDecoration: "underline",
                }}
              >
                Esqueceu a senha?
              </Box>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
      <ModalCustom
        open={open}
        onClose={handleOpen}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "55vw",
          },
        }}
      >
        <Stack gap={2} padding={"1.5rem"} marginTop={"3rem"}>
          <Typography
            sx={{
              fontSize: "1.4rem",
              color: "#344767",
              fontWeight: 600,
            }}
            component={"h2"}
          >
            ESQUECEU SUA SENHA DE ACESSO?
          </Typography>
          <Typography
            component={"p"}
            sx={{
              fontSize: "1rem",
              color: "#344767",
              fontWeight: 400,
            }}
          >
            Informe seu número do CPF ou CNPJ de cadastro:
          </Typography>
          {recoverPasswordData.error && (
            <Typography
              component={"p"}
              sx={{
                fontSize: "1rem",
                color: "#ff2323",
                fontWeight: 400,
              }}
            >
              Usuário não encontrado.
            </Typography>
          )}
          <TextField
            onChange={(e: any) => {
              setData((old) => ({ ...old, userIdentificator: e.target.value }));
            }}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Email ou CPF"
            variant="standard"
            value={data.userIdentificator}
          />
          <MDButton
            disabled={!data.userIdentificator.length || loading}
            onClick={recoverPassword}
            variant="gradient"
            color="info"
            sx={{
              "& .MuiCircularProgress-root": {
                color: "white !important",
                width: "22px !important",
                height: "auto !important",
              },
            }}
          >
            {loading ? <CircularProgress /> : <span>Enviar</span>}
          </MDButton>
        </Stack>
      </ModalCustom>
      <Snackbar
        open={!!recoverPasswordData.email}
        autoHideDuration={10000}
        onClose={() => setRecoverPasswordData(initialState)}
        message={`Um Email com os passos para a recuperação da conta, foi enviado para "${recoverPasswordData.email}"`}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setRecoverPasswordData(initialState)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Fragment>
  );
}

export default SignIn;
