export const UISchemaJson = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Categorias de FAQ",
      type: "Group",
      elements: [
        {
          type: "Control",
          label: "Nome",
          scope: "#/properties/name",
          options: {
            style: {
              marginBottom: "10px",
            },
          },
        },
        {
          type: "Control",
          label: "Slug",
          scope: "#/properties/slug",
          options: {
            style: {
              marginBottom: "10px",
            },
          },
        },
        {
          type: "Control",
          label: "Sort",
          scope: "#/properties/sort",
          options: {
            style: {
              marginBottom: "10px",
            },
          },
        },
      ],
      options: {
        style: {
          maxWidth: "300px",
          margin: "auto",
        },
      },
    },
  ],
};
