import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

// Declaring props types for LinkCell
interface LinkCellProps {
  value: string;
  href: string;
  suffix?: string | boolean;
}

function LinkCell({ value, suffix, href }: LinkCellProps): JSX.Element {
  return (
    <Link to={href}>
      <MDTypography variant="caption" fontWeight="medium" color="inherit">
        {value}
        {suffix && (
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            &nbsp;&nbsp;{suffix}
          </MDTypography>
        )}
      </MDTypography>
    </Link>
  );
}

// Declaring default props for LinkCell
LinkCell.defaultProps = {
  suffix: "",
};

export default LinkCell;
