import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation Mutation($data: UpdateGlobalKeyInput!) {
    updateGlobalKey(data: $data) {
      key
    }
  }
`);

export interface UpdateGql {
  updateGlobalKey: {
    key: string;
  };
}
