import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Comments(
    $statusExibition: StatusCommentEnum
    $page: Int
    $perPage: Int
    $blogSlug: String
  ) {
    Comments(
      statusExibition: $statusExibition
      page: $page
      perPage: $perPage
      blogSlug: $blogSlug
    ) {
      items {
        comment
        blogSlug
        userId
        statusExibition
        id
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  comment: string;
  blogSlug: string;
  userId: number;
  statusExibition: StatusCommentEnum;
}

export type StatusCommentEnum = "APPROVED" | "DENIED" | "PENDING";

export interface GetAllGql {
  Comments: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}

export interface CellProps {
  value: any;
  cell: {
    row: {
      original: GetAllItem;
    };
  };
}
