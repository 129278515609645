import { useCallback, useState } from "react";
import { CANCEL_ORDER, CancelOrderGql, cancelOrderInput } from "../queries/cancelOrder";
import { ApiFinancialRequest } from "data/ApiFinancial";
import { UseDeleteResponseProps } from "./types";

const useDeleteResponse = ({ setSnackBarInfo }: UseDeleteResponseProps) => {
  const [loading, setLoading] = useState(false);

  const handleCancelOrderError = (e: Error) => {
    setSnackBarInfo({
      open: true,
      color: "error",
      title: "Ops, algo deu errado",
      content: e?.message || "Ops. Algo Deu errado",
      autoHideDuration: null,
    });
  };

  const handleCancelOrderSuccess = () => {
    setSnackBarInfo({
      open: true,
      color: "success",
      icon: "check",
      autoHideDuration: null,
      title: "Solicitação de cancelamento feita com sucesso",
      redirectid: -1,
    });
  };

  const handleCancelOrder = useCallback(async ({ cancelOrderId, reason }: cancelOrderInput) => {
    setLoading(true);

    try {
      const response = await ApiFinancialRequest<CancelOrderGql>({
        query: CANCEL_ORDER,
        variables: {
          cancelOrderId,
          reason,
        },
      });

      if (response.status !== 200) {
        if (response.data?.errors?.[0]) throw response.data?.errors?.[0];
        throw new Error(JSON.stringify(response?.data || "Algo deu errado!"));
      }

      handleCancelOrderSuccess();
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      handleCancelOrderError(error);
    }
  }, []);

  return {
    handleCancelOrder,
    handleCancelOrderSuccess,
    handleCancelOrderError,
  };
};

export default useDeleteResponse;
