import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { StatusEnum } from "./getAll";

export const UPDATE_QUERY = print(gql`
  mutation UpdateFeedbackProduct($data: UpdateFeedbackProductInput!) {
    updateFeedbackProduct(data: $data) {
      id
      status
    }
  }
`);

export interface UpdateGql {
  updateFeedbackProduct: {
    id: number;
    status: StatusEnum;
  };
}
