import Create from "./Create";
import { path } from "./hooks/types";
import List from "./List";
import Update from "./Update";

export const PlanAdmissionWays = {
  List: List,
  Update: Update,
  Create: Create,
  path: path,
  Permissions: {
    read: "read:PlanAdmissionWay",
    create: "create:PlanAdmissionWay",
    delete: "delete:PlanAdmissionWay",
    list: "list:PlanAdmissionWay",
    edit: "update:PlanAdmissionWay",
  },
};
