import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonTwoLines from "components/CRPComponents/ButtonTwoLines";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import {
  READ_QUEUE_QUERY,
  ReadQueueGql,
  responseReadQueueKey,
  Message,
} from "../../CoursesFinancial/queries/readQueue";
import { MDSnackbar, MDSnackbarProps } from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

function ButtonReadQueue({
  loading,
  setLoading,
  onFailCreate,
  onSuccessCreate = () => {},
  readQueueProps = {},
  showButtonRead10Times = false,
}: {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  onFailCreate: (reason: any) => any | PromiseLike<any>;
  onSuccessCreate?: (response: Message[]) => void;
  readQueueProps?: {
    MaxNumberOfMessages?: number;
    VisibilityTimeout?: number;
    WaitTimeSeconds?: number;
  };
  showButtonRead10Times?: boolean;
}) {
  const [numberOfMessages, setNumberOfMessages] = useState<number>();
  const [loadingNumberOfMessages, setLoadingNumberOfMessages] = useState(true);
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setLoadingNumberOfMessages(true);
    ApiFinancialRequest<{ ApproximateNumberOfMessagesSQS: number }>({
      query: `query ApproximateNumberOfMessagesSQS {
        ApproximateNumberOfMessagesSQS
      }`,
    })
      .then((response) => {
        if (!response?.data?.data) {
          throw new Error("Não foi possivel Ler Fila", {
            cause: { response: response?.data || response },
          });
        }
        setNumberOfMessages(response?.data?.data?.ApproximateNumberOfMessagesSQS);
      })
      .catch(onFailCreate)
      .finally(() => setLoadingNumberOfMessages(false));
  }, []);

  const handleReadQueue = useCallback(async () => {
    setLoading(true);
    ApiFinancialRequest<ReadQueueGql>({
      query: READ_QUEUE_QUERY,
      variables: { data: readQueueProps },
    })
      .then((response) => {
        if (!response?.data?.data) {
          throw new Error("Não foi possivel Ler Fila", {
            cause: { response: response?.data || response },
          });
        }

        const messages = response?.data?.data?.[responseReadQueueKey];
        const deleted = messages.filter(({ messageWasDeleted }) => messageWasDeleted);
        const successfuls = messages.filter(({ result }) => result === "Successful");

        setNumberOfMessages((old) => old - deleted.length);

        setSnackBarInfo({
          open: true,
          color: "info",
          icon: "check",
          title: `Foram Lidas ${messages.length} mensage${messages.length > 1 ? "ns" : "m"}`,
          content: (
            <MDTypography color="inherit">
              <MDTypography color="inherit" variant="body2">
                {`Processadas com sucesso ${successfuls.length} mensage${
                  successfuls.length > 1 ? "ns" : "m"
                }`}
              </MDTypography>
              <MDTypography color="inherit" variant="body2">
                {`Deletadas com sucesso ${deleted.length} mensage${
                  deleted.length > 1 ? "ns" : "m"
                }`}
              </MDTypography>
              {messages.length - successfuls.length > 0 && (
                <MDTypography color="error" variant="body2">
                  {`Falhas ao processar mensagem: ${messages.length - successfuls.length}`}
                </MDTypography>
              )}
            </MDTypography>
          ),
        });
        onSuccessCreate(messages);
        console.log({ messages });
      })
      .catch(onFailCreate)
      .finally(() => {
        setLoading(false);
      });
  }, [onSuccessCreate, setLoading, readQueueProps]);

  const handleCloseSnackBar = () => {
    setSnackBarInfo((...prev) => ({ ...prev, open: false, title: "", content: "" }));
    if (numberOfMessages === 0) navigate(0);
  };

  const handleReadQueue10Times = useCallback(async () => {
    const times = 10;
    const iterations = Array.from({ length: times });
    let messagesTotal = 0;
    let successfulsTotal = 0;
    let deletedTotal = 0;
    let totalNumberOfMessages = numberOfMessages;
    setLoading(true);
    for (const _ of iterations) {
      const response =
        totalNumberOfMessages > 0
          ? await ApiFinancialRequest<ReadQueueGql>({
              query: READ_QUEUE_QUERY,
              variables: { data: readQueueProps },
            })
          : null;
      const messages = response?.data?.data?.[responseReadQueueKey] || [];
      const deleted = messages.filter(({ messageWasDeleted }) => messageWasDeleted);
      const successfuls = messages.filter(({ result }) => result === "Successful");
      messagesTotal = messagesTotal + messages.length;
      successfulsTotal = successfulsTotal + successfuls.length;
      deletedTotal = deletedTotal + deleted.length;
      totalNumberOfMessages = totalNumberOfMessages - deleted.length;

      setNumberOfMessages((old) => old - deleted.length);
      setSnackBarInfo({
        open: true,
        color: "info",
        icon: "check",
        title: `Foram Lidas ${messagesTotal} mensage${messagesTotal > 1 ? "ns" : "m"}`,
        content: (
          <MDTypography color="inherit">
            <MDTypography color="inherit" variant="body2">
              {`Processadas com sucesso ${successfulsTotal} mensage${
                successfulsTotal > 1 ? "ns" : "m"
              }`}
            </MDTypography>
            <MDTypography color="inherit" variant="body2">
              {`Deletadas com sucesso ${deletedTotal} mensage${deletedTotal > 1 ? "ns" : "m"}`}
            </MDTypography>
            {messagesTotal - successfulsTotal > 0 && (
              <MDTypography color="error" variant="body2">
                {`Falhas ao processar mensagem: ${messagesTotal - successfulsTotal}`}
              </MDTypography>
            )}
          </MDTypography>
        ),
      });

      onSuccessCreate(messages);
      console.log({ messages });
    }

    setLoading(false);
  }, [handleReadQueue]);

  return (
    <>
      <ButtonTwoLines
        loading={loading || loadingNumberOfMessages}
        title="Ler Fila"
        onClick={handleReadQueue}
        disabled={numberOfMessages === 0}
      >
        {loadingNumberOfMessages
          ? "verificando fila"
          : typeof numberOfMessages !== "number"
          ? `Forçar Leitura`
          : `${numberOfMessages} mensage${numberOfMessages > 1 ? "ns" : "m"} na fila`}
      </ButtonTwoLines>
      {!!showButtonRead10Times && (
        <ButtonTwoLines
          sx={{ marginLeft: 3 }}
          loading={loading || loadingNumberOfMessages}
          title="Ler Fila 10 vezes"
          onClick={handleReadQueue10Times}
          disabled={
            numberOfMessages === 0 || numberOfMessages < readQueueProps?.MaxNumberOfMessages
          }
        >
          {loadingNumberOfMessages
            ? "verificando fila"
            : typeof numberOfMessages !== "number"
            ? `Forçar Leitura`
            : `${numberOfMessages} mensage${numberOfMessages > 1 ? "ns" : "m"} na fila`}
        </ButtonTwoLines>
      )}
      <MDSnackbar
        autoHideDuration={20000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        title={snackBarInfo?.title || ""}
        color={snackBarInfo?.color || "info"}
        icon={snackBarInfo?.icon || "check"}
        {...snackBarInfo}
        close={handleCloseSnackBar}
        onClose={handleCloseSnackBar}
      />
    </>
  );
}

export default ButtonReadQueue;
