import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query Categories {
    Categories(perPage: -1, active: true, parentId: 0) {
      items {
        id
        name
      }
    }
  }
`);

export interface QueryGql {
  Categories: {
    items: Array<{
      id: number;
      name: string;
    }>;
  };
}

export default function useCategories() {
  const [categories, setCategories] = useState<null | QueryGql["Categories"]["items"]>(null);

  useEffect(() => {
    ApiCourseRequest<QueryGql>({
      query: QUERY,
    }).then(({ data }) => setCategories(data.data.Categories.items));
  }, []);

  return {
    categories,
    setCategories,
  };
}
