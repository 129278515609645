import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LogDownloadsTable from "components/CRPComponents/Tables/LogDownloadsTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LogDownloadsTable<GetAllGql>
        initialVariables={{}}
        path={`${props.path}/Downloads`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.LogDownloads.items}
        formatPagination={(data) => data.LogDownloads.paginationInfo}
        columnsTable={[
          {
            Header: "fileName",
            accessor: "fileName",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/Downloads/${value}`} value={value} />
            ),
          },
          {
            Header: "description",
            accessor: "description",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "user",
            accessor: "user",
            Cell: ({ value }: any) => <DefaultCell value={value?.userName} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
