import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation Update($data: UpdatePlanAdmissionWayInput!) {
    updatePlanAdmissionWay(data: $data) {
      id
    }
  }
`);

export const responseUpdateKey = "updatePlanAdmissionWay";
export interface UpdateGql {
  updatePlanAdmissionWay: {
    id: number;
  };
}
