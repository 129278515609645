import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiMailerWithSetData } from "data/queries/ApiMailer";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";

function List() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          active: null,
        }}
        path="/configuracoes/templates-de-email"
        query={GET_ALL_QUERY}
        formatRows={(data) => data.TemplatesEmail.items}
        formatPagination={(data) => data.TemplatesEmail.paginationInfo}
        // title="Novo Template"
        useDefaultFilter={false}
        ApiSetData={ApiMailerWithSetData}
        columnsTable={[
          {
            Header: "name",
            accessor: "name",
            Cell: ({ value }: any) => (
              <LinkCell href={`/configuracoes/templates-de-email/${value}`} value={value} />
            ),
          },
          {
            Header: "Nome do Arquivo",
            accessor: "fileName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Assunto padrão do email",
            accessor: "subjectDefault",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Exemplo de data esperado",
            accessor: "jsonDataExample",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
