import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const EducationalUnitConversion = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:EducationalUnitConversion",
    read: "read:EducationalUnitConversion",
    list: "list:EducationalUnitConversion",
    edit: "update:EducationalUnitConversion",
    delete: "delete:EducationalUnitConversion",
  },
};
