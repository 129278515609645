import { path } from "./hooks/types";
import Update from "./Update";

export const Inventorys = {
  Update: Update,
  path: path,
  Permissions: {
    listAndRead: "read:Inventory",
    edit: "update:Inventory",
  },
};
