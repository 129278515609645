import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LayoutContainer from "examples/LayoutContainers/LayoutContainer";
import StepperComponent from "layouts/aplicacoes/GenericMethods/Stepper";
import Row from "components/Row";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useAuth } from "context/useUserAuth";
import { GET_ITEM_QUERY, GetItemGql } from "../CoursesFinancial/queries/getItem";
import { GET_ITEM_QUERY as GET_COURSE, GetItemGql as CourseGQL } from "./queries/getItem";
import { getCourseStepper } from "./hooks/stepperCourseCreate";
import { path as CoursesFinancialPath } from "../CoursesFinancial/hooks/types";
import ButtonCreateCourse from "./Components/ButtonCreateCourse";
import ButtonReadQueue from "./Components/ButtonReadQueue";

const permissionsRequireds = {
  upsertCourse: "upsert:Course",
  ReadSQS: "ReadSQS",
};

function Create() {
  const id = Number(useParams()?.id);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<StateProp>();
  const [loadingPermission, setLoadingPermission] = useState<boolean>();
  const [permissions, setPermissions] = useState<{
    upsertCourse: boolean;
    ReadSQS: boolean;
  }>({ upsertCourse: false, ReadSQS: false });

  const navigate = useNavigate();

  const handleCriticalError = (error: any) => {
    console.log({ error });

    setState((prev) => ({
      ...prev,
      criticalError: error?.response?.data?.errors?.[0] || error,
    }));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    ApiFinancialRequest<GetItemGql>({ query: GET_ITEM_QUERY, variables: { id } })
      .then((response) => {
        if (!response?.data?.data?.Course) {
          throw new Error("Não encontrado", {
            cause: { response: response?.data || response },
          });
        }
        navigate(`${CoursesFinancialPath}/${id}`);
      })
      .catch((error) => {
        // Has to Create
        ApiCourseRequest<CourseGQL>({ query: GET_COURSE, variables: { id } })
          .then((response) => {
            if (!response?.data?.data?.Course) {
              throw new Error("Não encontrado", {
                cause: { response: response?.data || response },
              });
            }
            setState((prev) => ({ ...prev, data: response?.data?.data?.Course }));
          })
          .catch((error) => {
            console.log({ error });

            setState((prev) => ({
              ...prev,
              criticalError: error?.response?.data?.errors?.[0] || error,
            }));
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, [id]);

  useEffect(() => {
    setLoadingPermission(true);
    const permissions = user?.permissions.map(({ name }) => name) ?? [];
    setPermissions({
      upsertCourse: permissions.includes(permissionsRequireds.upsertCourse),
      ReadSQS: permissions.includes(permissionsRequireds.ReadSQS),
    });
    setLoadingPermission(false);
  }, [user?.permissions]);

  if (!id) return null;
  return (
    <LayoutContainer>
      <StepperComponent {...getCourseStepper(2, id)} />
      {state?.criticalError?.message ? (
        <Row color="red" padding={4}>
          <p>{"Error: " + state?.criticalError?.message}</p>
        </Row>
      ) : (
        <Row justifyContent="space-evenly" paddingY={4}>
          {!permissions?.ReadSQS && !permissions?.upsertCourse && loadingPermission === false && (
            <Row color="red">
              <p>{"Error: Sem permissão para criar curso ou ler fila"}</p>
            </Row>
          )}
          {!!permissions?.upsertCourse && (
            <ButtonCreateCourse
              loading={loading || loadingPermission}
              setLoading={setLoading}
              data={state?.data}
              onFailCreate={handleCriticalError}
            />
          )}
          {!!permissions?.ReadSQS && (
            <ButtonReadQueue
              loading={loading || loadingPermission}
              setLoading={setLoading}
              onFailCreate={handleCriticalError}
            />
          )}
        </Row>
      )}
    </LayoutContainer>
  );
}
export type StateProp = {
  data?: CourseGQL["Course"];
  criticalError?: { message: string };
};
export default Create;
