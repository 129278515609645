import { upsertFacultyMember } from "./hooks/UpsertFacultyMember";
import { convertObjectToUpsertInput, editEachDataRead } from "./hooks/convertObjectToUpsertInput";
import ReadCSVComponent from "../ReadCSVComponent";

function ReadCSVFacultyMemberComponent() {
  async function sendToAPi(data: { [key: string]: string }): Promise<{ id: number }> {
    const input = convertObjectToUpsertInput(data);
    if (!input?.email) throw Error("Sem Email");
    if (!input?.name) throw Error("Sem Nome");
    return upsertFacultyMember(input);
  }
  return (
    <>
      <ReadCSVComponent
        pathToSuccessSendToAPi="/ecommerce/corpo-docente"
        sendToAPi={sendToAPi}
        editEachDataRead={editEachDataRead} //this slugify all slugs
      />
    </>
  );
}

export default ReadCSVFacultyMemberComponent;
