import { useEffect, useState } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import useTemplate from "./useTemplate";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import { ApiGetCouseERPRequest } from "data/apiGetCourseERP";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const [responseData, setResponseData] = useState<SchemaData>();
  const { template } = useTemplate();

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiGetCouseERPRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.CategoryConversion) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = removeNullValues(response.data.data?.CategoryConversion);
          setResponseData(data);
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (template && responseData) {
      setTemplate(template);

      setState({
        data: responseData,
      });
    }
  }, [template, responseData]);
};

export default useGetItem;
