import { Box, Stack, Typography } from "@mui/material";
import { Group } from "./styles";
import { Fragment, useState } from "react";
import MDButton from "components/MDButton";
import { formatDate } from "./utils";
import useAnnotations from "./hooks/useAnnotations";
import { userPermissions } from "hooks/Permissions";
import { Orders } from ".";

function Annotations({ orderId }: { orderId: string }) {
  const { update } = userPermissions({ updatePermision: Orders.Permissions.update });
  const { annotations, loading, addComment } = useAnnotations(orderId);
  const [comment, setComment] = useState("");

  const handleSend = () => {
    addComment(comment);
    setComment("");
  };

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "6px",
      }}
    >
      <Group>
        <h3>Comentários ({annotations?.length ?? 0})</h3>
        <Stack gap={"12px"}>
          {update && (
            <Fragment>
              <Box
                component={"textarea"}
                value={comment}
                onChange={(evt) => setComment(evt.target.value)}
                placeholder="Deixe um comentário"
                sx={{
                  border: "1px solid rgb(116, 116, 116)",
                  borderRadius: "6px",
                  padding: "1rem",
                  fontSize: "14px",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              />
              <MDButton
                disabled={comment.length < 5 || loading}
                onClick={handleSend}
                sx={{
                  width: "100%",
                  background: "rgb(41, 83, 178) !important",
                  color: "white !important",
                  "&:disabled": {
                    opacity: 0.6,
                  },
                }}
              >
                Enviar
              </MDButton>
            </Fragment>
          )}
          <span>Os comentários não são enviados aos clientes.</span>
        </Stack>
        <Stack marginTop={"40px"} gap={"10px"}>
          {annotations?.map((item, i) => (
            <Stack key={i} gap={"2px"}>
              <Typography
                component={"p"}
                sx={{
                  color: "black !important",
                  fontSize: "15px",
                  margin: 0,
                  lineHeight: "20px",
                }}
              >
                <span>Comentário:</span>
                <br />
                {item.description}
              </Typography>
              <span>Data: {formatDate(item.lastUpdate)}</span>
            </Stack>
          ))}
        </Stack>
      </Group>
    </Box>
  );
}

export default Annotations;
