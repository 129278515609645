import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query PaymentMethods {
    PaymentMethods {
      id
      name
      salesChannels {
        id
      }
      paymentSystem {
        id
        name
        implementation
      }
      connector {
        implementation
        affiliationId
      }
      issuer {
        name
      }
      antifraud {
        implementation
        affiliationId
      }
      installmentOptions {
        dueDateType
        interestRateMethod
        minimumInstallmentValue
        installments {
          ruleId
          quantity
          value
          interestRate
          isExternalInstallmentService
          interestTax
        }
      }
      enabled
      installmentsService
      isDefault
      isSelfAuthorized
      requiresAuthentication
      beginDate
      endDate
      condition {
        id
        pullRate
        name
      }
      multiMerchantList
      country {
        name
        isoCode
      }
      dateIntervals {
        start
        end
      }
      externalInterest
      minimumValue
      deadlines {
        days
        interestRate
      }
      paymentOptions {
        days
        interestRate
      }
      cobrand {
        name
      }
      cardLevel {
        name
      }
      excludedBinsRanges
    }
  }
`);

export interface GetAllGql {
  PaymentMethods: Array<PaymentMethod>;
}

export interface PaymentMethod {
  id: string;
  name: string;
  salesChannels: Array<{
    id: string;
  }>;
  paymentSystem: {
    id: number;
    name: string;
    implementation: null | string;
  };
  connector: {
    implementation: string;
    affiliationId: string;
  };
  issuer: {
    name: null | string;
  };
  antifraud: {
    implementation: null | string;
    affiliationId: null | string;
  };
  installmentOptions: null | {
    dueDateType: number;
    interestRateMethod: number;
    minimumInstallmentValue: number;
    installments: Array<{
      ruleId: null | number;
      quantity: number;
      value: number;
      interestRate: number;
      isExternalInstallmentService: number | null;
      interestTax: number;
    }>;
  };
  enabled: boolean;
  installmentsService: boolean;
  isDefault: null | string;
  isSelfAuthorized: null | string;
  requiresAuthentication: null | string;
  beginDate: null | string;
  endDate: null | string;
  condition: null | Array<{
    name: string;
    isoCode: string;
  }>;
  multiMerchantList: null | string;
  country: null | string;
  dateIntervals: null | string;
  externalInterest: boolean;
  minimumValue: null | string;
  deadlines: Array<{
    days: number;
    interestRate: number;
  }>;
  paymentOptions: null | Array<{
    days: number;
    interestRate: number;
  }>;
  cobrand: {
    name: null | string;
  };
  cardLevel: {
    name: null | string;
  };
  excludedBinsRanges: null | number;
}
