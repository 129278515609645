import ActiveOrInactiveCell from "../../components/ActiveOrInactiveCell";
import DefaultCell from "../../components/DefaultCell";
import LinkCell from "../../components/LinkCell";
import TagCell from "../../components/TagCell";

export const columnsTable = [
  {
    Header: "id",
    accessor: "id",
    Cell: ({ value }: any) => <LinkCell href={`/uniftec/scholarShips/${value}`} value={value} />,
  },
  {
    Header: "Título",
    accessor: "title",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Categorias",
    accessor: "categories",
    Cell: ({ value }: any) => {
      if (Array.isArray(value)) {
        const categoryIds = value.map((category: any) => category?.name).join(", ");
        return <DefaultCell value={categoryIds} />;
      }
      return <DefaultCell value="-" />;
    },
  },
  {
    Header: "Modalidades",
    accessor: "modalities",
    Cell: ({ value }: any) => {
      if (Array.isArray(value)) {
        const categoryIds = value.map((category: any) => category?.name).join(", ");
        return <DefaultCell value={categoryIds} />;
      }
      return <DefaultCell value="-" />;
    },
  },
  {
    Header: "Data de ínicio",
    accessor: "startDate",
    Cell: ({ value }: any) => <DefaultCell value={value ?? "-"} />,
  },
  {
    Header: "Data de expiração",
    accessor: "endDate",
    Cell: ({ value }: any) => <DefaultCell value={value ?? "-"} />,
  },
  {
    Header: "Ativo",
    accessor: "active",
    Cell: ({ value, row: { values, ...rest } }: any) => {
      const isPublished: boolean = !values?.startDate
        ? true
        : Date.parse(values?.startDate) <= Date.now();
      const isExpired: boolean = !values?.endDate
        ? false
        : Date.parse(values?.endDate) <= Date.now();

      const status = !value
        ? "Inativo"
        : isPublished && !isExpired
        ? "Ativo"
        : `Ativo - ${isExpired ? "Expirado" : "Não Publicado"}`;
      const color = !value ? "error" : isPublished && !isExpired ? "success" : "warning";

      return (
        <div onClick={() => console.log({ values, rest })}>
          <TagCell value={status} color={color} />
        </div>
      );
    },
  },
];

// Cell: (pro: { value: string | number | boolean; row: { values: any } }) => JSX.element
// value is the value of acessor in this row
// row.values is all row value
