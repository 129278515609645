import MDButton from "components/MDButton";
import ModalCustom from "../ModalCustom";
import { ModalTableSelect } from "../JsonForm/renderers/SelectMultiple/types";
import { Subjects } from "layouts/ecommerce/Subjects";

const btnStyle = {
  border: "1px solid #7b809a",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

const btnStyleRemove = {
  ...btnStyle,
  border: "1px solid #F44335",
  color: "#F44335",
};

export default function ModalSubjectsSelect({ ids, handleOpen, onChange, open }: ModalTableSelect) {
  return (
    <ModalCustom
      onClose={handleOpen}
      open={open}
      sx={{
        "& .head": {
          justifyContent: "center",
          width: "100%",
        },
        "& .css-aim41u": {
          marginLeft: "0 !important",
        },
      }}
    >
      <Subjects.List
        path=""
        title=""
        action={{
          acessor: "id",
          column: {
            Header: "Ação",
            accessor: "id",
            Cell: ({ value, cell }: any) => (
              <MDButton
                sx={ids.includes(Number(value)) ? btnStyleRemove : btnStyle}
                onClick={() => onChange(cell.row.original)}
              >
                {ids.includes(Number(value)) ? "Remover" : "Adicionar"}
              </MDButton>
            ),
          },
        }}
      />
    </ModalCustom>
  );
}
