import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { Sites } from "data/utils/globals";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { SchemaData } from "./hooks/types";
import useGetItem from "./hooks/useGetItem";
import useUpdate from "./hooks/useUpdate";
import useDelete from "./hooks/useDelete";
import { updatePaymentMethod } from "./queries/updatePaymentMethod";

function Update() {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>();
  const id = useParams()?.id;

  if (!id) return null;

  return (
    <GenericMethods.Update<SchemaData, updatePaymentMethod>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath="/ecommerce/metodos-de-pagamentos"
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      useDelete={useDelete}
      queryParam={id}
      state={state}
      setState={setState}
    />
  );
}

export default Update;
