import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const Holidays = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    list: "list:Holiday",
    create: "create:Holiday",
    read: "read:Holiday",
    update: "update:Holiday",
    delete: "delete:Holiday",
  },
};
