// import Create from "../courses/CreateCourseFinancial";
import { path } from "./hooks/types";
import List from "./List";
import Update from "./Update";

export const CoursesFinancial = {
  List: List,
  path: path,
  Update: Update,
  // Create: Create,
  Permissions: {
    create: "upsert:Course",
    upsert: "upsert:Course",
    update: "update:Course",
  },
};
