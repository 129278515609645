import MuiStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import { StepperProps } from "./types";

export default function Stepper(stepper: StepperProps) {
  return (
    <MuiStepper nonLinear activeStep={stepper.activeStep}>
      {stepper.steps.map(({ label, onClick }, index) => {
        return (
          <Step
            key={label.concat(String(index))}
            completed={!stepper?.completed ? index < stepper.activeStep : stepper?.completed(index)}
          >
            {!onClick ? (
              <StepLabel sx={{ marginTop: 0 }}>{label}</StepLabel>
            ) : (
              <StepButton color="inherit" onClick={() => onClick(index)}>
                {label}
              </StepButton>
            )}
          </Step>
        );
      })}
    </MuiStepper>
  );
}
