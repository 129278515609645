import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { Handler } from "../types";

type FacultyMembersReponse = {
  FacultyMembers: {
    items: Array<{
      id: number;
      name: string;
      active: boolean;
    }>;
  };
};
type FacultyMemberReponse = {
  FacultyMember: {
    id: number;
    name: string;
    active: boolean;
  };
};
const FacultyMemberHandler: Handler<FacultyMembersReponse, FacultyMemberReponse> = {
  query: print(gql`
    query FacultyMembers($search: String) {
      FacultyMembers(search: $search, active: null) {
        items {
          id
          name
          active
        }
      }
    }
  `),
  formater: (data) =>
    data.FacultyMembers.items.map((item) => ({
      id: item.id,
      label: item.name,
      active: item.active,
    })),
  api: ApiCourseRequest,
  queryId: print(gql`
    query FacultyMember($id: Int!) {
      FacultyMember(id: $id) {
        id
        name
        active
      }
    }
  `),
  formaterId: (data) => ({
    id: data.FacultyMember.id,
    label: data.FacultyMember.name,
    active: data.FacultyMember.active,
  }),
};

export default FacultyMemberHandler;
