import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query GetOne($id: Int!) {
    AdmissionWay(id: $id) {
      id
      name
      content
      templateInvestmentName
    }
  }
`);

export const responseGetItemKey = "AdmissionWay";
export interface GetItemGql {
  AdmissionWay: {
    id: number;
    name: string;
    content?: string;
    templateInvestmentName: string;
  };
}
