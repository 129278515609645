import { useState, useEffect } from "react";

// Layout
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { JsonFormsComponent } from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { ApiCMSRequest } from "data/ApiCMS";
import { addDetailsToSchema, addDetailsToUiSchema } from "./utils/formatWithDetails";
import { DataFormType, QueryComponentResponse, QueryTemplateSchemaResponse } from "./types";
import type { userPermissionsProps } from "hooks/types";
import { userPermissions } from "hooks/Permissions";

function FormPage<ContentJsonType = any>({
  pageName,
  actionsPermission,
}: {
  pageName: string;
  actionsPermission: userPermissionsProps;
}): JSX.Element {
  const permission = userPermissions(actionsPermission);
  const [template, setTemplate] = useState<QueryTemplateSchemaResponse>();
  const [state, setState] = useState<JsonFormsStateProps<DataFormType & ContentJsonType>>();

  useEffect(() => {
    if (!!pageName) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCMSRequest<{ Page: QueryComponentResponse }>({
        query: `
      query Page($slug: String!) {
        Page(slug: $slug) {
          id
          name
          status
          publishOn
          expiresIn
          author
          contentJSON
          templateSchema {
            id
            name
            schemaJson
            UISchema
          }
          slug
          title
          description
          keywords
          addSiteMap
          createdAt
          updatedAt
        }
      }
      `,
        variables: { slug: pageName },
      })
        .then((response) => {
          if (!response?.data?.data?.Page) {
            throw new Error("Page not found", {
              cause: { response: response?.data || response },
            });
          }
          const { templateSchema, contentJSON, ...props } = response?.data?.data?.Page;

          setTemplate({
            ...templateSchema,
            schemaJson: addDetailsToSchema(templateSchema.schemaJson),
            UISchema: addDetailsToUiSchema(templateSchema.UISchema),
          });

          const convertPropsNull = Object.fromEntries(
            Object.entries(props).map(([key, value]) => [key, value === null ? undefined : value])
          );

          setState(({ data, ...prev }) => ({
            ...prev,
            data: { ...JSON.parse(contentJSON), details: convertPropsNull },
          }));
        })
        .catch((error) => {
          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [pageName]);

  const handleSave = async () => {
    const {
      details: { status, title, description, keywords, addSiteMap },
      ...content
    } = state?.data;

    const response = await ApiCMSRequest({
      query: `
      mutation Mutation($data: UpdatePageInput!) {
        updatePage(data: $data) {
          id
          name
          status
          publishOn
          expiresIn
          author
          contentJSON
          templateSchema {
            id
            name
            schemaJson
            UISchema
          }
          slug
          title
          description
          keywords
          addSiteMap
          createdAt
          updatedAt
        }
      }
      `,
      variables: {
        data: {
          contentJSON: JSON.stringify(content),
          slug: pageName,
          status,
          title,
          description,
          keywords,
          addSiteMap,
        },
      },
    });
    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data) {
      throw new Error("Ops. Sem resposta!");
    }
    return response;
  };

  if (!pageName) return null;

  return (
    <JsonFormsComponent
      template={template}
      state={state}
      onChange={setState}
      onSave={handleSave}
      permissions={permission}
    />
  );
}

export default FormPage;
