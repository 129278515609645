import { ReactNode } from "react";
import MDBox, { MDBoxProps } from "components/MDBox";
import MDTypography from "components/MDTypography";

function Container({
  title,
  children,
  sx,
  ...rest
}: { title?: string; children: ReactNode } & MDBoxProps) {
  return (
    <MDBox
      sx={{
        border: "0 solid rgba(0, 0, 0, 0.125)",
        borderRadius: "6px",
        boxShadow:
          "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
        background: "white",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
      className="container"
      {...rest}
    >
      {title && (
        <MDTypography variant="button" fontWeight="medium" color="text" textTransform="capitalize">
          {title}
        </MDTypography>
      )}
      {children}
    </MDBox>
  );
}

export default Container;
