import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { Colligate } from "../types";

export const UPDATE_QUERY = print(gql`
  mutation UpdateTeachingAreaConversion($data: UpdateTeachingAreaConversionInput!) {
    updateTeachingAreaConversion(data: $data) {
      id
      colligate {
        id
        databaseName
        description
        active
        partnerVisibleName
        createdAt
        updatedAt
      }
      idColligate
      databaseName
      idArea
      teachingAreaName
      createdAt
      updatedAt
    }
  }
`);

export interface UpdateGql {
  updateTeachingAreaConversion: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;
    idModality: string;
    modalityName: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
