import { path } from "./hooks/types";
import List from "./List";
import Update from "./Update";
import Create from "./Create";

export const TemplateInvestments = {
  List: List,
  Update: Update,
  Create: Create,
  path: path,
  Permissions: {
    read: "read:TemplateInvestment",
    edit: "update:TemplateInvestment",
    delete: "delete:TemplateInvestment",
    create: "create:TemplateInvestment",
    list: "list:TemplateInvestment",
  },
};
