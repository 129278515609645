import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreatePartnerCompany($data: CreatePartnerCompanyInput!) {
    createPartnerCompany(data: $data) {
      id
      name
      active
    }
  }
`);

export interface CreateGql {
  createPartnerCompany: {
    id: number;
    name: string;
    active: boolean;
  };
}
