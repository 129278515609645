import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { MDSnackbarProps } from "components/MDSnackbar";
import { ShemaJsonTemplate } from "types/SchemaJson";
import { Colligate } from "../types";

export interface UseGetItemProps<T> {
  setTemplate: React.Dispatch<React.SetStateAction<ShemaJsonTemplate>>;
  setState: React.Dispatch<React.SetStateAction<JsonFormsStateProps<T>>>;
  queryParam: number | string;
  siteId: number;
  sitePath: string;
}

export interface UseDeleteProps {
  queryParam: number | string;
  setSnackBarInfo: React.Dispatch<React.SetStateAction<Omit<MDSnackbarProps, "close" | "onClose">>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UseUpdateProps {
  queryParam: number | string;
}

export const path = "/config/habilitacoes";

export interface useCreateProps {
  siteId: number;
  sitePath: string;
}

export interface SchemaDataSync {
  databaseName?: string;
  idColligate?: number;
  idCourse?: string;
  noSendToAPI?: boolean;
}
export interface SchemaData {
  id: number;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;

  providerCourse: {
    id: number;
    createdAt: Date;
    updatedAt: Date;

    databaseName: string;
    idColligate: number;

    name: string;
    idCourse: string;
    idSchool?: number;
    idArea?: number;
    inPersonOrDistance?: string;
    idModality?: string;
    recModifiedOn?: Date;
    descriptionShort?: string;
    teachingAreaConversionId?: number;
    modalityConversionId?: number;

    teachingArea?: {
      id: number;
      colligate: Colligate;
      databaseName: string;
      idColligate: number;

      idArea: number;
      teachingAreaName: string;

      createdAt: Date;
      updatedAt: Date;
    };
  };
  modality?: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;

    idModality: string;
    modalityName: string;

    createdAt: Date;
    updatedAt: Date;
  };

  colligate: Colligate;
  databaseName: string;
  idColligate: number;
  idCourse: string;

  idAppliedMatrice: number;
  idHabilitation: string;
  idGrade: string;
  idShift: number;
  idBranch: number;
  activeOnProvider: string;
  idCourseType: number;
  name: string;
  courseLoadHours?: number;
  totalCredits?: number;
  maxCredPeriod?: number;
  minCredPeriod?: number;
  recModifiedOn_CM?: Date;
  excludesCreditCardPayment?: string;
  decree?: string;
  description?: string;
  idHabilitationType?: string;
  recModifiedOn?: Date;
  imageURL?: string;

  categoryConversionId?: number;
  educationalUnitConversionId?: number;

  category?: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;

    idCourseType: number;
    categorySlug: string;

    createdAt: Date;
    updatedAt: Date;
  };
  educationalUnit?: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;

    idBranch: number;
    educationalUnitVisibleName: string;

    createdAt: Date;
    updatedAt: Date;
  };
}
