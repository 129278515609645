import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Coupons($couponCode: String!, $archive: Boolean) {
    Coupon(couponCode: $couponCode, archive: $archive) {
      utmSource
      utmCampaign
      couponCode
      isArchived
      maxItemsPerClient
      expirationIntervalPerUse
      maxUsage
    }
  }
`);

export interface GetItemGql {
  Coupon: {
    utmSource: string;
    utmCampaign: string;
    couponCode: string;
    isArchived: boolean;
    maxItemsPerClient: number;
    expirationIntervalPerUse: string;
    maxUsage: number;
  };
}
