import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Page($slug: String!) {
    Page(slug: $slug) {
      id
      name
      status
      publishOn
      expiresIn
      author
      contentJSON
      dynamicPage
      templateSchema {
        id
        name
        schemaJson
        UISchema
      }
      slug
      title
      description
      keywords
      addSiteMap
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  Page: {
    id: number;
    name: string;
    status: boolean;
    publishOn: string;
    expiresIn: string;
    author: number;
    contentJSON: string;
    dynamicPage: boolean;
    templateSchema: {
      id: number;
      name: string;
      schemaJson: string;
      UISchema: string;
    };
    slug: string;
    title: string;
    description: string;
    keywords: string;
    addSiteMap: boolean;
    createdAt: string;
    updatedAt: string;
  };
}
