import { ApiCMSRequest } from "data/ApiCMS";
import { UseUpdateProps } from "./types";
import { useCallback } from "react";
import {
  MUTATION_UPDATE_CATEGORY_FAQ,
  CategoryFaqMutationUpdateGql,
} from "data/queries/ApiCMS/CategoriesFaq/MutationUpdate";
import { CategoryFaqGql } from "data/queries/ApiCMS/CategoriesFaq/QueryCategoryFaq";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<CategoryFaqGql["CategoryFaq"]>) => {
      const { slug, name, sort } = state?.data;

      const response = await ApiCMSRequest<CategoryFaqMutationUpdateGql>({
        query: MUTATION_UPDATE_CATEGORY_FAQ,
        variables: {
          data: {
            id: queryParam,
            slug,
            name,
            sort,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
