import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query Query($perPage: Int, $hasECommerceId: Boolean) {
    Categories(perPage: $perPage, hasECommerceId: $hasECommerceId, active: null) {
      items {
        id
        slug
        active
      }
    }
    PartnersBrands(perPage: $perPage, hasECommerceId: $hasECommerceId, active: null) {
      items {
        id
        visibleName
        active
      }
    }
    InvestmentInfos(perPage: $perPage) {
      items {
        id
        subtitle
        title
      }
    }
  }
`);

/** Type response data */
export interface GetAllGql {
  Categories: {
    items: {
      id: number;
      slug: string;
      active: boolean;
    }[];
  };
  PartnersBrands: {
    items: {
      id: number;
      visibleName: string;
      active: boolean;
    }[];
  };
  InvestmentInfos: {
    items: {
      id: number;
      subtitle: string;
      title: string;
    }[];
  };
}
