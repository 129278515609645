// @mui material components
import Card from "@mui/material/Card";

// Matoerial Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SearchInputControled from "components/CRPComponents/Forms/components/SearchInputControled";
import DataTableControled from "examples/Tables/DataTableControled";
import GenericTableProps from "./types";
import useDataTable from "./useDataTable";
import FilterMenu from "./FilterMenu";
import { ApiCMSWithSetData } from "data/ApiCMS";
import { useMemo } from "react";
import { DowloadCSV } from "components/CRPComponents/DowloadCSV";
function TableSubject<T>(props: GenericTableProps<T>): JSX.Element {
  const {
    handleChangePage,
    handleChangeFilter,
    error,
    loading,
    data,
    handleSearch,
    setSearchValue,
    searchValue,
    variables,
  } = useDataTable({
    ...props,
    ApiSetData: props.ApiSetData ?? ApiCMSWithSetData,
  });

  useMemo(() => {
    if (props?.hnadleResponse) props?.hnadleResponse(data as T);
  }, [data]);

  if ((data && error) || error) {
    return <p>Error : {error.message}</p>;
  }

  if (!data) return null;

  return (
    <MDBox my={3}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={2}
        className="head"
      >
        {!props.title ? (
          props?.buttonCreate ?? <div />
        ) : (
          <MDButton variant="gradient" color="info" href="/ecommerce/materias/new">
            {props.title}
          </MDButton>
        )}
        {props.dowloadSvgJson ? (
          <>
            <DowloadCSV allFildsSvgh={props.allFildsSvgh} dowloadSvgJson={props.dowloadSvgJson} />
          </>
        ) : null}
        <MDBox display="flex" gap={3} alignItems="baseline">
          {props?.hiddenSearch ? null : (
            <SearchInputControled
              onSubmitSearch={handleSearch}
              value={searchValue}
              onChangeValue={setSearchValue}
              disabled={loading}
            />
          )}
          {props.useDefaultFilter !== false && (
            <FilterMenu
              useStatusString={props?.useStatusString}
              variables={variables}
              onChange={handleChangeFilter}
              buttonProps={{ loading, disabled: loading }}
            />
          )}
          {props.childrenFilters}
        </MDBox>
      </MDBox>
      <Card>
        <DataTableControled
          table={{
            columns: props.columnsTable.map((item) =>
              props?.action?.acessor === item.accessor ? props?.action?.column : item
            ),
            rows: props.formatRows(data as T),
          }}
          pagination={props.formatPagination(data as T)}
          onChangePage={handleChangePage}
          loading={loading}
        />
      </Card>
    </MDBox>
  );
}

export default TableSubject;
