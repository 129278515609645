import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation Create($data: CreatePlanAdmissionWayInput!) {
    createPlanAdmissionWay(data: $data) {
      id
    }
  }
`);

export const responseCreateKey = "createPlanAdmissionWay";
export interface CreateGql {
  createPlanAdmissionWay: {
    id: number;
  };
}
