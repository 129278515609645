import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Colligates($page: Int, $perPage: Int, $active: Boolean) {
    Colligates(page: $page, perPage: $perPage, active: $active) {
      items {
        id
        databaseName
        description
        active
        partnerVisibleName
        createdAt
        updatedAt
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

/** Type each item in response data*/
export interface GetAllItem {
  id: number;
  databaseName: string;
  description?: string;
  partnerVisibleName: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const responseGetAllKey = "Colligates";

/** Type response data */
export interface GetAllGql {
  Colligates: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
