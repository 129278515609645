import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const MUTATION_UPDATE_CATEGORY_FAQ = print(gql`
  mutation CategoryFaqs($data: UpdateCategoryFaqInput!) {
    updateCategoryFaq(data: $data) {
      id
      name
      slug
      sort
    }
  }
`);

export interface CategoryFaqMutationUpdateGql {
  updateCategoryFaq: {
    id: number;
    name: string;
    slug: string;
    sort: number;
  };
}
