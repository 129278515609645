import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import {
  CategoryFaqsGql,
  GET_CATEGORIES_FAQS_QUERY,
} from "data/queries/ApiCMS/CategoriesFaq/QueryCategoriesFaqs";

export const columnsTable = [
  {
    Header: "Nome",
    accessor: "name",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Slug",
    accessor: "slug",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
];

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<CategoryFaqsGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
        }}
        path={`${props.path}/faq-categorias`}
        query={GET_CATEGORIES_FAQS_QUERY}
        formatRows={(data) => data.CategoryFaqs.items}
        formatPagination={(data) => data.CategoryFaqs.paginationInfo}
        title="NOVO CATEGORIA"
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/faq-categorias/${value}`} value={value} />
            ),
          },
          ...columnsTable,
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
