import { useEffect, useState } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import UISchemaEdit from "../schema/UISchemaEdit.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { removeNullValues } from "utils/removeNullValues";
import useTemplate from "./useTemplate";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const [responseData, setResponseData] = useState<SchemaData>();
  const { templateSchema } = useTemplate();

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCourseRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.Category) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = response.data.data?.Category;

          setResponseData(
            removeNullValues({
              name: data.name,
              description: data.description,
              keywords: data.keywords,
              slug: data.slug,
              showPartinerFilter: data.showPartinerFilter,
              active: data.active,
              urlContract: data.urlContract,
              parentId: data.parentId,
              templateSchemaName: data.templateSchemaName,
              contentJson: data.contentJson,
              isSubCategory: !!data?.parentId,
            })
          );
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (templateSchema && responseData) {
      setTemplate({
        schemaJson: templateSchema,
        UISchema: UISchemaEdit,
      });

      setState({
        data: responseData,
      });
    }
  }, [templateSchema, responseData]);
};

export default useGetItem;
