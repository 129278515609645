import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const DELETE_QUERY = print(gql`
  mutation DeleteColligate($id: Int!, $databaseName: String!) {
    deleteColligate(id: $id, databaseName: $databaseName) {
      id
      databaseName
    }
  }
`);

export const responseDeleteKey = "deleteColligate";
export interface DeleteGql {
  deleteColligate: {
    id: number;
    databaseName: string;
  };
}
