import useGetItem from "./hooks/useGetItem";
import { useParams } from "react-router-dom";
import useUpdate from "./hooks/useUpdate";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { UpdateGql } from "./queries/update";
import ModalCustom from "components/CRPComponents/ModalCustom";
import JsonFormsComponent from "components/CRPComponents/JsonForm/JsonFormsComponent";
import { Fragment, useCallback, useEffect, useState } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import ContentUISchema from "./schema/ContentUISchema.json";
import ContentSchemaJson from "./schema/ContentSchemaJson.json";
import { Box, SxProps } from "@mui/material";
import MDButton from "components/MDButton";

const editButtonSx: SxProps = {
  color: "white !important",
  borderColor: "none !important",
  background: "#1A73E8 !important",
  transition: "all 0.3s ease-in",
  "&:disabled": {
    color: "#344767 !important",
    border: "1px solid #344767 !important",
    background: "white !important",
  },
};

function Update() {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>();
  const [infoState, setInfoState] = useState<JsonFormsStateProps<any>>({});
  const id = Number(useParams()?.id);
  const [open, setModal] = useState(false);

  useEffect(() => {
    try {
      const data = JSON.parse(state?.data?.contentJson || "");
      setInfoState((prev) => ({ ...prev, data }));
    } catch (error) {
      setInfoState({});
    }
  }, [state?.data?.contentJson, open]);

  const handleModal = useCallback(() => setModal((old) => !old), []);

  const handlecontentJson = (data?: string) => {
    setState((prev) => {
      if (prev && infoState?.data) {
        return {
          ...prev,
          data: {
            ...prev.data,
            contentJson: data,
          },
        };
      }
      return prev;
    });
  };

  const saveInfo = async () => {
    handlecontentJson(JSON.stringify(infoState.data));

    handleModal();
  };

  if (!id) return null;

  return (
    <Fragment>
      <GenericMethods.Update<SchemaData, UpdateGql>
        siteId={Sites.UNIFTEC.id}
        sitePath={Sites.UNIFTEC.path}
        redirectPath="/ecommerce/nivel-de-ensino"
        useGetItem={useGetItem}
        useUpdate={useUpdate}
        queryParam={id}
        hideDeleteButton
        state={state}
        setState={setState}
      >
        <MDButton sx={editButtonSx} onClick={handleModal}>
          Editar Conteúdo
        </MDButton>
      </GenericMethods.Update>
      <ModalCustom open={open} onClose={handleModal}>
        <Box margin={3}>
          <JsonFormsComponent
            template={{
              schemaJson: ContentSchemaJson,
              UISchema: ContentUISchema,
            }}
            state={infoState}
            onChange={setInfoState}
            onSave={saveInfo}
          />
        </Box>
      </ModalCustom>
    </Fragment>
  );
}

export default Update;
