import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useEffect, useMemo, useState } from "react";
import { ShemaJsonTemplate } from "types/SchemaJson";
import MDSnackbar, { MDSnackbarProps } from "components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import JsonFormsComponent from "components/CRPComponents/JsonForm/JsonFormsComponent";
import DeleteButton from "components/CRPComponents/Forms/components/DeleteButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useAuth } from "context/useUserAuth";
import { GenerictMethodsUpdateProps, UseDeleteHookConditional } from "./types";
import StepperComponent from "./Stepper";

function Update<IJsonFormState, UpdateGql>({
  useDelete = () => ({
    handleDelete: () => {},
    handleDeleteError: (e: Error) => {},
    handleDeleteSuccess: () => {},
  }),
  useGetItem,
  useUpdate,
  redirectPath,
  queryParam,
  siteId,
  sitePath,
  hideDeleteButton = false,
  updatePermission,
  deletePermission,
  state: controlledState,
  setState: controlledSetState,
  children,
  deleteButtonLabel,
  stepper,
  afterForm,
  hideAfterButtons,
}: GenerictMethodsUpdateProps<IJsonFormState, UpdateGql> & UseDeleteHookConditional) {
  const { user } = useAuth();
  const [template, setTemplate] = useState<ShemaJsonTemplate>();
  const [state, setState] = useState<JsonFormsStateProps<IJsonFormState>>();
  const navigate = useNavigate();
  const [editing, setEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [deleteDisabled, setDeleteDisabled] = useState(true);

  useGetItem({
    setTemplate,
    setState: controlledSetState ?? setState,
    queryParam,
    siteId,
    sitePath,
  });
  const { handleSave } = useUpdate({
    queryParam,
    siteId,
    sitePath,
    setState: controlledSetState ?? setState,
  });

  useMemo(() => {
    if (snackBarInfo?.redirectid === -1) navigate(redirectPath);
  }, [snackBarInfo?.redirectid]);

  const handleCloseSnackBar = () => {
    setSnackBarInfo(({ redirectid, props }) => {
      if (redirectid) setEditing(false);
      if (redirectid === -1) navigate(redirectPath);
      return { ...props, open: false, autoHideDuration: undefined };
    });
    setLoading(false);
  };

  const { handleDelete, handleDeleteError, handleDeleteSuccess } = useDelete({
    queryParam,
    setSnackBarInfo,
    setEditing,
    siteId,
    sitePath,
  });

  useEffect(() => {
    const permissions = user?.permissions.map(({ name }) => name) ?? [];

    if (!updatePermission || permissions.includes(updatePermission)) {
      setUpdateDisabled(false);
    }

    if (!deletePermission || permissions.includes(deletePermission)) {
      setDeleteDisabled(false);
    }
  }, [user?.permissions, updatePermission, deletePermission]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!!stepper && <StepperComponent {...stepper} />}
      <JsonFormsComponent
        template={template}
        state={controlledState ?? state}
        onChange={controlledSetState ?? setState}
        onSave={() => handleSave(controlledState ?? state)}
        disabled={updateDisabled}
        afterForm={afterForm}
        hideAfterButtons={hideAfterButtons}
      >
        <>
          {children}
          {!deleteDisabled && !hideDeleteButton && (
            <DeleteButton
              propDelete={queryParam}
              handleDelete={handleDelete}
              onDeleteError={handleDeleteError}
              onDeleteSuccess={handleDeleteSuccess}
              disabled={!!snackBarInfo?.redirectid}
              loading={loading}
              label={deleteButtonLabel}
            />
          )}

          <MDSnackbar
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...snackBarInfo}
            title={snackBarInfo.title}
            close={handleCloseSnackBar}
            onClose={handleCloseSnackBar}
          />
        </>
      </JsonFormsComponent>
      <Footer />
    </DashboardLayout>
  );
}

export default Update;
