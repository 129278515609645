import { useEffect, useState } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import useEducationalGroups from "./useEducationalGroups";
import { ApiCourseRequest } from "data/queries/ApiCourse";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const { educationalGroups } = useEducationalGroups();
  const [responseData, setResponseData] = useState<GetItemGql["EducationalUnit"]>();

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCourseRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.EducationalUnit) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          setResponseData(response.data.data?.EducationalUnit);
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (!educationalGroups || !responseData) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.educationalGroupId = {
      ...schemaJsonParsed.properties.educationalGroupId,
      oneOf: educationalGroups.map(({ id, title }) => ({
        const: id,
        title,
      })),
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });

    setState({
      data: removeNullValues(responseData),
    });
  }, [educationalGroups, responseData]);
};

export default useGetItem;
