import useGetItem from "./hooks/useGetItem";
import useUpdate from "./hooks/useUpdate";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { UpdateGql } from "./queries/update";
import { useAuth } from "context/useUserAuth";

function Update() {
  const { tokenUser } = useAuth();

  if (!tokenUser) return null;

  return (
    <GenericMethods.Update<SchemaData, UpdateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath="/perfil/visao-geral"
      hideDeleteButton
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={tokenUser}
    />
  );
}

export default Update;
