import {
  JsonFormsStateProps,
  MessageProps,
} from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback, useState } from "react";
import { path, SchemaDataSync, useCreateProps } from "./types";
import { useNavigate } from "react-router-dom";
import { ApiGetCouseERPRequest } from "data/apiGetCourseERP";
import { CREATE_QUERY, CreateGql } from "../queries/create";

export default function useCreate(props: useCreateProps) {
  const navigate = useNavigate();
  const [messageSuccess, setMessageSuccess] = useState<MessageProps>();

  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaDataSync>) => {
    setMessageSuccess(null);
    const { databaseName, idColligate, idCourse, noSendToAPI } = state?.data;
    console.log({ databaseName, idColligate, idCourse, noSendToAPI });
    const response = await ApiGetCouseERPRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data: {
          databaseName: !databaseName ? null : databaseName,
          idColligate: !idColligate ? null : idColligate,
          idCourse: !idCourse ? null : idCourse,
          noSendToAPI: !noSendToAPI ? null : noSendToAPI,
        },
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data || !response?.data?.data?.syncTotvsCourses) {
      throw new Error("Ops. Sem resposta!");
    }

    if (
      response?.data?.data?.syncTotvsCourses?.updatesHabilitations &&
      response?.data?.data?.syncTotvsCourses?.updatesCourses
    ) {
      setMessageSuccess({
        title: `Provider Cursos Modificados: ${response?.data?.data?.syncTotvsCourses?.updatesCourses} \n Provider Habilitações Modificados: ${response?.data?.data?.syncTotvsCourses?.updatesHabilitations}`,
      });
    }

    alert(
      `Provider Cursos Modificados: ${response?.data?.data?.syncTotvsCourses?.updatesCourses} \n Provider Habilitações Modificados: ${response?.data?.data?.syncTotvsCourses?.updatesHabilitations}`
    );
    navigate(`${path.startsWith("/") ? "" : "/"}${path}`);

    return response;
  }, []);

  return {
    handleSave,
  };
}
