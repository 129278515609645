export const QUERY_FAQ = `query Query($faqId: Int!) {
  Faq(id: $faqId) {
    id
    question
    answer
    sort
    student
    site {
      name
      id
    }
    status
    createdAt
    updatedAt
    categoryFaq {
      id
      name
      level
      slug
    }
  }
  CategoryFaqs {
    items {
      updatedAt
      sort
      slug
      name
      level
      id
      createdAt
    }
  }
}`;

export const QUERY_PROPS_FAQ = `query Faqs {
  CategoryFaqs {
    items {
      updatedAt
      sort
      slug
      name
      level
      id
      createdAt
    }
  }
}`;

export type ResponseFaq = {
  id: number;
  question: string;
  answer: string;
  sort?: number;
  linkRegulation?: string;
  student?: boolean;
  site: {
    id: number;
    name: string;
  };
  status?: string;
  categoryFaq?: {
    id: number;
    name: string;
    slug: string;
    parentId?: number;
    level?: number;
  }[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type ResponseTemplateSchema = {
  schemaJson: string;
  UISchema: string;
};

export type ResponseCategoryFaqs = {
  items?: {
    id: number;
    name: string;
    updatedAt?: Date;
    sort?: number;
    slug: string;
    level?: number;
  }[];
};
