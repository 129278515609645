import useGetItem from "./hooks/useGetItem";
import { useNavigate, useParams } from "react-router-dom";
import useUpdate from "./hooks/useUpdate";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateOrUpdateGql } from "./queries/createOrUpdate";
import { useState } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import MDButton from "components/MDButton";
import { ApiFinancialRequest } from "data/queries/ApiFinancial";
import { print } from "graphql";
import { gql } from "@apollo/client";

function Update() {
  const [state, setState] = useState<JsonFormsStateProps<SchemaData>>();
  const navigate = useNavigate();
  const id = useParams()?.id;

  const handleArchive = () => {
    ApiFinancialRequest({
      query: print(gql`
        mutation ArchivePromotion($id: String!, $archive: Boolean!) {
          archivePromotion(id: $id, archive: $archive)
        }
      `),
      variables: {
        id,
        archive: !state?.data?.isArchived,
      },
    }).then(() => navigate("/ecommerce/promocoes"));
  };

  if (!id) return null;

  return (
    <GenericMethods.Update<SchemaData, CreateOrUpdateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath="/ecommerce/promocoes"
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={id}
      hideDeleteButton
      state={state}
      setState={setState}
    >
      <MDButton
        onClick={handleArchive}
        sx={{
          border: `1px solid ${state?.data?.isArchived ? "green" : "red"}`,
          color: `${state?.data?.isArchived ? "green" : "red"} !important`,
        }}
      >
        {state?.data?.isArchived ? "Desarquivar" : "Arquivar"}
      </MDButton>
    </GenericMethods.Update>
  );
}

export default Update;
