import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReadCSVFacultyMemberComponent from "./ReadCSVFacultyMemberComponent";

function ReadCSVFacultyMember() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ReadCSVFacultyMemberComponent />
      <Footer />
    </DashboardLayout>
  );
}

export default ReadCSVFacultyMember;
