import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query Pages(
    $page: Int
    $perPage: Int
    $search: String
    $status: StatusEnum
    $dynamicPage: Boolean
  ) {
    Pages(
      page: $page
      perPage: $perPage
      search: $search
      status: $status
      dynamicPage: $dynamicPage
    ) {
      items {
        id
        name
        status
        publishOn
        expiresIn
        author
        slug
        title
        description
        keywords
        addSiteMap
        createdAt
        updatedAt
        dynamicPage
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  name: string;
  status: string;
  publishOn: string;
  expiresIn: string;
  author: number;
  slug: string;
  title: string;
  description: string;
  keywords: string;
  addSiteMap: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface GetAllGql {
  Pages: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
