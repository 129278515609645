import { gql } from "@apollo/client";
import { print } from "graphql";

export const QUERY_GET_SESSIONS = print(gql`
  query Query($typeSession: TypeSessionEnum) {
    sessions(typeSession: $typeSession)
  }
`);

export type ResponseFindmanySessions = {
  sessions: number;
};
