export const QUERY_BANNER = `query Banner($bannerId: Int!) {
  banner(id: $bannerId) {
    id
    name
    title
    description
    url
    targetBlank
    active
    sort
    site {
      id
      name
    }
    mediaFileDesktop {
      id
      path
    }
    mediaFileMobile {
      id
      path
    }
    locale
  }
}`;

export type ResponseBanner = {
  banner: {
    id: number;
    name: string;
    title: string;
    description: string;
    url: string;
    targetBlank: boolean;
    active: boolean;
    sort: number;
    site: {
      id: number;
      name: string;
    };
    mediaFileDesktop: {
      id: number;
      path: string;
    };
    mediaFileMobile: {
      id: number;
      path: string;
    };
    locale: string | null;
  };
};
