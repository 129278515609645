import { withJsonFormsControlProps } from "@jsonforms/react";
import SelectMultiple from "../SelectMultiple";
import { CustomRendererProps } from "../types";
import { print } from "graphql";
import { gql } from "@apollo/client";
import ModalBlogTagsSelect from "components/CRPComponents/ModalBlogTagsSelect";
import { ApiCMSRequest } from "data/ApiCMS";

const query = print(gql`
  query Tag($id: Int!) {
    Tag(id: $id) {
      name
      id
    }
  }
`);

interface ItemGql {
  Tag: {
    name: string;
    id: number;
  };
}

const BlogTagsSelectMultiple = (props: CustomRendererProps<number[] | undefined>) => {
  return (
    <SelectMultiple
      {...props}
      Modal={ModalBlogTagsSelect}
      formatItem={({ data }) => data.data.Tag}
      requestItem={(id: number) => ApiCMSRequest<ItemGql>({ query, variables: { id } })}
    />
  );
};

export default withJsonFormsControlProps(BlogTagsSelectMultiple, true);
