import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateCalendar($data: UpdateCalendarInput!) {
    updateCalendar(data: $data) {
      id
    }
  }
`);

export interface UpdateGql {
  UpdateCalendar: {
    id: number;
  };
}
