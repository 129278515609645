import { useEffect, useState } from "react";
import { removeNullValues } from "utils/removeNullValues";
import { GET_ITEM_QUERY, GetItemGql, responseGetItemKey } from "../queries/getItem";
import { ApiRequest, SchemaData, UseGetItemProps } from "./types";
import useTemplate from "./useTemplate";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const [responseData, setResponseData] = useState<SchemaData>();
  const { template } = useTemplate();
  useEffect(() => {
    const [databaseName, id] = String(queryParam).split(/[\/]|%2F/g);
    if (!!databaseName && !!id) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: Number(id), databaseName },
      })
        .then((response) => {
          if (!response?.data?.data?.[responseGetItemKey]) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = response.data.data?.[responseGetItemKey];

          setResponseData(removeNullValues(data));
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (template && responseData) {
      setTemplate(template);

      setState({
        data: responseData,
      });
    }
  }, [template, responseData]);
};

export default useGetItem;
