import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PromotionType } from "../schemas/types";

export const GET_ITEM_QUERY = print(gql`
  query Promotion($id: String!) {
    Promotion(id: $id) {
      multipleSkusCause
      idCalculatorConfiguration
      name
      description
      beginDateUtc
      endDateUtc
      lastModified
      daysAgoOfPurchases
      isActive
      isArchived
      isFeatured
      disableDeal
      activeDaysOfWeek
      offset
      activateGiftsMultiplier
      newOffset
      maxPricesPerItems
      cumulative
      discountType
      nominalShippingDiscountValue
      absoluteShippingDiscountValue
      nominalDiscountValue
      nominalDiscountType
      maximumUnitPriceDiscount
      percentualDiscountValue
      rebatePercentualDiscountValue
      isAppliedToMostExpensive
      percentualShippingDiscountValue
      percentualTax
      shippingPercentualTax
      percentualDiscountValueList1
      percentualDiscountValueList2
      nominalRewardValue
      percentualRewardValue
      orderStatusRewardValue
      maxNumberOfAffectedItems
      maxNumberOfAffectedItemsGroupKey
      applyToAllShippings
      nominalTax
      origin
      idSeller
      idSellerIsInclusive
      idsSalesChannel
      areSalesChannelIdsExclusive
      marketingTags
      marketingTagsAreNotInclusive
      paymentsMethods {
        id
        name
      }
      storesAreInclusive
      categories {
        id
        name
      }
      brands {
        id
        name
      }
      products {
        id
        name
      }
      skus {
        id
        name
      }
      utmSource
      utmCampaign
      collections1BuyTogether {
        id
        name
      }
      collections2BuyTogether {
        id
        name
      }
      minimumQuantityBuyTogether
      quantityToAffectBuyTogether
      enableBuyTogetherPerSku
      listSku1BuyTogether {
        id
        name
      }
      listSku2BuyTogether {
        id
        name
      }
      coupon
      totalValueFloor
      totalValueCeling
      totalValueIncludeAllItems
      totalValueMode
      collections {
        id
        name
      }
      restrictionsBins
      cardIssuers
      totalValuePurchase
      slasIds
      isSlaSelected
      isFirstBuy
      firstBuyIsProfileOptimistic
      compareListPriceAndPrice
      isDifferentListPriceAndPrice
      zipCodeRanges {
        zipCodeFrom
        zipCodeTo
        inclusive
      }
      itemMaxPrice
      itemMinPrice
      installment
      isMinMaxInstallments
      minInstallment
      maxInstallment
      merchants
      clusterExpressions
      paymentsRules
      giftListTypes
      productsSpecifications
      brandsAreInclusive
      productsAreInclusive
      skusAreInclusive
      categoriesAreInclusive
      affiliates {
        id
        name
      }
      maxUsage
      maxUsagePerClient
      shouldDistributeDiscountAmongMatchedItems
      multipleUsePerClient
      accumulateWithManualPrice
      type
      useNewProgressiveAlgorithm
      recurrencyCrons
      percentualDiscountValueList {
        percent
        quantity
      }
      utmiCampaign
    }
  }
`);

export enum Type {
  regular = "Regular",
  combo = "Compre Junto",
  forThePriceOf = "Leve Mais Por Menos",
  rogressive = "Desconto progressivo",
  buyAndWin = "Compre e Ganhe",
  campaign = "Promoção de campanha",
}

export enum Status {
  active = "Ativo",
  inactive = "Inativo",
}

export interface GetItemGql {
  Promotion: {
    idCalculatorConfiguration: string;
    name: string;
    utmiCampaign: string;
    description: string;
    beginDateUtc: string;
    endDateUtc: string;
    lastModified: string;
    daysAgoOfPurchases: number;
    isActive: boolean;
    isArchived: boolean;
    isFeatured: boolean;
    disableDeal: boolean; // Deprecated
    activeDaysOfWeek: string[];
    offset: number;
    activateGiftsMultiplier: boolean;
    isAppliedToMostExpensive: boolean;
    newOffset: number;
    maxPricesPerItems: string[];
    cumulative: boolean;
    discountType: string;
    nominalShippingDiscountValue: number;
    absoluteShippingDiscountValue: number;
    nominalDiscountValue: number;
    nominalDiscountType: string;
    maximumUnitPriceDiscount: number;
    percentualDiscountValue: number;
    rebatePercentualDiscountValue: number;
    percentualShippingDiscountValue: number;
    percentualTax: number;
    shippingPercentualTax: number;
    percentualDiscountValueList1: number;
    recurrencyCrons: string[];
    percentualDiscountValueList2: number;
    quantitySelectable: number;
    gifts: number[];
    nominalRewardValue: number;
    percentualRewardValue: number;
    orderStatusRewardValue: string;
    maxNumberOfAffectedItems: number;
    maxNumberOfAffectedItemsGroupKey: string;
    applyToAllShippings: boolean;
    nominalTax: number;
    origin: string;
    idSeller: string;
    idSellerIsInclusive: boolean;
    idsSalesChannel: string[];
    areSalesChannelIdsExclusive: boolean;
    marketingTags: string[];
    marketingTagsAreNotInclusive: boolean;
    paymentsMethods: {
      id: string;
      name: string;
    }[];
    storesAreInclusive: boolean; // Deprecated
    categories: {
      id: string;
      name: string;
    }[];
    categoriesAreInclusive: boolean;
    brands: {
      id: string;
      name: string;
    }[];
    brandsAreInclusive: boolean;
    products: {
      id: string;
      name: string;
    }[];
    productsAreInclusive: boolean;
    skus: {
      id: string;
      name: string;
    }[];
    skusAreInclusive: boolean;
    utmSource: string;
    utmCampaign: string;
    collections1BuyTogether: {
      id: string;
      name: string;
    }[];
    collections2BuyTogether: {
      id: string;
      name: string;
    }[];
    minimumQuantityBuyTogether: number;
    quantityToAffectBuyTogether: number;
    enableBuyTogetherPerSku: boolean;
    listSku1BuyTogether: {
      id: string;
      name: string;
    }[];
    listSku2BuyTogether: {
      id: string;
      name: string;
    }[];
    coupon: string[];
    totalValueFloor: number;
    totalValueCeling: number;
    totalValueIncludeAllItems: boolean; // Deprecated
    totalValueMode: string;
    collections: {
      id: string;
      name: string;
    }[];
    collectionsIsInclusive: boolean;
    restrictionsBins: string[];
    cardIssuers: string[];
    totalValuePurchase: number;
    slasIds: string[];
    isSlaSelected: boolean;
    isFirstBuy: boolean;
    firstBuyIsProfileOptimistic: boolean;
    compareListPriceAndPrice: boolean;
    isDifferentListPriceAndPrice: boolean;
    zipCodeRanges: {
      zipCodeFrom: string;
      zipCodeTo: string;
      inclusive: boolean;
    }[];
    itemMaxPrice: number;
    itemMinPrice: number;
    installment: number; // Deprecated
    isMinMaxInstallments: boolean;
    minInstallment: number;
    maxInstallment: number;
    merchants: string[];
    clusterExpressions: string[];
    multipleSkusCause: {
      [skuId: string]: number; // SKU ID to percentage discount mapping
    };
    paymentsRules: string[];
    giftListTypes: string[];
    productsSpecifications: string[];
    affiliates: {
      id: string;
      name: string;
    }[];
    maxUsage: number;
    maxUsagePerClient: number;
    shouldDistributeDiscountAmongMatchedItems: boolean;
    multipleUsePerClient: boolean;
    accumulateWithManualPrice: boolean;
    type: PromotionType;
    useNewProgressiveAlgorithm: boolean;
    percentualDiscountValueList: number[];
  };
}
