export const getKeyNumber = (key: string): string => {
  // Expressão regular para verificar o padrão "1- Nome Grupo de Curso*"
  var padrao1 = /^(\d{1,5})-\s.+$/;

  // Expressão regular para verificar o padrão "Nome_Grupo_de_Curso_1"
  var padrao2 = /^.+_(\d{1,5})$/;

  if (padrao1.test(key)) {
    return key.match(padrao1)[1];
  }
  if (padrao2.test(key)) {
    return key.match(padrao2)[1];
  }

  switch (true) {
    case key.toUpperCase().includes("NOME"):
      return "1";
    case key.toUpperCase().includes("DESCRIÇÃO"):
      return "2";
    case key.toUpperCase().includes("FOTO"):
      return "3";
    case key.toUpperCase().includes("TITULAÇÃO"):
      return "4";
    case key.toUpperCase().includes("EMAIL"):
      return "5";
    case key.toUpperCase().includes("CURRÍCULO"):
    case key.toUpperCase().includes("CURRICULO"):
      return "6";
    case key.toUpperCase().includes("INTERNO"):
      return "7";
    case key.toUpperCase().includes("ATIVO"):
      return "8";
    case key.toUpperCase().includes("COORDENADOR"):
      return "9";
    default:
      return key;
  }
};

const fixString = (value: any) => {
  if (typeof value !== "string") return value;
  return value.replace(/^\s+/, "");
};

const convertKeys = (data: Record<string, string>): Record<string, string> => {
  const responseData: Record<string, string> = {};

  Object.entries(data).map(([key, value]) => (responseData[getKeyNumber(key)] = fixString(value)));

  return responseData;
};

const JsonParseOrUndefined = (data?: string) => {
  try {
    const json = JSON.parse(data);
    return json;
  } catch (error) {
    if (data) console.log({ FailJsonParseOrUndefined: data, error });
    return undefined;
  }
};

export const slugfy = (slug: string): string => {
  return slug.trim().toLocaleLowerCase().replaceAll("/", "-");
};

export const editEachDataRead = (data: Record<string, string>): Record<string, string> => {
  const clone = { ...data };
  // for (const [key, value] of Object.entries(clone)) {
  //   const keyNumber = getKeyNumber(key);
  //   if (keyNumber === "7" || keyNumber === "2" || keyNumber === "31") {
  //     clone[key] = slugfy(value);
  //   }
  // }
  return clone;
};

export const convertObjectToUpsertInput = (
  data: Record<string, string>
): CreateFacultyMemberInput => {
  const convertedData = convertKeys(data);
  return {
    name: convertedData?.["1"],
    description: convertedData?.["2"] || undefined,
    photoUrl: convertedData?.["3"] || undefined,
    active:
      String(convertedData?.["8"]) === "true" || String(convertedData?.["8"]) === "VERDADEIRO"
        ? true
        : undefined,

    title: convertedData?.["4"] || undefined,
    email: convertedData?.["5"]?.replace(/\s+/g, ""),
    lattesUrl: convertedData?.["6"] || undefined,

    idInternMember: String(convertedData?.["7"]) || undefined,
    isCoordinator:
      String(convertedData?.["9"]) === "true" || String(convertedData?.["9"]) === "VERDADEIRO"
        ? true
        : undefined,
  };
};

export type CreateFacultyMemberInput = {
  name: string;
  description?: string;
  photoUrl?: string;
  active?: boolean;

  title?: string;
  email: string;
  lattesUrl?: string;

  idInternMember?: string;
  isCoordinator?: boolean;
};
