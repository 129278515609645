import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query BlogCategories($page: Int, $perPage: Int, $search: String, $active: Boolean) {
    BlogCategories(page: $page, perPage: $perPage, search: $search, active: $active) {
      items {
        id
        name
        slug
        sort
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: number;
  name: string;
  slug: string;
  backgroundColor: string;
  textColor: string;
  sort: number;
}

export interface GetAllGql {
  BlogCategories: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
