import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Holiday($id: Int!) {
    Holiday(id: $id) {
      holidayDate
      id
      title
    }
  }
`);

export interface GetItemGql {
  Holiday: {
    id: number;
    holidayDate: string;
    title: string;
  };
}
