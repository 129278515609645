import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReadCSVCourseComponent from "./ReadCSVCourseComponent";

function ReadCSVCourse() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ReadCSVCourseComponent />
      <Footer />
    </DashboardLayout>
  );
}

export default ReadCSVCourse;
