import DefaultCell from "../../components/DefaultCell";
import LinkCell from "../../components/LinkCell";
import StatusCell from "../../components/StatusCell";

interface ColumnsTableProps {
  path: string;
}

export const columnsTable = ({ path }: ColumnsTableProps) => [
  {
    Header: "id",
    accessor: "id",
    Cell: ({ value }: any) => <LinkCell href={`${path}/faqs/${value}`} value={value} />,
  },
  {
    Header: "Título",
    accessor: "question",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Aluno?",
    accessor: "student",
    Cell: ({ value }: any) => {
      console.log(value);
      if (value) {
        return <DefaultCell value="Sim" />;
      }
      return <DefaultCell value="Não" />;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }: any) => {
      let status;

      if (value === "ACTIVE") {
        status = <StatusCell icon="done" color="success" status="ATIVO" />;
      } else if (value === "DRAFT") {
        status = <StatusCell icon="replay" color="dark" status="RASCUNHO" />;
      } else if (value === "INACTIVE") {
        status = <StatusCell icon="close" color="error" status="DESATIVADO" />;
      } else {
        status = <StatusCell icon="close" color="error" status="DESATIVADO" />;
      }

      return status;
    },
  },
];

// Cell: (pro: { value: string | number | boolean; row: { values: any } }) => JSX.element
// value is the value of acessor in this row
// row.values is all row value
