import { withJsonFormsControlProps } from "@jsonforms/react";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Editor } from "../../../EditorWysiwyg";
import { CustomRendererProps } from "../types";

const EditorControl = ({
  data,
  handleChange,
  path,
  label,
  required,
  uischema,
  description,
  errors,
  enabled,
}: CustomRendererProps<string>) => {
  const styles = {
    position: "relative",
    transform: "none",
    paddingTop: "10px",
  };

  return (
    <>
      <InputLabel sx={styles} data-shrink="true" required={required} variant="filled" size="small">
        {label}
      </InputLabel>
      <Editor
        placeholder={description}
        basic={uischema?.options?.basic}
        value={data}
        onChange={(newValue: string) => handleChange(path, newValue)}
        enabled={enabled}
      />
      {!!errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

export default withJsonFormsControlProps(EditorControl, true);
