export default {
  type: "object",
  properties: {
    databaseName: {
      type: "string",
    },
    idColligate: {
      type: "number",
    },
    idArea: {
      type: "number",
    },
    teachingAreaName: {
      type: "string",
    },
  },
  required: ["databaseName", "idArea", "idColligate", "teachingAreaName"],
};
