import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const Group = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: "1 1 0",
  border: "1px solid rgb(221, 221, 221)",
  gap: 5,
  padding: "2rem",
  h3: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.0023em",
    color: "rgb(0, 0, 0)",
    marginBottom: "1.5rem",
  },
  h4: {
    fontSize: "0.875rem",
    lineHeight: 1.14286,
    letterSpacing: "0.0125em",
    color: "rgb(0, 0, 0)",
    marginBottom: "0.7rem",
  },
  span: {
    lineHeight: "21px",
    color: "#747474",
    fontSize: "0.875rem",
  },
});
