import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateRole($id: Int!, $name: String, $description: String, $permissionsIds: [Int!]) {
    updateRole(id: $id, name: $name, description: $description, permissionsIds: $permissionsIds) {
      id
    }
  }
`);

export interface UpdateGql {
  updateRole: {
    id: number;
  };
}
