import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_CATEGORY_FAQ_QUERY = print(gql`
  query CategoryFaq($categoryFaqId: Int!) {
    CategoryFaq(id: $categoryFaqId) {
      name
      slug
      sort
    }
  }
`);

export interface CategoryFaqGql {
  CategoryFaq: {
    name: string;
    slug: string;
    sort: number;
  };
}
