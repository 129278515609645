import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

interface AlertDialogProps {
  open: boolean;
  onClose: (event: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  children?: React.ReactNode;
  title?: React.ReactNode;
}
export function AlertDialog({ open, onClose, children = "", title = "" }: AlertDialogProps) {
  return (
    <Dialog
      open={open}
      scroll="paper"
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} autoFocus>
          Fechar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

interface AlertLisProps {
  open: AlertDialogProps["open"];
  onClose: AlertDialogProps["onClose"];
  title?: AlertDialogProps["title"];
  children?: React.ReactNode;
  errors?: string[];
  link?: string;
}
export default function AlertList({ errors, link, children, ...props }: AlertLisProps) {
  return (
    <AlertDialog {...props}>
      <>
        {errors?.map((error: string) => <p>{error}</p>) || null}
        {link && <MDButton href={link}>{`Ver ${link}`}</MDButton>}
        {children}
      </>
    </AlertDialog>
  );
}
