export const QUERY_MEDIA_FILES = `query MediaFiles($page: Int, $perPage: Int, $search: String, $fileType: String) {
  mediaFiles(page: $page, perPage: $perPage, search: $search, fileType: $fileType) {
    items {
      id
      name
      path
      size
      type
      uploadedAt
    }
    paginationInfo {
      hasMore
      nextPage {
        page
        perPage
      }
      page
      perPage
      totalPages
    }
  }
}`;
export type VariablesTypeMediaFiles = {
  page?: number | null;
  perPage?: number | null;
  search?: string | null;
  fileType?: string | null; //"IMAGES" | "DOCUMENTS" | "SOUNDS" | "VIDEOS"
};
export type ResponseMediaFiles = {
  mediaFiles: {
    items: [
      {
        id: number;
        name: string;
        path: string;
        type: string;
        size?: number;
        uploadedAt?: string | Date;
      }
    ];
    paginationInfo: {
      hasMore: boolean;
      nextPage?: {
        page: number;
        perPage: number;
      } | null;
      page: number;
      perPage: number;
      totalData: number;
      totalPages: number;
    };
  };
};
