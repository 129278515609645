import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const CalendarModality = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    list: "list:CalendarModality",
    create: "create:CalendarModality",
    read: "read:CalendarModality",
    update: "update:CalendarModality",
    delete: "delete:CalendarModality",
  },
};
