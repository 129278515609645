import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiCourseWithSetData } from "data/queries/ApiCourse";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          active: null,
        }}
        path={`/ecommerce/tags`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Tags.items}
        formatPagination={(data) => data.Tags.paginationInfo}
        title="NOVA TAG"
        ApiSetData={ApiCourseWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => <LinkCell href={`/ecommerce/tags/${value}`} value={value} />,
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
