import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiGetCouseERPWithSetData } from "data/apiGetCourseERP";

function List() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
        }}
        useDefaultFilter={false}
        path="/config/conversao-area-de-ensino"
        query={GET_ALL_QUERY}
        formatRows={(data) => data.TeachingAreaConversions.items}
        formatPagination={(data) => data.TeachingAreaConversions.paginationInfo}
        title="Nova Conversão de Área de Ensino"
        ApiSetData={ApiGetCouseERPWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`/config/conversao-area-de-ensino/${value}`} value={value} />
            ),
          },
          {
            Header: "ID ÁREA",
            accessor: "idArea",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Banco de Dados",
            accessor: "databaseName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Id Coligada",
            accessor: "idColligate",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Nome área de Ensino",
            accessor: "teachingAreaName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
