/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import Tooltip from "@mui/material/Tooltip";
import MDTypography from "components/MDTypography";

// Declaring props types for DefaultCell
interface Props {
  value: string;
  maxLength?: number;
}

function LongTextCell({ value, maxLength = 50 }: Props): JSX.Element {
  function truncateString(str: string, maxLength: number) {
    return str?.length > maxLength ? str.substring(0, maxLength - 3) : str;
  }
  if (value?.length > maxLength) {
    return (
      <Tooltip
        title={
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {value}
          </MDTypography>
        }
        arrow
        placement="right-start"
      >
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {truncateString(value, maxLength)}
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            {"..."}
          </MDTypography>
        </MDTypography>
      </Tooltip>
    );
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value}
    </MDTypography>
  );
}

export default LongTextCell;
