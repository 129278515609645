import { path } from "../../../ecommerce/Price/hooks/types";
import ReadCSVComponent from "../ReadCSVComponent";
import { convertObjectToUpsertInput } from "./hooks/convertObjectToUpsertInput";
import { upsertPrice } from "./hooks/CreatePriceWithAdmissionWay";

function ReadCSVPriceCoursesComponent() {
  async function sendToAPi(data: { [key: string]: string }): Promise<{ id: number }> {
    const input = convertObjectToUpsertInput(data);
    console.log({ input });
    // if (!input?.email) throw Error("Sem Email");
    // if (!input?.name) throw Error("Sem Nome");
    return upsertPrice(input);
  }
  return (
    <>
      <ReadCSVComponent pathToSuccessSendToAPi={path} sendToAPi={sendToAPi} />
    </>
  );
}

export default ReadCSVPriceCoursesComponent;
