import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation Mutation($data: UpdateModalityInput!) {
    updateModality(data: $data) {
      id
      name
      description
      active
    }
  }
`);

export interface UpdateGql {
  updateModality: {
    id: number;
    name: string;
    description: string;
    active: boolean;
  };
}
