import { path } from "./hooks/types";
import ReadCSVFacultyMemberPage from "./ReadCSVFacultyMemberPage";

export const ReadCSVFacultyMembers = {
  Page: ReadCSVFacultyMemberPage,
  path: path,
  Permissions: {
    uploadCsv: "upsert:FacultyMember",
  },
};
