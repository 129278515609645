import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query PopUp($id: Int!) {
    PopUp(id: $id) {
      id
      name
      title
      description
      url
      targetBlank
      redirectUrl
      timeForDisplay
      mediaFileDesktop {
        id
      }
      mediaFileMobile {
        id
      }
      status
      createdAt
      updatedAt
    }
  }
`);

export interface GetItemGql {
  PopUp: {
    id: number;
    name: string;
    title: string;
    description: string;
    url: string;
    targetBlank: boolean;
    redirectUrl: string;
    timeForDisplay: number;
    mediaFileDesktop: {
      id: number;
      path: string;
    };
    mediaFileMobile: {
      id: number;
      path: string;
    };
    status: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
