import { withJsonFormsControlProps } from "@jsonforms/react";
import { Box, Stack } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import ModalMidiaFile from "components/CRPComponents/Forms/components/ModalMediaFileSelect";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Fragment, useCallback } from "react";
import { useState } from "react";
import { CustomRendererProps } from "../types";

const GalleryMediaString = ({
  data,
  handleChange,
  path,
  label,
  required,
  description,
  errors,
  enabled,
}: CustomRendererProps<string>) => {
  const [open, setOpen] = useState(false);
  const [hasError, setError] = useState(false);
  const onSelectImage = ({ url }: { url: string }) => {
    setOpen(false);
    handleChange(path, encodeURI(url));
    setError(false);
  };

  const handleOpen = useCallback(() => setOpen((old) => !old), []);

  return (
    <Fragment>
      <MDBox>
        <Stack gap={1} margin={"10px 0"}>
          <Box
            component={"p"}
            sx={{
              fontFamily: "Roboto",
              fontSize: "0.9rem",
              fontWeight: "400",
              lineHeight: "1.4375em",
              letterSpacing: "0.00938em",
              padding: 0,
              position: "relative",
              color: !!errors ? "#F44335" : "#7b809a",
            }}
          >
            {label}
            {required && " *"}
          </Box>
          {data && !hasError ? (
            <Box
              sx={{
                position: "relative",
                width: "fit-content",
                ".select-img-container": {
                  display: "none",
                },
                "&:hover .select-img-container": {
                  display: enabled ? "flex" : "none",
                },
              }}
            >
              <Box
                onError={() => setError(true)}
                component={"img"}
                sx={{ objectFit: "cover", maxHeight: 300, maxWidth: 300 }}
                src={data}
                alt=""
              />
              <Box
                className="select-img-container"
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  top: 0,
                  left: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backdropFilter: "blur(3px)",
                }}
              >
                <MDButton
                  sx={{
                    height: 30,
                    border: "1px solid #7b809a",
                    width: "fit-content",
                  }}
                  onClick={handleOpen}
                  disabled={!enabled}
                >
                  Alterar
                </MDButton>
              </Box>
            </Box>
          ) : (
            <MDButton
              sx={{
                height: 30,
                border: "1px solid #7b809a",
                width: "fit-content",
              }}
              onClick={handleOpen}
              disabled={!enabled}
            >
              Selecionar
            </MDButton>
          )}
        </Stack>
        {!!description && <FormHelperText>{description}</FormHelperText>}
        {!!errors && <FormHelperText error>{errors}</FormHelperText>}
      </MDBox>
      {enabled && (
        <ModalMidiaFile
          fileType="IMAGES"
          onClickMidia={onSelectImage}
          onClose={handleOpen}
          open={open}
        />
      )}
    </Fragment>
  );
};

export default withJsonFormsControlProps(GalleryMediaString, true);
