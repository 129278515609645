import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { Colligate } from "../types";

export const UPDATE_QUERY = print(gql`
  mutation UpdateEducationalUnitConversion($data: UpdateEducationalUnitConversionInput!) {
    updateEducationalUnitConversion(data: $data) {
      id
      colligate {
        id
        databaseName
        description
        active
        partnerVisibleName
        createdAt
        updatedAt
      }
      idColligate
      databaseName
      idBranch
      educationalUnitVisibleName
      createdAt
      updatedAt
    }
  }
`);

export interface UpdateGql {
  updateEducationalUnitConversion: {
    id: number;
    colligate: Colligate;
    databaseName: string;
    idColligate: number;
    idBranch: string;
    educationalUnitVisibleName: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
