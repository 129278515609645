import Create from "./Create";
import List from "./List";
import Update from "./Update";

export const CourseGroups = {
  List: List,
  Update: Update,
  Create: Create,
  Permissions: {
    create: "create:CourseGroup",
    read: "read:CourseGroup",
    list: "list:CourseGroup",
    update: "update:CourseGroup",
    delete: "delete:CourseGroup",
  },
};
