import { styled } from "@mui/material";

export const Key = styled("span")`
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 700;
`;

export const Group = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const Value = styled("span")`
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 400;
`;

export const Comment = styled("span")`
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 300;
`;

export const ApproveButton = styled("button")`
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2rem;
  padding: 0.375rem 1rem;
  font-size: 0.75rem;
  background-image: initial;
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  color: rgb(76, 175, 80);
  border: 0.0625rem solid rgb(76, 175, 80);
`;

export const ReprovedButton = styled("button")`
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2rem;
  padding: 0.375rem 1rem;
  font-size: 0.75rem;
  background-image: initial;
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  color: #f44335;
  border: 0.0625rem solid #f44335;
`;

export const AnalysisButton = styled("button")`
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2rem;
  padding: 0.375rem 1rem;
  font-size: 0.75rem;
  background-image: initial;
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  color: #7b809a;
  border: 0.0625rem solid #7b809a;
`;
