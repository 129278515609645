import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiRequest, SchemaData, UseUpdateProps } from "./types";
import { removeNullValues } from "utils/removeNullValues";

export default function useUpdate({ queryParam, setState }: UseUpdateProps<SchemaData>) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const {
        name,
        active,
        createdAt,
        updatedAt,
        maxAdmissionWays,
        maxAmountInstallmentsPrices,
        maxPlans,
        urlInscription,
        allowGift,
        allowPurchase,
        ...data
      } = state?.data;

      const response = await ApiRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          data: {
            active,
            maxAdmissionWays: !maxAdmissionWays ? null : maxAdmissionWays,
            maxAmountInstallmentsPrices: !maxAmountInstallmentsPrices
              ? null
              : maxAmountInstallmentsPrices,
            maxPlans: !maxPlans ? null : maxPlans,
            allowPurchase,
            allowGift: !allowPurchase ? false : allowGift,
            urlInscription: !allowPurchase ? urlInscription : null,
            ...data,
            id: queryParam,
          },
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }

      const templateInvestment = response.data.data.updateTemplateInvestment;

      setState({
        data: removeNullValues(templateInvestment),
      });
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
