import { useEffect, useState } from "react";
import { useAuth } from "context/useUserAuth";

import type { userPermissionsProps, Permissions } from "./types";

export const userPermissions = ({
  createPermision,
  updatePermision,
  deletePermision,
}: userPermissionsProps): Permissions => {
  const { user } = useAuth();
  const [permission, setPermission] = useState({
    create: false,
    update: false,
    delete: false,
  });

  useEffect(() => {
    const permissions = user?.permissions.map(({ name }) => name) ?? [];

    setPermission({
      create: createPermision ? permissions.includes(createPermision) : true,
      update: updatePermision ? permissions.includes(updatePermision) : true,
      delete: deletePermision ? permissions.includes(deletePermision) : true,
    });
  }, [user?.permissions, updatePermision, deletePermision, createPermision]);

  return {
    create: permission.create,
    update: permission.update,
    delete: permission.delete,
  };
};
