import useGetItem from "./hooks/useGetItem";
import { useParams } from "react-router-dom";
import useUpdate from "./hooks/useUpdate";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { UpdateGql } from "./queries/update";

function Update() {
  const id = Number(useParams()?.id);

  if (!id) return null;

  return (
    <GenericMethods.Update<SchemaData, UpdateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      redirectPath="/ecommerce/tags"
      hideDeleteButton
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={id}
    />
  );
}

export default Update;
