import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ALL_QUERY = print(gql`
  query Promotions($search: String) {
    Promotions(search: $search) {
      items {
        name
        status
        type
        idCalculatorConfiguration
      }
    }
  }
`);

export const GET_ALL_QUERY_ARCHIVED = print(gql`
  query PromotionsArchived {
    PromotionsArchived {
      items {
        name
        status
        type
        idCalculatorConfiguration
      }
    }
  }
`);

export interface GetAllItem {
  type: string;
  name: string;
  status: string;
  idCalculatorConfiguration: string;
}

export interface GetAllGql {
  Promotions: {
    items: GetAllItem[];
  };
}

export interface GetAllArchivedGql {
  PromotionsArchived: {
    items: GetAllItem[];
  };
}
