import useCreate from "./hooks/useCreate";
import schemaJson from "./schema/SchemaJson.json";
import UISchemaJson from "./schema/UISchema.json";
import { GenericMethods } from "../GenericMethods";
import { Sites } from "data/utils/globals";
import { SchemaData } from "./hooks/types";
import { CreateGql } from "./queries/create";
import { useEffect, useState } from "react";
import useCalendarModalities from "./hooks/useCalendarModalities";
import { ShemaJsonTemplate } from "types/SchemaJson";

function Create() {
  const { modalities } = useCalendarModalities();
  const [template, setTemplate] = useState<ShemaJsonTemplate | null>(null);

  useEffect(() => {
    if (!modalities) return;

    const schemaJsonParsed = JSON.parse(JSON.stringify(schemaJson));

    schemaJsonParsed.properties.calendarModalitiesIds = {
      ...schemaJsonParsed.properties.calendarModalitiesIds,
      items: {
        oneOf: modalities.map(({ id, title }) => ({
          const: id,
          title,
        })),
        type: "number",
      },
    };

    setTemplate({
      schemaJson: JSON.stringify(schemaJsonParsed),
      UISchema: UISchemaJson,
    });
  }, [modalities]);

  if (!template) return null;

  return (
    <GenericMethods.Create<SchemaData, CreateGql>
      siteId={Sites.UNIFTEC.id}
      sitePath={Sites.UNIFTEC.path}
      useCreate={useCreate}
      template={template}
    />
  );
}

export default Create;
