export const MUTATION_UPDATE_FAQ = `mutation Mutation($data: UpdateFaqInput!) {
  updateFaq(data: $data) {
    id
    answer
    question
    student
    status
    sort
    createdAt
    categoryFaq {
      level
      id
      name
      slug
      parentId
    }
  }
}
`;

export type ResponseUpdateScholarShip = {
  updateFaq: {
    id: number;
    question: string;
    answer: string;
    sort?: number;
    linkRegulation?: string;
    student?: boolean;
    status?: string;
    categoryFaq?: {
      id: number;
      name: string;
      slug: string;
      parentId?: number;
      level?: number;
    };
    createdAt?: Date;
    updatedAt?: Date;
  };
};
