export const schemaJsonEdit = {
  type: "object",
  required: ["value", "installments"],
  properties: {
    active: {
      type: "boolean",
    },
    activateIfPossible: {
      type: "boolean",
    },
    value: {
      type: "number",
    },
    valueWithoutDiscout: {
      type: "number",
    },
    installments: {
      type: "integer",
    },
    eCommerceSkuId: {
      type: "integer",
    },
    eCommerceLastUpdate: {
      type: "string",
      format: "date-time",
    },
    planAdmissionWay: {
      type: "object",
      properties: {
        title: {
          type: "string",
        },
      },
    },
    commercialConditionId: {
      type: "integer",
    },
    valueOtherInstallments: {
      type: "number",
      description:
        "Apenas para mais de uma parcela. Deixar em branco caso seja igual a primeira parcela.",
    },
    urlInscription: {
      type: "string",
      description: "Só aplicavel se o template não permitir venda!",
    },
  },
};

export const schemaJson = {
  type: "object",
  required: ["value", "installments", "planAdmissionWay"],
  properties: {
    active: {
      type: "boolean",
    },
    activateIfPossible: {
      type: "boolean",
    },
    value: {
      type: "number",
    },
    valueWithoutDiscout: {
      type: "number",
    },
    installments: {
      type: "integer",
    },
    eCommerceSkuId: {
      type: "integer",
    },
    eCommerceLastUpdate: {
      type: "string",
      format: "date-time",
    },
    noPlanAdmissionWays: {
      type: "boolean",
    },
    noAdmissionWays: {
      type: "boolean",
    },
    createPlanAdmissionWay: {
      type: "boolean",
    },
    createAdmissionWay: {
      type: "boolean",
    },
    planAdmissionWay: {
      type: "object",
      required: ["title", "admissionWay"],
      properties: {
        id: {
          type: "integer",
        },
        title: {
          type: "string",
        },
        tag: {
          type: "string",
        },
        admissionWay: {
          type: "object",
          required: ["name"],
          properties: {
            id: {
              type: "integer",
            },
            name: {
              type: "string",
            },
            content: {
              type: "string",
            },
          },
        },
      },
    },
    commercialConditionId: {
      type: "integer",
    },
    valueOtherInstallments: {
      type: "number",
      description:
        "Apenas para mais de uma parcela. Deixar em branco caso seja igual a primeira parcela.",
    },
    urlInscription: {
      type: "string",
      description: "Só aplicavel se o template não permitir venda!",
    },
  },
};
