import useGetItem from "./hooks/useGetItem";
import useDelete from "./hooks/useDelete";
import useUpdate from "./hooks/useUpdate";
import { GenericMethods } from "layouts/aplicacoes/GenericMethods";
import { SchemaData } from "./hooks/types";
import { UpdateGql } from "./queries/update";
import { ScheduleYourVisit } from ".";
import { UpdateArgsProps } from "./types";

function Update({ slug, path, siteId }: UpdateArgsProps) {
  return (
    <GenericMethods.Update<SchemaData, UpdateGql>
      siteId={siteId}
      sitePath={path}
      redirectPath={`${path}/forms`}
      useDelete={useDelete}
      hideDeleteButton={true}
      useGetItem={useGetItem}
      useUpdate={useUpdate}
      queryParam={slug}
      updatePermission={ScheduleYourVisit.Permissions.update}
      deletePermission={ScheduleYourVisit.Permissions.delete}
    />
  );
}

export default Update;
