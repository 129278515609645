import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const GET_ITEM_QUERY = print(gql`
  query Me {
    Me {
      id
      firstName
      email
      role {
        id
        name
        description
        permissions {
          id
          name
          description
        }
      }
      permissions {
        id
        name
        description
      }
      lastName
      userName
      phone
      cpf
      rg
      birthDate
      residentialPhone
      active
      academicEmail
      googleUid
    }
  }
`);

export interface User {
  id: string;
  firstName: string;
  email: string;
  role: {
    id: number;
    name: string;
    description: string;
    permissions: {
      id: number;
      name: string;
      description: string;
    };
  };
  permissions: Array<{
    id: number;
    name: string;
    description: string;
  }>;
  lastName: string;
  userName: string;
  phone: string;
  cpf: string;
  rg: string;
  birthDate: string;
  residentialPhone: string;
  academicEmail: string;
}

export type GetItemGql = {
  Me: User;
};
