import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import {
  GET_ALL_RESPONSES,
  GetAllResponsesGql,
  GetAllResponsesItem,
  CellProps,
  QueryArgs,
} from "./queries/getAllResponses";
import { ApiAuthWithSetData } from "data/ApiForm";
import { useState, Fragment, useCallback } from "react";
import { Sites } from "data/utils/globals";
import { useNavigate, useParams } from "react-router-dom";
import { ClickAwayListener, Dialog, IconButton, Stack } from "@mui/material";
import { AnalysisButton, Group, Key, Value } from "../BlogComments/styles";
import CloseIcon from "@mui/icons-material/Close";
import useDeleteResponse from "./hooks/useDeleteResponse";
import MDSnackbar, { MDSnackbarProps } from "components/MDSnackbar";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import { UseGetAllResponses } from "./hooks/useGetAllResponses";
function ListEntries() {
  const slug = useParams()?.slug;
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<GetAllResponsesItem>();
  const [snackBarInfo, setSnackBarInfo] = useState<Omit<MDSnackbarProps, "close" | "onClose">>({
    open: false,
    title: "",
  });
  const useResponsesFomr = UseGetAllResponses(slug);

  const { handleDelete } = useDeleteResponse({ setSnackBarInfo });

  const handleOpen = useCallback(() => setOpen((old) => !old), []);

  const handleClick = (data: GetAllResponsesItem) => {
    setOpen(true);
    setContent(data);
  };

  const handleDeleteResponse = async (data: GetAllResponsesItem) => {
    await handleDelete(data.id);
  };

  const handleCloseSnackBar = () => {
    setSnackBarInfo((old) => ({ ...old, open: false }));
  };
  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <GenericTable<GetAllResponsesGql>
          initialVariables={{ formSlug: slug }}
          path={`${Sites.UNIFTEC.path}/forms/:id/entries`}
          query={GET_ALL_RESPONSES}
          formatRows={(data) => data.FormResponses.items}
          formatPagination={(data) => data.FormResponses?.paginationInfo}
          ApiSetData={ApiAuthWithSetData}
          useDefaultFilter={false}
          dowloadSvgJson={useResponsesFomr.responses}
          allFildsSvgh={useResponsesFomr.allFilds}
          columnsTable={[
            {
              Header: "id",
              accessor: "id",
              Cell: ({ value }: any) => <DefaultCell value={value} />,
            },
            {
              Header: "User Id",
              accessor: "userId",
              Cell: ({ value }: any) => <DefaultCell value={value ?? "-"} />,
            },
            {
              Header: "Criado em",
              accessor: "createdAt",
              Cell: ({ value }: any) => (
                <DefaultCell value={new Date(value).toLocaleDateString()} />
              ),
            },
            {
              Header: "Reposta",
              accessor: "",
              Cell: ({ value, cell }: CellProps) => (
                <AnalysisButton onClick={() => handleClick(cell.row.original)}>ver</AnalysisButton>
              ),
            },
            {
              Header: "Excluir",
              accessor: "",
              Cell: ({ value, cell }: CellProps) => (
                <AnalysisButton onClick={() => handleDeleteResponse(cell.row.original)}>
                  Excluir
                </AnalysisButton>
              ),
            },
          ]}
        />
        <Footer />
      </DashboardLayout>
      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "30vw",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleOpen}>
          <Stack>
            <IconButton
              aria-label="close"
              size="large"
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              onClick={handleOpen}
            >
              <CloseIcon />
            </IconButton>
            {content && (
              <Stack
                sx={{
                  padding: "30px",
                  gap: "15px",
                }}
              >
                <Stack>
                  {Object.entries(JSON.parse(content.response)).map(
                    ([key, value]: [string, any], index) => (
                      <Group key={index}>
                        <Key>{key}</Key>
                        <Value>{value}</Value>
                      </Group>
                    )
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </ClickAwayListener>
      </Dialog>
      <MDSnackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        {...snackBarInfo}
        title={snackBarInfo.title}
        close={handleCloseSnackBar}
        onClose={handleCloseSnackBar}
      />
    </Fragment>
  );
}

export default ListEntries;
