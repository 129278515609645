import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { ApiAuthRequest } from "data/ApiAuth";
import { useEffect, useState } from "react";

export const QUERY = print(gql`
  query FindManyRoles {
    findManyRoles {
      id
      name
    }
  }
`);

export interface QueryGql {
  findManyRoles: Array<{
    id: number;
    name: string;
  }>;
}

export default function useRoles() {
  const [roles, setRoles] = useState<null | QueryGql["findManyRoles"]>(null);

  useEffect(() => {
    ApiAuthRequest<QueryGql>({
      query: QUERY,
    })
      .then(({ data }) => setRoles(data.data.findManyRoles))
      .catch((e) => console.log(e));
  }, []);

  return {
    roles,
  };
}
