import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function LayoutContainer({
  hideNavBar = false,
  hideFooter = false,
  children,
}: {
  children: React.ReactNode;
  hideNavBar?: boolean;
  hideFooter?: boolean;
}): JSX.Element {
  return (
    <DashboardLayout>
      {!hideNavBar && <DashboardNavbar />}
      {children}
      {!hideFooter && <Footer />}
    </DashboardLayout>
  );
}

export default LayoutContainer;
