import { useEffect } from "react";
import { removeNullValues } from "utils/removeNullValues";
import schemaJson from "../schema/SchemaJson.json";
import UISchemaJson from "../schema/UISchema.json";
import { GET_ITEM_QUERY, GetItemGql, responseGetItemKey } from "../queries/getItem";
import { ApiRequest, SchemaData, UseGetItemProps } from "./types";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: String(queryParam) },
      })
        .then((response) => {
          if (!response?.data?.data?.[responseGetItemKey]) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          setTemplate({
            schemaJson: schemaJson,
            UISchema: UISchemaJson,
          });

          const data = response.data.data?.[responseGetItemKey];

          setState({
            data: removeNullValues(data),
          });
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);
};

export default useGetItem;
