import React from "react";
import { FC, forwardRef } from "react";
import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";
import MDInputRoot from "components/MDInput/MDInputRoot";

// Declaring props types for MDInput
export interface InputControledFormProps
  extends Omit<
    OutlinedTextFieldProps | StandardTextFieldProps,
    "variant" | "onChange" | "value" | "name"
  > {
  variant?: "standard" | "outlined";
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Required
  name: string; // Required
  formData: { [key: string]: any }; // Required
  errorsForm?: { [key: string]: any };
}

export const InputControledForm: FC<InputControledFormProps> = forwardRef(
  (
    {
      error,
      success,
      disabled,
      helperText,
      formData,
      errorsForm,
      name,
      onChange,
      ...rest
    }: InputControledFormProps,
    ref
  ) => (
    <MDInputRoot
      fullWidth
      name={name}
      value={formData?.[name] || ""}
      error={!!errorsForm?.[name] || error}
      helperText={errorsForm?.[name] || helperText}
      onChange={onChange}
      {...rest}
      ref={ref}
      ownerState={{ error, disabled, success: !errorsForm?.[name] ? success : false }}
    />
  )
);

InputControledForm.defaultProps = {
  // Declaring default props for MDInput
  error: false,
  success: false,
  disabled: false,
  errorsForm: {},
  formData: {},
};
