export default {
  type: "object",
  properties: {
    databaseName: {
      type: "string",
    },
    idCourseType: {
      type: "number",
    },
    idBranch: {
      type: "number",
    },
    idColligate: {
      type: "number",
    },
    categorySlug: {
      type: "string",
    },
  },
  required: ["databaseName", "idCourseType", "idBranch", "idColligate", "categorySlug"],
};
