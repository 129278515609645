import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiCourseWithSetData } from "data/queries/ApiCourse";
import ActiveOrInactiveCell from "components/CRPComponents/Tables/components/ActiveOrInactiveCell";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          active: null,
        }}
        path={`/ecommerce/grupo-de-cursos`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.CourseGroups.items}
        formatPagination={(data) => data.CourseGroups.paginationInfo}
        title="NOVO GRUPO DE CURSO"
        ApiSetData={ApiCourseWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`/ecommerce/grupo-de-cursos/${value}`} value={value} />
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Nível de ensino",
            accessor: "category",
            Cell: ({ value }: any) => <DefaultCell value={value.name} />,
          },
          {
            Header: "Área de ensino",
            accessor: "teachingArea",
            Cell: ({ value }: any) => <DefaultCell value={value.name} />,
          },
          {
            Header: "Parceiro",
            accessor: "partner",
            Cell: ({ value }: any) => <DefaultCell value={value.name} />,
          },
          {
            Header: "Ativo",
            accessor: "active",
            Cell: ({ value }: any) => <ActiveOrInactiveCell value={value} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
